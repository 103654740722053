import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNdiFields, getNdiFields, getProductFields, getTicketSummary, addOrUpdateTicketData, downloadFileTicketData, getClientTransactions, addFoldersProducts, downloadFileInvoiceTicketData, downloadFileJudiciaryInvoice, getNeoDocNotes,downloadFileFideicommis } from '../../actions/NdiFieldsAction'; 
import { addOrUpdateNeoVentes, getNeoVentes, getClientSearch, getClientAddress, getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, getChargeDeskStatus, getClientTransactionsFullInfo, getClientPaymentsFullInfo } from '../../actions/NeoVentesAction'; 
import { Validations } from '../../utils/validations'; 

import Select from 'react-select';
import Autocomplete from '@material-ui/lab/Autocomplete';    
import InputMask from "react-input-mask";
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MuiAlert from '@material-ui/lab/Alert';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormGroup from '@material-ui/core/FormGroup'; 
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Toastr from "../../messages";
import  Loader from "../../loader";

import SaveIcon from '@material-ui/icons/Save';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
import CopyIconButton from "../helpers/CopyIconButton"
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 

import ZAFClient from 'zendesk_app_framework_sdk';
import { RotateRightSharp } from '@material-ui/icons';
import StripePayment from '../../StripePayment';
import NeoMiniPayment from './NeoMiniPayment';
import moment from 'moment';
import { encryptText, decryptText } from '../../appEncryption';
import {listOfRefundAgent} from '../../appConfig';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import NeoRefundPayment from './NeoRefundPayment';
import NeoRefundPaymentList from './NeoRefundPaymentList';
import PublicLinkForm from './PublicLinkForm';
import MoneyIcon from '@material-ui/icons/Money';
import ListIcon from '@material-ui/icons/List';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'; 
//import TaskIcon from '@material-ui/icons/Description';
import TaskIcon from '@material-ui/icons/Link';
//import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
//import PreviewIcon from '@material-ui/icons/Preview'; 
import PreviewIcon from '@material-ui/icons/FindInPage';  
 
 

/////////////////////////////
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';  
import Grid from '@material-ui/core/Grid'; 
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete'; 
import DownloadIcon from '@material-ui/icons/CloudDownload';
//import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import SpellcheckIcon from '@material-ui/icons/Edit';
//import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails'; 
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import  { dateFormat } from "../../appDateFormat";
import history from '../../history'; 

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import SearchIcon from '@material-ui/icons/Search';
//import NdiInterestConflict from '../../../components/neoventes/NdiInterestConflict';
import NdiInterestConflict from './NdiInterestConflict'; 
import NeoFilePreviewPDF from './NeoFilePreviewPDF'; 
import NdiNeoDocSendFileOptions from './NdiNeoDocSendFileOptions'; 
import { genLang } from '../../composables/genLang';
import { ChangeFolderProduct } from './ChangeFolderProduct';
import { Button } from 'reactstrap';

 
 


/*const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));*/

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'long', message:'incorrect value'};
FIELD_VALIDATION['product_field_id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['case_id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['field_data']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_data_raw']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['is_public']={check:false, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['creation_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['user_creation_id']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['user_update_id']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['last_update']={check:false, chkType:'Timestamp', message:'incorrect value'};

//const zafClient = ZAFClient.init(); 
let zafClient = ZAFClient.init(); 

//  State = {
// 	value: [{ [string]: string }],
//   };
  
  const styles = {
	multiValue: (base, state) => {
	  return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
	},
	multiValueLabel: (base, state) => {
	  return state.data.isFixed
		? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
		: base;
	},
	multiValueRemove: (base, state) => {
	  return state.data.isFixed ? { ...base, display: 'none' } : base;
	},
  };
  
  const orderOptions = values => {
	return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
  };

  //const consoleXlog=console.log;
  const consoleXlog = (...args) => {
	//console.log(args); 
  };

  const refundWhiteList=["stripe", "paypal", "Interac", "ChargeDesk", "Flexiti", "NeoCredit"];
  const pmethodList=["", "stripe", "paypal", "Interac", "ChargeDesk", "B2B", "Flexiti", "NeoCredit"];
 
  
class NdiFieldsFormzendesk extends React.Component {  //NdiFieldsFormzendesk extends React.Component {
  validation=new Validations();
  actionMode='new';
  colClassLeft='col-11'; //col-md-10
  colClassRight='col-1'; //col-md-2  
  colClassLeft2='col-12'; //col-md-10
  colClassRight2='col-2'; //col-md-2  
  valueNextStepall=[];
  indexNextStepall=-3;
  nameNextStepall="";
  backTicketId=0;
  loadOtherLang="";
  loadOtherLangtk=2;
  listElementChanges=[];
  currentElementChanged=""; 
  listElementChanges2=[];
  clientOnZendeskSCR=0;  
  neoemail=""; 
  zendlistElementChanges=[];
  zendlistElementChangesval=[]; 
  zendeskChangeLock=false;
  isZendekBusy=false;
  specialOnChangeFunc=null;
  customInvoiceData=null; 
   
  constructor(props) {
    super(props);
    this.state = {
		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
		id:0,
		product_field_id:'', e_product_field_id: false,
		case_id:'', e_case_id: false,
		field_data:'', e_field_data: false,
		field_data_raw:'', e_field_data_raw: false,
		is_public:'', e_is_public: false,
		creation_date:'', e_creation_date: false,
		user_creation_id:'', e_user_creation_id: false,
		user_update_id:'', e_user_update_id: false,
		last_update:'', e_last_update: false,

		dynamicForm:[], elementForms:[], elementsData:[], elementFormsErrors:[], dynamicForm2:{ }, 
		dynamicFormData:[], ticket:{}, realticket_id:'', //191807 197504 52583 191807 
		selectedFiles: [], openProductFolder:false, labelnodel:-3, 

		showProdMulti:0, productsList:{"products": []}, openPListAsOnTheSite: false, 
		productId:0, transactionId:0, 
		listOfSuggestion:[], listOfTransactions:[], 
		valueClientSearch:'', 
		inputValueClientSearch:'',
		selectedIdSuggestion:'',
		clientInfo:{ }, 
		clientAddress:{ },
		existedClientRO: false, errorTransacMessage: "", emailDoesNotExist: 0, openPossibleListOfClient: false, 
		product_id:"", client_id:"", transaction_id:"", 
		//valuegost: orderOptions([colourOptions[0], colourOptions[1], colourOptions[3]]), 
		valuegost:[], dynamicFormAllData:{}, fullticket:{}, 
		valueNextStep: [], valueNextStepall:[], mood:0, stackMenuIsOpen:false,  
		clientProdTransInfo:{}, valueNextStepgost: [], isClientLead:false, client_type:0, 
		openFileDelete:false,  openFileLabelUpdate:false,  tmpeventDataset:{}, 
		fileLabelDescription:"",  fileLabelDescription2:"",    
		listElementChanges:[], currentElementChanged:"", changeCounter:0, 
		isTherePALawyer:false, valueTab:0, domainInLaw:"", neoid:0, listOfTicketsPayments:[], 
		natureDosDomainLaw:null, 
		movingIndexFrom:-1, movingIndexTo:-1, zendchangeCounter:0,  
		stripe_id:'',  dossiernextstep:'', 
		refund_invoice:0, refund_payment:0,  refund_open:false, refundList_open:false, 
		openJudiciaryInvoice:false, invoiceDescriptions:[], province:"",  
		openNeoSearch:false, neoSearchFullName:'', 
		openPublicLinkForm:false, fromHistory:"0", publicLinkFormOrFile:1, publicFormOrFile:{}, 
		salesCloseId: "0", salesCloseInvId:"0",  
		allFieldsNotes:null, allFieldsNotesgo:0,
		customaddress:"", customcompany:"", openCustomInvoice:false,
		provinceInfotax:"QC", 
		openFilePreviewPDF:false, dataXFilePreview:{}, 
		openXNeoDocSendFile:false,    
	}


	this.onChangeStatutNextStep= this.onChangeStatutNextStep.bind(this);
	this.onChangeTest= this.onChangeTest.bind(this);
	this.dynamicFormRefs=[];
	this.elementFormsData=[]; //dataHolder
	this.dynamicStackFormRefs=[];
	//const locurl=window.location.href;
	const locurl=(""+window.location.href).replace("#", "");
	const patt=/([0-9]+)(\/edit)$/;
		
	const patt2=/([0-9]+)(\/ticket)(.*)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else if(patt2.test(locurl)){ 
		this.actionMode='ticket';  
		const regex = /([0-9]+)(\/ticket)(.*)$/g; ///[A-Z]/g;  
		const foundText = locurl.match(regex);
		const cleanId=(""+foundText[0]).replace("/invoice", "");
		this.backTicketId=parseInt(cleanId); 
		consoleXlog("before neeeeeeeeeeoemail.... "); 
		if((""+locurl).indexOf("neoemail") >-1){  
			//const query = new URLSearchParams(this.props.location.search);
			//const neoemail = query.get('token');  
			const neoemail = this.getQueryVariable("neoemail");
			if(neoemail!==false){ this.neoemail=neoemail; }   
			consoleXlog("neeeeeeeeeeoemail: "+neoemail+"  vs "+this.neoemail); 
		}
		//this.ticketInUse=''+cleanId;
		//consoleXlog(foundText, cleanId, this.backInvoiceId);
	}else{ 
		this.actionMode='new'; 
  	}
	
	
	////consoleXlog(' action mode '+this.actionMode);
	consoleXlog(' action locurl '+locurl);

	consoleXlog("qqqqqqqqqqqqqqqqqqqqqqqqq -0000000000 "); 
  }

  productsList={"products": []}; // this.state.productsList; // productList();
  natureDosDomainLaw=null; 
  
  
  componentDidMount() { 
	this.fetchAllData();
	const profile = decryptText(localStorage.profile);
	const parsed = JSON.parse(profile);
	this.isElawyer = parsed?.user_type === "elawyer";
  }

  fetchAllData = () => {
	let stepRunned=0; 
	let currentTabMain=this.props.currentTab;
	if(currentTabMain===undefined){
		currentTabMain="tab1";		
	}

	if(this.props.clientAddress!==undefined){ 
		this.setState({ ["clientAddress"]: this.props.clientAddress });	
	}

	if(this.props.currentTabTicket!==undefined){
		this.backTicketId=parseInt(this.props.currentTabTicket);
	}

	if(this.actionMode==='ticket'){  
		if(this.backTicketId >0){
			this.setState({ ["realticket_id"]: this.backTicketId });
		}	
	}
	const locurl=window.location.href; 
	const ticket_id = this.state.realticket_id; // 197504
	if(ticket_id!=="" || this.backTicketId >0){ 
		const objToSend={id:0, ticketId:ticket_id}; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
		if(this.backTicketId >0){
			objToSend.ticketId=this.backTicketId; 
		}
		stepRunned++;
		this.loadProductsFields(objToSend);	
	}

	if(zafClient!==undefined && zafClient!==false){ 
		const this00=this;
		zafClient.invoke('resize', { width: '100%', height: '600px' });
		this00.clientOnZendeskSCR=10; 
		zafClient.on('app.registered', function(e) {
			this00.clientOnZendeskSCR=11; 
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				stepRunned++;
				const objToSend={id:0, ticketId:response.ticket.id};
				if(this00.backTicketId <1){
					this00.backTicketId=parseInt(response.ticket.id);  
				}
				this00.loadProductsFields(objToSend);
			}, (response) => {
			});
		});

		zafClient.on('app.activated', function(e) {
			this00.clientOnZendeskSCR=12; 
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				stepRunned++;
				const objToSend={id:0, ticketId:response.ticket.id};
				if(this00.backTicketId <1){
					this00.backTicketId=parseInt(response.ticket.id);  
				}
				this00.loadProductsFields(objToSend);
				// }
			}, (response) => {
			});
		});

		if(stepRunned===0){
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject;
				this00.clientOnZendeskSCR=13; 
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				stepRunned++;
				const objToSend={id:0, ticketId:response.ticket.id};
				if(this00.backTicketId <1){
					this00.backTicketId=parseInt(response.ticket.id);  
				}
				this00.loadProductsFields(objToSend);
			}, (response) => {
			});
		}

		zafClient.on('*.changed', function(e) {
			const propertyName = e.propertyName;   // "ticket.assignee.user.email"
			const newValue = e.newValue;           // "sally.agent.1@example.org"
			if(this00.zendeskChangeLock!==true){
				this00.setZendekChangeTacker (propertyName, newValue);
			}else{
			}
		});

    	zafClient.on('ticket.submit.done', function(e) {
			if(this00.isZendekBusy){
				return false;
			}
      		this00.zendeskChangeLock=true; 
      		this00.saveUpdateTicketDataZendeskToNeo(e);
		});
	}
  }

  componentWillUnmount(){
	  //this.handleSaveAll(null);
  }

  getQueryVariable(variable)
  {
          const query = window.location.search.substring(1);
          //consoleXlog(query)//"app=article&act=news_content&aid=160990"
          const vars = query.split("&");
          //consoleXlog(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
          for (var i=0;i<vars.length;i++) {
            const pair = vars[i].split("=");
            //consoleXlog(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if(pair[0] == variable){return pair[1];}
          }
          return(false);
  }

  handleTestData = (event) => {
	consoleXlog("teeeeeeeeeeest handleTestData client zaf");
	const this00=this;
	const realticket_id=this.state.realticket_id;
	this.saveUpdateTicketData(event);  	 
  }

  saveUpdateTicketData = (event) => {
	consoleXlog("teeeeeeeeeeest handleTestData client zaf saveUpdateTicketData ");
	const this00=this;
	const realticket_id=this.state.realticket_id;
	const { t } = this.props;
	const idNeoId=this.state.neoid; // 0;
	if(zafClient!==undefined && zafClient!==false){ 
		//zafClient.invoke('resize', { width: '100%', height: '600px' });
		//this.setState({ ["isLoaderOpen"]: true});  

		this.setState({ ["isLoaderOpen"]: true});  
		//this.showToast("On va comparer les données de zendesk de gauche à droite.", "warning");  

		zafClient.get('ticketFields').then(function(data) {
      
			// Get all fields to query against
			let fields = data["ticketFields"];
			let CustomFieldName; let fieldsNeeded=[];
			const avoidList=["collaborator", ""];
			 
			// Iterate through each field
			for (let field in fields) { 
				
				//fieldsNeeded.push(fields[field].name);
				if((fields[field].name).indexOf("custom_field_")> -1){
					fieldsNeeded.push("ticket.customField:"+fields[field].name);
				}else{
					fieldsNeeded.push("ticket."+fields[field].name);
				}
				consoleXlog("FIELD ", fields[field].name, fields[field].label);
			}

			 consoleXlog("FIELDs ", fieldsNeeded);
			 consoleXlog("DATA FOR FIELDs ");
			zafClient.get(fieldsNeeded).then(function(data){
				
				// const fieldValue= Object.keys(data)[1];
				// consoleXlog( data[fieldValue]);
				let myNdiTicketDataLeftList=[];
				 
				// consoleXlog(data); 
				// consoleXlog("single block data ");  
				//const datax=JSON.parse(JSON.stringify(data));
				for (let fieldOne of fieldsNeeded){
					// const fieldValue= Object.keys(fieldData)[1];
					// consoleXlog( data[fieldValue]);
					let trackStrOrDates= data[fieldOne]; 
					//Wed Dec 09 2020 19:00:00 GMT-0500 (heure normale de l’Est nord-américain)
					//consoleXlog("=:==>", fieldOne, data[fieldOne]);
					if(data[fieldOne]!==null && data[fieldOne]!==undefined){
						const idDBField=null; const zendeskField=fieldOne; 
						let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
						let isRequired=false; 
						let fieldDataval=""; let isError=false;  let fieldDataRawval="";
							
						if(trackStrOrDates!==null && (""+trackStrOrDates).indexOf(" (heure normale de l’Est nord-américain)")> -1){
							trackStrOrDates=trackStrOrDates.replace(" (heure normale de l’Est nord-américain)", "");
							//trackStrOrDates=Date.parse(trackStrOrDates).toISOString();
							consoleXlog("WE USE THIS ---BF- ", trackStrOrDates);
							let event = new Date(trackStrOrDates);
							//consoleXlog("toString", event.toString()); consoleXlog("toISOString", event.toISOString());
							const isod=(event.toISOString()).split("T");
							consoleXlog("##isod",  isod[0]);
							event = new Date(isod[0]);
							//consoleXlog("##toString", event.toString()); consoleXlog("##toISOString", event.toISOString());
							//consoleXlog(event.toLocaleString('en-US', { timeZone: 'UTC' }));
							//consoleXlog(event.format('yyyy-mm-dd HH:MM:ss'));
							//consoleXlog(dateFormat(event, 'yyyy-mm-dd HH:MM:ss', false)); //dateFormat(this, mask, utc);
							//data[fieldOne]= dateFormat(event, 'yyyy-mm-dd HH:MM:ss', false);
							
							//data[fieldOne]= dateFormat(event, 'yyyy-mm-dd', false);
							data[fieldOne]= isod[0];
							//consoleXlog("WE USE THIS D ", dateFormat(event, 'yyyy-mm-dd', false));
							 
						}
						consoleXlog("=:===>", fieldOne, data[fieldOne], trackStrOrDates);
						 
						const blockDataForField=this00.getFullObjectField (idDBField, zendeskField);
						if(blockDataForField!==null){
							const fieldBlock=blockDataForField.fieldBlock;
							const productField=blockDataForField.productFields;
							const FieldData=blockDataForField.FieldData;
							const ticketDataLeft=FieldData.ticketDataLeft;
							let fieldDataval=""; let isError=false; let field_data_raw="";
							const indexField=FieldData.index;
							let elemVal=""; let field_data_rawx="";
							const fieldname=fieldBlock.name;
							if(productField.id >0){ productfieldid=productField.id; }
							if(productField.field_id >0){ fieldid=productField.field_id; }
							if(productField.required >0){ isRequired=true; isError=true;} 
							if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
							consoleXlog("x=:==>", fieldid, fieldname);
							if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
								tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
								// if(fieldDataval!==""){
								// 	isError=false;
								// }
								consoleXlog("y=:==>", fieldid, fieldname, ticketDataLeft.id);
								let isEmpty=true;
								if(ticketDataLeft.field_data_raw!==null && ticketDataLeft.field_data_raw!==""){
									field_data_rawx=ticketDataLeft.field_data_raw;  isEmpty=false;
								}
								if(fieldDataval!==""){
									isError=false;  isEmpty=false;
									elemVal=fieldDataval;
								}
								if(isEmpty){ 
									if(data[fieldOne].length> 245){
										field_data_rawx=[data[fieldOne]];
									}else{
										elemVal=data[fieldOne];
									}
								const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
									field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId, 
									fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 
									myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);	
								}
							}else{
								//for insert
								consoleXlog("z=:==>", fieldid, fieldname);
								if(data[fieldOne].length> 245){
									field_data_rawx=[data[fieldOne]];
								}else{
									elemVal=data[fieldOne];
								}
								const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
									field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId, 
									fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 
									myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);	
							} 
							 
						}
					}
				}
				
				if(myNdiTicketDataLeftList.length> 0){
					consoleXlog("DATAO SERVER"); consoleXlog(myNdiTicketDataLeftList);
					//const this00=this; 
					//this00.handleResetStatesXLoad(event); //********************HEADACH CENTER 
					const clientOnZendeskSCR=this00.clientOnZendeskSCR; 
					const myNdiTicketDataLeft={massFZENDESK:1, ZENDESKIgnoreAPI:1, massFields:myNdiTicketDataLeftList, realticket_id:realticket_id, clientOnZendeskSCR:clientOnZendeskSCR};
		
		this00.setState({ ["isLoaderOpen"]: true});  
		//this00.showToast("Maintenant on va actualiser les données de zendesk de gauche à droite.", "warning");  
	Promise.resolve(this00.props.addOrUpdateTicketData(myNdiTicketDataLeft))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		//const resp=response.NdiFields; 
		//const xid=parseInt(tickeId);  
		//this.showToast("Félicitations!!! On a finit de sauvegarder ou actualiser massivement avec succès!", "success"); 
		const ticket_id = this00.state.realticket_id; // 197504
		consoleXlog("check solving refresh... ", ticket_id, this00.backTicketId); 
		//forcerefresh here
		//const ticket_id = this.state.realticket_id; // 197504
		if(ticket_id!=="" || this00.backTicketId >0){ 
			const objToSend={id:0, ticketId:ticket_id}; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
			if(this00.backTicketId >0){
				objToSend.ticketId=this00.backTicketId; 
			}
			consoleXlog("solving refresh weeeeeeeeeeeeeeeeeeeere goooing to server ayayay ", objToSend); 
			   
			////this00.loadProductsFields(objToSend);	//this or load right here
			//this00.loadProductsFieldsOnce(objToSend);
			// data[fieldOne]= dateFormat(event, 'yyyy-mm-dd', false);	
			
		}


		/*
		const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
		const isClientId=clientProdTransInfo.client.id; NdiFields NdiFields
		const isClientLead=isClientId >0?false:true;
		const client_type= response.NdiFieldsOne.data.client_type;
		//consoleXlog("..HOHOHO response from server .... ");  //consoleXlog(response.NdiFieldsOne.data);
		
		this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
		//this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead, ["client_type"]:client_type }); 
		this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data,  }); 		
		//consoleXlog("HOHOHO just assinged to dynamicFormAllData .... "); 	
		this.setState({ ["isLoaderOpen"]: false}); //load&close 
		*/

		this00.handleDynamicFormGenerator(response.NdiFields);
		this00.setState({ ["isLoaderOpen"]: false, ["dynamicFormAllData"]: response.NdiFields, });  //response.NdiFields

		consoleXlog("QQQQQQQQQQ  REDUCE dynamicFormAllData .... ", response); 	


		return response;
	}).catch(function(error){ 
		const errObj=error;
		this00.setState({ ["isLoaderOpen"]: false});  
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
		  errMessage +="\n There was some error from the expected data from the server. Please try again later.";
		}
		else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			errMessage ="SERVER ERROR INTERNAL or file not found.";
		  }
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		
		//this00.showToastError(errMessage);
		//alert(errMessage); //error
	  });
				}else{
					//this00.showToast(t("zqwNFFtxt12_x"), "warning"); 
					this00.setState({ ["isLoaderOpen"]: false});   
				}
 
				
			});
 
	  
	  });
		
		 
	}
  }

  setZendekChangeTacker = (fieldName, fieldValue) =>{
    let fieldNamex=fieldName; 
    if(!this.zendlistElementChanges.includes(fieldNamex)){
      this.zendlistElementChanges.push(fieldNamex);	
      this.zendlistElementChangesval.push(fieldValue);	
      //this.listElementChanges2.push(indexField);	
      this.setState({ ["zendchangeCounter"]: this.zendlistElementChanges.length});  
    }else{
      const index=this.zendlistElementChanges.indexOf(fieldNamex);
      if(index>-1){
        this.zendlistElementChangesval[index]=fieldValue;	
      } 
    }
    consoleXlog("ZENDEK-MAP-CHANGED ",this.zendlistElementChanges, this.zendlistElementChangesval);
 
  
  }

  
  saveUpdateTicketDataZendeskToNeo = (event) => {
    //consoleXlog("ZENDESK to NEO -- teeeeeeeeeeest handleTestData client zaf saveUpdateTicketData ");
    const this00=this;
    const realticket_id=this.state.realticket_id;
    const { t } = this.props;
    const idNeoId=this.state.neoid; // 0;
    const ofilist=this00.zendlistElementChanges;
    if(zafClient!==undefined && zafClient!==false){ 
      //zafClient.invoke('resize', { width: '100%', height: '600px' });
      //this.setState({ ["isLoaderOpen"]: true});  
		this.isZendekBusy=true;		
      this.setState({ ["isLoaderOpen"]: true});  
      //this.showToast("On va comparer les données de zendesk de gauche à droite.", "warning");  
  
      zafClient.get('ticketFields').then(function(data) {
        
        // Get all fields to query against
        let fields = data["ticketFields"];
        let CustomFieldName; let fieldsNeeded=[];
        const avoidList=["collaborator", ""];
         
        // Iterate through each field
        for (let field in fields) { 
          const klx="ticket."+fields[field].name;
          if(!ofilist.includes(klx)){
            continue;   
          }
          //fieldsNeeded.push(fields[field].name); ticket.custom_field_360019452754 vs custom_field_360003324874 
          if((fields[field].name).indexOf("custom_field_")> -1){
            fieldsNeeded.push("ticket.customField:"+fields[field].name);
          }else{
            fieldsNeeded.push("ticket."+fields[field].name);
          }
          consoleXlog("zzFIELD ", field, fields[field].name, fields[field].label);
        }

        consoleXlog("OFILIST ", ofilist);  
         consoleXlog("xFIELDs ", fieldsNeeded);
         consoleXlog("DATA FOR FIELDs ");
         //return false;

        zafClient.get(fieldsNeeded).then(function(data){
          
          // const fieldValue= Object.keys(data)[1];
          // consoleXlog( data[fieldValue]);
          let myNdiTicketDataLeftList=[];
           
          // consoleXlog(data); 
           //consoleXlog("single block data ");  
          //const datax=JSON.parse(JSON.stringify(data));
          for (let fieldOne of fieldsNeeded){
            // const fieldValue= Object.keys(fieldData)[1];
            // consoleXlog( data[fieldValue]);
            // if(!this.zendlistElementChanges.includes(fieldNamex)){
            //   continue; 
            // }
            let trackStrOrDates= data[fieldOne]; 
            //Wed Dec 09 2020 19:00:00 GMT-0500 (heure normale de l’Est nord-américain)
            consoleXlog("qqq=:==>", fieldOne, data[fieldOne]);
            if(data[fieldOne]!==null && data[fieldOne]!==undefined){
              const idDBField=null; const zendeskField=fieldOne; 
              let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
              let isRequired=false; 
              let fieldDataval=""; let isError=false;  let fieldDataRawval="";
                
              if(trackStrOrDates!==null && (""+trackStrOrDates).indexOf(" (heure normale de l’Est nord-américain)")> -1){
                trackStrOrDates=trackStrOrDates.replace(" (heure normale de l’Est nord-américain)", "");
                //trackStrOrDates=Date.parse(trackStrOrDates).toISOString();
                consoleXlog("WE USE THIS ---BF- ", trackStrOrDates);
                let event = new Date(trackStrOrDates);
                //consoleXlog("toString", event.toString()); consoleXlog("toISOString", event.toISOString());
                const isod=(event.toISOString()).split("T");
                consoleXlog("##isod",  isod[0]);
                event = new Date(isod[0]);
                //consoleXlog("##toString", event.toString()); consoleXlog("##toISOString", event.toISOString());
                //consoleXlog(event.toLocaleString('en-US', { timeZone: 'UTC' }));
                //consoleXlog(event.format('yyyy-mm-dd HH:MM:ss'));
                //consoleXlog(dateFormat(event, 'yyyy-mm-dd HH:MM:ss', false)); //dateFormat(this, mask, utc);
                //data[fieldOne]= dateFormat(event, 'yyyy-mm-dd HH:MM:ss', false);
                
                //data[fieldOne]= dateFormat(event, 'yyyy-mm-dd', false);
                data[fieldOne]= isod[0];
                //consoleXlog("WE USE THIS D ", dateFormat(event, 'yyyy-mm-dd', false));
                 
              }

              consoleXlog("TRUUUUUUUUUUUUUUUUUUTH ", idDBField, zendeskField);
              consoleXlog("=:===>", fieldOne, data[fieldOne], trackStrOrDates);
               
              const blockDataForField=this00.getFullObjectField (idDBField, zendeskField);
              consoleXlog("TRUUUUUUUUUUUUUUUUUUTH2 ", blockDataForField);
              if(blockDataForField!==null){
                const fieldBlock=blockDataForField.fieldBlock;
                const productField=blockDataForField.productFields;
                const FieldData=blockDataForField.FieldData;
                const ticketDataLeft=FieldData.ticketDataLeft;
                let fieldDataval=""; let isError=false; let field_data_raw="";
                const indexField=FieldData.index;
                let elemVal=""; let field_data_rawx="";
                const fieldname=fieldBlock.name;
                if(productField.id >0){ productfieldid=productField.id; }
                if(productField.field_id >0){ fieldid=productField.field_id; }
                if(productField.required >0){ isRequired=true; isError=true;} 
                if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
                consoleXlog("test x=:==>", fieldid, fieldname);
                if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
                  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
                  // if(fieldDataval!==""){
                  // 	isError=false;
                  // }
                  consoleXlog("goUpdate...y=:==>", fieldid, fieldname, ticketDataLeft.id);
				  consoleXlog("OOOOOOOO2 goUpdate...y=:==>", fieldid, ticketDataLeft.field_data, ticketDataLeft.field_data_raw, "VS", data[fieldOne]);
                  let isEmpty=true;
                  if(ticketDataLeft.field_data_raw!==null && ticketDataLeft.field_data_raw!==""){
                    field_data_rawx=ticketDataLeft.field_data_raw;  isEmpty=false;
					const datRaw=JSON.parse(ticketDataLeft.field_data_raw);  
                    if("["+data[fieldOne]+"]"===ticketDataLeft.field_data_raw || data[fieldOne]===datRaw[0]){
                      consoleXlog("field_data_raw is EQUAL nooUpdate...y=:==>", fieldid, fieldname, ticketDataLeft.id);
                      continue;
                    }
                  }
                  if(fieldDataval!=="" && fieldDataval===data[fieldOne]){
                    consoleXlog("field_data is EQUAL nooUpdate2...y=:==>", fieldid, fieldname, ticketDataLeft.id);
                    continue;
                  }
                  if(fieldDataval!==""){
                    isError=false;  isEmpty=false;
                    elemVal=fieldDataval;
                  }
                  //if(isEmpty){ 
                    if(data[fieldOne].length> 245){
                      field_data_rawx=[data[fieldOne]];
                    }else{
                      elemVal=data[fieldOne];
                    }
					if(fieldBlock.field_type==="textarea"){
						field_data_rawx=[data[fieldOne]];
					}

                  const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
                    field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId, 
                    fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 
                    myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);	
                  //}
                }else{
                  //for insert
                  consoleXlog("z=:==>", fieldid, fieldname);
                  if(data[fieldOne].length> 245){
                    field_data_rawx=[data[fieldOne]];
                  }else{
                    elemVal=data[fieldOne];
                  }
                  const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
                    field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId, 
                    fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 
                    myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);	
                } 
                 
              }
            }
          }
          
          if(myNdiTicketDataLeftList.length> 0){
            consoleXlog("zendesk-to-neo DATAO SERVER"); consoleXlog(myNdiTicketDataLeftList);
            //const this00=this; 
            //this00.handleResetStatesXLoad(event); //********************HEADACH CENTER 
			const clientOnZendeskSCR=this00.clientOnZendeskSCR; 
            const myNdiTicketDataLeft={massFZENDESK:1, ZENDESKIgnoreAPI:1, massFields:myNdiTicketDataLeftList, realticket_id:realticket_id, clientOnZendeskSCR:clientOnZendeskSCR};
      
      this00.setState({ ["isLoaderOpen"]: true}); 
      //const ticketSummary=this00.state.ticketSummary; 
      //this00.showToast("Maintenant on va actualiser les données de zendesk de gauche à droite.", "warning");  
    Promise.resolve(this00.props.addOrUpdateTicketData(myNdiTicketDataLeft))
    .then((response)=>{
      //this.setState(response.NdiFieldsOne);
      //const resp=response.NdiFields;       
      consoleXlog("check solving refresh... ", );       
  
      //this00.handleDynamicFormGenerator(response.NdiFields);
      //this00.setState({ ["isLoaderOpen"]: false, ["dynamicFormAllData"]: response.NdiFields, });  //response.NdiFields
      //ticketSummary.zendeskduplex=response.NdiFields;
      //this00.setState({ ["isLoaderOpen"]: false, ["ticketSummary"]: ticketSummary, });
	  
	  //this00.setState({["isLoaderOpen"]: false, ["dynamicFormAllData"]: response.NdiFields, });
	  this00.setState({["dynamicFormAllData"]: response.NdiFields, });
	   
      this00.zendeskChangeLock=false; 
      this00.zendlistElementChanges=[];
      this00.zendlistElementChangesval=[]; 
      consoleXlog("000QQQQQQQQQQ  REDUCE dynamicFormAllData .... ", response); 
	  //this00.addDataToX(response.NdiFields.optionsData);	
  
	  //this00.isZendekBusy=true;		
	  setTimeout(() => {
		this00.isZendekBusy=true;	
		this00.setState({["isLoaderOpen"]: false, });	
		consoleXlog("lasttaction release isZendekBusy"); 
	  }, 5000); 
		
      return response;
    }).catch(function(error){ 
      const errObj=error;
      this00.setState({ ["isLoaderOpen"]: false});  
	  this00.isZendekBusy=false;		
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n There was some error from the expected data from the server. Please try again later.";
      }
      else if(errObj.status===500){
        //errMessage +="\n There was some error from the expected data from the server. Please try again later.";
        //errMessage +="\n 9999999 ".errObj.data.error.description;
        //errMessage +="\n 9999999 ".errObj.error.description;
        errMessage ="SERVER ERROR INTERNAL or file not found.";
        }
      //this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
      
      //this00.showToastError(errMessage);
      //alert(errMessage); //error
      });
          }else{
            //this00.showToast(t("zqwNFFtxt12_x"), "warning"); 
            this00.setState({ ["isLoaderOpen"]: false}); 
            this00.zendeskChangeLock=false;  
			this00.isZendekBusy=false;		 
          }
   
          
        });
   
      
      });
      
       
    }
  }

  saveUpdateTicketDataToZendesk = (eventObj) => {
	consoleXlog("teeeeeeeeeeest handleTestData client zaf");
	const this00=this;
	const { t } = this.props;
	const realticket_id=this.state.realticket_id;
	let idDBField=null; let zendeskField=null; 
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false; 
	let fieldDataval=""; let isError=false;  let fieldDataRawval="";
	if(zafClient!==undefined && zafClient!==false){ 
		//zafClient.invoke('resize', { width: '100%', height: '600px' });
		idDBField=eventObj.fieldId; 
		let fieldDatavalNeo=eventObj.dataval; 
		let field_data_rawNeo=eventObj.data_raw; 
		// Iterate through each field
		// for (let field in fields) {  
		// 	//fieldsNeeded.push(fields[field].name);
		// 	if((fields[field].name).indexOf("custom_field_")> -1){
		// 		fieldsNeeded.push("ticket.customField:"+fields[field].name);
		// 	}else{
		// 		fieldsNeeded.push("ticket."+fields[field].name);
		// 	}
		// 	consoleXlog("FIELD ", fields[field].name, fields[field].label);
		// }

 

		// if(trackStrOrDates!==null && (""+trackStrOrDates).indexOf(" (heure normale de l’Est nord-américain)")> -1){
		// 	trackStrOrDates=trackStrOrDates.replace(" (heure normale de l’Est nord-américain)", "");
		// 	//trackStrOrDates=Date.parse(trackStrOrDates).toISOString();
		// 	const event = new Date(trackStrOrDates);
		// 	/*consoleXlog(event.toString()); consoleXlog(event.toISOString());
		// 	consoleXlog(event.toLocaleString('en-US', { timeZone: 'UTC' }));
		// 	//consoleXlog(event.format('yyyy-mm-dd HH:MM:ss'));*/
		// 	consoleXlog(dateFormat('yyyy-mm-dd HH:MM:ss'));					
		// }
		consoleXlog("INSIDE =:==>", idDBField, fieldDatavalNeo, field_data_rawNeo);
			
		const blockDataForField=this00.getFullObjectField (idDBField, zendeskField);
		if(blockDataForField!==null){
			const fieldBlock=blockDataForField.fieldBlock;
			const productField=blockDataForField.productFields;
			const FieldData=blockDataForField.FieldData;
			const ticketDataLeft=FieldData.ticketDataLeft;
			let fieldDataval=""; let isError=false; let field_data_raw="";
			const indexField=FieldData.index; let isRequired=true;   
			let elemVal=""; let field_data_rawx="";
			const fieldname=fieldBlock.name;
			const ftype=fieldBlock.field_type;
			let zendeskFieldPrev=fieldBlock.zendesk_id;
			if(zendeskFieldPrev!==null){ 
				zendeskFieldPrev=zendeskFieldPrev.replace("=>", ":")
				if(zendeskFieldPrev!==null && (""+zendeskFieldPrev).indexOf("custom_field_")> -1){
					//zendeskField=("ticket.customField:"+zendeskFieldPrev);
					zendeskField=""+zendeskFieldPrev;
				}if(zendeskFieldPrev!==null && (""+zendeskFieldPrev)!==""){
					//zendeskField=("ticket."+fields[field].name);
					zendeskField=""+zendeskFieldPrev;
				}
			}

			if(zendeskField!==null && zendeskField.indexOf("ticket.")<0 && idDBField==="1"){
				//zendeskField="ticket."+zendeskField;
				//zendeskField="ticket.customField:due_date";   
				zendeskField="ticket.customField:due_date";   
				//zendeskField="ticket.due_date";  
			}

			consoleXlog("BFFFF zendeskField, zendeskValueData ", zendeskField, fieldDatavalNeo, field_data_rawNeo);   

			if(zendeskField!==null && zendeskField!==""){ 
				//zendeskField=""+zendeskFieldPrev;
				// Do some custom field magic for your Zendesk
				let zendeskValueData="";
				if(field_data_rawNeo!==null && field_data_rawNeo!==""){
					//zendeskValueData=field_data_rawNeo;
					if(Array.isArray(field_data_rawNeo)){
						zendeskValueData=field_data_rawNeo[0];  
					}else {
						const datraw=JSON.parse(field_data_rawNeo);
						zendeskValueData=datraw[0];  
					}
					// if( typeof field_data_rawNeo === 'string' ) {
					// 	someVar = [ someVar ];
					// }
					// const datraw=JSON.parse(field_data_rawNeo);
					// zendeskValueData=datraw[0];     
				}else if(fieldDatavalNeo!==null && fieldDatavalNeo!==""){
					zendeskValueData=fieldDatavalNeo;
				}
				 
				if(ftype==="datetime" && zendeskValueData!==""){ 
					//zendeskValueData=moment(zendeskValueData).utc().format("YYYY-MM-DDTHH:mm:ssz");
					zendeskValueData=moment(zendeskValueData).utc().format(); //+":00Z";					 
				}

				const totalt=new Date().getTime();
				sessionStorage.setItem("lasttaction", totalt); 
				sessionStorage.setItem("blockfrmzendtoneo", 7); 
				zafClient.set(zendeskField, zendeskValueData).then(function(data) {
					this00.showToast(t("zqwNFZendesk1_x"), "warning");  
					// https://SomeDomain.zendesk.com/api/v2/ticket_fields
					consoleXlog("zendeskField, zendeskValueData ");   
					consoleXlog(data); // { 'ticket.subject': 'Printer Overheating Incident' }
					setTimeout(() => {
						sessionStorage.setItem("lasttaction", 0); 
						sessionStorage.setItem("blockfrmzendtoneo", 0); 
						consoleXlog("lasttaction release"); 
					}, 15000); 
				});

				// zafClient.set('ticket.customField:' + CustomFieldName, DataForYourField).then(function(data) {
				// 	// https://SomeDomain.zendesk.com/api/v2/ticket_fields
				// 	consoleXlog(data); // { 'ticket.subject': 'Printer Overheating Incident' }
				// });
			}
  
				
		}

		//consoleXlog("FIELDs ", fieldsNeeded);
		consoleXlog("UPDATE ZENDESK DATA FIELD ");

		//consoleXlog("FIELDs ", fieldsNeeded);
			/*
			// Do some custom field magic for your Zendesk
			zafClient.set('ticket.customField:' + CustomFieldName, DataForYourField).then(function(data) {
				// https://SomeDomain.zendesk.com/api/v2/ticket_fields
				consoleXlog(data); // { 'ticket.subject': 'Printer Overheating Incident' }
			});*/ 
 
	}
  }

  addDataToX = (newData) =>{ 
  const dynamicForms=this.state.dynamicForm;
	//if(dynamicForm[index]["options"]===null){ dynamicForm[index]["options"]=[]; }
	//const options= dynamicForm[index]["options"]; 
	let myNdiTicketDataLeftList=[];
	const realticket_id=this.state.realticket_id;
	let counter=0;
	const dynamicFormData=this.state.dynamicFormData;
				 
	// consoleXlog(data); 
	// consoleXlog("single block data ");  
	//const datax=JSON.parse(JSON.stringify(data));
	const dynamicFormAllData=this.state.dynamicFormAllData;
	const fieldList=dynamicFormAllData.fields;  
	const productFields=dynamicFormAllData.productFields;  
	const client_type=this.state.client_type;
	const idNeoId=this.state.neoid; // 0;
	//consoleXlog("blokksfieldList", fieldList);     
	consoleXlog("ZZZZZZZZZZZZZZZZZiiiiiindex blokksfieldList", newData);       
	for (let indexk=0; indexk<newData.length; indexk++){
		let productField=null; let blockField=null; let field_id=0; let field_name="";
		const product_field_id=newData[indexk].product_field_id; 
		const field_data=newData[indexk].field_data; 
		const field_data_raw=newData[indexk].field_data_raw; 
		consoleXlog("ZZZZZZZZZZZZZZZZZiiiiiindex ADDDDDDING ", indexk, product_field_id); 
		   
		for(let xprodf of productFields){
			if(xprodf.id===product_field_id){
				productField=xprodf; //field_id
				field_id=xprodf.field_id; 
				for(let xfield of fieldList){
					if(xfield.id===field_id){
						blockField=xfield;
						field_name=xfield.name; 
						consoleXlog("ZZZZZZZZZZZZZZZZZfound FOOOOOOUND ", indexk,field_id, field_name, product_field_id); 
						break;
					}
				}
				break;
			}
		}

		for (let index=0; index<dynamicForms.length; index++){ 
			//consoleXlog("iiiiiiiiiindex ", index);    
			//const posiCh = this.listElementChanges2.indexOf(""+index);
			//const name=this.listElementChanges[posiCh]; 
			if(dynamicForms[index]["'"+field_name+"'"]===undefined){ continue; }
			let valuex=dynamicForms[index]["'"+field_name+"'"];
			const ero=dynamicForms[index]["error"];	
			const options= dynamicForms[index]["options"];
			//this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead, ["client_type"]:client_type }); 	
			//consoleXlog("OOOOOOsssssssssssssss=:==>", ero, index, name, valuex, options); //continue;
			if((field_data!==null && field_data!=="")){ 
				dynamicForms[index]["'"+field_name+"'"]=field_data;
			}
			if((field_data_raw!==null && field_data_raw.length>0)){
				dynamicForms[index]["options"]=JSON.parse(field_data_raw);
			}
			dynamicFormData[index]=newData[indexk]; 
			counter++;
			consoleXlog("ZZZZZZZZZZZZZZZZZiiiiiiiiiindex ADDDDDDDDDDDDDING ::: ", counter, index, field_data, field_data_raw);    
			//if((valuex!=="" && valuex!==null) || (options!==null && options.length>0)){}else{ continue; }
			//if(ero===true){ continue; }
			break;
	
		}
	}
	if(counter >0){
		this.setState({ ["dynamicForm"]: dynamicForms, ["dynamicFormData"]: dynamicFormData, });  
		consoleXlog("dynamicForm", dynamicForms);
	}
	 
  }


  saveUpdateTicketDataToZendesks = (eventObjs) => {
	const { t } = this.props;  
	consoleXlog("teeeeeeeeeeest MASS UPDATE handleTestData client zaf");
	const this00=this;
	const realticket_id=this.state.realticket_id;
	let idDBField=null; let zendeskField=null; 
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false; 
	let fieldDataval=""; let isError=false;  let fieldDataRawval="";
	if(zafClient!==undefined && zafClient!==false){ 
		//zafClient.invoke('resize', { width: '100%', height: '600px' });
		const eventList={};
		let obi=0; 

		for(let eventObj of eventObjs){
			idDBField=eventObj.fieldId; 
			let fieldDatavalNeo=eventObj.dataval; 
			let field_data_rawNeo=eventObj.data_raw; 
			// Iterate through each field
			zendeskField=null; 
			consoleXlog("MASS INSIDE =:==>", idDBField, fieldDatavalNeo, field_data_rawNeo);
				
			const blockDataForField=this00.getFullObjectField (idDBField, zendeskField);
			if(blockDataForField!==null){
				const fieldBlock=blockDataForField.fieldBlock;
				const productField=blockDataForField.productFields;
				const FieldData=blockDataForField.FieldData;
				const ticketDataLeft=FieldData.ticketDataLeft;
				let fieldDataval=""; let isError=false; let field_data_raw="";
				const indexField=FieldData.index; let isRequired=true;   
				let elemVal=""; let field_data_rawx="";
				const fieldname=fieldBlock.name;
				const ftype=fieldBlock.field_type;
				let zendeskFieldPrev=fieldBlock.zendesk_id;
				if(zendeskFieldPrev!==null){ 
					zendeskFieldPrev=zendeskFieldPrev.replace("=>", ":"); 
					if(zendeskFieldPrev!==null && (""+zendeskFieldPrev).indexOf("custom_field_")> -1){
						//zendeskField=("ticket.customField:"+zendeskFieldPrev);
						zendeskField=""+zendeskFieldPrev;
					}if(zendeskFieldPrev!==null && (""+zendeskFieldPrev)!==""){
						//zendeskField=("ticket."+fields[field].name);
						zendeskField=""+zendeskFieldPrev;
					}
				}

				if(zendeskField!==null && zendeskField.indexOf("ticket.")<0 && idDBField==="1"){
					//zendeskField="ticket."+zendeskField;
					//zendeskField="ticket.customField:due_date";   
					zendeskField="ticket.customField:due_date";   
					//zendeskField="ticket.due_date";  
				}

				consoleXlog("MASSBFFFF zendeskField, zendeskValueData ", zendeskField, fieldDatavalNeo, field_data_rawNeo);   

				if(zendeskField!==null && zendeskField!==""){ 
					//zendeskField=""+zendeskFieldPrev;
					// Do some custom field magic for your Zendesk
					let zendeskValueData="";
					// if(field_data_rawNeo!==null && field_data_rawNeo!==""){
					// 	zendeskValueData=field_data_rawNeo;
					// }else if(fieldDatavalNeo!==null && fieldDatavalNeo!==""){
					// 	zendeskValueData=fieldDatavalNeo;
					// }
					if(field_data_rawNeo!==null && field_data_rawNeo!==""){
						//zendeskValueData=field_data_rawNeo;
						if(Array.isArray(field_data_rawNeo)){
							zendeskValueData=field_data_rawNeo[0];  
						}else {
							const datraw=JSON.parse(field_data_rawNeo);
							zendeskValueData=datraw[0];  
						}
						// if( typeof field_data_rawNeo === 'string' ) {
						// 	someVar = [ someVar ];
						// }
						// const datraw=JSON.parse(field_data_rawNeo);
						// zendeskValueData=datraw[0];  
					}else if(fieldDatavalNeo!==null && fieldDatavalNeo!==""){
						zendeskValueData=fieldDatavalNeo;
					}
					
					if(ftype==="datetime" && zendeskValueData!==""){ 
						//zendeskValueData=moment(zendeskValueData).utc().format("YYYY-MM-DDTHH:mm:ssz");
						zendeskValueData=moment(zendeskValueData).utc().format(); //+":00Z";					 
					}

					//eventList={zendeskField:zendeskValueData}; 
					eventList[zendeskField]=zendeskValueData; 
					obi++;  
				}
	
					
			}
		}

		consoleXlog("MASS BULKzendeskField8888888, zendeskValueData888888 ", obi, eventList);    
		if(obi >0){
			const totalt=new Date().getTime();
			sessionStorage.setItem("lasttaction", totalt); 
			sessionStorage.setItem("blockfrmzendtoneo", 7); 
			consoleXlog("BULKzendeskField000000, zendeskValueData0000 ");    
			zafClient.set(eventList).then(function(data) {
			//zafClient.set(zendeskField, zendeskValueData).then(function(data) {
				// https://SomeDomain.zendesk.com/api/v2/ticket_fields
				//zafClient.invoke('ticket.save');
				//zafClient.invoke('ticket.submit');
				// zafClient.invoke('ticket.submit.done', 'open').then(function() {
				// 		consoleXlog('text has been saveeeeeeeeeeeeeeeeeeed');
				// });
				// zafClient.invoke('ticket.save', 'open').then(function() {
				// 		consoleXlog('text has been saveeeeeeeeeeeeeeeeeeed');
				// });
				// zqwNFZendesk1_x 
				this00.showToast(t("zqwNFZendesk1_x"), "warning");  
				consoleXlog("BULKzendeskField, zendeskValueData ");   
				consoleXlog(data); // { 'ticket.subject': 'Printer Overheating Incident' }
				setTimeout(() => {
					//zafClient.invoke('ticket.save');
					// zafClient.invoke('ticket.save ticket.comment.appendText', 'Help!').then(function() {
					// 	consoleXlog('text has been appended');
					// });
					sessionStorage.setItem("lasttaction", 0); 
					sessionStorage.setItem("blockfrmzendtoneo", 0); 
					consoleXlog("BULKlasttaction release"); 
				}, 15000); 
			}).catch(function(error) {
				sessionStorage.setItem("lasttaction", 0); 
				sessionStorage.setItem("blockfrmzendtoneo", 0); 
				consoleXlog("BULKlasttaction release in catch error "); 
				consoleXlog(error.toString()); // Error: "ticket.form.id" Invalid Ticket Form ID
			});
		}else{
			consoleXlog("BULKzendeskField8888888, NOTHING TO SEND TO ZENDESK ", obi, eventList);  
		}

		//consoleXlog("FIELDs ", fieldsNeeded);
		consoleXlog("UPDATE ZENDESK DATA FIELD "); 
 
	}
  }


  handleSaveAll = (event) =>{ 
	const dynamicForms=this.state.dynamicForm;
	//if(dynamicForm[index]["options"]===null){ dynamicForm[index]["options"]=[]; }
	//const options= dynamicForm[index]["options"]; 
	let myNdiTicketDataLeftList=[];
	const realticket_id=this.state.realticket_id;  
	// consoleXlog(data); 
	// consoleXlog("single block data ");  
	//const datax=JSON.parse(JSON.stringify(data));
	const dynamicFormAllData=this.state.dynamicFormAllData;
	const fieldList=dynamicFormAllData.fields;  
	const client_type=this.state.client_type;
	const idNeoId=this.state.neoid; // 0;
	const zendeskList=[];
	const optionsData=dynamicFormAllData.optionsData;
	consoleXlog("blokksfieldList", fieldList);      

	for (let index=0; index<dynamicForms.length; index++){
		//let index=-1;
		//for (let dynamicForm of dynamicForms){
		//index++;
		//consoleXlog("iiiiiiiiiindex ", index);   
		if(this.listElementChanges2.includes(""+index)){
			//this.listElementChanges.push(fieldNamex);
			const posiCh = this.listElementChanges2.indexOf(""+index);
			const name=this.listElementChanges[posiCh];
			let valuex=dynamicForms[index]["'"+name+"'"];
			const ero=dynamicForms[index]["error"];	
			const options= dynamicForms[index]["options"];
			//this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead, ["client_type"]:client_type }); 	
		 
			//consoleXlog("OOOOOOsssssssssssssss=:==>", ero, index, name, valuex, options); //continue;

			consoleXlog("1-OOOOOOsssssssssssssss=:==>", ero, index, name, valuex, options); //continue;

			
			//if((valuex!=="" && valuex!==null) || (options!==null && options.length>0)){}else{ continue; }
			consoleXlog("2-OOOOOOsssssssssssssss=:==>", ero, index, name, valuex, options); 
			//if(ero===true){ continue; }
			consoleXlog("3-OOOOOOsssssssssssssss=:==>", ero, index, name, valuex, options); 
			const fieldBlock0=this.getFieldRecordByName(fieldList, name);
			consoleXlog("3.b-OOOOOOsssssssssssssss=:==>", ero, index, name, valuex, fieldBlock0); 
			if(fieldBlock0===null || fieldBlock0===undefined){ continue; }
			//consoleXlog("fieldBlock0",name, fieldBlock0);  
			consoleXlog("4-OOOOOOsssssssssssssss=:==>", ero, index, name, valuex, fieldBlock0);     
			const blockDataForField=this.getFullObjectField (fieldBlock0.id, null); 
			const idDBField=null; const zendeskField=null; 
			let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
			let isRequired=false; 
			let fieldDataval=""; let isError=false;  let fieldDataRawval="";
			//consoleXlog("blokks", fieldBlock0, blockDataForField);
			if((valuex==="" || valuex===null)){ valuex=""; }
			consoleXlog("5-OOOOOOsssssssssssssss=:==>", ero, index, name, valuex, blockDataForField); 
			if(blockDataForField!==null){
				const fieldBlock=blockDataForField.fieldBlock;
				const productField=blockDataForField.productFields;
				const FieldData=blockDataForField.FieldData;
				const ticketDataLeft=FieldData.ticketDataLeft;
				let fieldDataval=""; let isError=false; let field_data_raw="";
				const indexField=FieldData.index;
				const isfileshere=0; let isRequired=false;  let required="false";
				let elemVal=""; let field_data_rawx="";
				const fieldname=fieldBlock.name;
				if(productField.id >0){ productfieldid=productField.id; }
				if(productField.field_id >0){ fieldid=productField.field_id; }
				if(productField.required >0){ isRequired=true; isError=true; required="true";} 
				if(FieldData.case_id >0){ caseid=FieldData.case_id; } 

				//consoleXlog("Befoooooooooreevaluation===:==>", fieldid, fieldname, valuex);
				const evalregexp=fieldBlock.eval_regexp; //field.eval_regexp;
				/*if(caseid===0 || productfieldid===0){
						continue;
				}

				if((elemVal==="" && field_data_rawx==="") && (caseid===0 || productfieldid===0) && required==="true" && isfileshere===0){
					continue;
				}*/

				//consoleXlog("RegExp  RegExpRegExpRegExp ", evalregexp, elemVal); 
				/*if(required==="true" && isfileshere===0 && evalregexp!==undefined && evalregexp!==null && evalregexp!==""){
					consoleXlog("################GO ", elemVal, "evalregexp", evalregexp); 
					//const pattr = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					const pattr= new RegExp(evalregexp); // /evalregexp/;    
					if(evalregexp!=="" && !elemVal.match(pattr)){  //re.test(email)   
					//if(evalregexp!=="" && !elemVal.match(pattr)){  //if(evalregexp!=="" && !elemVal.match(evalregexp)){
						continue;
					} 
				}*/


				consoleXlog("OOOOOOOOOOOooooohx=:==>", fieldid, fieldname, valuex);
				if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
					tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
					// if(fieldDataval!==""){
					// 	isError=false;
					// }
					consoleXlog("y=:==>", fieldid, fieldname, ticketDataLeft.id);
					let isEmpty=true;
					if(ticketDataLeft.field_data_raw!==null && ticketDataLeft.field_data_raw!==""){
						field_data_rawx=ticketDataLeft.field_data_raw;  isEmpty=false;
					}
					if(fieldDataval!==""){
						isError=false;  isEmpty=false;
						elemVal=fieldDataval;
					}
					// if(isEmpty){ 
					// 	if(data[fieldOne].length> 245){
					// 		field_data_rawx=[data[fieldOne]];
					// 	}else{
					// 		elemVal=data[fieldOne];
					// 	}
					// const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
					// 	field_data: elemVal, field_data_raw: field_data_rawx, 
					// 	fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 
					// 	myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);	
					// }

					if(valuex.length> 245){
						field_data_rawx=[valuex];
					}else{
						elemVal=valuex;
					}
					if(options!==null && (options.length>0 || options.data!==undefined)){  //if(options!==null && options.length>0){ 
						field_data_rawx=options;
						// if(options.data!==undefined){ field_data_rawx=JSON.parse(options); }else{
						// 	field_data_rawx=options;
						// }
					}
					if(fieldBlock.field_type==="checkbox"){
						field_data_rawx=options;
					}
					   
					if(fieldid==="45"){ //nextstep status 
						const valueNextStepall=this.state.valueNextStep;    
						let valueNextStep=valueNextStepall[index];     
						const valueNextStepgost=this.state.valueNextStepgost;    
						if(valueNextStepgost!==null){ valueNextStep=valueNextStepgost; }
						//consoleXlog(index+"...valueNextStep ", valueNextStep, "vgost", valueNextStepgost); 
						if(valueNextStep.length >0){
							for(let z=0; z<valueNextStep.length; z++){
								valueNextStep[z]["isFixed"]=true;
							}
							field_data_rawx=valueNextStep; //JSON.stringify(valueNextStep);
							valueNextStepall[index]=valueNextStep;
							consoleXlog("valueNextStep field", valueNextStep);  
						}

						//consoleXlog("YOOOOOOOOOO", valueNextStepall); 
					}  
				const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
					field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId,  
					fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, client_type:client_type  }; 
					myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);
					const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
					zendeskList.push(eventObj); 

				}else{
					//for insert
					consoleXlog("z=:==>", fieldid, fieldname);
					// if(data[fieldOne].length> 245){
					// 	field_data_rawx=[data[fieldOne]];
					// }else{
					// 	elemVal=data[fieldOne];
					// }
					if(valuex.length> 245){
						field_data_rawx=[valuex];
					}else{
						elemVal=valuex;
					}
					if(options!==null && options.length>0){ 
						field_data_rawx=options;
					}
					if(fieldBlock.field_type==="checkbox"){
						field_data_rawx=options;
					}
					if(fieldid==="45"){ //nextstep status 
						const valueNextStepall=this.state.valueNextStep;    
						let valueNextStep=valueNextStepall[index];     
						const valueNextStepgost=this.state.valueNextStepgost;    
						if(valueNextStepgost!==null){ valueNextStep=valueNextStepgost; }
						//consoleXlog(index+"...valueNextStep ", valueNextStep, "vgost", valueNextStepgost); 
						if(valueNextStep.length >0){
							for(let z=0; z<valueNextStep.length; z++){
								valueNextStep[z]["isFixed"]=true;
							}
							field_data_rawx=valueNextStep; //JSON.stringify(valueNextStep);
							valueNextStepall[index]=valueNextStep;
							consoleXlog("valueNextStep field", valueNextStep); 
						}

						//consoleXlog("YOOOOOOOOOO", valueNextStepall); 
					}
					if(tickeId===0){
						//consoleXlog("BIIIIIIIIIIIINGO000", optionsData);
						for (let p=0; p<optionsData.length; p++){
							const xoptionsData=optionsData[p];
							//consoleXlog("BIIIIIIIIIIIINGO001", xoptionsData);
							if(""+xoptionsData.product_field_id===""+productfieldid){
								//consoleXlog("BIIIIIIIIIIIINGO CATCH-ADD", xoptionsData.product_field_id, xoptionsData.id);
								tickeId=parseInt(xoptionsData.id);
								break;
							}
						}
					}
					const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
						field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId,  
						fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, client_type:client_type  }; 
						myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);	
						const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
						zendeskList.push(eventObj); 
				} 
					
			}

		}
		// if(!this.listElementChanges.includes(fieldNamex)){
		// 	this.listElementChanges.push(fieldNamex);	
		// }
	} 

	consoleXlog("weeeeeee myNdiTicketDataLeftList size server..."+myNdiTicketDataLeftList.length);

	if(myNdiTicketDataLeftList.length >0){
		consoleXlog("weeeeeee are sending towards server...");
		consoleXlog("22weeeeeee myNdiTicketDataLeftList size server...", myNdiTicketDataLeftList);

		////this.saveAllTicketData = (event, myNdiTicketDataLeftList);
		this.saveAllTicketData(event, myNdiTicketDataLeftList, zendeskList);
	}

	/*const dynamicForm=this.state.dynamicForm; 
	// dynamicFormx[index]["id"]=xidn;
	// dynamicFormx[index]["error"]=false;
	dynamicForm[index]["'"+name+"'"]= value;
	if(dynamicForm[index]["check"]===true && value===""){
		dynamicForm[index]["error"]= true;
		//consoleXlog("setting error flag");
	}else{
		dynamicForm[index]["error"]= false;
		//consoleXlog("setting error flag to false");
	}
	this.setState({["dynamicForm"]: dynamicForm });*/ 

	consoleXlog("FIELDS CHANGES ");
	consoleXlog("TRACKER F ", this.listElementChanges);
	consoleXlog("TRACKER F2 ", this.listElementChanges2);
	consoleXlog("FIELDS block data ", dynamicForms);  
	consoleXlog("DataSend", myNdiTicketDataLeftList);
	// dynamicForm:[], elementForms:[], elementsData:[], elementFormsErrors:[], dynamicForm2:{ }, 
	// 	dynamicFormData:[], ticket:{}, realticket_id:'', //191807 197504 52583 191807 
	// 	selectedFiles: [], openProductFolder:false, 

  }

  setChangeTacker = (event, fieldName, indexFieldx) =>{
	// 
	let fieldNamex=fieldName; 
	let indexField=""+indexFieldx;
	if(fieldName.indexOf("-blockfield-")>-1 || fieldName.indexOf("-bfcorpo-")>-1){
		const fieldNamex2=fieldName.split("-"); 
		fieldNamex=fieldNamex2[0];  
	} 
	consoleXlog("TRACKER FIELDS CHANGES "+fieldName, indexField, this.listElementChanges);
	if(fieldNamex!==this.currentElementChanged){
		if(!this.listElementChanges.includes(fieldNamex)){
			this.listElementChanges.push(fieldNamex);	
			this.listElementChanges2.push(indexField);	
			this.setState({ ["changeCounter"]: this.listElementChanges2.length});  
		}
	}

	if(fieldName.indexOf("-blockfield-")>-1){
		this.setBlockFieldChangeTacker(event, fieldName, indexField);
	}else if(fieldName.indexOf("-bfcorpo-")>-1){
		this.setCorpoFieldChangeTacker(event, fieldName, indexField);
	}
	
	//this.setBlockFieldChangeTacker(event, fieldName, indexField);

	//listElementChanges=[]; currentElementChanged=""; 

  }

  setBlockFieldChangeTacker = (event, fieldName, indexField) =>{
	const regexp = /^(.+)([\-]blockfield[\-])([0-9]+)(_)([0-9]+)$/g;
	// avocat_info_pa-blockfield-2_1246  
	const arBlockfield = [...fieldName.matchAll(regexp)];
	let onefieldValue=""; let onefieldName="";
	if(event!==null){
		if(event.target!==undefined && event.target!==null && 
			event.target.value!=undefined && event.target.value!=null ){
				onefieldValue=event.target.value; 
			}
	}
	if(arBlockfield.length >0){
		//consoleXlog("WE FOUND ", arBlockfield, event); 
		const fieldBaseName=arBlockfield[0][1];
		const fieldPosIndex=arBlockfield[0][3];
		const fieldLabelnodel=arBlockfield[0][5];
		const dynamicForms=this.state.dynamicForm;
		const options= dynamicForms[indexField]["options"]; 
		const rowObj=options[fieldPosIndex];
		consoleXlog("WE FOUND 3 SETS ", fieldBaseName, fieldPosIndex, fieldLabelnodel); 
		consoleXlog("GOOOOOOOOOD-VBLOK ", onefieldValue, options, rowObj); 
		//rowObj.value=
		if(rowObj===undefined || rowObj===null || rowObj.length===undefined){
			consoleXlog("EEEERRROR-EEEERRROR ", fieldBaseName, options, fieldPosIndex); 
			return false;
		}
		let trackCty=0;
		for(let z=0; z<rowObj.length; z++){
			//rowObj[z]["isFixed"]=true;
			if(fieldLabelnodel===""+rowObj[z].labelnodel){
				//rowObj.value=
				consoleXlog(".....GOOOOOOOOOD WE FOUND ", onefieldValue); 
				if(onefieldValue!==""){
					rowObj[z].value=onefieldValue;
					trackCty++;
				}
				break;
			}
		}
		if(trackCty >0){
			this.setState({ ["dynamicForm"]: dynamicForms}); 
			consoleXlog("WRITEGOOOOOOOOOD WE FOUND ", dynamicForms);  
		}
		 
		// if(fieldLabelnodel===""+rowObj.labelnodel){
		// 	//rowObj.value=
		// 	consoleXlog("GOOOOOOOOOD WE FOUND ", event); 
		// }
	}
  }


  setCorpoFieldChangeTacker = (event, fieldName, indexField) => {
	//this.setState({ [event.target.name]: event.target.value }); //dynamicForm 
	const { name, value } = event.target;
	//consoleXlog("zzz handleChange2", event.target.name, event.target.value, "OOOObj#######", event.target,  event);
	let index=indexField;
	//const index=event.target.dataset.index; //event.currentTarget.dataset.index;
	if(event.target.dataset!==undefined && event.target.dataset.index!==undefined){ 
		index=event.target.dataset.index; 
	} 
	//consoleXlog("handleChange2", index, event.target.name, event.target.value, "OOOObj", event.target.dataset);
  
	const nameParts=name.split("-bfcorpo-");
	const namex=nameParts[0];
	const nameSubkey=nameParts[1];
	consoleXlog("handleChange2Corpo 0000 ", index, event.target.name, namex, nameSubkey, nameParts);
	//this.setState({["elementForms"]: fieldList }); 
	const targetname=namex!==undefined&&namex!==null&&namex!==""?namex:event.target.name; 
	//this.setChangeTacker(event, event.target.name, index); 
	this.setChangeTacker(event, targetname, index); 
	const dynamicForm=this.state.dynamicForm;
	const myobj={};
	// myobj["'"+name+"'"]= value;
	// dynamicForm[index] = myobj; 
	// this.setState({["dynamicForm"]: dynamicForm });
	consoleXlog("handleChange2Corpo ", index, event.target.name, event.target.value, "OOOObjQQQ", dynamicForm);
  
	const options=dynamicForm[index]["options"];
	consoleXlog("handleChange2Corpo 0000Sux222 ", index, namex, nameSubkey, options.data[""+nameSubkey], value, options);
	if(options.data[""+nameSubkey]!==undefined){
	  options.data[""+nameSubkey]= value;
	  //dynamicForm[index]["options"]=options;
	  consoleXlog("handleChange2Corpo 0000Sux000 ", index, namex, nameSubkey);
	}
  
	// dynamicFormx[index]["id"]=xidn;
	// dynamicFormx[index]["error"]=false;
	dynamicForm[index]["'"+name+"'"]= value;
	if(dynamicForm[index]["check"]===true && value===""){
		dynamicForm[index]["error"]= true;
		//consoleXlog("setting error flag");
	}else{
		dynamicForm[index]["error"]= false;
		//consoleXlog("setting error flag to false");
	}
	this.setState({["dynamicForm"]: dynamicForm }); 
  
	//consoleXlog("handleChange2Corpo goOOOOOO ", index, event.target.name, event.target.value, "OOOObjQQQ", dynamicForm);
  };

  

  saveAllTicketData = (event, myNdiTicketDataLeftList, zendeskList) => {
	consoleXlog("teeeeeeeeeeest  saveAllTicketData ");
	const this00=this;
	const { t } = this.props;
	const realticket_id=this.state.realticket_id;  
	const clientOnZendeskSCR=this.clientOnZendeskSCR;
	if(myNdiTicketDataLeftList.length> 0){
		consoleXlog("DATAO SERVER"); consoleXlog(myNdiTicketDataLeftList);
		//const this00=this; 
		//this.handleResetStatesXLoad(event); 
		const myNdiTicketDataLeft={massFZENDESK:1, massFields:myNdiTicketDataLeftList, realticket_id:realticket_id, clientOnZendeskSCR:clientOnZendeskSCR};

		this.setState({ ["isLoaderOpen"]: true});  
		//this.showToast("Maintenant on va sauvegar des données massivement.", "warning"); #### 
		Promise.resolve(this.props.addOrUpdateTicketData(myNdiTicketDataLeft))
		.then((response)=>{
			//this.setState(response.NdiFieldsOne);
			//const resp=response.NdiFields; 
			//const xid=parseInt(tickeId); 
			this.listElementChanges=[];	
			this.listElementChanges2=[];
			this.setState({ ["changeCounter"]: 0});  	 
			//this.showToast("Félicitations!!! On a finit de sauvegarder ou actualiser massivement avec succès!", "success"); 
			const ticket_id = this00.state.realticket_id; // 197504
			consoleXlog("check solving refresh... ", ticket_id, this00.backTicketId); 
			consoleXlog("GOOOOOOOOOOFIXING",response); 
			//forcerefresh here
			//const ticket_id = this.state.realticket_id; // 197504
			if(ticket_id!=="" || this00.backTicketId >0){ 
				const objToSend={id:0, ticketId:ticket_id}; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
				if(this00.backTicketId >0){
					objToSend.ticketId=this00.backTicketId; 
				}
				consoleXlog("solving refresh weeeeeeeeeeeeeeeeeeeere goooing to server ayayay ", objToSend); 
				
				////this00.loadProductsFields(objToSend);	//this or load right here
				//this00.loadProductsFieldsOnce(objToSend);
				// data[fieldOne]= dateFormat(event, 'yyyy-mm-dd', false);	
				
			}

			this.listElementChanges2=[]; this.listElementChanges=[];  this.currentElementChanged="";

			//this.showToast("Félicitations, la quantité de champs actualisée est "+myNdiTicketDataLeftList.length, "success");  
			this.showToast(t("zqwNFFtxt16_x"), "success");   
	
			//this00.handleDynamicFormGenerator(response.NdiFields);
			//this00.setState({ ["isLoaderOpen"]: false, ["dynamicFormAllData"]: response.NdiFields, });  //response.NdiFields
			//this.setState({ ["isLoaderOpen"]: false,}); 
			this00.setState({ ["isLoaderOpen"]: false, ["dynamicFormAllData"]: response.NdiFields, }); 
			//consoleXlog("QQQQQQQQQQ  REDUCE dynamicFormAllData .... ", response); 
			this.saveUpdateTicketDataToZendesks(zendeskList);   
			 	


			return response;
		}).catch(function(error){ 
			const errObj=error;
			this00.setState({ ["isLoaderOpen"]: false});  
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
				errMessage +="\n There was some error from the expected data from the server. Please try again later.";
				this00.saveUpdateTicketDataToZendesks(zendeskList);  
				consoleXlog("FAKE ERRORS SOLVED MINIMIZED");  
			}
			else if(errObj.status===500){
				//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
				//errMessage +="\n 9999999 ".errObj.data.error.description;
				//errMessage +="\n 9999999 ".errObj.error.description;
				errMessage ="SERVER ERROR INTERNAL or file not found.";
				this00.showToast(errMessage, "error");  
			}else{
				//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
				
				//this00.showToastError(errMessage);
				//alert(errMessage); //error
				this00.showToast(errMessage, "error");  
			}
		});
	}else{
		//this.showToast("On n'a pas de données à sauvegarder.", "warning");  
	}
 
				
			 
  }

  handleSubmitTicketDataQQQQ = (event) =>{
	const dynamicForms=this.state.dynamicForm;
	//if(dynamicForm[index]["options"]===null){ dynamicForm[index]["options"]=[]; }
	//const options= dynamicForm[index]["options"]; 
	let myNdiTicketDataLeftList=[];
	const realticket_id=this.state.realticket_id;
	const { t } = this.props;
				 
	// consoleXlog(data); 
	// consoleXlog("single block data ");  
	//const datax=JSON.parse(JSON.stringify(data));
	const dynamicFormAllData=this.state.dynamicFormAllData;
	const fieldList=dynamicFormAllData.fields;  
	const client_type=this.state.client_type;
	consoleXlog("blokksfieldList", fieldList);

	consoleXlog("GOPRO FIELDS CHANGES ");
	consoleXlog("TRACKER F ", this.listElementChanges);
	consoleXlog("TRACKER F2 ", this.listElementChanges2);
	consoleXlog("FIELDS block data ", dynamicForms);  
	consoleXlog("DataSend", myNdiTicketDataLeftList);
  }

  saveAllTicketData00 = (event) => {
	consoleXlog("teeeeeeeeeeest handleTestData client zaf saveUpdateTicketData ");
	const this00=this;
	const realticket_id=this.state.realticket_id;
	const { t } = this.props;
	const idNeoId=this.state.neoid; // 0;
	if(zafClient!==undefined && zafClient!==false){ 
		//zafClient.invoke('resize', { width: '100%', height: '600px' });
		//this.setState({ ["isLoaderOpen"]: true});  
		this.showToast("On va comparer les données de zendesk de gauche à droite.", "warning");  

		zafClient.get('ticketFields').then(function(data) {
      
			// Get all fields to query against
			let fields = data["ticketFields"];
			let CustomFieldName; let fieldsNeeded=[];
			const avoidList=["collaborator", ""];
			 
			// Iterate through each field
			for (let field in fields) { 
				
				//fieldsNeeded.push(fields[field].name);
				if((fields[field].name).indexOf("custom_field_")> -1){
					fieldsNeeded.push("ticket.customField:"+fields[field].name);
				}else{
					fieldsNeeded.push("ticket."+fields[field].name);
				}
				consoleXlog("FIELD ", fields[field].name, fields[field].label);
			}

			 consoleXlog("FIELDs ", fieldsNeeded);
			 consoleXlog("DATA FOR FIELDs ");
			zafClient.get(fieldsNeeded).then(function(data){
				
				// const fieldValue= Object.keys(data)[1];
				// consoleXlog( data[fieldValue]);
				let myNdiTicketDataLeftList=[];
				 
				// consoleXlog(data); 
				// consoleXlog("single block data ");  
				//const datax=JSON.parse(JSON.stringify(data));
				for (let fieldOne of fieldsNeeded){
					// const fieldValue= Object.keys(fieldData)[1];
					// consoleXlog( data[fieldValue]);
					let trackStrOrDates= data[fieldOne]; 
					//Wed Dec 09 2020 19:00:00 GMT-0500 (heure normale de l’Est nord-américain)
					//consoleXlog("=:==>", fieldOne, data[fieldOne]);
					if(data[fieldOne]!==null && data[fieldOne]!==undefined){
						const idDBField=null; const zendeskField=fieldOne; 
						let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
						let isRequired=false; 
						let fieldDataval=""; let isError=false;  let fieldDataRawval="";
							
						if(trackStrOrDates!==null && (""+trackStrOrDates).indexOf(" (heure normale de l’Est nord-américain)")> -1){
							trackStrOrDates=trackStrOrDates.replace(" (heure normale de l’Est nord-américain)", "");
							//trackStrOrDates=Date.parse(trackStrOrDates).toISOString();
							consoleXlog("WE USE THIS ---BF- ", trackStrOrDates);
							let event = new Date(trackStrOrDates);
							//consoleXlog("toString", event.toString()); consoleXlog("toISOString", event.toISOString());
							const isod=(event.toISOString()).split("T");
							consoleXlog("##isod",  isod[0]);
							event = new Date(isod[0]);
							//consoleXlog("##toString", event.toString()); consoleXlog("##toISOString", event.toISOString());
							//consoleXlog(event.toLocaleString('en-US', { timeZone: 'UTC' }));
							//consoleXlog(event.format('yyyy-mm-dd HH:MM:ss'));
							//consoleXlog(dateFormat(event, 'yyyy-mm-dd HH:MM:ss', false)); //dateFormat(this, mask, utc);
							//data[fieldOne]= dateFormat(event, 'yyyy-mm-dd HH:MM:ss', false);
							
							//data[fieldOne]= dateFormat(event, 'yyyy-mm-dd', false);
							data[fieldOne]= isod[0];
							//consoleXlog("WE USE THIS D ", dateFormat(event, 'yyyy-mm-dd', false));
							 
						}
						consoleXlog("=:===>", fieldOne, data[fieldOne], trackStrOrDates);
						 
						const blockDataForField=this00.getFullObjectField (idDBField, zendeskField);
						if(blockDataForField!==null){
							const fieldBlock=blockDataForField.fieldBlock;
							const productField=blockDataForField.productFields;
							const FieldData=blockDataForField.FieldData;
							const ticketDataLeft=FieldData.ticketDataLeft;
							let fieldDataval=""; let isError=false; let field_data_raw="";
							const indexField=FieldData.index;
							let elemVal=""; let field_data_rawx="";
							const fieldname=fieldBlock.name;
							if(productField.id >0){ productfieldid=productField.id; }
							if(productField.field_id >0){ fieldid=productField.field_id; }
							if(productField.required >0){ isRequired=true; isError=true;} 
							if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
							consoleXlog("x=:==>", fieldid, fieldname);
							if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
								tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
								// if(fieldDataval!==""){
								// 	isError=false;
								// }
								consoleXlog("y=:==>", fieldid, fieldname, ticketDataLeft.id);
								let isEmpty=true;
								if(ticketDataLeft.field_data_raw!==null && ticketDataLeft.field_data_raw!==""){
									field_data_rawx=ticketDataLeft.field_data_raw;  isEmpty=false;
								}
								if(fieldDataval!==""){
									isError=false;  isEmpty=false;
									elemVal=fieldDataval;
								}
								if(isEmpty){ 
									if(data[fieldOne].length> 245){
										field_data_rawx=[data[fieldOne]];
									}else{
										elemVal=data[fieldOne];
									}
								const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
									field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId,  
									fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 
									myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);	
								}
							}else{
								//for insert
								consoleXlog("z=:==>", fieldid, fieldname);
								if(data[fieldOne].length> 245){
									field_data_rawx=[data[fieldOne]];
								}else{
									elemVal=data[fieldOne];
								}
								const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
									field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId,  
									fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 
									myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);	
							} 
							 
						}
					}
				}
				
				if(myNdiTicketDataLeftList.length> 0){
					consoleXlog("DATAO SERVER"); consoleXlog(myNdiTicketDataLeftList);
					//const this00=this; 
					this00.handleResetStatesXLoad(event); 
					const clientOnZendeskSCR=this00.clientOnZendeskSCR;
					const myNdiTicketDataLeft={massFZENDESK:1, massFields:myNdiTicketDataLeftList, realticket_id:realticket_id, clientOnZendeskSCR:clientOnZendeskSCR};
		
		this00.setState({ ["isLoaderOpen"]: true});  
		this00.showToast("Maintenant on va actualiser les données de zendesk de gauche à droite.", "warning");  
	Promise.resolve(this00.props.addOrUpdateTicketData(myNdiTicketDataLeft))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		//const resp=response.NdiFields; 
		//const xid=parseInt(tickeId);  
		//this.showToast("Félicitations!!! On a finit de sauvegarder ou actualiser massivement avec succès!", "success"); 
		const ticket_id = this00.state.realticket_id; // 197504
		consoleXlog("check solving refresh... ", ticket_id, this00.backTicketId); 
		//forcerefresh here
		//const ticket_id = this.state.realticket_id; // 197504
		if(ticket_id!=="" || this00.backTicketId >0){ 
			const objToSend={id:0, ticketId:ticket_id}; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
			if(this00.backTicketId >0){
				objToSend.ticketId=this00.backTicketId; 
			}
			consoleXlog("solving refresh weeeeeeeeeeeeeeeeeeeere goooing to server ayayay ", objToSend); 
			   
			////this00.loadProductsFields(objToSend);	//this or load right here
			//this00.loadProductsFieldsOnce(objToSend);
			// data[fieldOne]= dateFormat(event, 'yyyy-mm-dd', false);	
			
		}


		/*
		const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
		const isClientId=clientProdTransInfo.client.id; NdiFields NdiFields
		const isClientLead=isClientId >0?false:true;
		const client_type= response.NdiFieldsOne.data.client_type;
		//consoleXlog("..HOHOHO response from server .... ");  //consoleXlog(response.NdiFieldsOne.data);
		
		this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
		//this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead, ["client_type"]:client_type }); 
		this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data,  }); 		
		//consoleXlog("HOHOHO just assinged to dynamicFormAllData .... "); 	
		this.setState({ ["isLoaderOpen"]: false}); //load&close 
		*/

		this00.handleDynamicFormGenerator(response.NdiFields);
		this00.setState({ ["isLoaderOpen"]: false, ["dynamicFormAllData"]: response.NdiFields, });  //response.NdiFields

		consoleXlog("QQQQQQQQQQ  REDUCE dynamicFormAllData .... ", response); 	


		return response;
	}).catch(function(error){ 
		const errObj=error;
		this00.setState({ ["isLoaderOpen"]: false});  
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
		  errMessage +="\n There was some error from the expected data from the server. Please try again later.";
		}
		else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			errMessage ="SERVER ERROR INTERNAL or file not found.";
		  }
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		
		//this00.showToastError(errMessage);
		//alert(errMessage); //error
	  });
				}else{
					this00.showToast(t("zqwNFFtxt12_x"), "warning");  
				}
 
				
			});

	  		//consoleXlog("FIELDs ", fieldsNeeded);
			/*
			// Do some custom field magic for your Zendesk
			client.set('ticket.customField:' + CustomFieldName, DataForYourField).then(function(data) {
				// https://SomeDomain.zendesk.com/api/v2/ticket_fields
				consoleXlog(data); // { 'ticket.subject': 'Printer Overheating Incident' }
			});*/
	  
	  });
		 
	}
  }
   

  handleOpenProductXLang = (event) => {
	//this.setState({ [event.target.name]: event.target.value });
	const ticket_id = this.state.realticket_id; // 197504
	this.handleResetStatesXLoad(event);
	if(ticket_id!==""){
		const myLang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
		let golang="fr";
		if(this.loadOtherLangtk%2===0){ 
			if(myLang.indexOf("fr")< 0){
				golang="fr";  this.loadOtherLang="fr"; this.loadOtherLangtk++;
			}else{
				golang="en";
				this.loadOtherLang="en"; this.loadOtherLangtk++;
			}
		}else{
			if(myLang.indexOf("fr")< 0){
				golang="en";  this.loadOtherLang="en"; this.loadOtherLangtk++;
			}else{
				golang="fr";
				this.loadOtherLang="fr"; this.loadOtherLangtk++;
			}
		}

		const objToSend={id:0, ticketId:ticket_id, lang:golang }; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
		 
		consoleXlog("change LANG weeeeeeeeeeeeeeeeeeeere goooing to server ayayay ", objToSend); 
		this.loadProductsFields(objToSend);	

		/*Promise.resolve(this.props.getProductFields(objToSend))
		.then((response)=>{
			//this.setState(response.NdiFieldsOne);
			//consoleXlog("HOHOHO response from server .... ");  //consoleXlog(response.NdiFieldsOne.data);
			//this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
			this.loadProductsFields(objToSend);		  
			return response;
		});*/
	}
  };

  handleResetStatesXLoad = (event) => {
	this.setState({["dynamicForm"]: [], ["dynamicFormAllData"]: {}, 
	["elementsData"]: [], ["dynamicFormData"]: [], 
	["elementFormsErrors"]: [], ["selectedFiles"]: [], }); 	
  };

  handleOpenProductXLoad = (event) => {
	//this.setState({ [event.target.name]: event.target.value });
	// this.setState({["dynamicForm"]: [], ["dynamicFormAllData"]: {}, 
	// ["elementsData"]: [], ["dynamicFormData"]: [], 
	// ["elementFormsErrors"]: [], ["selectedFiles"]: [], }); 	
		//consoleXlog("HOHOHO just assinged to dynamicFormAllData .... "); 
    this.handleResetStatesXLoad(event);
	const ticket_id = this.state.realticket_id; // 197504
	if(ticket_id!==""){
		const myLang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
		let golang="fr";
		if(myLang.indexOf("fr")< 0){
			golang="en";  
		}else{
			golang="fr"; 
		}
		  
		const objToSend={id:0, ticketId:ticket_id, lang:golang }; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
		 
		consoleXlog("relooooooooooooooooooad weeeeeeeeeeeeeeeeeeeere goooing to server ayayay ", objToSend); 
		this.loadProductsFields(objToSend);	
 
	}
  };
  


  handleChange = (event) => {
	this.setState({ [event.target.name]: event.target.value });
	const index=event.target.dataset.index; 
	this.setChangeTacker(event, event.target.name, index); 
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };

  handleChange2 = (event) => {
	//this.setState({ [event.target.name]: event.target.value }); //dynamicForm 
	const { name, value } = event.target;
	//consoleXlog("zzz handleChange2", event.target.name, event.target.value, "OOOObj#######", event.target,  event);
	const index=event.target.dataset.index; //event.currentTarget.dataset.index;
	//consoleXlog("handleChange2", index, event.target.name, event.target.value, "OOOObj", event.target.dataset);

	//this.setState({["elementForms"]: fieldList }); 
	this.setChangeTacker(event, event.target.name, index); 
	const dynamicForm=this.state.dynamicForm;
	const myobj={};
	// myobj["'"+name+"'"]= value;
	// dynamicForm[index] = myobj; 
	// this.setState({["dynamicForm"]: dynamicForm });
	//consoleXlog("handleChange2", index, event.target.name, event.target.value, "OOOObjQQQ", dynamicForm);
 

	// dynamicFormx[index]["id"]=xidn;
	// dynamicFormx[index]["error"]=false;
	dynamicForm[index]["'"+name+"'"]= value;
	if(dynamicForm[index]["check"]===true && value===""){
		dynamicForm[index]["error"]= true;
		//consoleXlog("setting error flag");
	}else{
		dynamicForm[index]["error"]= false;
		//consoleXlog("setting error flag to false");
	}
	this.setState({["dynamicForm"]: dynamicForm }); 

	//consoleXlog("handleChange2QQ", index, event.target.name, event.target.value, "OOOObjQQQ", dynamicForm);
  };

  handleChange2textarea = (event) => {
	const { name, value } = event.target;
	const index=event.target.dataset.index; //event.currentTarget.dataset.index;
	//consoleXlog("handleChange2", index, event.target.name, event.target.value, "OOOObj", event.target.dataset);

	//this.setState({["elementForms"]: fieldList }); 
	this.setChangeTacker(event, event.target.name,index); 
	const dynamicForm=this.state.dynamicForm; 
	if(dynamicForm[index]["options"]===null){ dynamicForm[index]["options"]=[]; }
	const options= dynamicForm[index]["options"]; 

	//options.push(value);
	if(options.length==0){ options.push(value); }else{ options[0]=value;}   
	dynamicForm[index]["'"+name+"'"]= ""; //value;
	//dynamicForm[index]["options"]= options;
	if(dynamicForm[index]["check"]===true && (value==="" && options.length===0)){
		dynamicForm[index]["error"]= true;
		//consoleXlog("setting error flag");
	}else{
		dynamicForm[index]["error"]= false;
		//consoleXlog("setting error flag to false");
	}
	this.setState({["dynamicForm"]: dynamicForm });


	// const myobj={}; 
	// dynamicForm[index]["'"+name+"'"]= value;
	// if(dynamicForm[index]["check"]===true && value===""){
	// 	dynamicForm[index]["error"]= true;
	// 	//consoleXlog("setting error flag");
	// }else{
	// 	dynamicForm[index]["error"]= false;
	// 	//consoleXlog("setting error flag to false");
	// }
	// this.setState({["dynamicForm"]: dynamicForm }); 

	//consoleXlog("handleChange2QQ", index, event.target.name, event.target.value, "OOOObjQQQ", dynamicForm);
  };

  handleChange3 = (event, index) => {
	//this.setState({ [event.target.name]: event.target.value }); //dynamicForm 
	const { name, value } = event.target;
	 
	const dynamicForm=this.state.dynamicForm;
	this.setChangeTacker(event, event.target.name,index); 

	//consoleXlog("handleChange3 setting flag ", event.target.name, index, value, event.target.isChecked);
	 
	dynamicForm[index]["'"+name+"'"]= value;
	if(dynamicForm[index]["check"]===true && value===""){
		dynamicForm[index]["error"]= true;
		//consoleXlog("setting error flag");
	}
	this.setState({["dynamicForm"]: dynamicForm }); 
 

  };


  handleFocusoutCorpo = (event) => {
	if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		this.setState({ ["e_"+event.target.name]: false });
		if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			this.setState({ ["e_"+event.target.name]: true }); 	
		}
	} 	
};

handleChange2Corpo = (event) => {
  //this.setState({ [event.target.name]: event.target.value }); //dynamicForm 
  const { name, value } = event.target;
  //consoleXlog("zzz handleChange2", event.target.name, event.target.value, "OOOObj#######", event.target,  event);
  const index=event.target.dataset.index; //event.currentTarget.dataset.index;
  //consoleXlog("handleChange2", index, event.target.name, event.target.value, "OOOObj", event.target.dataset);

  const nameParts=name.split("-bfcorpo-");
  const namex=nameParts[0];
  const nameSubkey=nameParts[1];
  consoleXlog("handleChange2Corpo 0000 ", index, event.target.name, namex, nameSubkey, nameParts);
  //this.setState({["elementForms"]: fieldList }); 
  const targetname=namex!==undefined&&namex!==null&&namex!==""?namex:event.target.name; 
  //this.setChangeTacker(event, event.target.name, index); 
  this.setChangeTacker(event, targetname, index); 
  const dynamicForm=this.state.dynamicForm;
  const myobj={};
  // myobj["'"+name+"'"]= value;
  // dynamicForm[index] = myobj; 
  // this.setState({["dynamicForm"]: dynamicForm });
  consoleXlog("handleChange2Corpo ", index, event.target.name, event.target.value, "OOOObjQQQ", dynamicForm);

  const options=dynamicForm[index]["options"];
  consoleXlog("handleChange2Corpo 0000Sux222 ", index, namex, nameSubkey, options.data[""+nameSubkey], value, options);
  if(options.data[""+nameSubkey]!==undefined){
	options.data[""+nameSubkey]= value;
	//dynamicForm[index]["options"]=options;
	consoleXlog("handleChange2Corpo 0000Sux000 ", index, namex, nameSubkey);
  }

  // dynamicFormx[index]["id"]=xidn;
  // dynamicFormx[index]["error"]=false;
  dynamicForm[index]["'"+name+"'"]= value;
  if(dynamicForm[index]["check"]===true && value===""){
	  dynamicForm[index]["error"]= true;
	  //consoleXlog("setting error flag");
  }else{
	  dynamicForm[index]["error"]= false;
	  //consoleXlog("setting error flag to false");
  }
  this.setState({["dynamicForm"]: dynamicForm }); 

  consoleXlog("handleChange2Corpo goOOOOOO ", index, event.target.name, event.target.value, "OOOObjQQQ", dynamicForm);
};

  showToast = (arg, toastType) => {
    this.setState({ // update a property
        toastrMessage: arg, 
      toastrType: toastType
      });
    setTimeout(() => { 
      this.setState({ 
        toastrMessage: "", 
        toastrType: 'success'
      }); 
    }, 5500);
  }

  showToastError = (arg) => {
	  let toastType="error";
	this.showToast(arg, toastType);
  }

  handleChangeFile= (event) => {
	const index=event.target.dataset.index;  
	const selectedFiles=this.state.selectedFiles;
	let selectedFile= selectedFiles[index];
	let labelnodel=0;
	const labelnodelx=event.target.dataset.labelnodel;
	if(labelnodelx!==undefined && labelnodelx!==null){
		labelnodel=parseInt(labelnodelx);
	}  
	////consoleXlog("qQQQQQQQQQQQQQQQQQQQQQQQ handleChangeFile", index, event.target.files[0]);
	if(event.target.files[0]===undefined){
		if(selectedFile.length==0){
			selectedFile.push([]);
		}else{
			selectedFile=[];
		}
		selectedFiles[index]=selectedFile;
		this.setState({["selectedFiles"]: selectedFiles });   
		return false;
	} 

	// const files = event.target.files;
	// for (let i = 0; i < files.length; i++) {
	// 	formData.append(`images[${i}]`, files[i])
	// }

	if(selectedFile.length==0){
		//selectedFile.push(event.target.files[0]);
		selectedFile.push(event.target.files);
	}else{
		selectedFile[0]=event.target.files; //event.target.files[0];
	}
 
	selectedFiles[index]=selectedFile;
	this.setState({["selectedFiles"]: selectedFiles, ["labelnodel"]: labelnodel });
  }

  handleChangeRSelect = (event, name, index) => {
	const value= event?.value;  
	const dynamicForm=this.state.dynamicForm;
	this.setChangeTacker(event, name, index); 
	dynamicForm[index]["'"+name+"'"]= value;
	if(dynamicForm[index]["check"]===true && value===""){
		dynamicForm[index]["error"]= true;
	}
	this.setState({["dynamicForm"]: dynamicForm }); 
  };

  handleChangeIdsClo = (event, name, index) => {
	const value= event ?? [];  
	const dynamicForm=this.state.dynamicForm;
	this.setChangeTacker(event, name, index); 
	dynamicForm[index]["'"+name+"'"]= value;
	dynamicForm[index]["options"]= value;
	if(dynamicForm[index]["check"]===true && value===""){
		dynamicForm[index]["error"]= true;
	}
	this.setState({["dynamicForm"]: dynamicForm }); 
  };

  handleChangeOptions = (event) => {
	//this.setState({ [event.target.name]: event.target.value }); //dynamicForm 
	const { name, value } = event.target;
	const index=event.target.dataset.index; //event.currentTarget.dataset.index;
	//consoleXlog("handleChangeOptions", index, event.target.name, event.target.value, "OOOObj", event.target.dataset);

	//this.setState({["elementForms"]: fieldList }); 
	const dynamicForm=this.state.dynamicForm;
	if(dynamicForm[index]["options"]===null){ dynamicForm[index]["options"]=[]; }
	const options= dynamicForm[index]["options"]; 
	this.setChangeTacker(event, event.target.name, index); 

	consoleXlog("handleChange3 setting flag ", event.target.name, index, value, event.target.checked);
	 
	//consoleXlog("dynamicForm ", dynamicForm);
    // check is checked or unchecked
    if (event.target.checked) {
      // add to options array
      options.push(event.target.value);
    } else {
      // or remove 
      const xindex = options.indexOf(event.target.value)
      options.splice(xindex, 1)
    }

	consoleXlog("handleChange3 setting flag#2 ", event.target.name, index, value, options);
	 
	 
	dynamicForm[index]["'"+name+"'"]= ""; //value;
	//dynamicForm[index]["options"]= options;
	if(dynamicForm[index]["check"]===true && (value==="" && options.length===0)){
		dynamicForm[index]["error"]= true;
		//consoleXlog("setting error flag");
	}
	this.setState({["dynamicForm"]: dynamicForm }); 

	consoleXlog("handleChange3 setting flag#3 ", event.target.name, index, value, options, dynamicForm);
	 
 

  };

  handleChangeOptionsmulti = (event) => {
	//this.setState({ [event.target.name]: event.target.value }); //dynamicForm 
	// let selectedValues = Array.from(event.target.selectedOptions, option => option.value);
  	// this.setState({values: value});
	const { name, value } = event.target;
	const index=event.target.dataset.index; //event.currentTarget.dataset.index;
	let selectedValues = Array.from(event.target.selectedOptions, option => option.value);
	//consoleXlog("handleChangeOptionsmulti", index, event.target.name, event.target.value, "OOOObj", event.target.dataset);

	//this.setState({["elementForms"]: fieldList }); 
	this.setChangeTacker(event, event.target.name, index); 
	const dynamicForm=this.state.dynamicForm;
	const myobj={}; 
	// dynamicFormx[index]["id"]=xidn;  
	// dynamicFormx[index]["error"]=false; 
	const options= dynamicForm[index]["options"]; 
	 
	//consoleXlog("dynamicForm ", dynamicForm);
	//consoleXlog("handleChangeOptionsmulti selectedValues", index, selectedValues);
    // check is checked or unchecked
    /*if (event.target.checked) {
      // add to options array
      options.push(event.target.value);
    } else {
      // or remove 
      const xindex = options.indexOf(event.target.value)
      options.splice(xindex, 1)
    }*/
	 
	dynamicForm[index]["'"+name+"'"]= ""; //value;
	//dynamicForm[index]["options"]= options;
	dynamicForm[index]["options"]= selectedValues;
	if(dynamicForm[index]["check"]===true && (value==="" && options.length===0)){
		dynamicForm[index]["error"]= true;
		//consoleXlog("setting error flag");
	}
	this.setState({["dynamicForm"]: dynamicForm }); 
 

  };

  handleDynamicStateSetter = (myObjSetter) => {
	//this.setState({ [event.target.name]: event.target.value }); //dynamicForm
	//consoleXlog("handleDynamicStateSetter", myObjSetter.index, myObjSetter.name, myObjSetter.value);
	//const { name, value } = event.target;
	const name=myObjSetter.name;
	const value=myObjSetter.value;
	const index=myObjSetter.index;
	const error=myObjSetter.error;
	const check=myObjSetter.check;
	const id=myObjSetter.id;
	const options= myObjSetter.options;
	

	const dynamicForm=this.state.dynamicForm;
	const myobj={};
	myobj["id"]= id;
	myobj["'"+name+"'"]= value;
	myobj["check"]= check;
	myobj["error"]= error;
	myobj["options"]= options;
	dynamicForm[index] = myobj; 
	this.setState({["dynamicForm"]: dynamicForm }); 

    /*this.setState(previousState => {
      const rows = [...previousState.dynamicForm];
      rows[index] = { ...rows[index], [name]: value };
      return { rows };
    });*/

  };
  
  handleFocusout2 = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  loadProductsFields000 = (objToSend) => {
	this.setState({ ["isLoaderOpen"]: true});  
	const this2=this; 
	const { t } = this.props;
	Promise.resolve(this.props.getProductFields(objToSend))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
		const isClientId=clientProdTransInfo.client.id;
		const isClientLead=isClientId >0?false:true;
		const client_type= response.NdiFieldsOne.data.client_type;
		//consoleXlog("..HOHOHO response from server .... ");  //consoleXlog(response.NdiFieldsOne.data);
		this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
		this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead, ["client_type"]:client_type }); 	
		//consoleXlog("HOHOHO just assinged to dynamicFormAllData .... "); 	
		this.setState({ ["isLoaderOpen"]: false});
		this.showToast(t("zqwNFFtxt9_x"), "success"); 
		// this.saveUpdateTicketData(null);  
		setTimeout(() => {
			//this.showToast(t("zqwNFFtxt10_x"), "warning");  
			this.saveUpdateTicketData(null);  
		}, 10000);   
		return response;
	}).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		alert(error);
	});

  }

  loadProductsFields = (objToSend) => {
	const { t } = this.props;   
	let currentTabMain=this.props.currentTab;
	if(currentTabMain===undefined){
		currentTabMain="tab1";		
	} 

	//this.loadProductsFields_Oldone(objToSend); return false;

	if(currentTabMain==="tab3"){
	this.setState({ ["isLoaderOpen"]: true});  
	const this2=this; 
	//consoleXlog("clientOnZendeskSCR ", this.clientOnZendeskSCR);
	if(this.clientOnZendeskSCR===0){
		objToSend.zendeskapi=1;
	}
	objToSend.zendeskapi=1;

	const neoemail = this.neoemail; //this.getQueryVariable("neoemail"); 
	//if(neoemail!==false){ this.neoemail=neoemail; }   
	//consoleXlog("sendiiiing neeeeeeeeeeoemail: "+neoemail+"  vs "+this.neoemail); 
	 
		
	const theticketId00=objToSend.ticketId; //this.state.realticket_id;
	let clientId=this.state.neoid; //0; //this.state.idClient;
	if(clientId===null || clientId===""){ clientId=0; }		   
    const serachObj={clientId:clientId, ticketId:theticketId00, neoemail:neoemail};
	objToSend.neoemail=neoemail;

	consoleXlog("*******TRAP PARALLELE*******");

	const p1 = new Promise((resolve, reject) => {
		//return resolve(this.props.getProductFields(objToSend));

		try {
			return resolve(this.props.getProductFields(objToSend));
		}
		catch(err) { 
			reject(err.message);
		}
		
	
	});

	//const p2 = new Promise(this.props.getClientPaymentsFullInfo(serachObj));
	const p2 = new Promise((resolve, reject) => {

		//return resolve(this.props.getClientPaymentsFullInfo(serachObj));
		try {
			return resolve(this.props.getClientPaymentsFullInfo(serachObj));
		}
		catch(err) { 
			reject(err.message);
		}
		
	});
	
	Promise.all([p1, p2])
	//.then(([{data: response}, {data: response2}] )=> {
    .then((responseX)=> {
	/*Promise.resolve(this.props.getProductFields(objToSend))
	.then((response)=>{*/
		//this.setState(response.NdiFieldsOne); consoleXlog
		consoleXlog("NOOOOOOOOOOOOOERROR-ERROR-TRAP PARALLELE---", responseX); //return false;
		//console.log("ERROR-ERROR-TRAP PARALLELE----", response2); 
		this.setState({ ["isLoaderOpen"]: false}); 
		const response=responseX[0];
		const response2=responseX[1];
		
		const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
		const myClientId=clientProdTransInfo.client.id;
		const isClientLead=myClientId >0?false:true;
		const client_id=response.NdiFieldsOne.data.client_id;
		const istherelawyer=response.NdiFieldsOne.data.isTherePALawyer===false?false:true;
		const domainInLaw=response.NdiFieldsOne.data.domainInLaw;
		const client_type= response.NdiFieldsOne.data.client_type;
		const dossiernextstep=response.NdiFieldsOne.data.dossiernextstep;
		const myClientprov=clientProdTransInfo.address.province;
		//consoleXlog("..HOHOHO response from server .... ");  //consoleXlog(response.NdiFieldsOne.data);
		this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
		this.setState({ 
		["isClientLead"]: isClientLead, ["client_type"]:client_type, 
		["isTherePALawyer"]:istherelawyer, ["domainInLaw"]:domainInLaw, ["neoid"]:client_id,
		["dynamicFormAllData"]: response.NdiFieldsOne.data, ["dossiernextstep"]:dossiernextstep, }); 

		consoleXlog("....HOHOHO myClientprov-myClientprov GOOOOOOOOOOOOOOOOO .... ", myClientprov); 	
		if(myClientprov!==undefined && myClientprov!==null  && myClientprov!==""){
			this.setState({ ["provinceInfotax"]: myClientprov}); 
			consoleXlog("....HOHOHO myClientprov-myClientprov GOOOOOOOOOOOOOOOOO .... _~~__ ", myClientprov); 	
		}	
		//consoleXlog("HOHOHO just assinged to dynamicFormAllData .... "); 	
		consoleXlog("..HOHOHO response  istherelawyer .... "+istherelawyer, "domainInLaw", domainInLaw); 
		
		this.setState({ ["isLoaderOpen"]: false}); //load&close 
		this.setState({ ["listOfTicketsPayments"]: response2.NeoVentesOne, }); 
		this.showToast(t("zqwNFFtxt9_x"), "success");  
		// this.saveUpdateTicketData(null); 
		if(currentTabMain==="tab3"){
			consoleXlog("....HOHOHO AJOOOOOOOOUT DE PRODUIT GOOOOOOOOOOOOOOOOO .... "); 	
			const theticketId=objToSend.ticketId; //this.state.realticket_id;
		  //this.getClientGoTransactionList (theticketId); 
		}else{
			setTimeout(() => {
				consoleXlog("#####HOHOHO saveUpdateTicketData GOOOOOOOOOOOOOOOOO .... "); 	
				//this.showToast(t("zqwNFFtxt10_x"), "warning");  
				this.saveUpdateTicketData(null); //***************load&close  
			}, 1000);   
		}

		// setTimeout(() => {
		// 	//this.showToast(t("zqwNFFtxt10_x"), "warning");  
		// 	this.saveUpdateTicketData(null); //***************load&close  
		// }, 1000);   
		return responseX;
	}).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		consoleXlog(error); 
		window.alert(error);
	});
   }else{
	   this.loadProductsFields_Oldone(objToSend);
   } 

  }

  loadProductsFields_Oldone = (objToSend) => {
	const { t } = this.props;   
	let currentTabMain=this.props.currentTab;
	if(currentTabMain===undefined){
		currentTabMain="tab1";		
	} 

	this.setState({ ["isLoaderOpen"]: true});  
	const this2=this; 
	//consoleXlog("clientOnZendeskSCR ", this.clientOnZendeskSCR);
	if(this.clientOnZendeskSCR===0){
		objToSend.zendeskapi=1;
	}
	objToSend.zendeskapi=1;

	Promise.resolve(this.props.getProductFields(objToSend))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		consoleXlog("ERROR-ERROR-TRAP", response); //return false;
		const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
		const myClientId=clientProdTransInfo.client.id;
		const isClientLead=myClientId >0?false:true;
		const client_id=response.NdiFieldsOne.data.client_id;
		const istherelawyer=response.NdiFieldsOne.data.isTherePALawyer===false?false:true;
		const domainInLaw=response.NdiFieldsOne.data.domainInLaw;
		const client_type= response.NdiFieldsOne.data.client_type;
		const dossiernextstep=response.NdiFieldsOne.data.dossiernextstep;
		//consoleXlog("..HOHOHO response from server .... ");  //consoleXlog(response.NdiFieldsOne.data);
		this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
		this.setState({ 
		["isClientLead"]: isClientLead, ["client_type"]:client_type, 
		["isTherePALawyer"]:istherelawyer, ["domainInLaw"]:domainInLaw, ["neoid"]:client_id,
		["dynamicFormAllData"]: response.NdiFieldsOne.data, ["dossiernextstep"]:dossiernextstep, }); 	
		//consoleXlog("HOHOHO just assinged to dynamicFormAllData .... "); 	
		consoleXlog("..HOHOHO response  istherelawyer .... "+istherelawyer, "domainInLaw", domainInLaw); 
		
		this.setState({ ["isLoaderOpen"]: false}); //load&close 
		this.showToast(t("zqwNFFtxt9_x"), "success"); 
		// this.saveUpdateTicketData(null); 
		if(currentTabMain==="tab3"){
			consoleXlog("....HOHOHO AJOOOOOOOOUT DE PRODUIT GOOOOOOOOOOOOOOOOO .... "); 	
			const theticketId=objToSend.ticketId; //this.state.realticket_id;
		  this.getClientGoTransactionList (theticketId); 
		}else{
			setTimeout(() => {
				consoleXlog("#####HOHOHO saveUpdateTicketData GOOOOOOOOOOOOOOOOO .... "); 	
				//this.showToast(t("zqwNFFtxt10_x"), "warning");  
				this.saveUpdateTicketData(null); //***************load&close  
			}, 1000);   
		} 

		// setTimeout(() => {
		// 	//this.showToast(t("zqwNFFtxt10_x"), "warning");  
		// 	this.saveUpdateTicketData(null); //***************load&close  
		// }, 1000);   
		return response;
	}).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		consoleXlog(error); 
		alert(error);
	});

  }

  loadProductsFieldsOnce = (objToSend) => {
	//##this.setState({ ["isLoaderOpen"]: true}); 
	const { t } = this.props; 
	const this2=this; 
	Promise.resolve(this.props.getProductFields(objToSend))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
		const isClientId=clientProdTransInfo.client.id;
		const isClientLead=isClientId >0?false:true; 
		//consoleXlog("..HOHOHO response from server .... ");  //consoleXlog(response.NdiFieldsOne.data);
		this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
		this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead }); 	
		//consoleXlog("HOHOHO just assinged to dynamicFormAllData .... "); 	
		
		this.setState({ ["isLoaderOpen"]: false});
		this.showToast(t("zqwNFFtxt11_x"), "success"); 
		// this.saveUpdateTicketData(null);  
		// setTimeout(() => {
		// 	this.showToast(t("zqwNFFtxt10_x"), "warning");  
		// 	this.saveUpdateTicketData(null);  
		// }, 10000);   
		return response;
	}).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		alert(error);
	});

  }


  
  handleDynamicFormGenerator = (fullObj) => {
	const products=fullObj.products;  
	const productFields=fullObj.productFields;  
	const fields=fullObj.fields; 
	const fieldOptions=fullObj.fieldOptions;
	const fieldsIdsopt=fullObj.fieldsIdsopt;
	const fieldServices=fullObj.fieldServices; 
	//const case_id=fullObj.case_id;
	const ticketDataLeft=fullObj.optionsData;
	const ticketProds=fullObj.ticketProds; //folders_products
	let ticketDataLeftRec=null;
	const realticket_id=fullObj.ticket_id;
	const clientProdTransInfo=fullObj.clientProdTransInfo;
	const neodesks=fullObj.neodesks;
	//const product=fullObj.product;  
	//const product=fullObj.product;
	let fieldList = []; let text="";
	let dynamicForm = []; let dynamicFormData = []; 
	const typeListx = ["select","checkbox","radio"];
	let selectedFiles=[]; let valueNextStep=[];
	
	if(productFields===undefined){
		return false;
	}

	let promosList00 = productFields.length > 0
    	&& productFields.map((item, i) => { 
		 
		selectedFiles.push([]); valueNextStep.push([]);  
		this.valueNextStepall.push([]); dynamicFormData.push({});
		dynamicForm.push({id:0, check: false, error: false, options: []});
		this.dynamicStackFormRefs[i]=React.createRef();  
	}, this);

	
	//this.setState({ ["valueNextStep"]: valueNextStep });
	this.setState({["dynamicForm"]: dynamicForm, ["realticket_id"]: realticket_id, 
	["selectedFiles"]: selectedFiles, ["valueNextStep"]: valueNextStep,
	["dynamicFormData"]: dynamicFormData, }); 
	 


    let promosList = productFields.length > 0
    	&& productFields.map((item, i) => { 
        
		let fieldBlock=this.getFieldRecord(fields, item.field_id); 
		const x = this.getFieldTypeId(fieldBlock.field_type);
		ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		let FieldData=null;
		let product=this.getProductRecord(products, item.product_type_id);  
		const prodId=product.name;
		let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; }  
		const case_id=ticketProd.id;
		//consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		if(typeListx.includes(fieldBlock.field_type)){
			let fieldOptionsx=null;
			//consoleXlog("QQQQQQQQQQQ222", item.field_id);
			if(fieldsIdsopt.includes(item.field_id)){
				//consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			}
			 
			FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		}else{
			FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		}

		let blockElem =null;
		//consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		//this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		switch (x) {
		  case 0:
			 blockElem = this.genTextField(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;
		  case 1:
			 blockElem = this.genNumberField(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;
		  case 2:
			 blockElem = this.genRadioBox(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;
		  case 3:
			 blockElem = this.genCheckBox(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;	
		  case 4:
				 fieldBlock["isMultiple"]=false; 
				 blockElem = this.genSelectBox(fieldBlock, item, FieldData);
				if(blockElem!==null){
					fieldList.push(blockElem);
				}
				break;
		  case 5:
			 blockElem = this.genTextarea(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;
		case 6:
			 blockElem = this.genDateTimeField(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;	
		case 7:
			 blockElem = this.genDateTimeField(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;
		case 8:
			 blockElem = this.genDateTimeField(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;
		case 9:
			 fieldBlock["selectType"]=1; 
			 blockElem = this.genSelectReactField(fieldBlock, item, FieldData); // react-select
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;	
		case 10:
			 blockElem = this.genInputMaskField(fieldBlock, item, FieldData);
			if(blockElem!==null){
				fieldList.push(blockElem);
			}
			break;
		case 11:
				fieldBlock["isMultiple"]=true; 
				blockElem = this.genSelectBox(fieldBlock, item, FieldData);
			   if(blockElem!==null){
				   fieldList.push(blockElem);
			   }
			   break;							
		case 12:  
				blockElem = this.genFileField(fieldBlock, item, FieldData);
			   if(blockElem!==null){
				   fieldList.push(blockElem);
			   }
			   break;
		case 13:  
				fieldBlock["inputType"]="email"; 
				blockElem = this.genAnyGenericTextField(fieldBlock, item, FieldData);
			  if(blockElem!==null){
				  fieldList.push(blockElem);
			  }
			  break;
		case 14:
				fieldBlock["selectType"]=2; 
				blockElem = this.genSelectReactField(fieldBlock, item, FieldData);
			   if(blockElem!==null){
				   fieldList.push(blockElem);
			   }
			   break;
		case 15:
				fieldBlock["selectType"]=3; 
				blockElem = this.genSelectReactField(fieldBlock, item, FieldData);
			   if(blockElem!==null){
				   fieldList.push(blockElem);
			   }
			   break;					   					  	   
		case 16:
				//fieldBlock["selectType"]=3; 
				blockElem = this.genBlockField(fieldBlock, item, FieldData);
			   if(blockElem!==null){
				   fieldList.push(blockElem);
			   }
			   break;	
		case 17:
				//fieldBlock["selectType"]=3; 
				FieldData.neodesks=neodesks;
				blockElem = this.genBlockFieldCorpo(fieldBlock, item, FieldData);
			   if(blockElem!==null){
				   fieldList.push(blockElem);
			   }
			   break;	   
	    default:
			text = "No valid field found";  
		}
		 	
      return (
        null
      );
	}, this);
	
	const client=clientProdTransInfo.client;
	const transactions=clientProdTransInfo.transactions;
	let transactionId=0;   
	let productId=0;  
	//if(transactions==null || transactions==undefined){ return false; }
	const clienSearch=(client.firstname+' '+client.lastname+' '+client.email);
	// const transactionId=transactions.id;   
	// const productId=transactions.id_products;  
	if(transactions!==null && transactions!==undefined){ 
		// transactionId=transactions.id;   
		// productId=transactions.id_products;  
		if(transactions.id!==null && transactions.id!==undefined){
			transactionId=transactions.id;   
			productId=transactions.id_products;  
			consoleXlog("RESEULT FRM #1 ", productId, "transactionId", transactionId);
		}else if(transactions[0].id!==null && transactions[0].id!==undefined){
			transactionId=transactions[0].id;   
			productId=transactions[0].id_products;  
			consoleXlog("RESEULT FRM #2 ", productId, "transactionId", transactionId);
		}
	} 
	//this.setState({ ["valueClientSearch"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)}); 
    //ZZZZthis.setState({ ["clientInfo"]: newValue})zzzz; productId   transactionId

	//this.setState({["elementForms"]: fieldList, ["clientProdTransInfo"]: clientProdTransInfo, 
	this.setState({["elementForms"]: [], ["clientProdTransInfo"]: clientProdTransInfo, 
	["clientInfo"]: client, ["valueClientSearch"]: clienSearch,
	["productId"]: productId, ["transactionId"]: transactionId}); 
	// const dynamicForm=this.state.dynamicForm;
	// //consoleXlog("RESEULT FRM ", dynamicForm);
	setTimeout(() => {
		const dynamicForm=this.state.dynamicForm;
		//consoleXlog("RESEULT FRM ", dynamicForm);
    }, 3000);
  }
  
  getFieldRecord = (myArr, fieldId) => {
	  let fieldBlock=null; 
	  let promList = myArr.length > 0
    	&& myArr.map((item, i) => {  
		//let fieldBlock=null;
		if(item.id===fieldId){
          fieldBlock=item; 
        }
		 
      return (
        null
      );
    }, this);
	
	return fieldBlock;
  }

  getProductRecord = (myArr, fieldId) => {
	let fieldBlock=null; 
	let promList = myArr.length > 0
		&& myArr.map((item, i) => {  
		//let fieldBlock=null;
		if(item.product_type_id===fieldId){
			fieldBlock=item; 
		}
		
		return (
		null
		);
	}, this);
	
	return fieldBlock;
  }

	 
	getTicketProductRecord = (myArr, fieldId) => {
		let fieldBlock=null; 
		let promList = myArr.length > 0
			&& myArr.map((item, i) => {  
			if(item.product_id===fieldId){
				fieldBlock=item; 
			}
			
			return (
			null
			);
		}, this);
		
		return fieldBlock;
	}
	
  
  
  getFieldTypeId = (fieldType) => {
	  const myArr=['text','number','radio','checkbox','select','textarea','date','datetime','time','selectreact','inputmask', 'selectmultiple', 'file','email','selectreactmany','selectreactmanystack','blockfield','bfcorpo'];
	  let fieldBlock=-3; 
	  let promList = myArr.length > 0
    	&& myArr.map((item, i) => {  
		//let fieldBlock=null;
		if(item===fieldType){
          fieldBlock=i; 
        }
		 
      return (
        null
      );
    }, this);
	
	return fieldBlock;
  }

  getFieldOptionsRecords = (myArr, fieldId) => {
	let fieldBlock=null; 
  	////consoleXlog("mmmmQQmmmmm7777QQQQQQQQQQQ222", fieldId);
	for (let keyVal in myArr) {
		//if (key === 'length' || !widthRange.hasOwnProperty(key)) continue;
		//var value = widthRange[key];
		//const objKey=Object.keys(item); 
		////consoleXlog("mmmmmmmmm7777QQQQQQQQQQQ222", fieldId,  keyVal, myArr[keyVal]);
		if(keyVal===fieldId){ //if(myArr[keyVal]===item.field_id){
			////consoleXlog("goooooooooooo222", fieldId,  keyVal);
			//fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			fieldBlock=myArr[fieldId]; 
			break;
		}
	}
  
	return fieldBlock;
  }

	getTicketDataRecord = (myArr, fieldId) => {
		let fieldBlock=null; 
		let promList = myArr.length > 0
		&& myArr.map((item, i) => {  
			//let fieldBlock=null;
			if(item.product_field_id===fieldId){
				fieldBlock=item; 
			}
			
			return (
			null
			);
		}, this);
	
		return fieldBlock;
	}
  
  
  
  genTextField = (field, productField, FieldData) => {
	  const { t } = this.props;
	  const name=(""+field.name).replace(" ", "");
	  const textLabel=field.text_label;
	  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	  let isRequired=false;
	  const ticketDataLeft=FieldData.ticketDataLeft;
	  let fieldDataval=""; let isError=false;
	  const indexField=FieldData.index;
	  if(productField.id >0){ productfieldid=productField.id; }
	  if(productField.field_id >0){ fieldid=productField.field_id; }
	  if(productField.required >0){ isRequired=true; isError=true;} 
	  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	   
	  if(ticketDataLeft!==null && (ticketDataLeft.id >0 || ticketDataLeft.field_data!==null)){  //if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		  //tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		  if(ticketDataLeft.id >0){ tickeId=ticketDataLeft.id; }
		  fieldDataval=ticketDataLeft.field_data; 
		  if(isRequired && fieldDataval!==""){
			isError=false;
		  }
		} 
	  const elementFormsErrors=this.state.elementFormsErrors;
	  elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	  const fieldDatavals=this.state.elementFormsErrors;
	  fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	  this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
		error: isError, check:isRequired, options: []};
	  this.handleDynamicStateSetter(myObjSetter); 
	  this.dynamicFormRefs[indexField]=React.createRef();
	  //consoleXlog("addSetter gentxtfield ", myObjSetter); 
	  //check: true, error: true
	  //const dynamicForm=this.state.dynamicForm;
 
		
		return ("");
  }

  genNumberField = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		if(isRequired && fieldDataval!==""){
		isError=false;
		}
	} 
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: []};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter genNumberField ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;

	
	return ("");

 
  }

  genDateTimeField = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	const ftype=field.field_type;
	let xInputMode="", xpattern=""; 
	//inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
	if(ftype==="date"){
		xInputMode="date"; 
		xpattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"; 
	}else if(ftype==="datetime"){
		xInputMode="datetime"; 
		xpattern="([0-2][0-9]{3})\-([0-1][0-9])\-([0-3][0-9])T([0-5][0-9])\:([0-5][0-9])\:([0-5][0-9])(Z|([\-\+]([0-1][0-9])\:00))"; 
	}else if(ftype==="time"){
		xInputMode="time"; xpattern="(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]){2}"; 
	}else{
		xInputMode="date"; 
		xpattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"; 
	}

	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		if(isRequired && fieldDataval!==""){
		isError=false;
		}
	} 
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: []};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter genDateTimeField ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;

	
	return ("");
 
  }

  genInputMaskField = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	const mask=field.mask;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
	  } 
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: []};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();

	const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
	const letter = /(?!.*[DFIOQU])[A-Z]/i;
	const digit = /[0-9]/;
	const maskCanadaZipcode = [firstLetter, digit, letter, " ", digit, letter, digit];
	let maskApplied=null;
	if(mask==="canada-zipcode"){
		maskApplied=maskCanadaZipcode;
	}else{
		maskApplied=mask;
	}

	//consoleXlog("addSetter gentxtfield ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;
  	//inputProps={{placeholder: 'Input 1', 'data-state': 'Data State 1'}}
   
	  
	  return ("");
 
}

  genTextarea = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;  let fieldDataRawval="";
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	// if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	// 	tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
	// 	if(isRequired && fieldDataval!==""){
	// 	isError=false;
	// 	}
	// } 
	//////////////////////////////////////////////////////////////////////////////
   
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		  fieldDataRawval=ticketDataLeft.field_data_raw; 
		  if(isRequired && (fieldDataval!=="" || fieldDataRawval!=="")){
		  isError=false;
		  }
	  } 
  
	  const fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
	  /*let fieldDataRaw=[];
	  try{
		fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
	  }
	  catch(error){ 
        //throw(error); 
		try{
			//fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
			const fieldDataRawvalx=fieldDataRawval.replace("\n", "~~");
			fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawvalx); 
		  }
		  catch(error){ }
      }*/

	

	  if(fieldDataRawval!=="" && fieldDataRaw!==null && fieldDataRaw.length >0){
		  fieldDataval=fieldDataRaw[0]; 
	  }
	/////////////////////////////////////////////////////////////////////////////
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: [fieldDataval]};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter genTextarea ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;

	
	return ("");
 
  }

  genRadioBox = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let fieldSet=null; //field.field_dataset;
	if(field.field_dataset!==""){
		fieldSet= JSON.parse(field.field_dataset);
	}

	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false; let fieldDataRawval="";
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		fieldDataRawval=ticketDataLeft.field_data_raw; 
		if(isRequired && fieldDataval!==""){
		isError=false;
		}
	} 
	const fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: fieldDataRaw};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter genCheckBox ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;
	//fieldDataRaw=ticketDataLeft.field_data_raw===""?"":JSON.parse(ticketDataLeft.field_data_raw); 

	
	return ("");

  
  }

  genCheckBox = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let fieldSet=null; //field.field_dataset;
	if(field.field_dataset!==""){
		fieldSet= JSON.parse(field.field_dataset);
	}

	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false; let fieldDataRawval="";
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		fieldDataRawval=ticketDataLeft.field_data_raw; 
		if(isRequired && fieldDataval!==""){
		isError=false;
		}
	} 
	const fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: fieldDataRaw};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter genCheckBox ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;
	//fieldDataRaw=ticketDataLeft.field_data_raw===""?"":JSON.parse(ticketDataLeft.field_data_raw); 

	
	return ("");
 
  }

  genSelectBox = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let fieldSet=null; //field.field_dataset;
	const fieldOptions=FieldData.fieldOptions;
	const isMultiple=field.isMultiple; 
	//consoleXlog("aaaaoooooooooooooooohhhh 000", field.field_dataset);
	if(field.field_dataset!=="" && field.field_dataset!==null){
		fieldSet= JSON.parse(field.field_dataset);
	}else if(fieldOptions!==null){
		fieldSet=[];
		//consoleXlog("aaaaoooooooooooooooohhhh");
		//fieldSet= JSON.parse(field.field_dataset);
		for (const element of fieldOptions) {
			////consoleXlog(element);
			//consoleXlog("oooooooooooooooohhhh", element);
			fieldSet.push({text:element.text_label, value:element.id});
		}

		//consoleXlog("aaaaoooooooooooooooohhhh zz", fieldSet);
	}

	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;  let fieldDataRawval="";
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		fieldDataRawval=ticketDataLeft.field_data_raw; 
		if(isRequired && fieldDataval!==""){
		isError=false;
		}
	} 
	 
	const fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 

	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: []};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter genSelectBox ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;
	 

	return ("");
  }

  genSelectReactField = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const selectType=field.selectType; 
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false; let fieldDatavalLabel="";
	const indexField=FieldData.index;   let fieldDataRawval="";  
	let myListOptions=[]; let myListOptions2=[]; 
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		fieldDataRawval=ticketDataLeft.field_data_raw; 
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
		//const fieldDatavalraw=ticketDataLeft.field_data; 
	}

	// let fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
	let fieldDataRaw=(fieldDataRawval==="" || fieldDataRawval===null)?[]:JSON.parse(fieldDataRawval); 

	let fieldSet=null; //field.field_dataset;
	const fieldOptions=FieldData.fieldOptions;
	//consoleXlog("aaaaoooooooooooooooohhhh 000", field.field_dataset);
	if(field.field_dataset!=="" && field.field_dataset!==null){
		//fieldSet= JSON.parse(field.field_dataset);
		myListOptions= JSON.parse(field.field_dataset);
	}else if(fieldOptions!==null){
		fieldSet=[];
		//consoleXlog("aaaaoooooooooooooooohhhh");
		//fieldSet= JSON.parse(field.field_dataset);
		for (const element of fieldOptions) { 
			//consoleXlog("oooooooooooooooohhhh", element);
			//fieldSet.push({text:element.text_label, value:element.id});
			myListOptions.push({ value: element.id, label: element.text_label });
		}
		//orderOptions([colourOptions[0], colourOptions[1], colourOptions[3]])

		//consoleXlog("aaaaoooooooooooooooohhhh zz", fieldSet);
	}

	let fieldDataRawNext=[];
	const valueNextStep=this.state.valueNextStep;
	if(selectType===3){
		for (const element of fieldDataRaw) {
			//fieldDataRawNext.push({ value: element.value, label: element.label, isFixed:true });
			fieldDataRawNext.push({ value: element.value, label: element.label, isFixed:false });
			//myListOptionsWellPOZO  
		} 
		valueNextStep[indexField]= fieldDataRawNext;
		//this.setState({ ["valueNextStep"]: fieldDataRawNext });
		//this.setState({ ["valueNextStep"]: valueNextStep });
		this.setState({ ["valueNextStep"]: valueNextStep, ["valueNextStepgost"]: fieldDataRawNext });
		//fieldDataRaw  
		//consoleXlog("QQQQQQQQQQQQaaaaoooooooooooooooohhhh qq", valueNextStep);
	}

	for (const element of myListOptions) {  
		if(element.id!==undefined && element.text_label!==undefined){
			myListOptions2.push({ value: element.id, label: element.text_label });   
		}else if(element.value!==undefined && element.label!==undefined){
			myListOptions2.push({ value: element.value, label: element.label });  
		}
		//myListOptions2.push({ value: element.id, label: element.text_label });
		if(element.value===fieldDataval){
			fieldDatavalLabel=element.label;
			//break;
		}
	}

	if(selectType===3){
		for (let k=0; k<myListOptions2.length; k++) {
			//fieldDataRawNext.push({ value: element.value, label: element.label, isFixed:true });
			//if(myListOptions2[k].value===fieldDataRawNext.includes(myListOptions2[k].value)) 
			if(fieldDataRawNext.includes(myListOptions2[k].value)){
				//myListOptions2[k]["isFixed"]=true;
				myListOptions2[k]["isFixed"]=false;
				//myListOptions2[k]["key"]="kx"+indexField;
			}else{ myListOptions2[k]["isFixed"]=false;   } 
		} 
		//fieldDataRaw  myListOptionsWellPOZO 
		//this.setState({ ["valueNextStepall"]: myListOptions2 });
		this.valueNextStepall[indexField]=myListOptions2;
	}

	//consoleXlog("AAAAAAAAAAAAAAAAAAAA", selectType, myListOptions2); 
	
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: fieldDataRaw}; //fieldDataRaw
	    
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter genSelectReactField genSelectReactField ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;
 
  return("");
}

handleInputChange = input => {
    this.setState({ stackMenuIsOpen: !!input });
}

genFileField = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	let myListOptions=[]; 
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
	}

	/////////////////////////////////////////////////////////////
	
	let fieldSet=null; //field.field_dataset; 
	//consoleXlog("Gooooooooooooooooooohhhh 000///000000 ", ticketDataLeft);
	////consoleXlog("aaaaoooooooooooooooohhhh 000///000000 ", ticketDataLeft.field_data_raw);
	// if(field.field_dataset!=="" && field.field_dataset!==null){
	// 	//fieldSet= JSON.parse(field.field_dataset);
	// 	myListOptions= JSON.parse(field.field_dataset);
	// }
	if(ticketDataLeft!==null && ticketDataLeft.field_data_raw!=="" && field.field_data_raw!==null){
		//fieldSet= JSON.parse(field.field_dataset);
		myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	}
	/////////////////////////////////////////////////////////////

	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: []};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter gentxtfield ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;
 
  	  
	  return ( "");
}


genBlockField = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	let myListOptions=[]; 
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
	}

	/////////////////////////////////////////////////////////////
	
	let fieldSet=null; //field.field_dataset; 
	//consoleXlog("Gooooooooooooooooooohhhh 000///000000 ", ticketDataLeft);
	////consoleXlog("aaaaoooooooooooooooohhhh 000///000000 ", ticketDataLeft.field_data_raw);
	// if(field.field_dataset!=="" && field.field_dataset!==null){
	// 	//fieldSet= JSON.parse(field.field_dataset);
	// 	myListOptions= JSON.parse(field.field_dataset);
	// }
	if(ticketDataLeft!==null && ticketDataLeft.field_data_raw!=="" && field.field_data_raw!==null){
		//fieldSet= JSON.parse(field.field_dataset);
		myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	}
	/////////////////////////////////////////////////////////////

	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: myListOptions};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter gentxtfield ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;
 
  	  
	  return ( "");
}

genBlockFieldCorpo = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	let myListOptions=[]; 
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
	}
	const neodesks=FieldData.neodesks; 

	consoleXlog("genBlockFieldCorpo-genBlockFieldCorpo ", productField.id, ticketDataLeft); 

	/////////////////////////////////////////////////////////////
	
	let fieldSet=null; //field.field_dataset; 
	//consoleXlog("Gooooooooooooooooooohhhh 000///000000 ", ticketDataLeft);
	////consoleXlog("aaaaoooooooooooooooohhhh 000///000000 ", ticketDataLeft.field_data_raw);
	// if(field.field_dataset!=="" && field.field_dataset!==null){
	// 	//fieldSet= JSON.parse(field.field_dataset);
	// 	myListOptions= JSON.parse(field.field_dataset);
	// }
	if(ticketDataLeft!==null && ticketDataLeft.field_data_raw!=="" && field.field_data_raw!==null){
		//fieldSet= JSON.parse(field.field_dataset);
		myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	}else{
		//consoleXlog("###NeogenBlockFieldCorpo--neodesks******* ", neodesks);
		if(neodesks.length >0){
			let myNeodeskData=null;
			for(let neodeskData of neodesks){
				consoleXlog("###NeogenBlockFieldCorpo--neodesks******* ...0", field.extraconfig, neodeskData.deskapp, neodeskData);
				if(neodeskData.deskapp===field.extraconfig && neodeskData.desk_id==="1"){
					consoleXlog("###NeogenBlockFieldCorpo--neodesks******* ...1");
					myNeodeskData=neodeskData;
					break;
				}
			}
			if(myNeodeskData!==null){
				//const definition=myNeodeskData.definition;
				const definition= JSON.parse(myNeodeskData.definition);
				if(definition.data!==undefined){
					definition.neo_desk_id=myNeodeskData.id;
					//myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
					myListOptions= definition;
					consoleXlog("###NeogenBlockFieldCorpo--neodesks******* WEEEEEEEE ", myListOptions);
				}
			}
		}
	}
	/////////////////////////////////////////////////////////////

	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: myListOptions};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	consoleXlog("genBlockFieldCorpo addSetter gentxtfield ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;
 
  	  
	  return ( "");
}

 

genAnyGenericTextField = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const inputType=field.inputType;
	const placeholder=field.placeholder;
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
	  } 
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: []};
	this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter gentxtfield ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;

	  
	  return ("");
}

  genTextField0000 = (field, productField, FieldData) => {
	const { t } = this.props;
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	return (<div key={productField.id} className="form-group"> 
		  <TextField name={name} id={name} label={textLabel} /> 		 
		</div>);
  }

  getFieldRecordByName = (myArr, fieldName) => {
		let fieldBlock=null; 
		//console.log("wooooooooooooop ", myArr.length);		
		let promList = myArr.length > 0
		&& myArr.map((item, i) => {  
		//let fieldBlock=null;
		const namelc=(""+item.name).replace(" ","");
		//console.log("wooooooooooooop item.name vs fieldName ", item.name, fieldName);		 
		if(namelc===fieldName){   //if(item.name===fieldName)
			fieldBlock=item; 
		}
		
		return (
		null
		);
	}, this);
	
	return fieldBlock;
  }

  getFieldRecordx = (myArr, fieldId) => {
	let fieldBlock=null; 
	let promList = myArr.length > 0
	  && myArr.map((item, i) => {  
	  //let fieldBlock=null;
	  let zendesk_id=item.zendesk_id;
	  if(zendesk_id===undefined || zendesk_id===null){
		zendesk_id=""; 
	  }else{ zendesk_id=zendesk_id.replace("=>", ":");  }
	  if(item.id===fieldId || (zendesk_id!==undefined && zendesk_id===fieldId)){
		fieldBlock=item; 
	  }
	   
	return (
	  null
	);
  }, this);
  
  return fieldBlock;
}

  getFullObjectField = (idDBField, zendeskField) => {
	const fullObj=this.state.dynamicFormAllData;
	const products=fullObj.products;  
	const productFields=fullObj.productFields;  
	const fields=fullObj.fields; 
	const fieldOptions=fullObj.fieldOptions;
	const fieldsIdsopt=fullObj.fieldsIdsopt;
	const fieldServices=fullObj.fieldServices; 
	//const case_id=fullObj.case_id;
	const ticketDataLeft=fullObj.optionsData;
	const ticketProds=fullObj.ticketProds; //folders_products
	let ticketDataLeftRec=null;
	const realticket_id=fullObj.ticket_id;
	const clientProdTransInfo=fullObj.clientProdTransInfo;
	//const product=fullObj.product;  
	//const product=fullObj.product;
	let fieldList = []; let text="";
	let dynamicForm = []; let dynamicFormData = []; 
	const typeListx = ["select","checkbox","radio"];
	let selectedFiles=[]; let valueNextStep=[];
	
	if(productFields===undefined){
		return null;
	}

	let blockElemData=null;

	consoleXlog("QAAAAAAAAAAA", idDBField, zendeskField);
	//this.setState({ ["valueNextStep"]: valueNextStep });
	const field_idx=zendeskField!==null?zendeskField:idDBField;
	let fieldBlock=this.getFieldRecordx(fields, field_idx);  
	consoleXlog("xQAAAAAAAAAAA", fieldBlock);
	let promosListx = productFields.length > 0
    	&& productFields.map((item, i) => { 
        if(fieldBlock!==null && item.field_id===fieldBlock.id){
		 
		//let fieldBlock=this.getFieldRecord(fields, item.field_id); 
		//const x = this.getFieldTypeId(fieldBlock.field_type);
		ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		let FieldData=null;
		let product=this.getProductRecord(products, item.product_type_id);  
		const prodId=product.name;
		let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; }  
		const case_id=ticketProd.id;

		FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
			case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};

		//consoleXlog("QQQQQQQQQQQ1111", item.field_id);
				 
			//fieldBlock, item, FieldData
			//blockElem = this.genTextField(fieldBlock, item, FieldData);
			blockElemData= {fieldBlock:fieldBlock, productFields:item, FieldData:FieldData};
			//return {fieldBlock:fieldBlock, productFields:item, FieldData:FieldData};
			consoleXlog("blockElemData", blockElemData); 
			return (
				null
			  );
		}
		 
      return (
        null
      );
	}, this);

	/////////////////////////////////////////////////////////////// 


    /*let promosList = productFields.length > 0
    	&& productFields.map((item, i) => { 
        
		let fieldBlock=this.getFieldRecord(fields, item.field_id); 
		const x = this.getFieldTypeId(fieldBlock.field_type);
		ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		let FieldData=null;
		let product=this.getProductRecord(products, item.product_type_id);  
		const prodId=product.name;
		let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; }  
		const case_id=ticketProd.id;
		//consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		if(typeListx.includes(fieldBlock.field_type)){
			let fieldOptionsx=null;
			//consoleXlog("QQQQQQQQQQQ222", item.field_id);
			if(fieldsIdsopt.includes(item.field_id)){
				//consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			}
			 
			FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		}else{
			FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		}

		let blockElem =null;
		//consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		//this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 

		//fieldBlock, item, FieldData
		//blockElem = this.genTextField(fieldBlock, item, FieldData);

		 
      return (
        null
      );
	}, this);*/
	
	 
	 
	//this.setState({ ["valueClientSearch"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)}); 
    //ZZZZthis.setState({ ["clientInfo"]: newValue})zzzz; productId   transactionId
 
	// const dynamicForm=this.state.dynamicForm;
	// //consoleXlog("RESEULT FRM ", dynamicForm);

	return blockElemData;
	 
  }



  //this.props.addOrUpdateTicketData
  handleSubmitTicketData = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const product_field_id = this.state.product_field_id;
    const case_id = this.state.case_id;
    const field_data = this.state.field_data;
    const field_data_raw = this.state.field_data_raw;
    const is_public = this.state.is_public;
    const creation_date = this.state.creation_date;
    const user_creation_id = this.state.user_creation_id;
    const user_update_id = this.state.user_update_id;
	const last_update = this.state.last_update;
	const realticket_id=this.state.realticket_id; 
    const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	const clientOnZendeskSCR=this.clientOnZendeskSCR;
	
	// <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	// 		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	// 		onClick={this.handleSubmitTicketData}> 
	const fieldname=event.currentTarget.dataset.fieldname;
	const index=event.currentTarget.dataset.index;
	let tickeId=0;
	const tickeIdx=parseInt(event.currentTarget.dataset.id);
	const caseid=event.currentTarget.dataset.caseid;
	const productfieldid=event.currentTarget.dataset.productfieldid;
	const fieldid=event.currentTarget.dataset.fieldid;
	const required=event.currentTarget.dataset.required;
	const fileshere=event.currentTarget.dataset.fileshere; 
	const evalregexp=event.currentTarget.dataset.evalregexp;
	const editlabelText=event.currentTarget.dataset.filelabel; //editlabeltext;
	const fieldblockelem=event.currentTarget.dataset.fieldblockelem;

	const movingxtoy=event.currentTarget.dataset.movingxtoy;
	const movingIndexFrom =""+ this.state.movingIndexFrom;
	const movingIndexTo =""+ this.state.movingIndexTo;
	const ftype=event.currentTarget.dataset.datetimeformat;

	const dynamicForm=this.state.dynamicForm; // handleSubmitTicketDataQQQQ
	const elementObj=dynamicForm[index]; 
	let elemVal=elementObj["'"+fieldname+"'"]===null?"":elementObj["'"+fieldname+"'"];
	const options= dynamicForm[index]["options"];
	const deleteGo=event.currentTarget.dataset.delete;
	const deleteFileName=event.currentTarget.dataset.deletefile;
	//let field_data_rawx=options.length===0?"":JSON.stringify(options);
	let field_data_rawx=(options!==null && options.length >0)?JSON.stringify(options):"";
	let isfileshere=0;  let idNeoId=this.state.neoid; // 0;
	const client_type=this.state.client_type;
	//this.setState({["dynamicForm"]: dynamicForm }); 
	const tickeIdNeo=parseInt(dynamicForm[index]["id"]);
	if(tickeIdNeo >0 && tickeIdx===0){ tickeId=tickeIdNeo; }else if(tickeIdx >0){ tickeId=tickeIdx; }

	let pacx=" fieldname="+fieldname+" index="+index+" dataId="+tickeId+" caseid="+caseid+" realticket_id="+realticket_id+
	"\n productfieldid="+productfieldid+" fieldid="+fieldid+" required="+required+" zx="+elemVal+" raw="+field_data_rawx;
	 
	//consoleXlog("...field_data_rawx @@index@@@ ", index, tickeIdx, tickeIdNeo, options.length, "options",options, "field_data_rawx",field_data_rawx); 
	if(fieldname!==undefined && fieldname.indexOf("-bfcorpo-")>-1 && field_data_rawx===""){
		field_data_rawx=JSON.stringify(options);
		consoleXlog("...field_data_rawx @@index@@@22 ", index, tickeIdx, tickeIdNeo, options.length, "options",options); 
	}

	//const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	// let myNdiTicketDataLeft = null;
	// const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
	// 	field_data: elemVal, field_data_raw: field_data_rawx, 
	// 	fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 

	const selectedFiles=this.state.selectedFiles;
	const labelnodel=this.state.labelnodel;
	/*const selectedFile= selectedFiles[index]; 
	if(selectedFile.length==0){
		selectedFile.push(event.target.files[0]);
	}else{
		selectedFile[0]=event.target.files[0];
	}*/	

	const valueNextStepall=this.state.valueNextStep;  
	if(fieldid==="45"){ //nextstep status  myListOptionsWellPOZO 
		//let valueNextStep=this.state.valueNextStep[index];  
		let valueNextStep=valueNextStepall[index];     
		const valueNextStepgost=this.state.valueNextStepgost;   
		//valueNextStep=valueNextStepgost;
		if(valueNextStepgost!==null){ valueNextStep=valueNextStepgost; }
		//consoleXlog(index+"...valueNextStep ", valueNextStep, "vgost", valueNextStepgost); 
		if(valueNextStep.length >0){
			for(let z=0; z<valueNextStep.length; z++){
				valueNextStep[z]["isFixed"]=true;
			}
			field_data_rawx=JSON.stringify(valueNextStep);
			valueNextStepall[index]=valueNextStep;  
			// const field_data_rawx0=JSON.stringify(valueNextStep);    
			 //this.showToastError("weeeeeeeeeeeeeeeeeeeeeeeeee "+field_data_rawx0);   
			 consoleXlog(index+"...field_data_rawx @@@@@ ",  field_data_rawx); 
			// return false;
		}

		//consoleXlog("YOOOOOOOOOO", valueNextStepall); 
	}  
	 
	if(deleteGo!==undefined && deleteGo!==""){   //&& deleteGo==="delete")
		field_data_rawx= deleteFileName;
	}

	consoleXlog("moment YOOOOOOOOOO moment=", ftype, elemVal); 
	
	if(ftype!==undefined && ftype==="datetime" && elemVal!==null && elemVal!==""){ 
		//fieldDataval=moment(fieldDataval).utc().format("YYYY-MM-DDTHH:mm:ss");
		//fieldDataval=moment(fieldDataval).format("YYYY-MM-DDTHH:mm:ss");
		elemVal=moment(elemVal).utc().format(); //+":00Z"; //format("YYYY-MM-DDTHH:mm:ssz");
		consoleXlog("moment YOOOOOOOOOO moment=", elemVal); 
		 
	}


	pacx=" fieldname="+fieldname+" index="+index+" dataId="+tickeId+" caseid="+caseid+" realticket_id="+realticket_id+
	"\n productfieldid="+productfieldid+" fieldid="+fieldid+" required="+
	required+" Data="+elemVal+" Dataraw="+field_data_rawx+" deleteGo="+deleteGo;
	
	let myNdiTicketDataLeft = null;
	const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
		field_data: elemVal, field_data_raw: field_data_rawx, 
		fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, client_type:client_type, 
		labelnodel:labelnodel, }; 
		myNdiTicketDataLeftx.neoid=idNeoId;
		 
	if(deleteGo!==undefined && deleteGo!==""){ 
		myNdiTicketDataLeftx.deleteFileGo=deleteGo; //"delete"; 
		//myNdiTicketDataLeftx.deleteFileName=deleteFileName; 
		//field_data_rawx= deleteFileName;  editlabelText
		if(deleteGo==="editlabel" || deleteGo==="reqMoreDoc" || deleteGo!=="delete"){ myNdiTicketDataLeftx.editlabelText=editlabelText; }
	}	
	//const fileshere=event.currentTarget.dataset.fileshere;
	if(fileshere!==undefined && selectedFiles[index].length===0 ) {
		this.showToastError("Sorry, you have to submit a file to be able to save.");
		return false;
	} 
	 
	//const selectedFile= selectedFiles[index]; 
	//if(selectedFile.length==0){
	let totFiles=0; 	
	if(fileshere!==undefined && selectedFiles[index].length >0) {
		isfileshere=1;
		//myNdiTicketDataLeftx["fileshere"]=1;
		// const dataFile = new FormData(); 
		
		//const selectedFile= selectedFiles[index][0]; 
    	//// dataFile.append('file', selectedFile); 
		////myNdiTicketDataLeftx["file"]=selectedFile;

		myNdiTicketDataLeft = new FormData(); 
		//myNdiTicketDataLeft.append('file', selectedFile, selectedFile.name); 
		//myNdiTicketDataLeft.append('xfiletype', selectedFile.type);
		//let totFiles=0; 
		//const selectedFilesx= selectedFiles[index][0]; 
		for (let i = 0 ; i < selectedFiles[index][0].length ; i++) {
			//myNdiTicketDataLeft.append("images", images[i]);
			const selectedFile= selectedFiles[index][0][i]; //selectedFiles[index][i][0]; 
			myNdiTicketDataLeft.append('file[]', selectedFile, selectedFile.name); 
			myNdiTicketDataLeft.append('xfiletype', selectedFile.type); 
			totFiles++;
			//const selectedFile2= selectedFiles[index][i][1]; 
			consoleXlog("OOOOOOOOOOoooooo file ", selectedFile.name);
			//consoleXlog("OOOOOOOOOOoooooo file ", selectedFile2.name);
		}
		myNdiTicketDataLeft.append('isMULTIPART', 1); 
		myNdiTicketDataLeft.append('totFiles', totFiles); 
		 
		//consoleXlog("wooooooooooooooooooooooy", selectedFile.name); 
	  
		myNdiTicketDataLeft.append('fileshere', 1); 
		myNdiTicketDataLeft.append('id', tickeId); 
		myNdiTicketDataLeft.append('product_field_id', productfieldid); 
		myNdiTicketDataLeft.append('case_id', caseid); 
		myNdiTicketDataLeft.append('field_data', elemVal); 
		myNdiTicketDataLeft.append('field_data_raw', field_data_rawx); 
		myNdiTicketDataLeft.append('fieldname', fieldname); 
		myNdiTicketDataLeft.append('fieldid', fieldid); 
		myNdiTicketDataLeft.append('realticket_id', realticket_id);
		myNdiTicketDataLeft.append('client_type', client_type); 
		myNdiTicketDataLeft.append('labelnodel', labelnodel); 
		myNdiTicketDataLeft.append('neoid', idNeoId); 
		 
		 
		 
		// myNdiTicketDataLeft.append('file', zxxxx); 
		// myNdiTicketDataLeft.append('file', zxxxx); 
 
	}else{ myNdiTicketDataLeft = myNdiTicketDataLeftx; }

	//consoleXlog("INDEX SEND FIELD ", index, options); 
	if(fieldblockelem!==undefined){
		myNdiTicketDataLeft.fieldblockelem=fieldblockelem; 
	}else if(fieldname!==undefined && fieldname.indexOf("-blockfield-")>-1){
		myNdiTicketDataLeft.fieldblockelem="edit"; 
	}
	
	myNdiTicketDataLeft.clientOnZendeskSCR=clientOnZendeskSCR;

	//dynamicForm={dynamicForm} 
	// if(evalregexp!==""){

	// }  
	//window.alert(JSON.stringify(myNdiTicketDataLeft));
	//this.showToastError(totFiles+" totFiles "+fileshere+" laaaaaaaaaaaaast message to show \n"+pacx); return false;
	consoleXlog("handleSubmitTicketData labelnodel ", labelnodel); //return false;  
	
	if(caseid===0 || productfieldid===0){
		this.showToastError(t("zqwNFFtxt17_x"));
		return false;
	}

	// if((elemVal==="" && field_data_rawx==="") && required==="true" && isfileshere===0){
	// 	this.showToastError("Sorry, we do not save empty data. Make sure it is filled first!");
	// 	return false;
	// }

	if((elemVal==="" && field_data_rawx==="") && (caseid===0 || productfieldid===0) && required==="true" && isfileshere===0){
		this.showToastError(t("zqwNFFtxt17_x")+"..");
		return false;
	}

	//consoleXlog("RegExp  RegExpRegExpRegExp ", evalregexp, elemVal); 
	if(required==="true" && isfileshere===0 && evalregexp!==undefined){
		consoleXlog("################GO ", elemVal, "evalregexp", evalregexp); 
		//const pattr = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const pattr= new RegExp(evalregexp); // /evalregexp/;    
		if(evalregexp!=="" && !elemVal.match(pattr)){  //re.test(email)   
		//if(evalregexp!=="" && !elemVal.match(pattr)){  //if(evalregexp!=="" && !elemVal.match(evalregexp)){
			this.showToastError(t("zqwNFFtxt18_x"));
			return false;
		} 
	}
	

	const isClientLead=this.state.isClientLead; //const xidnoo=parseInt(resp[0]);
	if(isClientLead===true && fieldid>29 && fieldid<37){
		this.showToastError(t("zqwNFFtxt14_x"));
		return false;
	}

	 
	if(movingxtoy!==undefined && movingxtoy!==""){ 
		//if(deleteGo!==undefined && deleteGo!==""){ 
		if(movingIndexFrom!==movingIndexTo && movingIndexFrom!=="-1" && movingIndexTo!=="-1"){
			//go 
			myNdiTicketDataLeft.deleteFileGo=movingxtoy;
			const commandx={movingIndexFrom:movingIndexFrom, movingIndexTo:movingIndexTo};
			myNdiTicketDataLeft.editlabelText=commandx;
		}else{
			this.showToastError(t("zqwNFFtxt15_x"));
			return false;
		}	
		//myNdiTicketDataLeftx.deleteFileGo=deleteGo; //"delete"; 
		//myNdiTicketDataLeftx.deleteFileName=deleteFileName; 
		//field_data_rawx= deleteFileName;  editlabelText
		//if(deleteGo==="editlabel" || deleteGo==="reqMoreDoc" || deleteGo!=="delete"){ myNdiTicketDataLeftx.editlabelText=editlabelText; }
	}

	//this.showToastError("nooooooooooooooooooooooooooo ooooooooooooooooooh!\n"+pacx+" regxp="+evalregexp); 	return false; 
	const this00=this; 
	Promise.resolve(this.props.addOrUpdateTicketData(myNdiTicketDataLeft))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		const resp=response.NdiFields;
		//consoleXlog("HOHOHO response from server .... ");  //consoleXlog(resp); //NdiFields
		//this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
		const xid=parseInt(tickeId);
		if(xid ===0){
			const dynamicFormx=this.state.dynamicForm; 
			// const elementObj=dynamicFormx[index]; 
			// const elemVal=elementObj["'"+fieldname+"'"];
			// const field_data_rawx="";
			const xidn=parseInt(resp[0]);
			dynamicFormx[index]["id"]=xidn;
			dynamicFormx[index]["error"]=false;
			this.setState({["dynamicForm"]: dynamicFormx }); 
			 
			//consoleXlog("weeeeeeeeeeeeeeeeeeeeeee save applied");
			//consoleXlog("weeeeeeeeeeeeeeeeeeeeeee save applied");
		}

		let objDataResp=null;
		const xidnoo=parseInt(resp[0]);
		if(xidnoo >0){
			const objData= resp[2] ? resp[2] : resp[3] && resp[3]?.field_data_raw ? resp[3] : null;
			if(objData!==null && objData!==undefined){
				const dynamicFormData=this.state.dynamicFormData;
				dynamicFormData[index]=objData; 
				objDataResp=objData; 
				const dynamicFormAllData=this.state.dynamicFormAllData;
				const optionsData=dynamicFormAllData.optionsData;
				//consoleXlog("BIIIIIIIIIIIINGO000", optionsData);
				let fndIndexy=-3;
				for (let p=0; p<optionsData.length; p++){
					const xoptionsData=optionsData[p];
					//consoleXlog("KKKBIIIIIIIIIIIINGO001", xoptionsData);
					if(""+xoptionsData.product_field_id===""+productfieldid){
						//consoleXlog("KKBIIIIIIIIIIIINGO CATCH-ADD", xoptionsData.product_field_id, xoptionsData.id);
						//tickeId=parseInt(xoptionsData.id);
						fndIndexy=p;
						break;
					}
				}

				if(fndIndexy >-1){ 	optionsData[fndIndexy]=objData; 
				}else{ optionsData.push(objData); }
				dynamicFormAllData.optionsData=optionsData;

				this.setState({["dynamicFormData"]: dynamicFormData, ["dynamicFormAllData"]: dynamicFormAllData, 
				["openFileDelete"]: false, ["openFileLabelUpdate"]: false }); 
				if(movingxtoy!==undefined && movingxtoy!==""){  
					this.setState({["movingIndexFrom"]: "-1", ["movingIndexTo"]:"-1"});   
				}
				// if(fieldblockelem!==undefined && fieldblockelem==="add" && 
				// objData.field_data_raw!==undefined && objData.field_data_raw!==null){
				if(fieldblockelem!==undefined && fieldblockelem==="add" && 
				objData.field_data_raw!==undefined && objData.field_data_raw!==null){
					const dynamicForms=this.state.dynamicForm;  
					//const options= dynamicForms[index]["options"]; 
					//const rowObj=options[index]; 
					dynamicForms[index]["options"]=JSON.parse(objData.field_data_raw); 
					consoleXlog("AAAAAAAYAAAAAAYAY new data index=", index);
					this.setState({ ["dynamicForm"]: dynamicForms}); 
				}

				consoleXlog("wooooooooooooooooooooooy new data index=", index); consoleXlog(objData); 
				consoleXlog(dynamicFormData);
			}

		}


		if(fieldid==="1" || fieldid==="72"){
			//const eventObj={fieldId: fieldid,	dataval:,  data_raw};
			let dataGozend=null; let dataGozendraw=null; 
			if(fieldid==="72"){ 
				dataGozend=false;
				if(field_data_rawx.indexOf("yes") >-1){ dataGozend=true; dataGozendraw=null; }
			}else{ dataGozend=elemVal; dataGozendraw=field_data_rawx; }
			//const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
			const eventObj={fieldId:fieldid, dataval:dataGozend, data_raw:dataGozendraw}; 
			consoleXlog("00TRYING UPDATING ZENDESK IF EXISTS FIELD "+fieldid); 
			this00.saveUpdateTicketDataToZendesk(eventObj);
		}

		

		if(fileshere!==undefined && selectedFiles[index].length >0){
			//remove file selection
			//const selectedFiles=this.state.selectedFiles;
			//let selectedFile= selectedFiles[index]; 
			// if(selectedFile.length==0){
			// 	selectedFile.push([]);
			// }else{
			// 	selectedFile=[];
			// }
			selectedFiles[index]=[]; //selectedFile;  openFileDelete  openFileLabelUpdate  
			this.setState({["selectedFiles"]: selectedFiles, ["labelnodel"]:-4}); 
		}else{
			// const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
			// this.saveUpdateTicketDataToZendesk(eventObj); 
			// consoleXlog("UPDATING ZENDESK IF EXISTS FIELD "+fieldid); 
		}

		this.setState({["labelnodel"]:-4});
		if(fieldid==="123"){  
			//avocat_pa isTherePALawyer
			if(objDataResp!==null && objDataResp!==undefined){
				const dataraw=objDataResp.field_data_raw;
				if(dataraw!==null && dataraw!==undefined){
					if(dataraw.indexOf("yes")> -1){
						this.setState({["isTherePALawyer"]:true});
						//consoleXlog("SHOW COORDONNÉES AVOCAT  FIELD "+fieldid); 
					}else{
						this.setState({["isTherePALawyer"]:false});
						//consoleXlog("DO NOT SHOW COORDONNÉES AVOCAT  FIELD "+fieldid); 
					}
				}else{
					this.setState({["isTherePALawyer"]:false});
					//consoleXlog("...2 DO NOT SHOW COORDONNÉES AVOCAT  FIELD "+fieldid); 
				}
			} 
		}else if(fieldid==="44"){  
			// domainInLaw  domainInLaw  
			if(objDataResp!==null && objDataResp!==undefined){
				const dataraw=objDataResp.field_data_raw;
				const data_simple=objDataResp.field_data;
				if(data_simple!==null && data_simple!==undefined){
					this.setState({["domainInLaw"]:data_simple});
				}
				   
				//consoleXlog("SHOW COORDONNÉES AVOCAT  FIELD "+fieldid);  
			} 
		}else if(fieldid==="45" && deleteGo!==undefined && deleteGo==="addFollowup"){  
			// domainInLaw  domainInLaw 
			const objData=resp[2]; 
			if(objData!==null && objData!==undefined){
				/*const dynamicFormData=this.state.dynamicFormData;
				dynamicFormData[index]=objData; 
				objDataResp=objData;
				this.setState({["dynamicFormData"]: dynamicFormData, 
				["openFileDelete"]: false, ["openFileLabelUpdate"]: false }); 
				consoleXlog("wooooooooooooooooooooooy new data index=", index); consoleXlog(objData); 
				consoleXlog(dynamicFormData);*/

				const dynamicFormAllData=this.state.dynamicFormAllData;
				const fields=dynamicFormAllData.fields; 
				const fieldIdk=parseInt(fieldid);
				let promList = fields.length > 0
					&& fields.map((item, i) => {   
					if(item.id===fieldid){
						//fieldBlock=item; 
						consoleXlog("qwertyfound ", i, objData);
						fields[i]=objData; 
						dynamicFormAllData.fields=fields;
					}					
				return (null);   
				}, this);    
				//for(k=0;k)

				this.setState({["dynamicFormAllData"]: dynamicFormAllData, 
				["openFileDelete"]: false, ["openFileLabelUpdate"]: false,
				["fileLabelDescription"]: "", ["fileLabelDescription2"]: "",});  
				//consoleXlog("wooooooooooooooooooooooy new data index=", index); consoleXlog(objData); 
				//consoleXlog(dynamicFormAllData);  

			} 
			// if(objDataResp!==null && objDataResp!==undefined){
			// 	const dataraw=objDataResp.field_data_raw;
			// 	const data_simple=objDataResp.field_data;
			// 	if(data_simple!==null && data_simple!==undefined){
			// 		this.setState({["domainInLaw"]:data_simple});
			// 	}
				   
			// 	//consoleXlog("SHOW COORDONNÉES AVOCAT  FIELD "+fieldid);  
			// } 

			// const objData=resp[2];
			// if(objData!==null && objData!==undefined){
			// 	const dynamicFormData=this.state.dynamicFormData;
			// 	dynamicFormData[index]=objData; 
			// 	objDataResp=objData;
			// 	this.setState({["dynamicFormData"]: dynamicFormData, 
			// 	["openFileDelete"]: false, ["openFileLabelUpdate"]: false }); 
			// 	consoleXlog("wooooooooooooooooooooooy new data index=", index); consoleXlog(objData); 
			// 	consoleXlog(dynamicFormData);
			// }
		}else if(fieldid==="45"){  
			// domainInLaw  domainInLaw  
			if(objDataResp!==null && objDataResp!==undefined){
				const dataraw=JSON.parse(objDataResp.field_data_raw);
				const data_simple=objDataResp.field_data; 
				let suiviList = dataraw.length > 0
					&& dataraw.map((item, i) => {   
					if(item.value==="455" || item.value==="456"){
						//fieldBlock=item; 
						this.setState({["dossiernextstep"]:item.value});
					}					
					return (null);   
				}, this);
				  
			} 
		}

		// const dossiernextstep=this.state.dossiernextstep;
		// if(fieldid==="132"){
		// 	if(dossiernextstep==="455" || dossiernextstep==="456"){
		// 	}else{
		// 		return null;
		// 	}
		// }

		
		  
		if(fieldid==="1" || fieldid==="72"){
			//const eventObj={fieldId: fieldid,	dataval:,  data_raw};
			let dataGozend=null; let dataGozendraw=null; 
			if(fieldid==="72"){ 
				dataGozend=false;
				if(field_data_rawx.indexOf("yes") >-1){ dataGozend=true; dataGozendraw=null; }
			}else{ dataGozend=elemVal; dataGozendraw=field_data_rawx; }
			//const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
			const eventObj={fieldId:fieldid, dataval:dataGozend, data_raw:dataGozendraw}; 
			consoleXlog("TRYING UPDATING ZENDESK IF EXISTS FIELD "+fieldid); 
			this00.saveUpdateTicketDataToZendesk (eventObj);
		}else{
			const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
			this00.saveUpdateTicketDataToZendesk(eventObj); 
			consoleXlog("UPDATING ZENDESK IF EXISTS FIELD "+fieldid); 
		}

		this.showToast(t("zqwNFFtxt16_x"), "success"); 

		return response;
	}).catch(function(error){ 
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
		  errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			if(fieldid==="1" || fieldid==="72"){
				//const eventObj={fieldId: fieldid,	dataval:,  data_raw};
				let dataGozend=null; let dataGozendraw=null; 
				if(fieldid==="72"){ 
					dataGozend=false;
					if(field_data_rawx.indexOf("yes") >-1){ dataGozend=true; dataGozendraw=null; }
				}else{ dataGozend=elemVal; dataGozendraw=field_data_rawx; }
				//const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
				const eventObj={fieldId:fieldid, dataval:dataGozend, data_raw:dataGozendraw}; 
				consoleXlog("TRYING UPDATING ZENDESK IF EXISTS FIELD "+fieldid); 
				this00.saveUpdateTicketDataToZendesk (eventObj);
			}else{
				const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
				this00.saveUpdateTicketDataToZendesk(eventObj); 
				consoleXlog("UPDATING ZENDESK IF EXISTS FIELD "+fieldid); 
			}
			consoleXlog("FAKE ERRORS SINGLE SAVE"); 

		}
		else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			errMessage ="SERVER ERROR INTERNAL or file not found.";
			this00.showToastError(errMessage);
		}else{
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		this00.showToastError(errMessage);
		}

		// const eventObj={fieldId:fieldid, dataval:elemVal, data_raw:field_data_rawx}; 
		// this00.saveUpdateTicketDataToZendesk(eventObj); 
		// consoleXlog("ERRORtHROWN UPDATING ZENDESK IF EXISTS FIELD "+fieldid); 
		 
		consoleXlog("ERROR WITH TRANSACTION"); 
		//consoleXlog(error);
		consoleXlog("SHORT ERROR MESSAGE: ", errMessage);
		//consoleXlog("RWA RESPONSE"); 
		////consoleXlog(rawError);
		consoleXlog("RWA RESPONSE"); 
		consoleXlog(error);
		 
		//localStorage.setItem("transerror", JSON.stringify(error));
		//localStorage.setItem("transerrorrawresp", JSON.stringify(rawError));
		//alert(errMessage); //error
	  });

	/*let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  ////consoleXlog(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NdiTicketDataLeft[fieldName];
		  ////consoleXlog(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }*/

	//this.showToastError("---- laaaaaaaaaaaaast message to show \n"+pacx); 
	return false;     

	// if(this.actionMode==='new'){ 
	// 	this.props.addOrUpdateNdiTicketDataLeft(NdiTicketDataLeft, 1); 
	// 	//this.props.addNdiTicketDataLeft(NdiTicketDataLeft); 
	// }else{ 
	// 	this.props.addOrUpdateNdiTicketDataLeft(NdiTicketDataLeft, 2);
	// 	//this.props.updateNdiTicketDataLeft(NdiTicketDataLeft);
		
	// }
  };

  handleDownloadFile = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const product_field_id = this.state.product_field_id;
    const case_id = this.state.case_id;
    const field_data = this.state.field_data;
    const field_data_raw = this.state.field_data_raw;
    const is_public = this.state.is_public;
    const creation_date = this.state.creation_date;
    const user_creation_id = this.state.user_creation_id;
    const user_update_id = this.state.user_update_id;
	const last_update = this.state.last_update;
	const realticket_id=this.state.realticket_id; 
    const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	
	
	// <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	// 		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	// 		onClick={this.handleSubmitTicketData}> 
	const fieldname=event.currentTarget.dataset.fieldname;
	const index=event.currentTarget.dataset.index;
	let tickeId=0;
	const tickeIdx=parseInt(event.currentTarget.dataset.id);
	const caseid=event.currentTarget.dataset.caseid;
	const productfieldid=event.currentTarget.dataset.productfieldid;
	const fieldid=event.currentTarget.dataset.fieldid;
	const required=event.currentTarget.dataset.required;
	const fileshere=event.currentTarget.dataset.fileshere; 

	const filesheredown=event.currentTarget.dataset.filesheredown; 
	const fileaddress=event.currentTarget.dataset.fileaddress; 
	const bucketTxt=event.currentTarget.dataset.bucket; 
	const filedownloadall=event.currentTarget.dataset.filedownloadall; 

	//filedownloadall={"allFiles.zip"}
	

	const dynamicForm=this.state.dynamicForm; 
	const elementObj=dynamicForm[index]; 
	const elemVal=elementObj["'"+fieldname+"'"];
	const options= dynamicForm[index]["options"];
	const field_data_rawx=options.length===0?"":JSON.stringify(options);
	let isfileshere=0;
	//this.setState({["dynamicForm"]: dynamicForm }); 
	const tickeIdNeo=parseInt(dynamicForm[index]["id"]);
	if(tickeIdNeo >0 && tickeIdx===0){ tickeId=tickeIdNeo; }else if(tickeIdx >0){ tickeId=tickeIdx; }
	const fileaddress00=fileaddress.split("/");
	const shortFilename=fileaddress00[(fileaddress00.length-1)];

	let pacx=" fieldname="+fieldname+" index="+index+" dataId="+tickeId+" caseid="+caseid+" realticket_id="+realticket_id+
	"\n productfieldid="+productfieldid+" fieldid="+fieldid+" required="+required+" zx="+elemVal+" raw="+field_data_rawx+
	" fileaddress="+fileaddress+" shortFilename="+shortFilename;
	 
	//const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	//let myNdiTicketDataLeft = null;
	const myNdiTicketDataLeft = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
		field_data: elemVal, field_data_raw: field_data_rawx, 
		fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, fileaddress:fileaddress, shortFilename:shortFilename }; 

	const selectedFiles=this.state.selectedFiles;

	//this.showToastError(fileshere+" laaaaaaaaaaaaast message to show \n"+pacx); return false;
	
	if(caseid===0 || productfieldid===0){
		this.showToastError("Sorry, we do not save empty data. Make sure it is filled first.");
		return false;
	}

	if((elemVal==="" && field_data_rawx==="") && required==="true" && isfileshere===0){
		this.showToastError("Sorry, we do not save empty data. Make sure it is filled first!");
		return false;
	}

	if(bucketTxt!==undefined && bucketTxt!==null){
		myNdiTicketDataLeft.bucket=bucketTxt;
	}

	if(filedownloadall!==undefined && filedownloadall!==null){
		myNdiTicketDataLeft.filedownloadall=filedownloadall; 
		if(myNdiTicketDataLeft.field_data_raw!==null && myNdiTicketDataLeft.field_data_raw.length===0){
			//myNdiTicketDataLeft.bucket=bucketTxt;
			//if(options >0){ tickeId=tickeIdNeo; } 
			consoleXlog("HOHOHO filedownloadall .... ");  consoleXlog(elementObj); 
		}

		if(tickeId <1){
			this.showToastError("Désolé, pas de fichier à télélcharger/ Sorry, no file to download!");
			return false;
		}
	}
	

	

	//this.showToastError("nooooooooooooooooooooooooooo ooooooooooooooooooh!\n"+pacx); 	return false; 
	
	Promise.resolve(this.props.downloadFileTicketData(myNdiTicketDataLeft))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		//const resp=response.NdiFields;
		////consoleXlog("HOHOHO response from server .... ");  //consoleXlog(resp); //NdiFields
		//this.handleDynamicFormGenerator(response.NdiFieldsOne.data);

		return response;
	}).catch(function(error){ 
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
		  errMessage +="\n There was some error from the expected data from the server. Please try again later."
		}
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		this.showToastError(errMessage);
		 
		//consoleXlog("ERROR WITH TRANSACTION"); 
		//consoleXlog(error);
		//consoleXlog("SHORT ERROR MESSAGE: "+errMessage);
		//consoleXlog("RWA RESPONSE"); 
		////consoleXlog(rawError);
		 
		//localStorage.setItem("transerror", JSON.stringify(error));
		//localStorage.setItem("transerrorrawresp", JSON.stringify(rawError));
		//alert(errMessage); //error
	  });

	//this.showToastError("---- laaaaaaaaaaaaast message to show \n"+pacx); 
	return false; 
 
  };


  handleDownloadFileInvoice = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const product_field_id = this.state.product_field_id;
    const case_id = this.state.case_id;
    const field_data = this.state.field_data;
    const field_data_raw = this.state.field_data_raw;
    const is_public = this.state.is_public;
    const creation_date = this.state.creation_date;
    const user_creation_id = this.state.user_creation_id;
    const user_update_id = this.state.user_update_id;
	const last_update = this.state.last_update;
	const realticket_id=this.state.realticket_id; 
    const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	
	
	// <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	// 		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	// 		onClick={this.handleSubmitTicketData}> 
	const fieldname=event.currentTarget.dataset.fieldname;
	const index=event.currentTarget.dataset.index;
	let tickeId=0;
	const tickeIdx=parseInt(event.currentTarget.dataset.id);
	const caseid=event.currentTarget.dataset.caseid;
	const productfieldid=event.currentTarget.dataset.productfieldid;
	const fieldid=event.currentTarget.dataset.fieldid;
	const required=event.currentTarget.dataset.required;
	const fileshere=event.currentTarget.dataset.fileshere; 

	const filesheredown=event.currentTarget.dataset.filesheredown; 
	//const fileaddress=event.currentTarget.dataset.fileaddress; 
	const datefile = moment().format('YYYYMMDDhhmmss');
	const fileaddress="somewhere/inv_"+tickeIdx+"_"+datefile+".pdf";
	//$fileaddress="inv_".$id_invoice."_".date("YmdHis").".pdf";

	const dynamicForm=this.state.dynamicForm; 
	const elementObj=dynamicForm[index]; 
	const elemVal=elementObj["'"+fieldname+"'"];
	const options= dynamicForm[index]["options"];
	const field_data_rawx=options.length===0?"":JSON.stringify(options);
	let isfileshere=0;
	//this.setState({["dynamicForm"]: dynamicForm }); 
	const tickeIdNeo=parseInt(dynamicForm[index]["id"]);
	if(tickeIdNeo >0 && tickeIdx===0){ tickeId=tickeIdNeo; }else if(tickeIdx >0){ tickeId=tickeIdx; }
	const fileaddress00=fileaddress.split("/");
	const shortFilename=fileaddress00[(fileaddress00.length-1)];

	let pacx=" fieldname="+fieldname+" index="+index+" dataId="+tickeId+" caseid="+caseid+" realticket_id="+realticket_id+
	"\n productfieldid="+productfieldid+" fieldid="+fieldid+" required="+required+" zx="+elemVal+" raw="+field_data_rawx+
	" fileaddress="+fileaddress+" shortFilename="+shortFilename;
	 
	//const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	//let myNdiTicketDataLeft = null;
	const myNdiTicketDataLeft = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
		field_data: elemVal, field_data_raw: field_data_rawx, 
		fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, 
		fileaddress:fileaddress, shortFilename:shortFilename,
		invoiceId:tickeIdx }; 

	const selectedFiles=this.state.selectedFiles;

	//this.showToastError(fileshere+" laaaaaaaaaaaaast message to show \n"+pacx); return false;
	
	/*if(caseid===0 || productfieldid===0){
		this.showToastError("Sorry, we do not save empty data. Make sure it is filled first.");
		return false;
	}

	if((elemVal==="" && field_data_rawx==="") && required==="true" && isfileshere===0){
		this.showToastError("Sorry, we do not save empty data. Make sure it is filled first!");
		return false;
	}*/

	//check invoice ID tickeIdx  fieldname
	if(tickeIdx >0){ }
	else{
		this.showToastError("Sorry, we need the invoice Id to be able to download the invoice.");
		return false;
	}

	//this.showToastError("nooooooooooooooooooooooooooo ooooooooooooooooooh!\n"+pacx); 	return false; 
	
	//Promise.resolve(this.props.downloadFileTicketData(myNdiTicketDataLeft))
	Promise.resolve(this.props.downloadFileInvoiceTicketData(myNdiTicketDataLeft))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		//const resp=response.NdiFields;
		////consoleXlog("HOHOHO response from server .... ");  //consoleXlog(resp); //NdiFields
		//this.handleDynamicFormGenerator(response.NdiFieldsOne.data);

		return response;
	}).catch(function(error){ 
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
		  errMessage +="\n There was some error from the expected data from the server. Please try again later."
		}
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		this.showToastError(errMessage);
		 
		//consoleXlog("ERROR WITH TRANSACTION"); 
		//consoleXlog(error);
		//consoleXlog("SHORT ERROR MESSAGE: "+errMessage);
		//consoleXlog("RWA RESPONSE"); 
		////consoleXlog(rawError);
		 
		//localStorage.setItem("transerror", JSON.stringify(error));
		//localStorage.setItem("transerrorrawresp", JSON.stringify(rawError));
		//alert(errMessage); //error
	  });

	//this.showToastError("---- laaaaaaaaaaaaast message to show \n"+pacx); 
	return false; 
 
  };

  handleDownloadFileInvoiceCus = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const product_field_id = this.state.product_field_id;
    const case_id = this.state.case_id;
    const field_data = this.state.field_data;
    const field_data_raw = this.state.field_data_raw;
    const is_public = this.state.is_public;
    const creation_date = this.state.creation_date;
    const user_creation_id = this.state.user_creation_id;
    const user_update_id = this.state.user_update_id;
	const last_update = this.state.last_update;
	const realticket_id=this.state.realticket_id; 
    const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	 
	const fieldname=event.currentTarget.dataset.fieldname;
	const index=event.currentTarget.dataset.index;
	let tickeId=0;
	const tickeIdx=parseInt(event.currentTarget.dataset.id);
	const caseid=event.currentTarget.dataset.caseid;
	const productfieldid=event.currentTarget.dataset.productfieldid;
	const fieldid=event.currentTarget.dataset.fieldid;
	const required=event.currentTarget.dataset.required;
	const fileshere=event.currentTarget.dataset.fileshere; 

	const filesheredown=event.currentTarget.dataset.filesheredown; 
	//const fileaddress=event.currentTarget.dataset.fileaddress; 
	const datefile = moment().format('YYYYMMDDhhmmss');
	const fileaddress="somewhere/inv_"+tickeIdx+"_"+datefile+".pdf";
	//const fileaddress=tickeIdx+".pdf";
	///$fileaddress="inv_".$id_invoice."_".date("YmdHis").".pdf";

	const dynamicForm=this.state.dynamicForm; 
	const elementObj=dynamicForm[index]; 
	const elemVal=elementObj["'"+fieldname+"'"];
	const options= dynamicForm[index]["options"];
	const field_data_rawx=options.length===0?"":JSON.stringify(options);
	let isfileshere=0;
	//this.setState({["dynamicForm"]: dynamicForm }); 
	const tickeIdNeo=parseInt(dynamicForm[index]["id"]);
	if(tickeIdNeo >0 && tickeIdx===0){ tickeId=tickeIdNeo; }else if(tickeIdx >0){ tickeId=tickeIdx; }
	const fileaddress00=fileaddress.split("/");
	const shortFilename=fileaddress00[(fileaddress00.length-1)];

	let pacx=" fieldname="+fieldname+" index="+index+" dataId="+tickeId+" caseid="+caseid+" realticket_id="+realticket_id+
	"\n productfieldid="+productfieldid+" fieldid="+fieldid+" required="+required+" zx="+elemVal+" raw="+field_data_rawx+
	" fileaddress="+fileaddress+" shortFilename="+shortFilename;
	 
	//const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	//let myNdiTicketDataLeft = null;
	const myNdiTicketDataLeft000 = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
		field_data: elemVal, field_data_raw: field_data_rawx, 
		fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, 
		fileaddress:fileaddress, shortFilename:shortFilename,
		invoiceId:tickeIdx }; 

	const myNdiTicketDataLeft = {fileaddress:fileaddress, invoiceId:tickeIdx, shortFilename:shortFilename, }; 

	const selectedFiles=this.state.selectedFiles;

	//this.showToastError(fileshere+" laaaaaaaaaaaaast message to show \n"+pacx); return false;
	
	//check invoice ID tickeIdx  fieldname
	if(tickeIdx >0){ }
	else{
		//this.showToastError("Sorry, we need the invoice Id to open popup and to be able to download the invoice.");
		this.showToastError(t("zqwNFFrmZn0134q_x"));
		return false;
	}

	//this.showToastError("nooooooooooooooooooooooooooo ooooooooooooooooooh!\n"+pacx); 	return false; 
	
	this.customInvoiceData=myNdiTicketDataLeft;
	this.setState({ ["openCustomInvoice"]: true }); 

	//this.showToastError("---- laaaaaaaaaaaaast message to show \n"+pacx); 
	return false; 
 
  };


  handleDownloadFileFideicommis = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const product_field_id = this.state.product_field_id;
    const case_id = this.state.case_id;
    const field_data = this.state.field_data;
    const field_data_raw = this.state.field_data_raw;
    const is_public = this.state.is_public;
    const creation_date = this.state.creation_date;
    const user_creation_id = this.state.user_creation_id;
    const user_update_id = this.state.user_update_id;
	const last_update = this.state.last_update;
	const realticket_id=this.state.realticket_id; 
    const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	
	
	// <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	// 		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	// 		onClick={this.handleSubmitTicketData}> 
	const fieldname=event.currentTarget.dataset.fieldname;
	const index=event.currentTarget.dataset.index;
	let tickeId=0;
	const tickeIdx=parseInt(event.currentTarget.dataset.id);
	const caseid=event.currentTarget.dataset.caseid;
	const productfieldid=event.currentTarget.dataset.productfieldid;
	const fieldid=event.currentTarget.dataset.fieldid;
	const required=event.currentTarget.dataset.required;
	const fileshere=event.currentTarget.dataset.fileshere; 

	const fieldInvoice=event.currentTarget.dataset.invoice;
	const ticketTA=event.currentTarget.dataset.ta;
	const fideioutin=event.currentTarget.dataset.fideioutin===undefined?"fideiin":event.currentTarget.dataset.fideioutin;

	const filesheredown=event.currentTarget.dataset.filesheredown; 
	//const fileaddress=event.currentTarget.dataset.fileaddress; 
	const datefile = moment().format('YYYYMMDDhhmmss');
	//const fileaddress="somewhere/inv_"+tickeIdx+"_"+datefile+".pdf";
	const fileaddress="somewhere/fid_"+tickeIdx+"_"+datefile+".pdf";
	//$fileaddress="inv_".$id_invoice."_".date("YmdHis").".pdf";

	const dynamicForm=this.state.dynamicForm; 
	const elementObj=dynamicForm[index]; 
	const elemVal=elementObj["'"+fieldname+"'"];
	const options= dynamicForm[index]["options"];
	const field_data_rawx=options.length===0?"":JSON.stringify(options);
	let isfileshere=0;
	//this.setState({["dynamicForm"]: dynamicForm }); 
	const tickeIdNeo=parseInt(dynamicForm[index]["id"]);
	if(tickeIdNeo >0 && tickeIdx===0){ tickeId=tickeIdNeo; }else if(tickeIdx >0){ tickeId=tickeIdx; }
	const fileaddress00=fileaddress.split("/");
	const shortFilename=fileaddress00[(fileaddress00.length-1)];

	let pacx=" fieldname="+fieldname+" index="+index+" dataId="+tickeId+" caseid="+caseid+" realticket_id="+realticket_id+
	"\n productfieldid="+productfieldid+" fieldid="+fieldid+" required="+required+" zx="+elemVal+" raw="+field_data_rawx+
	" fileaddress="+fileaddress+" shortFilename="+shortFilename;
	 
	//const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
	//let myNdiTicketDataLeft = null;
	const myNdiTicketDataLeft = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
		field_data: elemVal, field_data_raw: field_data_rawx, 
		fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, 
		fileaddress:fileaddress, shortFilename:shortFilename, fideioutin:fideioutin, 
		transId:tickeIdx, invoiceId:fieldInvoice, ta:ticketTA }; 

	const selectedFiles=this.state.selectedFiles;

	//this.showToastError(fileshere+" laaaaaaaaaaaaast message to show \n"+pacx); return false;
	
	/*if(caseid===0 || productfieldid===0){
		this.showToastError("Sorry, we do not save empty data. Make sure it is filled first.");
		return false;
	}

	if((elemVal==="" && field_data_rawx==="") && required==="true" && isfileshere===0){
		this.showToastError("Sorry, we do not save empty data. Make sure it is filled first!");
		return false;
	}*/

	//check invoice ID tickeIdx  fieldname
	if(tickeIdx >0){ }
	else{
		this.showToastError("Sorry, we need the transaction Id to be able to download the invoice.");
		return false;
	}

	//this.showToastError("nooooooooooooooooooooooooooo ooooooooooooooooooh!\n"+pacx); 	return false; 
	
	//Promise.resolve(this.props.downloadFileTicketData(myNdiTicketDataLeft))
	Promise.resolve(this.props.downloadFileFideicommis(myNdiTicketDataLeft))
	.then((response)=>{
		//this.setState(response.NdiFieldsOne);
		//const resp=response.NdiFields;
		////consoleXlog("HOHOHO response from server .... ");  //consoleXlog(resp); //NdiFields
		//this.handleDynamicFormGenerator(response.NdiFieldsOne.data);

		return response;
	}).catch(function(error){ 
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
		  errMessage +="\n There was some error from the expected data from the server. Please try again later."
		}
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		this.showToastError(errMessage);
		 
		//consoleXlog("ERROR WITH TRANSACTION"); 
		//consoleXlog(error);
		//consoleXlog("SHORT ERROR MESSAGE: "+errMessage);
		//consoleXlog("RWA RESPONSE"); 
		////consoleXlog(rawError);
		 
		//localStorage.setItem("transerror", JSON.stringify(error));
		//localStorage.setItem("transerrorrawresp", JSON.stringify(rawError));
		//alert(errMessage); //error
	  });

	//this.showToastError("---- laaaaaaaaaaaaast message to show \n"+pacx); 
	return false; 
 
  };
  	

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const text_label = this.state.text_label;
    const placeholder = this.state.placeholder;
    const field = this.state.field;
    const field_type = this.state.field_type;
    const field_default_value = this.state.field_default_value;
    const mask = this.state.mask;
    const field_service = this.state.field_service;
    const field_dataset = this.state.field_dataset;
    const tag_id = this.state.tag_id;
    const NdiFields = {id: id, name: name, text_label: text_label, placeholder: placeholder, field: field, field_type: field_type, field_default_value: field_default_value, mask: mask, field_service: field_service, field_dataset: field_dataset, tag_id: tag_id, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  ////consoleXlog(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NdiFields[fieldName];
		  ////consoleXlog(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNdiFields(NdiFields, 1); 
		//this.props.addNdiFields(NdiFields); 
	}else{ 
		this.props.addOrUpdateNdiFields(NdiFields, 2);
		//this.props.updateNdiFields(NdiFields);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/NdiFields`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/NdiFields/${idx}`);
		//this.props.history.push(`/NdiFields/${this.props.NdiFields.id}`);
	}
  }


  handleCanceltest = () => { 
	// if(this.actionMode==='new'){ 
	// 	this.props.history.push(`/NdiFields`);
	// }else{ 
	// 	const idx = this.props.match.params.id; 
	// 	this.props.history.push(`/NdiFields/${idx}`);
	// 	//this.props.history.push(`/NdiFields/${this.props.NdiFields.id}`);
	// }

	//this.setState({["elementForms"]: fieldList }); 
	const dynamicForm=this.state.dynamicForm;
	//consoleXlog("weeeeell RESEULT FRM ", dynamicForm);
	// setTimeout(() => {
	// 	const dynamicForm=this.state.dynamicForm;
	// 	//consoleXlog("RESEULT FRM ", dynamicForm);
	// }, 3000);
	
	//consoleXlog("zeeeeeeeeeeeeeendesk client zaf");

	if(ZAFClient!==undefined){ 
	// var client = ZAFClient.init();
	// client.get('ticket.requester.name').then(function(data) {
	// //consoleXlog(data); // { "ticket.requester.name": "Mikkel Svane" }
	// });
	zafClient.get('ticket').then((response) => {
		const subject = response.ticket.subject
		this.setState({ticket: {subject}});
		//this.showToastError(response.ticket);
		//consoleXlog("####inside if zeeeeeendesk client zaf   goooooooooood ");
		//consoleXlog(response.ticket);
	  }, (response) => {
		//consoleXlog(`Error from zafClient ticket status: ${response.status}`)
	  })

		//consoleXlog("inside if zeeeeeeeeeeeeeendesk client zaf");
	}

	//consoleXlog("end zeeeeeeeeeeeeeendesk client zaf");

  }

  handleOpenFolderProduct  = (event) => {
	  const productsList=this.state.productsList.products;   
	  const transactionList=this.state.listOfTransactions; 
	  let closeid="0";  let closeinvoiceid="0"; 
	  const index2=event.currentTarget.dataset.closeid; //event.target. event.currentTarget.dataset.index;
	  const index3=event.currentTarget.dataset.closeinvoiceid;
	  if(index2!==undefined){ closeid=index2; }
	  if(index3!==undefined){ closeinvoiceid=index3; }
	  //   data-closeid={3} data-closeinvoiceid={transaction.id_invoices} 
	  consoleXlog("weeeeeeeeeeeeeeeeeeeeee handle-OpenFolder-Product ", index2, index3);      
    this.setState({["openProductFolder"]: true, ["salesCloseId"]: closeid,["salesCloseInvId"]: closeinvoiceid, }); 
	//this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
	if(productsList.length===0){
		//getAllProducts
		let myLang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
    	const objProd={lang:myLang, upsell:"yes"}; 
    	Promise.resolve(this.props.getAllProducts(objProd))
		//Promise.resolve(this.props.getAllProducts())
		.then((response)=>{ 
			/*
			//productsList=response.NeoVentes;
			////consoleXlog("responseHEEEEEEEEEEEERE from .... ");  //consoleXlog(response);
		  const valDynProd=JSON.parse(response.NeoVentes.products);	  
			//this.productsList.products=response.NeoVentes.products;
		  //this.productsList.products=valDynProd.products;
		  this.setState({["productsList"]: {products:valDynProd.products} });  
			//productsList={"products": []}; // this.state.productsList; */
			
			let productsList=[];
			const productListx=response.NeoVentes.products;
			let productList = productListx.length > 0
			&& productListx.map((item, i) => {
				//productOptions.push({ value: item.id, label: item.titre }); 
				//consoleXlog(item);
				if(item!==null){
				productsList.push(item); 
				}
			return (
				null
			);
			}, this);

			//this.setState({ ["productsList"]: {"products": response.NeoVentes.products}, });
			this.setState({ ["productsList"]: {"products": productsList}, });
			//const valDynProd=JSON.parse(response.NeoVentes.products);	  
			//this.productsList.products=response.NeoVentes.products;			
			// this.productsList.products=response.NeoVentes.products; //valDynProd.products; 
			//this.productsList.products=productsList; 


		  return response;
		});
	}

	if(transactionList.length===0){
		const clientId=this.state.dynamicFormAllData.client_id;
		this.getClientTransactionList(clientId);
	}

	 
  }

  closeFolderProductPopup  = (anyEvent) => {     
    this.setState({["openProductFolder"]: false }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
  }

  handleProductChange = (event) => {                                                             
    //event.preventDefault();  
    //const stepTrack=event.target.dataset.prodcontrol;
    const selVal= event.value;  //productId
    this.setState({ ["productId"]: selVal });   
    // this.workflowProductList=true;
    // const product=this.getProductChange(selVal);
    // this.applyProductChange(selVal);	
	
	//this.showToast("Produit ajouté #"+product.titre, "success");	
    ////consoleXlog('well hellooooooooooooo '+selVal);
    ////consoleXlog(event);
  };

  handleTransactionChange = (event) => {                                                             
    //event.preventDefault();  
    //const stepTrack=event.target.dataset.prodcontrol;
    const selVal= event.value;  //productId
    this.setState({ ["transactionId"]: selVal });    
  };

  getClientSearchSuggestion = (search) => {   
		const valueClientSearch=this.state.valueClientSearch;
		const inputValueClientSearch=this.state.inputValueClientSearch;
		if(valueClientSearch===inputValueClientSearch){ 
		////consoleXlog('WE DONOT GO TO SERVER FOR  '+inputValueClientSearch);  
		return false; 
		}
		const serachObj={search:search, searchEmail:""};
		Promise.resolve(this.props.getClientSearch(serachObj))
		.then((response)=>{  
		this.setState({ ["listOfSuggestion"]: response.NeoClientsOne }); 
		return response;
		});
		
  }

  getClientTransactionList = (clientId) => {   
	const serachObj={clientId:clientId};
	Promise.resolve(this.props.getClientTransactions(serachObj))
	.then((response)=>{ 
	this.setState({ ["listOfTransactions"]: response.NdiFields }); 
	return response;
	});
	
  }

  handleSaveForlderProduct = (event) => {
	const clientInfo=this.state.clientInfo; 
	const id = 0; //this.state.id;
    const ticket_id = this.state.realticket_id; //this.state.ticket_id; "197504"; // 
    const product_id = this.state.productId; //this.state.product_id;
	const enabled = 1; //this.state.enabled;
	 
    const creation_date = ""; //this.state.creation_date;
    const user_creation_id = ""; //this.state.user_creation_id;
    const user_update_id = ""; //this.state.user_update_id;
    const last_update = ""; //this.state.last_update;
    const client_id = clientInfo.id; //this.state.client_id;
    const client_type = 1; //this.state.client_type;
    const transaction_id = this.state.transactionId; //this.state.transaction_id;
    const NdiFoldersProducts = {id: id, ticket_id: ticket_id, product_id: product_id, enabled: enabled, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, client_id: client_id, client_type: client_type, transaction_id: transaction_id, }
	
	//clientInfo transactionId  productId                              


	if(ticket_id==="" || client_id===""){
		this.showToastError("Sossy you need a ticket id and select a product at least to continue"); 
		return false;
	}
     
	// this.showToastError("Holala this this de thals waaaaaaaaaaaaaaarning"+JSON.stringify(NdiFoldersProducts)); 
	// return false;
	const this00=this;
	Promise.resolve(this.props.addFoldersProducts(NdiFoldersProducts))
	.then((response)=>{ 
		//productsList=response.NeoVentes;
		//consoleXlog("responseHEEEEEEEEEEEERE NdiFoldersProducts from .... ");  //consoleXlog(response);
	  //const valDynProd=JSON.parse(response.NeoVentes.products);	  
		//this.productsList.products=response.NeoVentes.products;
	  //this.productsList.products=valDynProd.products;
	  //this.setState({["productsList"]: {products:valDynProd.products} });  
		//productsList={"products": []}; // this.state.productsList;  
		//const ticket_id = this.state.realticket_id; // 197504
		const objToSend={id:0, ticketId:ticket_id}; 
		this.loadProductsFields(objToSend);
		this.closeFolderProductPopup(event);
	  return response;
	}).catch(function(error){ 
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
		  errMessage +="\n There was some error from the expected data from the server. Please try again later."
		}
		this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		 
		//consoleXlog("ERROR WITH TRANSACTION"); 
		//consoleXlog(error);
		//consoleXlog("SHORT ERROR MESSAGE: "+errMessage);
		//consoleXlog("RWA RESPONSE"); 
		////consoleXlog(rawError); 
		this00.showToastError(errMessage);
	  });
  } 
  
  onChangeStatutNextStep(valuex, { actionx, removedValue }) {
	  let action=-1;
	  const index= this.indexNextStepall;
	  const nameInd= this.nameNextStepall;
	  let valueNextStepall=this.valueNextStepall[index]; //this.state.valueNextStepall;
	  const valueNextStepx=this.state.valueNextStep;
	  const valueNextStep=valueNextStepx[index]; //this.state.valueNextStep[index];
	  const valueGox=this.state.valueNextStepgost;
	  //const valuex2=valuex[0];
	  
	  this.setChangeTacker(null, nameInd,index); 
	  //const index= this.indexNextStepall;
	  //this.valueNextStepall
	  if(actionx==="remove-value"){ action=1;  }
	  else if(actionx==="pop-value"){ action=2;  }
	  else if(actionx==="clear"){ action=3;  }
	//consoleXlog(index, " StatutNextStep", action, actionx, removedValue, valuex, "zx", valuex2, "valueNextStep", valueNextStep);
	//consoleXlog(index, " StatutNextStep", action, actionx, removedValue, valuex, "valueNextStep", valueNextStep, "valueGox", valueGox);

	consoleXlog(index, " StatutNextStep", action, actionx, removedValue, valuex, "valueNextStep", valueNextStep, "valueGox", valueGox);
	if (removedValue!==undefined &&  removedValue.isFixed) {
		return false;
	}

	/*if (removedValue!==undefined &&  !removedValue.isFixed) {
		//consoleXlog(".....remoOOOOOOOOOOOOOOOving value ", removedValue, valuex, "all", valueNextStepall);
		//valuex = valueNextStepall.filter(v => v.isFixed);
		valueNextStepall=[];
		//consoleXlog(".....2remoOOOOOOOOOOOOOOOving value ", removedValue, valuex, "all", valueNextStepall);
		return;
	}*/
	 
    switch (action) {
      case 1:
      case 2:
        if (removedValue!==undefined && removedValue.isFixed) {
          return;
        }
        break;
      case 3:
		//valuex = colourOptions.filter(v => v.isFixed);
		valuex = valueNextStepall.filter(v => v.isFixed);
        break;
	}
	
	//valueNextStep[z]["isFixed"]=true;

	//consoleXlog(".....OOOOOOOOOOOOOOOOOOOOO", valuex);
	/*
	//valuex = orderOptions(valuex);
	const valuexMx=valuex[(valuex.length-1)];
	valueNextStep.push(valuexMx);*/
	//valueNextStep=valuex;
	//valueNextStepx[index]=valueNextStep; 
	const mood=Date.now();

	if (removedValue!==undefined &&  !removedValue.isFixed) {
		//valuex = orderOptions(valuex);
		//valueNextStep=null;
		valueNextStep.length = 0;
		//valueNextStep=valuex;
		//valueNextStep.push(valuex);
		if(valuex!==null){
			for(let myvaluex of valuex){
				valueNextStep.push(myvaluex);
			}
		}

		valueNextStepx[index]=valuex; 
		//valueNextStepx[index]=valueNextStep;

		const hrdCopValueNextStepx= JSON.parse(JSON.stringify(valueNextStepx)); // Object.assign(this.stateInit); 
		//this.setState({ ["valueNextStep"]: valueNextStepx, ["mood"]: mood, valueNextStepgost});
		
		//consoleXlog(index+"REEEEEMOOOOOOOOOOOOOOOOOOOOO", valuex,  "valueNextStep", valueNextStep, "valueNextStepgost",valuex ,"valueNextStepxAll", valueNextStepx);
		
		this.setState({ ["valueNextStep"]: valueNextStepx, ["mood"]: mood, ["valueNextStepgost"]: valuex,});
		//consoleXlog("remoooooooooooooooooooooooving option ", removedValue, valuex, "current", valuex);
		//this.dynamicStackFormRefs[index].current.click(); 
		//this.dynamicStackFormRefs[index].current.innerHTML = ">>> I was forced to click!!";
		//this.dynamicStackFormRefs[index].current.focus(); 
		
		/*const elementForms=this.state.elementForms;
		const elementForm=elementForms[index]; //JSON.parse(JSON.stringify(elementForms[index])); 
		// Object.assign(this.stateInit); 
		 
		setTimeout(() => {
			elementForms[index]="<div className=\"form-control\">loading...</div>";
			this.setState({ ["stackMenuIsOpen"]: true, ["elementForms"]: elementForms });
			//consoleXlog("1zzzzzz remoooooooooooooooooooooooving option ", removedValue, valuex);
		}, 100); 
		// setTimeout(() => {
		// 	this.setState({ ["stackMenuIsOpen"]: true});
		// 	//consoleXlog("2zzzzzz remoooooooooooooooooooooooving option ", removedValue, valuex);
		// }, 2000); 
		setTimeout(() => {
			this.setState({ ["stackMenuIsOpen"]: false});
			//consoleXlog("3zzzzzz remoooooooooooooooooooooooving option ", removedValue, valuex);
        }, 5000); */
	}else{ 
		const valuexMx=valuex[(valuex.length-1)];
		
		//valueNextStep.push(valuexMx);
		valueNextStepx[index]=valuex; 

		//this.setState({ ["mood"]: mood, }); 
		//valueNextStepx[index]=valueNextStep;
		//consoleXlog(index+"GOOOOOOOOOOOOOOOOOOOOO", valuex, valuexMx, "valueNextStep", valueNextStep, "valueNextStepgost",valuex ,"valueNextStepxAll", valueNextStepx);
		this.setState({ ["valueNextStep"]: valueNextStepx, ["mood"]: mood, ["valueNextStepgost"]: valuex,});
		//valueNextStepx[index]=valueNextStep; 
		//consoleXlog("aaaaaaaaaaaaaaaaaaaaaaaaaaaaadiiiiiing option ", valueNextStep, valuexMx, "mood", mood );
	}

	//consoleXlog("OOOOOOOOOOOOOOOOOOOOO", valuex, "valueNextStep", valueNextStep, "valueNextStepxAll", valueNextStepx);
	
	//  this.setState({ ["valueNextStep"]: [] });
	//  this.setState({ ["valueNextStep"]: valueNextStepx });
	// if (removedValue!==undefined &&  !removedValue.isFixed) {
	// 	this.setState({ ["valueNextStep"]: [] });
	// 	//consoleXlog("all remoooooooooooooooooooooooving option ");
	// }else{ this.setState({ ["valueNextStep"]: valueNextStepx }); }
  }

  onChangeStatutNextStep000(value, { action, removedValue }) {
	//const valueNextStepall=this.state.valuegost;  
	const index= this.indexNextStepall;
	let valueNextStepall=this.valueNextStepall[index]; //this.state.valueNextStepall;
	const valueNextStepx=this.state.valueNextStep;
	const valueNextStep=valueNextStepx[index]; //this.state.valueNextStep[index];
	//const valuex2=valuex[0];
	//consoleXlog("StatutNextStep", action, removedValue, valueNextStepall);
	switch (action) {
		case 'remove-value':
		case 'pop-value':
		if (removedValue.isFixed) {
			return;
		}
		break;
		case 'clear':
		value = valueNextStepall.filter(v => v.isFixed);
		break;
	}

	const mood=Date.now();

	//consoleXlog("onChangeTest ", action, "removedValue ", removedValue, "value ",value, "valueNextStepall ",valueNextStepall);
	value = orderOptions(value);
	//consoleXlog("....onChangeTest ", action, "removedValue ", removedValue, "value ",value, "valueNextStepall ",valueNextStepall);
	//this.setState({ valuegost: value });
	this.setState({ ["valueNextStep"]: value, ["mood"]: mood, ["valueNextStepgost"]: value,});
	//this.setState({ valueNextStep: valuex });
  }

  /*onChangeStatutNextStep(value, { action, removedValue }) {
	//consoleXlog("StatutNextStep", action, removedValue, );
  switch (action) {
	case 'remove-value':
	case 'pop-value':
	  if (removedValue.isFixed) {
		return;
	  }
	  break;
	case 'clear':
	  value = colourOptions.filter(v => v.isFixed);
	  break;
  }

  value = orderOptions(value);
  this.setState({ value: value });
}*/

onChangeTest(value, { action, removedValue }) {
	const valueNextStepall=this.state.valuegost;
	/*//consoleXlog("StatutNextStep", action, removedValue, valueNextStepall);
  switch (action) {
	case 'remove-value':
	case 'pop-value':
	  if (removedValue.isFixed) {
		return;
	  }
	  break;
	case 'clear':
	  value = colourOptions.filter(v => v.isFixed);
	  break;
  }

  //consoleXlog("onChangeTest ", action, "removedValue ", removedValue, "value ",value, "valueNextStepall ",valueNextStepall);
  value = orderOptions(value);
  //consoleXlog("....onChangeTest ", action, "removedValue ", removedValue, "value ",value, "valueNextStepall ",valueNextStepall);
  this.setState({ valuegost: value });*/
  //this.setState({ valueNextStep: valuex });
}

setCurrentIndexStack = (event) => {
	//const index=event.target.dataset.index;
	const index2=event.currentTarget.dataset.index;
	const name2=event.currentTarget.dataset.name;
	consoleXlog("EEEEEEEEEEEEEEEEEEEE ", name2, index2);
	this.indexNextStepall=index2;
	this.nameNextStepall=name2;
}

 
handleOpenCloseDataUnfold = (event) => {
	//const index=event.target.dataset.index;
	const index2=event.currentTarget.dataset.index;
	const nameType=event.currentTarget.dataset.transtype;
	consoleXlog("DataUnfold EEEEEEEEEEEEEEEEEEEE ", nameType, index2);
	const listOfTicketsInfo=this.state.listOfTicketsPayments;
	if(nameType==="1"){
		const isIconOpenedx=listOfTicketsInfo.pendtransactions[index2]["isIconOpened"];
		let isIconOpened=false;
		if(isIconOpenedx===undefined || isIconOpenedx===false){ isIconOpened=true; }
		listOfTicketsInfo.pendtransactions[index2]["isIconOpened"]= isIconOpened;
		this.setState({ ["listOfTicketsPayments"]: listOfTicketsInfo });
	}else if(nameType==="2"){
		const isIconOpenedx=listOfTicketsInfo.payments[index2]["isIconOpened"];
		let isIconOpened=false;
		if(isIconOpenedx===undefined || isIconOpenedx===false){ isIconOpened=true; }
		listOfTicketsInfo.payments[index2]["isIconOpened"]= isIconOpened;
		this.setState({ ["listOfTicketsPayments"]: listOfTicketsInfo });
	}else if(nameType==="3"){
		const isIconOpenedx=listOfTicketsInfo.gohistory.transactions[index2]["isIconOpened"];
		let isIconOpened=false;
		if(isIconOpenedx===undefined || isIconOpenedx===false){ isIconOpened=true; }
		listOfTicketsInfo.gohistory.transactions[index2]["isIconOpened"]= isIconOpened;
		this.setState({ ["listOfTicketsPayments"]: listOfTicketsInfo });
	}else if(nameType==="4"){
		const isIconOpenedx=listOfTicketsInfo.gohistory.payments[index2]["isIconOpened"];
		let isIconOpened=false;
		if(isIconOpenedx===undefined || isIconOpenedx===false){ isIconOpened=true; }
		listOfTicketsInfo.gohistory.payments[index2]["isIconOpened"]= isIconOpened;
		this.setState({ ["listOfTicketsPayments"]: listOfTicketsInfo });
	}
}

render() {
	const { t } = this.props;
	const currentTabMain=this.props.currentTab;
	if(currentTabMain!==undefined){
		if(currentTabMain==="tab3"){
			return this.renderTab3(null);
		}else if(currentTabMain==="tab4"){
			return this.renderTab4(null);
		}else{
			return this.renderQQQ3(null);
		}
	}else{
		return this.renderQQQ3(null);
	}
}

tryParseJSONObject = (jsonString) =>{
    try {
        var o = JSON.parse(jsonString);
        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return null;
}

divTransactionList = (transaction) =>{
	//consoleXlog("QQQQQQQQQQ holalala#0 ", transaction.preachat);
	//const preachat=	JSON.parse(transaction.preachat);
	const preachat=this.tryParseJSONObject(transaction.preachat);
	//const preachat=JSON.parse(JSON.stringify(transaction.preachat)); 
	//consoleXlog("QQQQQQQQQQ holalala ", preachat);
	let descriptionDiv= null;
	const isIconOpened=transaction.isIconOpened===undefined?false:transaction.isIconOpened;
	//consoleXlog("QQQQQQQQQQ holalala ", transaction.isIconOpened, isIconOpened);
	const transTotal=(((transaction.amount*1)+(transaction.tax1*1)+(transaction.tax2*1))).toFixed(2);
	
	if(preachat!==undefined && preachat!==null){
		consoleXlog("QQQQQQQQQQ holalala-preachat ", preachat);
	descriptionDiv=(<div className="dvblkx">
			<div className="table-responsive">
				<table className="table table-sm">
					<tbody>
						<tr>
							<td>Date</td>
							<td>{transaction.created}</td>
						</tr> 
						<tr>
							<td>#Facture</td>
							<td>{transaction.id_invoices}</td>
						</tr> 
						<tr>
							<td>Province</td>
							<td>{preachat.province}</td>
						</tr> 
						<tr>
							<td>Langue</td>
							<td>{preachat.language}</td>
						</tr> 
						<tr>
							<td>Montant</td>
							<td>{preachat.amounts===undefined? "": preachat.amounts.subtotal}$</td>
						</tr> 
						{preachat.amounts!==undefined && preachat.amounts.tax1_name!=="0" && preachat.amounts.tax2_name!=="0"?
						<tr>
							<td>{preachat.amounts.tax1_name}({preachat.amounts.tax1_amount}%)</td>
							<td>{preachat.amounts.tax1}$</td>
						</tr>:<tr/>}  
						{preachat.amounts!==undefined && preachat.amounts.tax1_name!=="0" && preachat.amounts.tax2_name==="0"?
						<tr>
							<td>{preachat.amounts.tax1_name}({(parseFloat(preachat.amounts.tax1_amount)+parseFloat(preachat.amounts.tax2_amount)).toFixed(3)}%)</td>
							<td>{(parseFloat(preachat.amounts.tax1)+parseFloat(preachat.amounts.tax2))}$</td>
						</tr>:<tr/>}
						{preachat.amounts!==undefined && preachat.amounts.tax2_name!=="0" && preachat.amounts.tax1_name!=="0"?
						<tr>
							<td>{preachat.amounts.tax2_name}({preachat.amounts.tax2_amount}%)</td>
							<td>{preachat.amounts.tax2}$</td>
						</tr>:<tr/>}
						{preachat.amounts!==undefined && preachat.amounts.tax2_name!=="0" && preachat.amounts.tax1_name==="0"?
						<tr>
							<td>{preachat.amounts.tax2_name}({(parseFloat(preachat.amounts.tax2_amount)+parseFloat(preachat.amounts.tax1_amount)).toFixed(3)}%)</td>
							<td>{(parseFloat(preachat.amounts.tax2)+parseFloat(preachat.amounts.tax1))}$</td>
						</tr>:<tr/>}
						<tr>
							<td>Total</td>
							<td>{transTotal}$</td>
						</tr>

					</tbody>
				</table>
			</div>
		</div>);
	}
	
	return descriptionDiv;
}

divPaymentsList = (payment) =>{
	let descriptionDiv= null;
		let transact=	null;
		let transaction="", currency="", country="", brand="", last4="", cardno="", statusTxt="";
		//const transact=	JSON.parse(payment.transact);
		transact=this.tryParseJSONObject(payment.transact);
		const totRefundAmount=payment.refundAmount;
		//consoleXlog("###*******CHECK transact   ", payment.transact);
		if(transact!==null ){
		//if(payment.transact!==undefined &&payment.transact!==null && payment.transact!=="" && payment.transact!=="0"){
			//transact=	JSON.parse(payment.transact);
			if(transact.id!==undefined && transact.id.indexOf("ch_")>-1){
				transaction=transact.id;
				currency=transact.currency;
				country=transact.source.country;
				brand=transact.source.brand;
				last4=transact.source.last4;
				cardno="xxxx xxxx ";
			}else if(transact.id!==undefined && transact.id.indexOf("PAY-")>-1){
				transaction=transact.id;
				currency=transact.transactions[0].amount.currency;
				country=transact.payer.payer_info.country_code;
				brand="Paypal"; //transact.brand;
				last4=""; //transact.last4;
				cardno="xxxx xxxx ";
			}else if(transact.id!==undefined && transact.id===transact.charge_id && transact.support_url.indexOf("https://chargedesk.com/")>-1){
				transaction=transact.id;
				currency=transact.currency;
				//country=transact.payer.payer_info.country_code;
				country=transact.currency;
				brand="ChargeDesk"; //transact.brand;
				last4=transact.last_4_digits;
				cardno="xxxx xxxx ";
				statusTxt=transact.is_paid===true?"oui/yes":"non/no";
			}
		}
		const isIconOpened=payment.isIconOpened===undefined?false:payment.isIconOpened;
		//return ( <option key={item} value={item}>{item}</option>);
		// return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		// {" code "+payment.code} {" ("+pmethodList[pmethod]+")"}</p>);
		let refundedAmount=0; let totalAfRefund=0;
		const payment_total=parseFloat(payment.total);

		if(totRefundAmount>0){
			if(totRefundAmount < payment_total){
				totalAfRefund=payment_total-totRefundAmount;
			}
		}
			 
        
		  //if(preachat!==undefined && preachat!==null){
			descriptionDiv=(<div className="dvblkx">
					<div className="table-responsive">
						<table className="table table-sm">
							<tbody>
								<tr>
									<td>Date</td>
									<td>{payment.created}</td>
								</tr> 
								<tr>
									<td>#Facture</td>
									<td>{payment.id_invoices}</td>
								</tr> 
								{/* <tr>
									<td>Province</td>
									<td>{preachat.province}</td>
								</tr> 
								<tr>
									<td>Langue</td>
									<td>{preachat.language}</td>
								</tr>  */}
								<tr>
									<td>Montant</td>
									<td>{payment.amount}$</td>
								</tr> 
								<tr>
									<td>Taxe 1</td>
									<td>{payment.tax1}$</td>
								</tr> 
								<tr>
									<td>Taxe 2</td>
									<td>{payment.tax2}$</td>
								</tr> 
								<tr>
									<td>Total</td>
									<td>{payment.total}$</td>
								</tr>
								{totRefundAmount>0?
									<tr>
										<td>Refund</td>
										<td>-{totRefundAmount.toFixed(2)}$</td>
									</tr>:<tr/>
								}
								{totRefundAmount>0?
									<tr>
										<td>Total</td>
										<td>{totalAfRefund.toFixed(2)}$</td>
									</tr>:<tr/>
								}
								<tr>
									<td>Token</td>
									<td>{payment.token}</td>
								</tr> 
								<tr>
									<td>Email</td>
									<td>{payment.email}</td>
								</tr>

								<tr>
									<td>Transaction</td>
									<td>{transaction}</td>
								</tr> 
								<tr>
									<td>Currency</td>
									<td>{currency}</td>
								</tr> 
								<tr>
									<td>Pays</td>
									<td>{country}</td>
								</tr> 
								<tr>
									<td>Carte</td>
									<td>{cardno+" "+last4+" ("+brand+")"}</td>
								</tr>
								{last4!==""?<tr>
									<td>Last4</td>
									<td>{last4}</td>
								</tr>:<tr/>} 

								{statusTxt!==""?<tr>
									<td>Payé/Paid</td>
									<td>{statusTxt}</td>
								</tr>:<tr/>} 

								   


								{/* <tr>
									<td>Montant</td>
									<td>{preachat.amounts.subtotal}$</td>
								</tr> 
								{preachat.amounts.tax1_name!=="0"?
								<tr>
									<td>{preachat.amounts.tax1_name}({preachat.amounts.tax1_amount}%)</td>
									<td>{preachat.amounts.tax1}$</td>
								</tr>:<tr/>}  
								{preachat.amounts.tax2_name!=="0"?
								<tr>
									<td>{preachat.amounts.tax2_name}({preachat.amounts.tax2_amount}%)</td>
									<td>{preachat.amounts.tax2}$</td>
								</tr>:<tr/>}  */}
							</tbody>
						</table>
					</div>
				</div>);
			//} 
			
		//const isIconOpened=payment.isIconOpened===undefined?false:payment.isIconOpened;
		//let descriptionDiv=divTransactionList(transaction);
	return descriptionDiv;
}

//this tab big sreen 
renderTab3 = (eventQQ) => { 
	//renderTab3(){
	const { t } = this.props;
	const currentTabMain=this.props.currentTab;
	//consoleXlog("TAB3WWOOOOOOOOOOOOOOOOOO "+currentTabMain);
	const elementForms=this.state.elementForms;
	//const classes = useStyles(); //<form onSubmit={this.handleSubmit} className={classes.root} noValidate autoComplete="off">
	const FolderProductPopup=this.FolderProductPopup;
	const RefundProcessPopup=this.RefundProcessPopup; 
	const RefundProcessPopupList=this.RefundProcessPopupList;
	const JudiciaryInvoicePopup=this.JudiciaryInvoicePopup; 
	const CustomInvoicePopup=this.CustomInvoicePopup; 
	const myToastMessage=this.state.toastrMessage;
	  const toastrType=this.state.toastrType;
	  //const realticket_id=this.state.realticket_id;
	  const indProd=120;

	 const fullObj=this.state.dynamicFormAllData;   
	  let ticketDataLeftRec=null;
	  const realticket_id=fullObj.ticket_id; 
	  const personType=fullObj.personType;
	  //const product=fullObj.product;  
	  //const product=fullObj.product;
	  let fieldList = []; let text="";
	  let dynamicForm = []; 
	  const typeListx = ["select","checkbox","radio"];
	  let selectedFiles=[]; let valueNextStep=[];
	  const dynamicFormall=this.state.dynamicForm;
	  const dynamicFormData=this.state.dynamicFormData;
  
	 
	const realElementForms = null;
	const this0=this;
	
	  //const realElementForms00 = null;
	  let myLang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
	  if(this.loadOtherLang!==""){ myLang=this.loadOtherLang; } 
	  let prodTitle="Champs par Défault";
	  let accordeonClient="Info du Client";
	  let accordeonPA="La Partie Adverse (PA)";
	  let accordeonXFields="... Champs Additionnels";
	  let txtaddprodpo="Ajouter produits";
	  let txtaddprodpoto="au";
	  if(myLang.indexOf("fr")< 0){
		prodTitle="Default Fields";
		accordeonClient="Client Info";
		accordeonPA="The Opposing Party (OP)";
		accordeonXFields="... Additional Fields";
		txtaddprodpo="Add products";
		txtaddprodpoto="to";
	  }

	  //const listOfFieldInfoClient=[30,31,32,33,34,35,36,121,126,130]; 114 98

	  const listOfFieldInfoClient=[30,31,32,33,34,35,36,121,126,133,138,139,142,167];
	  const listOfFieldInfoPA=[37,38,39,40,41,42,43,123,124,125,140,141,143];
	  //const listOfFieldInfoCliPa=[30,31,32,33,34,35,36,121,126,133, 37,38,39,40,41,42,43,123,124,125];
	  const listOfFieldInfoCliPa=listOfFieldInfoClient.concat(listOfFieldInfoPA); 

	  const listOfNatDoCivil=[45,95,74,168,90,91,97,111,113,131,1,132,137,   109,144,163, 172, 171];
	  const listOfNatDoFamille=[45,95,74,168,90,91,97,111,113,131,1,132,  109,134,144,163, 173, 171];
	  const listOfNatDoCivilVC=[45,95,74,168,90,97,111,113,131,1,132,   109,135,144,163, 172, 171]; //109 untill here
	  const listOfNatDoPenal=[95,91,93,149,110,78,92,97,88,1,98,145,136,   109,127,144,150,151,163,164,165,166, 171, 185];
	  const listOfNatDoCrimi=[45,115,95,91,93,149,110,78,92,97,88,111,113,1,132,   109,127,144,163, 171];
	  const listOfNatDoCorpo=[45,95,74,168,90,91,97,111,113,1,131,132,   109,129,144,148,163, 171, 180, 181, 182, 183];
	  const listOfNatDoNotariat=[45,95,74,168,97,112,111,113,1,132,  109,128,144,163, 171];  
	  let listOfNatDoInUsed=[];
	  if(personType==="2"){
		listOfFieldInfoClient.push(130);
	  }     


	  let realElementFormsDoc =null;
	  const domainInLaw=this.state.domainInLaw;

	   
	if(domainInLaw!==null || domainInLaw!==""){ 
		// 445-	Civil
		// 442-	Civil – Vices cachés
		// 446-	Famille
		// 448-	Notariat
		// 447-	Pénal
		// 449-	Corporatif
	
		if(domainInLaw==="442"){ listOfNatDoInUsed=listOfNatDoCivilVC;  }
		else if(domainInLaw==="445"){ listOfNatDoInUsed=listOfNatDoCivil;  }
		else if(domainInLaw==="446"){ listOfNatDoInUsed=listOfNatDoFamille;  }
		else if(domainInLaw==="447"){ listOfNatDoInUsed=listOfNatDoPenal;  }
		else if(domainInLaw==="448"){ listOfNatDoInUsed=listOfNatDoNotariat;  }
		else if(domainInLaw==="449"){ listOfNatDoInUsed=listOfNatDoCorpo;  }
		//consoleXlog("domainInLaw domainInLaw "+domainInLaw, listOfNatDoInUsed);
	}

	

	//realElementFormsDoc =null;


	  const FileDeletePopup=this.FileDeletePopup;     
	  const FileLabelUpdatePopup=this.FileLabelUpdatePopup;
	  const client_type=this.state.client_type;
	  //consoleXlog("CLIENT TYPE ", client_type);
	  const TabPanel = this.TabPanel;
	  const value=this.state.valueTab;
	  const handleOFP=this.handleOpenFolderProduct;
	  const NdiNeoDocSendFileOptionsPopup=this.NdiNeoDocSendFileOptionsPopup;

	  const listOfTicketsInfo=this.state.listOfTicketsPayments;
	  let listOfTickets=[]; //this.state.listOfTicketsPayments;
	  let listOfTicketsHist=[]; 
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.payments!==undefined){
			listOfTickets=listOfTicketsInfo.payments;
	  }
	  let extraInfo=[]; //let extraInfo="";
	  let listOfTransactions=[]; let listOfTransactionsHist=[]; 
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.pendtransactions!==undefined){
		listOfTransactions=listOfTicketsInfo.pendtransactions; 
	  }

	  if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.transactions!==undefined){
		listOfTransactionsHist=listOfTicketsInfo.gohistory.transactions; 
	  }
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.payments!==undefined){
		listOfTicketsHist=listOfTicketsInfo.gohistory.payments; 
	  }

	  const this2=this;
	  const agents=listOfRefundAgent;
	  let allowRefundBtn=false;  let userId=0;
	  const profile = decryptText(localStorage.profile);
	  //consoleXlog("###*******CHECK PROFILE DEC  "+profile);
	  if(profile!==undefined && profile!==null && profile!==""){  
		const profileObj=JSON.parse(profile); 
		if(profileObj!==undefined && profileObj!==null && profileObj.id_users!==null){
			userId=profileObj.id_users; 
		} 
	  } 
	  if(agents.includes(parseInt(userId))){
		allowRefundBtn=true; 
	  } 
	  const refunds=this.state.listOfTicketsPayments.refunds;
	  const paymentList = listOfTickets.map((payment, i) => { 
		const rndKey=Math.floor(Math.random()*100);
		const pmethodx=payment.pmethod!==null?payment.pmethod:0; 
		const pmethod=parseInt(pmethodx);
		//let descriptionDiv= null;
		let transact=	null;
		let transaction="", currency="", country="", brand="", last4="", cardno="", statusTxt="";
		//const transact=	JSON.parse(payment.transact);
		/*transact=this.tryParseJSONObject(payment.transact);
		//consoleXlog("###*******CHECK transact   ", payment.transact);
		if(transact!==null ){
		//if(payment.transact!==undefined &&payment.transact!==null && payment.transact!=="" && payment.transact!=="0"){
			//transact=	JSON.parse(payment.transact);
			if(transact.id.indexOf("ch_")>-1){
				transaction=transact.id;
				currency=transact.currency;
				country=transact.source.country;
				brand=transact.source.brand;
				last4=transact.source.last4;
				cardno="xxxx xxxx ";
			}else if(transact.id.indexOf("PAY-")>-1){
				transaction=transact.id;
				currency=transact.transactions[0].amount.currency;
				country=transact.payer.payer_info.country_code;
				brand="Paypal"; //transact.brand;
				last4=""; //transact.last4;
				cardno="xxxx xxxx ";
			}else if(transact.id===transact.charge_id && transact.support_url.indexOf("https://chargedesk.com/")>-1){
				transaction=transact.id;
				currency=transact.currency;
				//country=transact.payer.payer_info.country_code;
				country=transact.currency;
				brand="ChargeDesk"; //transact.brand;
				last4=transact.last_4_digits;
				cardno="xxxx xxxx ";
				statusTxt=transact.is_paid===true?"oui/yes":"non/no";
			}
		}*/
		const isIconOpened=payment.isIconOpened===undefined?false:payment.isIconOpened;
		//return ( <option key={item} value={item}>{item}</option>);
		// return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		// {" code "+payment.code} {" ("+pmethodList[pmethod]+")"}</p>);
		let refundedAmount=0; let refundedAmountTot=0;
		//const paymentAmount=(payment.amount+payment.tax1+payment.tax2).toFixed(2); //payment.amount
		const paymentAmount=payment.total;  
          if(refunds!==undefined && refunds.length > 0){
            for(const refund of refunds){    
              if(refund.id_payments===payment.id && refund.status==="3"){
                //refundedAmount +=parseFloat(refund.amount);
				refundedAmount +=parseFloat(refund.total);
				refundedAmountTot +=parseFloat(refund.total);
              } 
            }
          }

		//consoleXlog("#refundedAmount-refundedAmount ", refundedAmount, refunds);
		payment.refundAmount=refundedAmountTot;  
		  			
		//const isIconOpened=payment.isIconOpened===undefined?false:payment.isIconOpened;
		let descriptionDiv=this2.divPaymentsList(payment);
return (<div className="lstp lstpdv" key={"m00tb3p2x"+rndKey+i}><p className="lstpx" key={"00px"+rndKey+payment.id}>${paymentAmount} {"#Fac "+payment.id_invoices} 
		 {" ("+pmethodList[pmethod]+")"}
		 <IconButton edge="end" aria-label="download Facture/Invoice"  data-fileaddress={payment.id_invoices} 
					data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} 
					onClick={this.handleDownloadFileInvoice}>
						<DownloadIcon />
						<CheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
					</IconButton>
		<IconButton edge="end" aria-label="download customized Facture/Invoice"  data-fileaddress={payment.id_invoices} 
				data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
				data-productfieldid={0} data-fieldid={0} data-required={false} 
				data-filesheredown={1} data-labelnodel={0} 
				onClick={this.handleDownloadFileInvoiceCus}>
					<DownloadIcon />
					<SpellcheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
			</IconButton>			
					{/* (allowRefundBtn && pmethodList[pmethod]==='Interac')?			 */}

					{/* (allowRefundBtn && (pmethodList[pmethod]==='Interac' || pmethodList[pmethod]==='stripe' || pmethodList[pmethod]==='ChargeDesk' || pmethodList[pmethod]==='paypal'))? */}
								
		  {(allowRefundBtn && refundWhiteList.includes(pmethodList[pmethod]))?
		  <IconButton edge="end" aria-label="Remborsement/Refund"  
		  data-fieldname={payment.id_invoices} data-id={payment.id_invoices} 
		   data-paymentid={payment.id} onClick={this.showRefundProcess}>
			  <AttachMoneyIcon /> Refund
		  </IconButton>:''} 
		  {refundedAmount > 0? <span className='red'> (
			{/* déjà remboursé  */}
		  {refundedAmount.toFixed(2)}$) 
		  <button type="button" className="btn btn-primary btn-smx btn-xs xbtnsmx" 
	data-fieldname={payment.id_invoices} data-id={payment.id_invoices} 
	data-paymentid={payment.id} onClick={this.showRefundProcess2}> 
		<ListIcon /> Details  &nbsp; 
	</button>
		  </span> : ""}
		  &nbsp; &nbsp;  
			  <button type="button" className="btn btn-default btn-xs" data-index={i} data-transtype={2} onClick={this.handleOpenCloseDataUnfold}>
				{isIconOpened===false?<ChevronRightIcon />:<ExpandMoreIcon />}
			  </button>						
		 </p>
		 <p className="lstpsub2">{payment.created}</p>
			{isIconOpened===true?			
				<>{descriptionDiv}</>:""
			}	
		 </div>);

		return (<p className="lstp" key={"tb3px"+rndKey+i}>${payment.amount} {"#Fac "+payment.id_invoices} 
		{" ("+pmethodList[pmethod]+")"}</p>);
	  }, this);

	  
	  //consoleXlog("QQQQQQQQQQ holalala#Info ", listOfTicketsInfo, listOfTransactions);
	  const transactionList = listOfTransactions.map((transaction, i) => { 
		const rndKey=Math.floor(Math.random()*100);	
		const status=transaction.status;
		//consoleXlog("QQQQQQQQQQ holalala#0 ", transaction.preachat);
		//const preachat=	JSON.parse(transaction.preachat);
		const preachat=	JSON.parse(transaction.preachat);
		let existedFideicom=false;
		//zendeskValueData=moment(zendeskValueData).utc().format("YYYY-MM-DDTHH:mm:ssz");
		//zendeskValueData=moment(zendeskValueData).utc().format(); //+":00Z";
		
		let date1 = moment(""+transaction.created);
		let date2 = moment("2023-04-05");
		let difference = date1.diff(date2, "seconds" );
		if(difference>=0){	existedFideicom=true; 	}
		if(preachat.neoprod_breakdown && preachat.neoprod_breakdown.invoice!==undefined && preachat.neoprod_breakdown.invoice.length > 0){
            for(const invRow of preachat.neoprod_breakdown.invoice){
              //let labelx=invRow.fr;
			  const labelkey=invRow.key;
			  let percentageval=parseFloat(invRow.percentage);
              //let maxamount0=preachat.amounts.serviceFee;
			  if(labelkey==="2" && percentageval >0){
				existedFideicom=true;
				break;
			  }

            }
        }
		//const preachat=JSON.parse(JSON.stringify(transaction.preachat)); 
		//consoleXlog("QQQQQQQQQQ holalala ", preachat);
		//let descriptionDiv= null;
		const isIconOpened=transaction.isIconOpened===undefined?false:transaction.isIconOpened;
		//consoleXlog("QQQQQQQQQQ holalala ", transaction.isIconOpened, isIconOpened);
					
		//const isIconOpened=transaction.isIconOpened===undefined?false:transaction.isIconOpened;
		let descriptionDiv=this2.divTransactionList(transaction);
		//const zzzzzzzz=transaction.amount; //transaction.amount
		const transactionAmount=(parseFloat(transaction.amount)+parseFloat(transaction.tax1)+parseFloat(transaction.tax2)).toFixed(2);

		if(!this.isElawyer && status!==undefined && (status!=="payed" && status!=="paid-b2b")){
			return (<div className="lstp lstpdv" key={"m00tb3p2x"+rndKey+i}><p className="lstpx red" key={"tb3p2x"+rndKey+i}>{transaction.description}   &nbsp; &nbsp; ${transactionAmount} <b>({transaction.status})</b>
			  &nbsp; &nbsp;  
			  {(status==="sent" || status==="partial")?<button type="button" className="btn btn-primary btn-sm xbtnsm" data-closeid={3} data-closeinvoiceid={transaction.id_invoices} onClick={handleOFP}>{t("zqwNFFrmZn021_x")}</button>:""} 
			  &nbsp; &nbsp;  

			  {existedFideicom===true?			
				<button type="button" className="btn btn-primary btn-smx btn-xs xbtnsmx" id="fideicommisgo" 										
					data-invoice={transaction.id_invoices}  data-ta={realticket_id}   
					data-fileaddress={transaction.id} 
					data-fieldname={"fideicommis"+transaction.id} data-id={transaction.id} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} 
					onClick={this.handleDownloadFileFideicommis} > 
					<DownloadIcon /> Fideicommis  &nbsp; </button>:""
			  }	
			  &nbsp;  
			  {existedFideicom===true?			
				<button type="button" className="btn btn-success btn-smx btn-xs xbtnsmx" id="fideicommisgo2" 										
					data-invoice={transaction.id_invoices}  data-ta={realticket_id}   
					data-fileaddress={transaction.id} 
					data-fieldname={"fideicommis"+transaction.id} data-id={transaction.id} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} data-fideioutin={"fideiout"}
					onClick={this.handleDownloadFileFideicommis} > 
					<DownloadIcon /> Fideico-out  &nbsp; </button>:""
			  }	
			  &nbsp;  
			  <button type="button" className="btn btn-default btn-smx btn-xs xbtnsmx" data-index={i} data-transtype={1} onClick={this.handleOpenCloseDataUnfold}>
				{isIconOpened===false?<ChevronRightIcon />:<ExpandMoreIcon />}
				</button>  
			</p>
			<p className="lstpsub">{transaction.created}</p>
			{isIconOpened===true?			
				<>{descriptionDiv}</>:""
			}
			{/* {existedFideicom===true?			
				<button type="button" className="btn btn-primary btn-smx btn-xs xbtnsmx" id="fideicommisgo" 
	data-fieldname={transaction.id_invoices} data-invoice={transaction.id_invoices} 
	data-id={transaction.id} data-ta={realticket_id} onClick={this.showRefundProcess2000000}> 
		<ListIcon /> Fideicommis  &nbsp; </button>:""
			}	 */}
			</div>);
		}

		//<ChevronRightIcon htmlColor="#FF0000" />
		return (<div className="lstp lstpdv" key={"m00tb3p2x"+rndKey+i}>
			<p className="lstpx" key={"tb3p2x"+rndKey+i}>{transaction.description}   &nbsp; &nbsp; ${transactionAmount} &nbsp; &nbsp; 
			{existedFideicom===true?			
				<button type="button" className="btn btn-primary btn-smx btn-xs xbtnsmx" id="fideicommisgo" 										
					data-invoice={transaction.id_invoices}  data-ta={realticket_id}   
					data-fileaddress={transaction.id} 
					data-fieldname={"fideicommis"+transaction.id} data-id={transaction.id} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} 
					onClick={this.handleDownloadFileFideicommis} > 
					<DownloadIcon /> Fideicommis  &nbsp; </button>:""
			  }	  
			&nbsp;  
			{existedFideicom===true?			
				<button type="button" className="btn btn-success btn-smx btn-xs xbtnsmx" id="fideicommisgo2" 										
					data-invoice={transaction.id_invoices}  data-ta={realticket_id}   
					data-fileaddress={transaction.id} 
					data-fieldname={"fideicommis"+transaction.id} data-id={transaction.id} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} data-fideioutin={"fideiout"}
					onClick={this.handleDownloadFileFideicommis} > 
					<DownloadIcon /> Fideico-out  &nbsp; </button>:""
			  }	
			  &nbsp;  
			<button type="button" className="btn btn-default btn-smx btn-xs xbtnsmx" data-index={i} data-transtype={1} onClick={this.handleOpenCloseDataUnfold}>
				{isIconOpened===false?<ChevronRightIcon />:<ExpandMoreIcon />}
				</button> 				
			</p>
			<p className="lstpsub">{transaction.created}</p> 
			{isIconOpened===true?			
				<>{descriptionDiv}</>:""
			}
			{/* {existedFideicom===true?			
				<button type="button" className="btn btn-primary btn-smx btn-xs xbtnsmx" id="fideicommisgo" 
	data-fieldname={transaction.id_invoices} data-invoice={transaction.id_invoices} 
	data-id={transaction.id} data-ta={realticket_id} onClick={this.showRefundProcess2000000}> 
		<ListIcon /> Fideicommis  &nbsp; </button>:""
			}				 */}
			</div>);


	  }, this);




	  let refunds2=[];
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.refunds!==undefined){
		refunds2=listOfTicketsInfo.gohistory.refunds; 
	  }
	  //const rndKey0=Math.floor(Math.random()*100);	
	  const transactionListHist = listOfTransactionsHist.map((transaction, i) => { 
		const rndKey=Math.floor(Math.random()*100);	
		const rndKey0=Math.floor(Math.random()*10000);	
		const status=transaction.status;
		const isIconOpened=transaction.isIconOpened===undefined?false:transaction.isIconOpened;
		let descriptionDiv=this2.divTransactionList(transaction);	  
		//'payed', 'sent', 'overdue', 'view', 'declined', 'refund', 'recurrent', 'partial', 'cancelled', 'paid-b2b', 'partial-b2b', 'cancelled-b2b')
		/*
		if(status!==undefined && (status!=="payed" && status!=="paid-b2b")){
			return (<p className="lstp red" key={"zxtbK3p2x"+rndKey+i+rndKey0}>{transaction.description} <b>({transaction.status})</b></p>);
		}

		return (<p className="lstp" key={"zxtbK3p2x"+rndKey+i+rndKey0}>{transaction.description} </p>);
		*/

		if(status!==undefined && (status!=="payed" && status!=="paid-b2b")){
			return (<div className="lstp lstpdv" key={"m00zxtbK3p2x"+rndKey+i+rndKey0}>
			<p className="lstpx red" key={"zxtbK3p2x"+rndKey+i+rndKey0}>{transaction.description} <b>({transaction.status})</b>
			&nbsp; &nbsp; 
			<button type="button" className="btn btn-default btn-smx btn-xs xbtnsmx" data-index={i} data-transtype={3} onClick={this.handleOpenCloseDataUnfold}>
				{isIconOpened===false?<ChevronRightIcon />:<ExpandMoreIcon />}
				</button>
			</p>
			<p className="lstpsub">{transaction.created}</p> 
			{isIconOpened===true?			
				<>{descriptionDiv}</>:""
			}	
			</div>);
		}

		return (<div className="lstp lstpdv" key={"m00zxtbK3p2x"+rndKey+i+rndKey0}>
			<p className="lstpx" key={"zxtbK3p2x"+rndKey+i+rndKey0}>{transaction.description} 
			&nbsp; &nbsp; 
			<button type="button" className="btn btn-default btn-smx btn-xs xbtnsmx" data-index={i} data-transtype={3} onClick={this.handleOpenCloseDataUnfold}>
				{isIconOpened===false?<ChevronRightIcon />:<ExpandMoreIcon />}
				</button>
			</p>
			<p className="lstpsub">{transaction.created}</p> 
			{isIconOpened===true?			
				<>{descriptionDiv}</>:""
			}	
			</div>);
	  }, this);

	  const paymentListHist = listOfTicketsHist.map((payment, i) => { 
		const rndKey=Math.floor(Math.random()*100);
		const pmethodx=payment.pmethod!==null?payment.pmethod:0; 
		const pmethod=parseInt(pmethodx); 
		const isIconOpened=payment.isIconOpened===undefined?false:payment.isIconOpened;
		let descriptionDiv=this2.divPaymentsList(payment);
		let refundedAmount=0;   let refundedAmountTot=0;
		//const paymentAmount=(payment.amount+payment.tax1+payment.tax2).toFixed(2); //payment.amount
		const paymentAmount=payment.total;  
          if(refunds2!==undefined && refunds2.length > 0){
            for(const refund of refunds2){    
              if(refund.id_payments===payment.id && refund.status==="3"){
                refundedAmount +=parseFloat(refund.amount);
				refundedAmountTot +=parseFloat(refund.total);
              } 
            }
          }

		  /*
		return (<p className="lstp" key={"h00px"+rndKey+payment.id}><MoneyIcon  /> {"#"+payment.id} &nbsp; ${payment.amount}  &nbsp; &nbsp; &nbsp;  <ListIcon  /> {"#Fac "+payment.id_invoices} 
		 {" ("+pmethodList[pmethod]+")"}
		 <IconButton edge="end" aria-label="download Facture/Invoice"  data-fileaddress={payment.id_invoices} 
					data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} 
					onClick={this.handleDownloadFileInvoice}>
						<DownloadIcon />
						<CheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
					</IconButton>
					{/* (allowRefundBtn && pmethodList[pmethod]==='Interac')?			 /}
		  {(allowRefundBtn && (pmethodList[pmethod]==='Interac' || pmethodList[pmethod]==='stripe'))?
		  <IconButton edge="end" aria-label="Remborsement/Refund"  
		  data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-history={3} 
		   data-paymentid={payment.id} onClick={this.showRefundProcess}>
			  <AttachMoneyIcon /> Refund
		  </IconButton>:''} 
		  {refundedAmount > 0? <span className='red'> (déjà remboursé {refundedAmount}$)</span> : ""}						
		 </p>);*/

return (<div className="lstp lstpdv" key={"m00h00px"+rndKey+i+payment.id}>
		<p className="lstpx" key={"h00px"+rndKey+payment.id}><MoneyIcon  /> {"#"+payment.id} &nbsp; ${paymentAmount}  &nbsp; &nbsp; &nbsp;  <ListIcon  /> {"#Fac "+payment.id_invoices} 
{" ("+pmethodList[pmethod]+")"}
<IconButton edge="end" aria-label="download Facture/Invoice"  data-fileaddress={payment.id_invoices} 
		   data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
		   data-productfieldid={0} data-fieldid={0} data-required={false} 
		   data-filesheredown={1} data-labelnodel={0} 
		   onClick={this.handleDownloadFileInvoice}>
			   <DownloadIcon />
			   <CheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
		   </IconButton>
<IconButton edge="end" aria-label="download customized Facture/Invoice"  data-fileaddress={payment.id_invoices} 
				data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
				data-productfieldid={0} data-fieldid={0} data-required={false} 
				data-filesheredown={1} data-labelnodel={0} 
				onClick={this.handleDownloadFileInvoiceCus}>
					<DownloadIcon />
					<SpellcheckIcon style={{ color: "blue", fontWeight:"bolder" }} />	
			</IconButton>		   
		   {/* (allowRefundBtn && pmethodList[pmethod]==='Interac')?			 */}
		   {/* allowRefundBtn &&   (pmethodList[pmethod]==='Interac' || pmethodList[pmethod]==='stripe' || pmethodList[pmethod]==='ChargeDesk' || pmethodList[pmethod]==='paypal') */}
 {(allowRefundBtn && refundWhiteList.includes(pmethodList[pmethod]))?
 <IconButton edge="end" aria-label="Remborsement/Refund"  
 data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-history={3} 
  data-paymentid={payment.id} onClick={this.showRefundProcess}>
	 <AttachMoneyIcon /> Refund
 </IconButton>:''} 
 {refundedAmount > 0? <span className='red'> (
	{/* déjà remboursé  */}
 {refundedAmountTot.toFixed(2)}$
	{/* NeoRefundPaymentList */}
	<button type="button" className="btn btn-primary btn-smx btn-xs xbtnsmx" 
	data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-history={3} 
	data-paymentid={payment.id} onClick={this.showRefundProcess2}>
		<ListIcon /> Details &nbsp; 
	</button>
 )</span> : ""}
 &nbsp; &nbsp; 
			<button type="button" className="btn btn-default btn-smx btn-xs xbtnsmx" data-index={i} data-transtype={4} onClick={this.handleOpenCloseDataUnfold}>
				{isIconOpened===false?<ChevronRightIcon />:<ExpandMoreIcon />}
				</button>				
</p>
		<p className="lstpsub2">{payment.created}</p> 
			{isIconOpened===true?			
				<>{descriptionDiv}</>:""
			}	
			</div>);

		return (<p className="lstp" key={"htb3px"+rndKey+i}>${payment.amount} {"#Fac "+payment.id_invoices} 
		{" ("+pmethodList[pmethod]+")"}</p>);
	  }, this);
  


	return (
		<div className="iamneombzin"> 
				 <div className="form-group">
						{client_type==="2"?
						<><span style={{display:'inline'}}><b>{t("zqwNFFtxt19_x")}</b></span>   </>:
						<div style={{display: "flex", gap: "1rem", marginTop: "1rem"}}>
						<Button size='sm' onClick={handleOFP} color='primary'>
							<PostAddIcon />
							{`${txtaddprodpo} ${txtaddprodpoto} #${realticket_id ?? ""}`} 
						</Button>
							<ChangeFolderProduct ticketId={realticket_id} toast={this.showToast} fetchData={this.getClientGoTransactionList} />
							</div>
						}						
				 </div>
				 <p className = "font-16"><strong>{t("zqwNFFtxt6_x")}</strong> </p>
				 <div className="form-group">
					  {transactionList}
				 </div>
				 <p className = "font-16"> &nbsp; </p> 
				 <p className = "font-16"><strong>{t("zqwNFFtxt7_x")}</strong>  </p>
				 <div className="form-group">
					 {paymentList}
				 </div> 
				 <p className = "font-16"> &nbsp; </p> 
				 <p className = "font-16 neospxpay"><strong>{t("zqwNFFrmZn01_x")}</strong> <span style={{fontSize: "12.5px", fontWeight: "600", color: "#575252"}}>{genLang({en: "Warning! this is not the customer invoice", fr: "Attention ! ceci n'est pas la facture client !"})}</span></p>
				 <p className = "font-16"> Générer <span style={{display:'inline'}}>
							<a href="#" data-id={0} onClick={this.handleOpenJudiciaryInvoice}><PostAddIcon /></a>
							 
						</span> 
				</p>


				<p className = "font-16"> &nbsp; </p> 
				<p className = "font-16 neospxpay"><strong>Les Historiques  du Client (Produits et  Paiements)</strong> </p>
				<div className="container neoHistLPT">
					<div className="containerx">				
						<Accordion key={"datHistLPT"+indProd}>
							<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-label="Expand"
							aria-controls={"HistLPT-sub2actions"+indProd+"-content"}
							id={"HistLPT-subac2-block"+indProd}
							>
								<span><b>Historique de Produits achetés</b></span>
							</AccordionSummary>
							<AccordionDetails> 
								{/* aaaaaaaaaa aaaaaaaaaaa aaaaaaa */}
								<div className="container">{transactionListHist}</div>
							</AccordionDetails>
						</Accordion>  
						<Accordion key={"restHistLPT"+indProd}>
							<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-label="Expand"
							aria-controls={"HistLPT-restcactions"+indProd+"-content"}
							id={"pHistLPT-restc-block"+indProd}
							>
								<span><b>Historique de Paiements Éffectués</b></span>
							</AccordionSummary>
							<AccordionDetails> 
								{/* bbbbbbbbbb bbbbbb bbbbbb bbbbbbbb bbbbbbbbb */}
								<div className="container">{paymentListHist}</div>
							</AccordionDetails>
						</Accordion> 
					</div>  
				 </div>  
				 <p className = "font-16"> &nbsp; </p> 
				 <p className = "font-16"> &nbsp; </p>     
				   

			  <FolderProductPopup /> 
			  <JudiciaryInvoicePopup /> <CustomInvoicePopup />  
			  <RefundProcessPopup /> 
			  <RefundProcessPopupList />  
				<Toastr messagex={myToastMessage} open={true} toastType={toastrType} anchorOrigin={1} />
				<FileDeletePopup /> <FileLabelUpdatePopup /> <NdiNeoDocSendFileOptionsPopup />
				<Loader  openx={this.state.isLoaderOpen}  />  
			</div>
	  );
}

renderTab4 = (eventQQ) => {
	//renderTab4(){
	const { t } = this.props;
	const currentTabMain=this.props.currentTab;
	//consoleXlog("TAB4WWOOOOOOOOOOOOOOOOOO "+currentTabMain);
	const elementForms=this.state.elementForms;
	//const classes = useStyles(); //<form onSubmit={this.handleSubmit} className={classes.root} noValidate autoComplete="off">
	const FolderProductPopup=this.FolderProductPopup;
	const RefundProcessPopup=this.RefundProcessPopup;  
	const JudiciaryInvoicePopup=this.JudiciaryInvoicePopup;
	const CustomInvoicePopup=this.CustomInvoicePopup; 
	const myToastMessage=this.state.toastrMessage;
	  const toastrType=this.state.toastrType;
	  //const realticket_id=this.state.realticket_id;

	 const fullObj=this.state.dynamicFormAllData; 

	  const products=fullObj.products;  
	  const productFields=fullObj.productFields;  
	  const fields=fullObj.fields; 
	  const fieldOptions=fullObj.fieldOptions;
	  const fieldsIdsopt=fullObj.fieldsIdsopt;
	  const fieldServices=fullObj.fieldServices; 
	  //const case_id=fullObj.case_id;
	  const ticketDataLeft=fullObj.optionsData;
	  const ticketProds=fullObj.ticketProds; //folders_products
	  let ticketDataLeftRec=null;
	  const realticket_id=fullObj.ticket_id;
	  const clientProdTransInfo=fullObj.clientProdTransInfo;
	  const personType=fullObj.personType;
	  //const product=fullObj.product;  
	  //const product=fullObj.product;
	  let fieldList = []; let text="";
	  let dynamicForm = []; 
	  const typeListx = ["select","checkbox","radio"];
	  let selectedFiles=[]; let valueNextStep=[];
	  const dynamicFormall=this.state.dynamicForm;
	  const dynamicFormData=this.state.dynamicFormData;
 
	  //consoleXlog("wooooooooooooooololoooooooooooooy");  // window.alert(JSON.stringify(myNdiTicketDataLeft));
	  //consoleXlog(fullObj);
	  //consoleXlog("####wooooooooooooooololoooooooooooooy dynamicFormall "); //consoleXlog(dynamicFormall);
 
	const saveAllText=t("zqwTDCtxt1_x"); //Sauvegarde Tout/Save All
	const changeCounter=this.state.changeCounter;
 
	const realElementForms = null;
	const this0=this;
	
	  //const realElementForms00 = null;
	  let myLang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
	  if(this.loadOtherLang!==""){ myLang=this.loadOtherLang; } 
	  let prodTitle="Champs par Défault";
	  let accordeonClient="Info du Client";
	  let accordeonPA="La Partie Adverse (PA)";
	  let accordeonXFields="... Champs Additionnels";
	  let txtaddprodpo="Ajouter produits";
	  let txtaddprodpoto="au";
	  if(myLang.indexOf("fr")< 0){
		prodTitle="Default Fields";
		accordeonClient="Client Info";
		accordeonPA="The Opposing Party (OP)";
		accordeonXFields="... Additional Fields";
		txtaddprodpo="Add products";
		txtaddprodpoto="to";
	  }

	  //const listOfFieldInfoClient=[30,31,32,33,34,35,36,121,126,130];  98

	  const listOfFieldInfoClient=[30,31,32,33,34,35,36,121,126,133,138,139,142,167];
	  const listOfFieldInfoPA=[37,38,39,40,41,42,43,123,124,125,140,141,143];
	  //const listOfFieldInfoCliPa=[30,31,32,33,34,35,36,121,126,133, 37,38,39,40,41,42,43,123,124,125];
	  const listOfFieldInfoCliPa=listOfFieldInfoClient.concat(listOfFieldInfoPA); 

	  const listOfNatDoCivil=[45,95,74,168,90,91,97,111,113,131,1,132,137,   109,144,163, 172, 171];
	  const listOfNatDoFamille=[45,95,74,168,90,91,97,111,113,131,1,132,  109,134,144,163, 173,171];
	  const listOfNatDoCivilVC=[45,95,74,168,90,97,111,113,131,1,132,   109,135,163, 172, 171];
	  const listOfNatDoPenal=[95,91,93,149,110,78,92,97,88,1,98,145,136,   109,127,144,150,151,163,164,165,166, 171, 185];
	  const listOfNatDoCrimi=[45,115,95,91,93,149,110,78,92,97,88,111,113,1,132,   109,127,144,163, 171];
	  const listOfNatDoCorpo=[45,95,74,168,90,91,97,111,113,1,131,132,   109,129,144,148,163, 171, 180, 181, 182, 183];
	  const listOfNatDoNotariat=[45,95,74,168,97,112,111,113,1,132,  109,128,144,163, 171];  
	  let listOfNatDoInUsed=[];
	  if(personType==="2"){
		listOfFieldInfoClient.push(130); 
	  }     


	let realElementFormsDoc =null;
	const domainInLaw=this.state.domainInLaw;

	
	if(domainInLaw!==null || domainInLaw!==""){ 
		// 445-	Civil
		// 442-	Civil – Vices cachés
		// 446-	Famille
		// 448-	Notariat
		// 447-	Pénal
		// 449-	Corporatif
	
		if(domainInLaw==="442"){ listOfNatDoInUsed=listOfNatDoCivilVC;  }
		else if(domainInLaw==="445"){ listOfNatDoInUsed=listOfNatDoCivil;  }
		else if(domainInLaw==="446"){ listOfNatDoInUsed=listOfNatDoFamille;  }
		else if(domainInLaw==="447"){ listOfNatDoInUsed=listOfNatDoPenal;  }
		else if(domainInLaw==="448"){ listOfNatDoInUsed=listOfNatDoNotariat;  }
		else if(domainInLaw==="449"){ listOfNatDoInUsed=listOfNatDoCorpo;  }
		//consoleXlog("domainInLaw domainInLaw "+domainInLaw, listOfNatDoInUsed);
	}

	
	const realElementFormsDocQQQ = products!==undefined && products.length > 0
	&& products.map((itemProd, indProd) => { 
	   const productId= itemProd.name;
	   const productTypeId= itemProd.product_type_id;
	   const prodBlock=JSON.parse(itemProd.product_block);
	   const productBlockTitle=productId==="1"? prodTitle : prodBlock.titre;

	   if(domainInLaw===null || domainInLaw===""){ return (null); }
  
//realElementFormsDoc =null;
const realElementFormsDoc000 = productFields!==undefined && productFields.length > 0
	&& productFields.map((item, i) => { 
	
	  if(productTypeId!==item.product_type_id){ return (null); }
	  //if(item.field_id >29 && item.field_id <44){  return (null); }	
	  if(listOfFieldInfoCliPa.includes(parseInt(item.field_id))){  return (null); } 
	  if(!listOfNatDoInUsed.includes(parseInt(item.field_id))){  return (null); }			  	
	let fieldBlock=this0.getFieldRecord(fields, item.field_id);  
	if(fieldBlock.field_type!=="file"){  return (null); }	
	let x = this0.getFieldTypeId(fieldBlock.field_type);
	ticketDataLeftRec=this0.getTicketDataRecord(ticketDataLeft, item.id);
	let FieldData=null;
	let product=this0.getProductRecord(products, item.product_type_id);  
	const prodId=product.name;
	let ticketProd=this0.getTicketProductRecord(ticketProds, prodId); 
	let isticketProdNull=false; 
	if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
	const case_id=ticketProd.id;
	//consoleXlog("QQQQQQQQQQQ1111", item.field_id);
	if(typeListx.includes(fieldBlock.field_type)){
		let fieldOptionsx=null;
		//consoleXlog("QQQQQQQQQQQ222", item.field_id);
		if(fieldsIdsopt.includes(item.field_id)){
			//consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
			fieldOptionsx=this0.getFieldOptionsRecords(fieldOptions, item.field_id);
		}
		 
		FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
			case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
	}else{
		FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
			case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
	}

	const moodx=this0.state.mood;
	let blockElem =null;
	const dynamicForm=this0.state.dynamicForm[i];
	const dynamicFormDatax=dynamicFormData[i];
	//consoleXlog("###THATS A DOC ", item.field_id, fieldBlock.id, fieldBlock.name);
	//consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
	//this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
	if(isticketProdNull){ x=-9; } 
	return this0.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
  
}, this0);

//return(<div className="container">{realElementFormsInfo}</div>);
return realElementFormsDoc000;   

  return ( null );
}, this);

	  const FileDeletePopup=this.FileDeletePopup;     
	  const FileLabelUpdatePopup=this.FileLabelUpdatePopup;
	  const client_type=this.state.client_type;
	  //consoleXlog("CLIENT TYPE ", client_type);
	  const TabPanel = this.TabPanel;
	  const value=this.state.valueTab;
	  const handleOFP=this.handleOpenFolderProduct;
	  const NdiNeoDocSendFileOptionsPopup=this.NdiNeoDocSendFileOptionsPopup;

	  const listOfTicketsInfo=this.state.listOfTicketsPayments;
	  let listOfTickets=[]; //this.state.listOfTicketsPayments;
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.payments!==undefined){
			//listOfTickets=listOfTicketsInfo.payments;
			if(domainInLaw===null || domainInLaw===""){   }else{
				listOfTickets=listOfTicketsInfo.payments;
			}
	  }
	  let extraInfo=[]; //let extraInfo="";
	  let listOfTransactions=[]; 
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.transactions!==undefined){
		//listOfTransactions=listOfTicketsInfo.transactions;
		if(domainInLaw===null || domainInLaw===""){  }else{
			listOfTransactions=listOfTicketsInfo.transactions;
		}
	  }

	  const PublicLinkFormPopup=this.PublicLinkFormPopup;
	  const NeoFilePreviewPDFPopup=this.NeoFilePreviewPDFPopup;
  

	return (
		<div className="iamneombzin">   
				 {/* <p className = "font-16"><strong>OTHER TAB #5 DOCUMENTS: </strong> </p> gotoSendPublicForm */}
				 <div className="form-group"  > 
					 <button type="button" className="btn btn-primary" onClick={this.gotoSendPublicForm}>{t('zqwNFFrmZn09_x')}</button> 
				 </div>
				 <div className="form-group"  >
					{realElementFormsDoc}
					{/* <p className = "font-16"><strong>Liste de Documents: </strong> </p> */}
					{realElementFormsDocQQQ}	 
				 </div>
			   

			  <FolderProductPopup />
			  <JudiciaryInvoicePopup />  <CustomInvoicePopup /> 
			  <RefundProcessPopup /> 
				<Toastr messagex={myToastMessage} open={true} toastType={toastrType} anchorOrigin={1} />
				<FileDeletePopup /> <FileLabelUpdatePopup /> <NdiNeoDocSendFileOptionsPopup />
				<Loader  openx={this.state.isLoaderOpen}  />
				<PublicLinkFormPopup />
				<NeoFilePreviewPDFPopup />      
			</div>
	  );
}

//tab 2: dossier-client
renderQQQ3 = (eventQQ) => { 
	//renderQQQ3() {
	const { t } = this.props;
	const currentTabMain=this.props.currentTab;
	//consoleXlog("WWOOOOOOOOOOOOOOOOOO "+currentTabMain);
	const elementForms=this.state.elementForms;
	//const classes = useStyles(); //<form onSubmit={this.handleSubmit} className={classes.root} noValidate autoComplete="off">
	const FolderProductPopup=this.FolderProductPopup;
	const RefundProcessPopup=this.RefundProcessPopup; 
	const JudiciaryInvoicePopup=this.JudiciaryInvoicePopup; 
	const CustomInvoicePopup=this.CustomInvoicePopup; 
	const myToastMessage=this.state.toastrMessage;
	  const toastrType=this.state.toastrType;
	  //const realticket_id=this.state.realticket_id;

	 const fullObj=this.state.dynamicFormAllData; 

	  const products=fullObj.products;  
	  const productFields=fullObj.productFields;  
	  const fields=fullObj.fields; 
	  const fieldOptions=fullObj.fieldOptions;
	  const fieldsIdsopt=fullObj.fieldsIdsopt;
	  const fieldServices=fullObj.fieldServices; 
	  //const case_id=fullObj.case_id;
	  const ticketDataLeft=fullObj.optionsData;
	  const ticketProds=fullObj.ticketProds; //folders_products
	  let ticketDataLeftRec=null;
	  const realticket_id=fullObj.ticket_id;
	  const clientProdTransInfo=fullObj.clientProdTransInfo;
	  const personType=fullObj.personType;
	  //const product=fullObj.product;  
	  //const product=fullObj.product;
	  let fieldList = []; let text="";
	  let dynamicForm = []; 
	  const typeListx = ["select","checkbox","radio"];
	  let selectedFiles=[]; let valueNextStep=[];
	  const dynamicFormall=this.state.dynamicForm;
	  const dynamicFormData=this.state.dynamicFormData;
 
	  //consoleXlog("wooooooooooooooololoooooooooooooy");  // window.alert(JSON.stringify(myNdiTicketDataLeft));
	  //consoleXlog(fullObj);
	  //consoleXlog("####wooooooooooooooololoooooooooooooy dynamicFormall "); //consoleXlog(dynamicFormall);

	const NeogenTextField=this.NeogenTextField;
	const NeogenNumberField=this.NeogenNumberField;
	const NeogenRadioBox=this.NeogenRadioBox;
	const NeogenCheckBox=this.NeogenCheckBox;
	const NeogenSelectBox=this.NeogenSelectBox;
	const NeogenTextarea=this.NeogenTextarea;
	const NeogenDateTimeField=this.NeogenDateTimeField;  
	const NeogenSelectReactField=this.NeogenSelectReactField;
	const NeogenInputMaskField=this.NeogenInputMaskField; 
	const NeogenFileField=this.NeogenFileField;
	const NeogenAnyGenericTextField=this.NeogenAnyGenericTextField; 
	const saveAllText=t("zqwTDCtxt1_x"); //Sauvegarde Tout/Save All
	const changeCounter=this.state.changeCounter;
	const NeoSearchActions=this.NeoSearchActions;
	const Alert=this.Alert;
	const NeoFilePreviewPDFPopup=this.NeoFilePreviewPDFPopup;


		 
	 
	const realElementForms = null;
	const this0=this;
	
	  //const realElementForms00 = null;
	  let myLang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
	  if(this.loadOtherLang!==""){ myLang=this.loadOtherLang; } 
	  let prodTitle="Champs par Défault";
	  let accordeonClient="Info du Client";
	  let accordeonPA="La Partie Adverse (PA)";
	  let accordeonXFields="... Champs Additionnels";
	  let txtaddprodpo="Ajouter produits";
	  let txtaddprodpoto="au";
	  let neoDocNoteXFields="... NeoDoc Notes";
	  let neoDocNoteIncorpo="...Corporatif Formulaires de NeoDoc";
	  let leadNoteXFields="... Notes de Lead";
	  let accordeonMEC="Mis en Cause(enfants)";
	  if(myLang.indexOf("fr")< 0){
		prodTitle="Default Fields";
		accordeonClient="Client Info";
		accordeonPA="The Opposing Party (OP)";
		accordeonXFields="... Additional Fields";
		txtaddprodpo="Add products";
		txtaddprodpoto="to";
		neoDocNoteIncorpo="...Corporation NeoDoc Forms";
		leadNoteXFields="... Lead Notes";
		accordeonMEC="Put into Context(children)";
	  }

	  //const listOfFieldInfoClient=[30,31,32,33,34,35,36,121,126,130]; 114  90  72 46 98 
	  const listOfFieldInfoClient=[30,31,32,33,34,35,36,121,126,133,138,139,142,167];
	  const listOfFieldInfoPA=[37,38,39,40,41,42,43,123,124,125,140,141,143];
	  //const listOfFieldInfoCliPa=[30,31,32,33,34,35,36,121,126,133, 37,38,39,40,41,42,43,123,124,125];
	  const listOfFieldInfoCliPa=listOfFieldInfoClient.concat(listOfFieldInfoPA); 

	  const listOfNatDoCivil=[45,95,74,168,90,91,97,111,113,131,1,132,137,   109,144,163, 172, 171];
	  const listOfNatDoFamille=[45,95,74,168,90,91,97,111,113,131,1,132,  109,134,144,163, 173, 171];
	  const listOfNatDoCivilVC=[45,95,74,168,90,97,111,113,131,1,132,   109,135,144,163, 172, 171]; 
	  const listOfNatDoPenal=[95,91,93,149,110,78,92,97,88,1,98,145,136,   109,127,144,150,151,163,164,165,166, 171, 185];
	  const listOfNatDoCrimi=[45,115,95,91,93,149,110,78,92,97,88,111,113,1,132,   109,127,144,163, 171];
	  const listOfNatDoCorpo=[45,95,74,168,90,91,97,111,113,1,131,132,   109,129,144,148,163, 171, 180, 181, 182, 183];
	  const listOfNatDoNotariat=[45,95,74,168,97,112,111,113,1,132,  109,128,144,163, 171];
	  const listOfNatDoCorpoNeoDoc=[146,147];
	  const listOfNatDoFamilleMEC=[162];

	  let listOfNatDoInUsed=[];
	  if(personType==="2"){
		listOfFieldInfoClient.push(130);
	  }     


	  let realElementFormsDoc =null;
	  const domainInLaw=this.state.domainInLaw;

	   
	  if(domainInLaw!==null || domainInLaw!==""){ 
			// 445-	Civil
			// 442-	Civil – Vices cachés
			// 446-	Famille
			// 448-	Notariat
			// 447-	Pénal   //450 criminel 
			// 449-	Corporatif
		
		  if(domainInLaw==="442"){ listOfNatDoInUsed=listOfNatDoCivilVC;  }
		  else if(domainInLaw==="445"){ listOfNatDoInUsed=listOfNatDoCivil;  }
		  else if(domainInLaw==="446"){ listOfNatDoInUsed=listOfNatDoFamille;  }
		  else if(domainInLaw==="447"){ listOfNatDoInUsed=listOfNatDoPenal;  }
		  else if(domainInLaw==="448"){ listOfNatDoInUsed=listOfNatDoNotariat;  }
		  else if(domainInLaw==="449"){ listOfNatDoInUsed=listOfNatDoCorpo;  }
		  else if(domainInLaw==="450"){ listOfNatDoInUsed=listOfNatDoCrimi;  }
		  //consoleXlog("domainInLaw domainInLaw "+domainInLaw, listOfNatDoInUsed);
	   }

	  const realElementFormsNatureDo = products!==undefined && products.length > 0
		  && products.map((itemProd, indProd) => { 
			 const productId= itemProd.name;
			 const productTypeId= itemProd.product_type_id;
			 const prodBlock=JSON.parse(itemProd.product_block);
			 const productBlockTitle=productId==="1"? prodTitle : prodBlock.titre;

			// if(productId!=="1"){ return (null); }

			 const realElementForms = productFields!==undefined && productFields.length > 0
		  && productFields.map((item, i) => { 
			//if(productId!=="1"){ return (null); }
			if(productTypeId!==item.product_type_id){ return (null); }
			if(item.field_id!=="44"){ return (null); }
			//if(item.field_id >29 && item.field_id <44){  return (null); }	
			if(listOfFieldInfoCliPa.includes(parseInt(item.field_id))){  return (null); }  	
		  let fieldBlock=this.getFieldRecord(fields, item.field_id); 
		  if(fieldBlock.field_type==="file"){  return (null); }	
		  //consoleXlog("shooooooooooooooowing NATURE ");
		  let x = this.getFieldTypeId(fieldBlock.field_type);
		  ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		  let FieldData=null;
		  let product=this.getProductRecord(products, item.product_type_id);  
		  const prodId=product.name;
		  let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		  let isticketProdNull=false; 
		  if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
		  const case_id=ticketProd.id;
		  //consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		  if(typeListx.includes(fieldBlock.field_type)){
			  let fieldOptionsx=null;
			  //consoleXlog("QQQQQQQQQQQ222", item.field_id);
			  if(fieldsIdsopt.includes(item.field_id)){
				  //consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				  fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			  }
			   
			  FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }else{
			  FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }
  
		  const moodx=this.state.mood;
		  let blockElem =null;
		  const dynamicForm=this.state.dynamicForm[i];
		  const dynamicFormDatax=dynamicFormData[i];
		  //consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		  //this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		  if(isticketProdNull){ x=-9; } 
		  return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
		
	  }, this); 

	  if(productId==="1"){
		return (
			<div key={"mxz-oneNado-3444"+indProd} className="container">{realElementForms}</div>
		);
	   }

	   return null;

		}, this);

	  const realElementForms00 = products!==undefined && products.length > 0
		  && products.map((itemProd, indProd) => { 
			 const productId= itemProd.name;
			 const productTypeId= itemProd.product_type_id;
			 const prodBlock=JSON.parse(itemProd.product_block);
			 const productBlockTitle=productId==="1"? prodTitle : prodBlock.titre;

			 if(domainInLaw===null || domainInLaw===""){ return (null); }
			  
		//const realElementFormsInfo=null; 
		const realElementFormsInfo = productFields!==undefined && productFields.length > 0
		  && productFields.map((item, i) => { 
		  
			if(productTypeId!==item.product_type_id){ return (null); }
			//if(item.field_id >29 && item.field_id <37){}else{  return (null); }	
			if(listOfFieldInfoClient.includes(parseInt(item.field_id))){}else{  return (null); }  	
		  let fieldBlock=this.getFieldRecord(fields, item.field_id); 
		  let x = this.getFieldTypeId(fieldBlock.field_type);
		  ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		  let FieldData=null;
		  let product=this.getProductRecord(products, item.product_type_id);  
		  const prodId=product.name;
		  let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		  let isticketProdNull=false; 
		  if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
		  const case_id=ticketProd.id;
		  //consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		  if(typeListx.includes(fieldBlock.field_type)){
			  let fieldOptionsx=null;
			  //consoleXlog("QQQQQQQQQQQ222", item.field_id);
			  if(fieldsIdsopt.includes(item.field_id)){
				  //consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				  fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			  }
			   
			  FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }else{
			  FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }
  
		  const moodx=this.state.mood;
		  let blockElem =null;
		  const dynamicForm=this.state.dynamicForm[i];
		  const dynamicFormDatax=dynamicFormData[i];
		  //consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		  //this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		  if(isticketProdNull){ x=-9; } 
		  return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
		   
	  }, this);

	  const realElementFormsPA = productFields!==undefined && productFields.length > 0
		  && productFields.map((item, i) => { 
		  
			if(productTypeId!==item.product_type_id){ return (null); }
			//if(item.field_id >36 && item.field_id <44){}else{  return (null); }	
			if(listOfFieldInfoPA.includes(parseInt(item.field_id))){}else{  return (null); }  
			
		  let fieldBlock=this.getFieldRecord(fields, item.field_id); 
		  let x = this.getFieldTypeId(fieldBlock.field_type);
		  ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		  let FieldData=null;
		  let product=this.getProductRecord(products, item.product_type_id);  
		  const prodId=product.name;
		  let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		  let isticketProdNull=false; 
		  if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
		  const case_id=ticketProd.id;
		  //consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		  if(typeListx.includes(fieldBlock.field_type)){
			  let fieldOptionsx=null;
			  //consoleXlog("QQQQQQQQQQQ222", item.field_id);
			  if(fieldsIdsopt.includes(item.field_id)){
				  //consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				  fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			  }
			   
			  FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }else{
			  FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }
  
		  const moodx=this.state.mood;
		  let blockElem =null;
		  const dynamicForm=this.state.dynamicForm[i];
		  const dynamicFormDatax=dynamicFormData[i];
		  //consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		  //this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		  if(isticketProdNull){ x=-9; } 
		  return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
		   
	  }, this);

	  //listOfNatDoFamilleMEC
	  const realElementFormsMEC = productFields!==undefined && productFields.length > 0
		  && productFields.map((item, i) => { 
		  
			if(productTypeId!==item.product_type_id){ return (null); }
			//if(item.field_id >36 && item.field_id <44){}else{  return (null); }	
			if(listOfNatDoFamilleMEC.includes(parseInt(item.field_id))){}else{  return (null); }  
			
		  let fieldBlock=this.getFieldRecord(fields, item.field_id); 
		  let x = this.getFieldTypeId(fieldBlock.field_type);
		  ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		  let FieldData=null;
		  let product=this.getProductRecord(products, item.product_type_id);  
		  const prodId=product.name;
		  let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		  let isticketProdNull=false; 
		  if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
		  const case_id=ticketProd.id;
		  //consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		  if(typeListx.includes(fieldBlock.field_type)){
			  let fieldOptionsx=null;
			  //consoleXlog("QQQQQQQQQQQ222", item.field_id);
			  if(fieldsIdsopt.includes(item.field_id)){
				  //consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				  fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			  }
			   
			  FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }else{
			  FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }
  
		  const moodx=this.state.mood;
		  let blockElem =null;
		  const dynamicForm=this.state.dynamicForm[i];
		  const dynamicFormDatax=dynamicFormData[i];
		  //consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		  //this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		  if(isticketProdNull){ x=-9; } 
		  return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
		   
	  }, this);


	  const realElementForms = productFields!==undefined && productFields.length > 0
		  && productFields.map((item, i) => { 
		  
			if(productTypeId!==item.product_type_id){ return (null); }
			if(!listOfNatDoInUsed.includes(parseInt(item.field_id))){  return (null); }		
			if(this.clientOnZendeskSCR >0 && parseInt(item.field_id)===53){  return (null); }  			 
			//if(item.field_id >29 && item.field_id <44){  return (null); }	
			if(listOfFieldInfoCliPa.includes(parseInt(item.field_id))){  return (null); }  	
		  let fieldBlock=this.getFieldRecord(fields, item.field_id); 
		  if(fieldBlock.field_type==="file"){  return (null); }	
		  let x = this.getFieldTypeId(fieldBlock.field_type);
		  ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		  let FieldData=null;
		  let product=this.getProductRecord(products, item.product_type_id);  
		  const prodId=product.name;
		  let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		  let isticketProdNull=false; 
		  if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
		  const case_id=ticketProd.id;
		  //consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		  if(typeListx.includes(fieldBlock.field_type)){
			  let fieldOptionsx=null;
			  //consoleXlog("QQQQQQQQQQQ222", item.field_id);
			  if(fieldsIdsopt.includes(item.field_id)){
				  //consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				  fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			  }
			   
			  FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }else{
			  FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }
  
		  const moodx=this.state.mood;
		  let blockElem =null;
		  const dynamicForm=this.state.dynamicForm[i];
		  const dynamicFormDatax=dynamicFormData[i];
		  //consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		  //this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		  if(isticketProdNull){ x=-9; } 
		  return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
		
	  }, this); 
	  

	  let realElementFormsIncBFields=null;
	  if(domainInLaw==="449"){
		consoleXlog("INCORPO paaaaaaaAAAAAAA 2.0");
		realElementFormsIncBFields = productFields!==undefined && productFields.length > 0
			&& productFields.map((item, i) => { 
			
			if(productTypeId!==item.product_type_id){ return (null); }
			//if(item.field_id >36 && item.field_id <44){}else{  return (null); }	
			if(listOfNatDoCorpoNeoDoc.includes(parseInt(item.field_id))){}else{  return (null); } 
			
			consoleXlog("paaaaaaaaaaaaaaaaasssssssssssseeeeeeeeeeeee 2.2");
			
			let fieldBlock=this.getFieldRecord(fields, item.field_id); 
			let x = this.getFieldTypeId(fieldBlock.field_type);
			ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
			let FieldData=null;
			let product=this.getProductRecord(products, item.product_type_id);  
			const prodId=product.name;
			let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
			let isticketProdNull=false; 
			if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
			const case_id=ticketProd.id;
			//consoleXlog("QQQQQQQQQQQ1111", item.field_id);
			if(typeListx.includes(fieldBlock.field_type)){
				let fieldOptionsx=null;
				//consoleXlog("QQQQQQQQQQQ222", item.field_id);
				if(fieldsIdsopt.includes(item.field_id)){
					//consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
					fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
				}
				
				FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
					case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
			}else{
				FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
					case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
			}

			consoleXlog("paaaaaaaAAAAAAA 2.3", FieldData);

			const moodx=this.state.mood;
			let blockElem =null;
			const dynamicForm=this.state.dynamicForm[i];
			const dynamicFormDatax=dynamicFormData[i];
			//consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
			//this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
			if(isticketProdNull){ x=-9; } 
			return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
			
		}, this);
	  }

	  //let neoleadNoteForms=null;


	  const ticket=this.state.realticket_id;
	  //let neoDocNoteForms=null;
	  let form_name ="";
	  let notesList=[];
	  //let notesList=this.state.allFieldsNotes.notesList; //let notesList=this.props.allFieldsNotes;

	if(this.state.allFieldsNotes!==undefined && this.state.allFieldsNotes!==null){ 
		if(this.state.allFieldsNotes.notesList!==undefined && this.state.allFieldsNotes.notesList!==null){ 
			notesList=this.state.allFieldsNotes.notesList;
		}
	}
	if(form_name===undefined || form_name===null || form_name===""){ 
        console.error("form name vide");
        //return null; 
    }
    if(notesList!==undefined || notesList!==null && notesList.length > 0){  
        //notesList=notesList.filter(note => (note.ticket===ticket && note.form_name===form_name));
        ///return state.filter(NeoVentes => NeoVentes.id !== action.payload.id);
    }
    //consoleXlog("WEEEEEEEEEQQQQX", notesList);  //notesfields 
	consoleXlog("...................FORM-allFieldsNotes", this.state.allFieldsNotes); 
	
	const nameField="";
	const noteGo=this.state.allFieldsNotesgo;

    let countQty=0;
	const pvcomment=" pvcomment";
    let neoDocNoteForms=notesList.length > 0
      && notesList.map((item, i) => { 
        //if(item.field_name!==nameField){ return null; } 
        const myItemtxt=""+item.id;
        const index=i; 
        countQty++;
        //const pvcomment=" pvcomment";
        const author = <span className="btnuser"><i className="fas fa-user"></i> {item.author}</span>;
		//const created= moment(item.creation_date).format('LLLL'); //raw.created_at;
        const created= item.creation_date;
        //let descBody=raw.html_body;
        let descBody=item.data_raw;
		consoleXlog("...................FORM NAME ID", i, item.id); 
        return(<div className="zendcomment" key={"cmt"+i}>
			<div className="unotetitleBlck">{t("zqwNeonotesfrmn_x")}: {item.form_name}</div>   
            <div className={"tcomment"+pvcomment}> 
                <div dangerouslySetInnerHTML={{ __html: descBody }}></div>
            </div>
            <div className="usernote-view"> 
                <small><i>{author}</i> &nbsp; &nbsp;  {created}</small>
            </div>    

        </div>);
	            
		return null; 

    }, this);

    if(countQty===0 && noteGo>0){
        const descBody="Pas de Notes trouvées/ No notes found.";
        neoDocNoteForms=(<div className="zendcomment" key={"cmt00000"}> 
        <div className={"tcomment"+pvcomment}> 
            <div dangerouslySetInnerHTML={{ __html: descBody }}></div>
        </div>
    </div>);
    }else if(countQty===0 && noteGo===0){
        //const descBody="Pas de Notes encore, cliquez sur le bouton pour charger/ No notes";
		const descBody=t("zqwNFnoneonotesyet_x");
        neoDocNoteForms=(<div className="zendcomment" key={"cmt00002"}> 
        <div className={"tcomment"+pvcomment}> 
            <div dangerouslySetInnerHTML={{ __html: descBody }}></div>
        </div>
    </div>);
    }

	/////////////////////////////////////////////////////////////////////////////////
	let countQty2=0;
	//let neoleadNoteForms=null;
	//const pvcomment=" pvcomment";
	//"leadsNotes": { "leadsInfo": [       
	// fullObj.leadsNotes.leadsInfo  leads && notes
	console.log("neoleadNoteForms-neoleadNoteForms ", fullObj.leadsNotes);
	//fullObj.leadsNotes.leadsInfo.notes.length > 0
	const this2=this;
	let neoleadNoteForms=[]; 

	if(fullObj.leadsNotes!==undefined && fullObj.leadsNotes.leadsInfo!==undefined){ 
    let neoleadNoteForms2=fullObj.leadsNotes.leadsInfo.length > 0
      && fullObj.leadsNotes.leadsInfo.map((itemObj, iobj) => { 
        //if(item.field_name!==nameField){ return null; }
		const leadIdTxt=itemObj.leads.id;

		let neoleadNoteForms002=itemObj.notes.length > 0
      		&& itemObj.notes.map((item, i) => { 
        const myItemtxt=""+item.id;
        const index=i; 
        countQty2++;
		let actionName="N/A";
		if(item.actionName!==undefined && item.actionName!==null  && item.actionName!==""){
			actionName=item.actionName;
		}
		let privateOrPublic=(<span><LockIcon style={{ fontSize: 10 }} />{t("privatef")} - {actionName}</span>);
		if(item.isPublic!==undefined && item.isPublic==="1"){
			privateOrPublic=(<span><LockOpenIcon style={{ fontSize: 10 }} />{t("privatef")} - {actionName}</span>);
		}

        //const pvcomment=" pvcomment";
        const author = <span className="btnuser"><i className="fas fa-user"></i> {item.user}</span>;
		//const created= moment(item.creation_date).format('LLLL'); //raw.created_at;
        const created= item.date_created;
        //let descBody=raw.html_body;
        let descBody=item.note;
		consoleXlog("...................FORM NAME ID", i, item.id); 
        const retx=(<div className="zendcomment" key={"lncmt"+i}>
			{/* <div className="unotetitleBlck">{t("zqwNeonotesfrmn_x")}: {item.form_name}</div>    */}
            <div className={"tcomment"+pvcomment}> 
                <div dangerouslySetInnerHTML={{ __html: descBody }}></div>
            </div>
            <div className="usernote-view"> 
                <small><i>{author}</i> &nbsp; &nbsp;  {created} #{myItemtxt} {privateOrPublic}</small>
            </div>    

        	</div>);

		//neoleadNoteForms.push(retx);
		return retx;
		}, this2); 

		let transactionsInfo="";
		const lead=itemObj.leads; //fullObj.leadsNotes.leadsInfo.leads;
		if(lead!==undefined && lead.transactionsInfo!==undefined){
			transactionsInfo=lead.transactionsInfo;
		}

		//console.log("qwwwweeeeeeeeeeeeeeeeeeeeeeeeeeef");
		//consoleXlog(lead); 
		//console.log("qwwwweeeeeeeeeeeeeeeeeeeeeeeeeeef");
		//LockIcon   LockOpenIcon   


		const leadBlock=(
			<Accordion key={"leaNotesblck"+iobj}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"leadnote-subac2"+iobj+"-content"}
					id={"leadnot-subac2-block"+iobj}>
						<span>Lead #{leadIdTxt}</span>
					</AccordionSummary>
					<AccordionDetails> 
						<Alert severity="info">
							<p><b>{t("soldprods")}:</b></p>
							<div dangerouslySetInnerHTML={{ __html: transactionsInfo }}></div>
						</Alert>
						{/* <Alert severity="info"><span style={{textAlign:'center',fontSize:'1.25rem'}}>Lead2Action</span></Alert> */}
					  {/* <div style={{width:'100%', marginLeft:'10px', paddingLeft:'10px'}}>							 */}
						<div className="container">
							<div style={{width:'100%', marginLeft:'10px', paddingLeft:'10px'}}>	
							{neoleadNoteForms002}</div>
							</div>
					  {/* </div> */}
					</AccordionDetails>
				</Accordion>
		);

		neoleadNoteForms.push(leadBlock);
	            
		return null; 

    }, this); 
   }

    if(countQty2===0){
        const descBody="Pas de Notes trouvées/ No notes found.";
        neoleadNoteForms=(<div className="zendcomment" key={"lncmt00000"}> 
        <div className={"tcomment"+pvcomment}> 
            <div dangerouslySetInnerHTML={{ __html: descBody }}></div>
        </div>
    </div>);
    }
	// else if(countQty2===0 && noteGo===0){
    //     //const descBody="Pas de Notes encore, cliquez sur le bouton pour charger/ No notes";
	// 	const descBody=t("zqwNFnoneonotesyet_x");
    //     neoleadNoteForms=(<div className="zendcomment" key={"lncmt00002"}> 
    //     <div className={"tcomment"+pvcomment}> 
    //         <div dangerouslySetInnerHTML={{ __html: descBody }}></div>
    //     </div>
    // </div>);
    // }



 
	  if(productId==="1"){
		return(	
			<div className="row"  key={"datMToop"+indProd} style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
			<Accordion key={"datCustom"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-subactions"+indProd+"-content"}
					id={"product-subac1-block"+indProd}
					>
						<span>{accordeonClient}</span>
					</AccordionSummary>
					<AccordionDetails>
					{/* <IconButton
						aria-label="Sauvegarde Tout/Save All" 
						aria-haspopup="false"
						onClick={this.handleSaveAll}
						color="inherit"
						className="float-right "
						>
						<SaveOutlinedIcon htmlColor="#0A3CA4" /> {saveAllText}
					</IconButton> */}
					{/* / <a href="#"  onClick={this.handleSaveAll}> 
		<span className="danger neosavehover float-right "><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</span>
		</a>  */}

{/* zqwTDCtxt1_x */}
<button type="button" 
className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 

						<div className="container">{realElementFormsInfo}</div>
					</AccordionDetails>
				</Accordion>	
				{(domainInLaw!==null && domainInLaw!=="" && domainInLaw!=="449" && this.state.productId !== "210" && this.state.productId !== "172")?
				<Accordion key={"datPartieAdverse"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-sub2actions"+indProd+"-content"}
					id={"product-subac2-block"+indProd}
					>
						<span>{accordeonPA}</span>
					</AccordionSummary>
					<AccordionDetails>
					{/* <IconButton
						aria-label="Sauvegarde Tout/Save All" 
						aria-haspopup="false"
						onClick={this.handleSaveAll}
						color="inherit"
						>
						<SaveOutlinedIcon htmlColor="#0A3CA4" /> {saveAllText}
					</IconButton> */}
					<button type="button" 
					className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
					onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 
						<div className="container">{realElementFormsPA}</div>
					</AccordionDetails>
				</Accordion>:""}
				{(domainInLaw!==null && domainInLaw==="446")?
				<Accordion key={"datMiseencause"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"mecadditional-sub2actions"+indProd+"-content"}
					id={"mec-subac2-block"+indProd}
					>
						<span>{accordeonMEC}</span>
					</AccordionSummary>
					<AccordionDetails>
					<button type="button" 
					className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
					onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 
						<div className="container">{realElementFormsMEC}</div>
					</AccordionDetails>
				</Accordion>:""} 
				<Accordion key={"restcont"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-restcactions"+indProd+"-content"}
					id={"product-restc-block"+indProd}
					>
						<span>{accordeonXFields}</span>
					</AccordionSummary>
					<AccordionDetails>
					{/* <IconButton
						aria-label="Sauvegarde Tout/Save All" 
						aria-haspopup="false"
						onClick={this.handleSaveAll}
						color="inherit" 
						>
						<SaveOutlinedIcon htmlColor="#0A3CA4" /> {saveAllText}
					</IconButton> */}
					<button type="button" 
					className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
					onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 
						<div className="container">{realElementForms}</div>
					</AccordionDetails>
				</Accordion> 
				{(domainInLaw!==null && domainInLaw!=="" && domainInLaw==="449")?
				<Accordion key={"restincorpo"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-incorpo"+indProd+"-content"}
					id={"incorpo-restc-block"+indProd}
					>
						<span>{neoDocNoteIncorpo}</span>
					</AccordionSummary>
					<AccordionDetails> 
					{/* <br/>
					<br/><br/> */}
					<button type="button" 
					className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
					onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 					
						<div className="container"><br/>
						{realElementFormsIncBFields}
						<br/>
						</div>
					</AccordionDetails>
				</Accordion>:""} 
				{!this.props.hideNotes && <Accordion key={"leadneoDocNote"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-leadneoDocNote"+indProd+"-content"}
					id={"leadneoDocNote-restc-block"+indProd}>
						<span>{leadNoteXFields}</span>
					</AccordionSummary> 
					<AccordionDetails> 
						<div className="container"><br/>
						<div style={{width:'100%', marginLeft:'10px', paddingLeft:'10px'}}>
						{neoleadNoteForms}
						</div>
						<br/></div>
					</AccordionDetails>
				</Accordion>}
				{!this.props.hideNotes && <Accordion key={"restneoDocNote"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-neoDocNote"+indProd+"-content"}
					id={"neoDocNote-restc-block"+indProd}
					>
						<span>{neoDocNoteXFields}</span>
					</AccordionSummary> 
					<AccordionDetails> 
					<button type="button" className={"btn btn-primary"} 
					onClick={this.handleLoadNeoDocNotes}><ListIcon htmlColor="#FFFFFF" /> {t("zqwNFnoneonotesbtn_x")}</button> 
   					<br/>
					<br/><br/>					
						<div className="container"><br/>{neoDocNoteForms}<br/></div>
					</AccordionDetails>
				</Accordion>}
				</div>
			</div>
		);
	  }
	  
	  return null;
	  
	  if(productId==="1"){
		return(
			<Accordion key={"mkprod"+indProd}>
			<AccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-label="Expand"
			aria-controls={"additional-actions"+indProd+"-content"}
			id={"product-ac1-block"+indProd}
			>
				<span>{productBlockTitle}</span>
			</AccordionSummary>
			<AccordionDetails>
			<div className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
				<Accordion key={"datCustom"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-subactions"+indProd+"-content"}
					id={"product-subac1-block"+indProd}
					>
						<span>{accordeonClient}</span>
					</AccordionSummary>
					<AccordionDetails>
					{/* <IconButton
						aria-label="Sauvegarde Tout/Save All" 
						aria-haspopup="false"
						onClick={this.handleSaveAll}
						color="inherit"
						className="float-right "
						>
						<SaveOutlinedIcon htmlColor="#0A3CA4" /> {saveAllText}
					</IconButton> */}
					{/* / <a href="#"  onClick={this.handleSaveAll}> 
		<span className="danger neosavehover float-right "><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</span>
		</a>  */}

<button type="button" 
className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 

						<div className="container">{realElementFormsInfo}</div>
					</AccordionDetails>
				</Accordion></div>
			</div>
			<div className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
				<Accordion key={"datPartieAdverse"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-sub2actions"+indProd+"-content"}
					id={"product-subac2-block"+indProd}
					>
						<span>{accordeonPA}</span>
					</AccordionSummary>
					<AccordionDetails>
					{/* <IconButton
						aria-label="Sauvegarde Tout/Save All" 
						aria-haspopup="false"
						onClick={this.handleSaveAll}
						color="inherit"
						>
						<SaveOutlinedIcon htmlColor="#0A3CA4" /> {saveAllText}
					</IconButton> */}
					<button type="button" 
					className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
					onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 
						<div className="container">{realElementFormsPA}</div>
					</AccordionDetails>
				</Accordion></div>
			</div>
			{/* <div className="row" style={{display:'block', width:'100%'}}> 	
			<div className="container">{realElementForms}</div></div> */}

			<div className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
				<Accordion key={"restcont"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-restcactions"+indProd+"-content"}
					id={"product-restc-block"+indProd}
					>
						<span>{accordeonXFields}</span>
					</AccordionSummary>
					<AccordionDetails>
					{/* <IconButton
						aria-label="Sauvegarde Tout/Save All" 
						aria-haspopup="false"
						onClick={this.handleSaveAll}
						color="inherit" 
						>
						<SaveOutlinedIcon htmlColor="#0A3CA4" /> {saveAllText}
					</IconButton> */}
					<button type="button" 
					className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
					onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 
						<div className="container">{realElementForms}</div>
					</AccordionDetails>
				</Accordion></div>
			</div>
			</AccordionDetails>
		</Accordion>
		);
	  }
	  
	  

	  return(
		<Accordion key={"mkprod"+indProd}>
		<AccordionSummary
		expandIcon={<ExpandMoreIcon />}
		aria-label="Expand"
		aria-controls={"additional-actions"+indProd+"-content"}
		id={"product-ac1-block"+indProd}
		>
			<span>{productBlockTitle}</span>
		</AccordionSummary>
		<AccordionDetails> 
		<div className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
		<div className="containerx"> 
			<Accordion key={"restcont"+indProd}>
				<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-label="Expand"
				aria-controls={"additional-restcactions"+indProd+"-content"}
				id={"product-restc-block"+indProd}
				>
					<span>{accordeonXFields}</span>
				</AccordionSummary>
				<AccordionDetails>
					{/* <IconButton
						aria-label="Sauvegarde Tout/Save All" 
						aria-haspopup="false"
						onClick={this.handleSaveAll}
						color="inherit"
						>
						<SaveOutlinedIcon htmlColor="#0A3CA4" />
					</IconButton> */}
					<button type="button" 
					className={changeCounter>0?"btn btn-primary float-right":"btn btn-secondary float-right"} 
					onClick={this.handleSaveAll}><SaveOutlinedIcon htmlColor="#000000" /> {saveAllText}</button> 
					<div className="container">{realElementForms}</div>
				</AccordionDetails>
			</Accordion></div>
		</div>
		</AccordionDetails>
	</Accordion>
	);

		/*return(
			<Accordion key={"mkprod"+indProd}>
			<AccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-label="Expand"
			aria-controls={"additional-actions"+indProd+"-content"}
			id={"product-ac1-block"+indProd}
			>
				<span>{productBlockTitle}</span>
			</AccordionSummary>
			<AccordionDetails>
			<div className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
				<Accordion key={"datCustom"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-subactions"+indProd+"-content"}
					id={"product-subac1-block"+indProd}
					>
						<span>{accordeonClient}</span>
					</AccordionSummary>
					<AccordionDetails>
						<div className="container">{realElementFormsInfo}</div>
					</AccordionDetails>
				</Accordion></div>
			</div>
			<div className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
				<Accordion key={"datPartieAdverse"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-sub2actions"+indProd+"-content"}
					id={"product-subac2-block"+indProd}
					>
						<span>{accordeonPA}</span>
					</AccordionSummary>
					<AccordionDetails>
						<div className="container">{realElementFormsPA}</div>
					</AccordionDetails>
				</Accordion></div>
			</div> 

			<div className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
				<Accordion key={"restcont"+indProd}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"additional-restcactions"+indProd+"-content"}
					id={"product-restc-block"+indProd}
					>
						<span>{accordeonXFields}</span>
					</AccordionSummary>
					<AccordionDetails>
						<div className="container">{realElementForms}</div>
					</AccordionDetails>
				</Accordion></div>
			</div>
			</AccordionDetails>
		</Accordion>
		);*/

		return ( null );
	}, this);  


	const realElementFormsDocQQQ = products!==undefined && products.length > 0
	&& products.map((itemProd, indProd) => { 
	   const productId= itemProd.name;
	   const productTypeId= itemProd.product_type_id;
	   const prodBlock=JSON.parse(itemProd.product_block);
	   const productBlockTitle=productId==="1"? prodTitle : prodBlock.titre;

	   if(domainInLaw===null || domainInLaw===""){ return (null); }
  
//realElementFormsDoc =null;
const realElementFormsDoc000 = productFields!==undefined && productFields.length > 0
	&& productFields.map((item, i) => { 
	
	  if(productTypeId!==item.product_type_id){ return (null); }
	  //if(item.field_id >29 && item.field_id <44){  return (null); }	
	  if(listOfFieldInfoCliPa.includes(parseInt(item.field_id))){  return (null); } 
	  if(!listOfNatDoInUsed.includes(parseInt(item.field_id))){  return (null); }			  	
	let fieldBlock=this0.getFieldRecord(fields, item.field_id);  
	if(fieldBlock.field_type!=="file"){  return (null); }	
	let x = this0.getFieldTypeId(fieldBlock.field_type);
	ticketDataLeftRec=this0.getTicketDataRecord(ticketDataLeft, item.id);
	let FieldData=null;
	let product=this0.getProductRecord(products, item.product_type_id);  
	const prodId=product.name;
	let ticketProd=this0.getTicketProductRecord(ticketProds, prodId); 
	let isticketProdNull=false; 
	if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
	const case_id=ticketProd.id;
	//consoleXlog("QQQQQQQQQQQ1111", item.field_id);
	if(typeListx.includes(fieldBlock.field_type)){
		let fieldOptionsx=null;
		//consoleXlog("QQQQQQQQQQQ222", item.field_id);
		if(fieldsIdsopt.includes(item.field_id)){
			//consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
			fieldOptionsx=this0.getFieldOptionsRecords(fieldOptions, item.field_id);
		}
		 
		FieldData={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
			case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
	}else{
		FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
			case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
	}

	const moodx=this0.state.mood;
	let blockElem =null;
	const dynamicForm=this0.state.dynamicForm[i];
	const dynamicFormDatax=dynamicFormData[i];
	consoleXlog("###THATS A DOC ", item.field_id, fieldBlock.id, fieldBlock.name);
	//consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
	//this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
	if(isticketProdNull){ x=-9; } 
	return this0.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
  
}, this0);

//return(<div className="container">{realElementFormsInfo}</div>);
return realElementFormsDoc000;   

  return ( null );
}, this);

	  const FileDeletePopup=this.FileDeletePopup;     
	  const FileLabelUpdatePopup=this.FileLabelUpdatePopup;
	  const client_type=this.state.client_type;
	  //consoleXlog("CLIENT TYPE ", client_type);
	  const TabPanel = this.TabPanel;
	  const value=this.state.valueTab;
	  const handleOFP=this.handleOpenFolderProduct;
	  const NdiNeoDocSendFileOptionsPopup=this.NdiNeoDocSendFileOptionsPopup;

	  const listOfTicketsInfo=this.state.listOfTicketsPayments;
	  let listOfTickets=[]; //this.state.listOfTicketsPayments;
	  let listOfTicketsHist=[]; 
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.payments!==undefined){
			listOfTickets=listOfTicketsInfo.payments;
			// if(domainInLaw===null || domainInLaw===""){   }else{
			// 	listOfTickets=listOfTicketsInfo.payments;
			// }
	  }
	  let extraInfo=[]; //let extraInfo="";
	  let listOfTransactions=[];  let listOfTransactionsHist=[]; 
		//   if(listOfTicketsInfo!==null && listOfTicketsInfo.transactions!==undefined){
		// 	listOfTransactions=listOfTicketsInfo.transactions; 
		//   }
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.pendtransactions!==undefined){
		listOfTransactions=listOfTicketsInfo.pendtransactions; 
	  }

	  if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.transactions!==undefined){
		listOfTransactionsHist=listOfTicketsInfo.gohistory.transactions; 
	  }
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.payments!==undefined){
		listOfTicketsHist=listOfTicketsInfo.gohistory.payments; 
	  }

	  
      /*if(b2b.raw_data!==undefined && b2b.raw_data!==null && b2b.raw_data!=="" && b2b.raw_data!=="manually" && b2b.raw_data!=="mannually"
      && b2b.raw_data!=="{\"source\":\"manual\"}"){
        //extraInfo=b2b.raw_data;
        //consoleXlog("ERROR SOURCE ", b2b.raw_data);
        const extraInfoEntry=JSON.parse(b2b.raw_data);
        //const dfeKey= Date.now();
        for(let okey in extraInfoEntry){
          //extraInfo +=okey+":"+extraInfoEntry[okey]+"<br/>\n";
          //const dfeKey= Date.now();
          const rndKey=Math.floor(Math.random()*100); 
          //extraInfo.push(<><span className="lstp" key={"px"+rndKey}>{okey}: {extraInfoEntry[okey]}</span><br/></>);
          //extraInfo.push(<span className="lstp" key={"px"+rndKey}>{okey}: {extraInfoEntry[okey]}<br/></span>);
          extraInfo.push(<p className="lstp" key={"px"+rndKey}>{okey}: {extraInfoEntry[okey]}</p>);
        }

	  }*/

	  //1:stripe 2:paypal 3:Interac  4:ChargeDesk
	  //const pmethodList=["", "stripe", "paypal", "Interac", "ChargeDesk", "B2B", "Flexiti"];
	  const agents=listOfRefundAgent;
	  let allowRefundBtn=false;  let userId=0;
	  const profile = decryptText(localStorage.profile);
	  //consoleXlog("###*******CHECK PROFILE DEC  "+profile);
	  if(profile!==undefined && profile!==null && profile!==""){  
		const profileObj=JSON.parse(profile); 
		if(profileObj!==undefined && profileObj!==null && profileObj.id_users!==null){
			userId=profileObj.id_users; 
		} 
	  } 
	  //consoleXlog("###*******CHECK PROFILE ALLOWED  "+userId);
	  if(agents.includes(parseInt(userId))){
		allowRefundBtn=true; 
	  } 
	  //consoleXlog("###*******CHECK PROFILE ALLOWED  "+userId, "allow", allowRefundBtn, agents);
	  
	  /*const agents=listOfRefundAgent;
	  let allowRefundBtn=false;  let userId=0;
	  const profile = decryptText(localStorage.profile);
	  consoleXlog("###*******CHECK PROFILE DEC  "+profile);
	  if(profile!==undefined && profile!==null && profile!==""){ 
		// return dispatch(loginUser(null)); 
		const profileObj=JSON.parse(profile);
		//userId=profile.id_users; 
		if(profileObj!==undefined && profileObj!==null && profileObj.id_users!==null){
			userId=profileObj.id_users; 
		}
		//return null; 
	  } 
	  consoleXlog("###*******CHECK PROFILE ALLOWED  "+userId);
	  //if(agents.includes(userId)){} */ 	
	  
	  /*const paymentList = listOfTickets.map((payment, i) => { 
		const rndKey=Math.floor(Math.random()*100);
		const pmethodx=payment.pmethod!==null?payment.pmethod:0; 
		const pmethod=parseInt(pmethodx);
		//return ( <option key={item} value={item}>{item}</option>);
		// return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		// {" code "+payment.code} {" ("+pmethodList[pmethod]+")"}</p>);

		return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		 {" ("+pmethodList[pmethod]+")"}
		 <IconButton edge="end" aria-label="download Facture/Invoice"  data-fileaddress={payment.id_invoices} 
					data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} 
					onClick={this.handleDownloadFileInvoice}>
						<DownloadIcon />
						<CheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
					</IconButton>
		{(allowRefundBtn && pmethodList[pmethod]==='Interac')?
		  <IconButton edge="end" aria-label="Remborsement/Refund"  
		  data-fieldname={payment.id_invoices} data-id={payment.id_invoices} 
		   data-paymentid={payment.id} onClick={this.showRefundProcess}>
			  <AttachMoneyIcon /> Refund
		  </IconButton>:''} 					
		 </p>);

		return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		 {" ("+pmethodList[pmethod]+")"}</p>);
	  }, this);*/

	  const refunds=this.state.listOfTicketsPayments.refunds;
	  const paymentList = listOfTickets.map((payment, i) => { 
		const rndKey=Math.floor(Math.random()*100);
		const pmethodx=payment.pmethod!==null?payment.pmethod:0; 
		const pmethod=parseInt(pmethodx);
		//return ( <option key={item} value={item}>{item}</option>);
		// return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		// {" code "+payment.code} {" ("+pmethodList[pmethod]+")"}</p>);
		let refundedAmount=0;
          if(refunds!==undefined && refunds.length > 0){
            for(const refund of refunds){    
              if(refund.id_payments===payment.id && refund.status==="3"){
                refundedAmount +=parseFloat(refund.amount);
              } 
            }
          }

		return (<p className="lstp" key={"00px"+rndKey+payment.id}>${payment.amount} {"#Fac "+payment.id_invoices} 
		 {" ("+pmethodList[pmethod]+")"}
		 <IconButton edge="end" aria-label="download Facture/Invoice"  data-fileaddress={payment.id_invoices} 
					data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} 
					onClick={this.handleDownloadFileInvoice}>
						<DownloadIcon />
						<CheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
					</IconButton>
		<IconButton edge="end" aria-label="download customized Facture/Invoice"  data-fileaddress={payment.id_invoices} 
				data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
				data-productfieldid={0} data-fieldid={0} data-required={false} 
				data-filesheredown={1} data-labelnodel={0} 
				onClick={this.handleDownloadFileInvoiceCus}>
					<DownloadIcon />
					<SpellcheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
			</IconButton>		
		  {(allowRefundBtn && pmethodList[pmethod]==='Interac' || pmethodList[pmethod]==='stripe' || pmethodList[pmethod]==='ChargeDesk' || pmethodList[pmethod]==='paypal')?
		  <IconButton edge="end" aria-label="Remborsement/Refund"  
		  data-fieldname={payment.id_invoices} data-id={payment.id_invoices} 
		   data-paymentid={payment.id} onClick={this.showRefundProcess}>
			  <AttachMoneyIcon /> Refund
		  </IconButton>:''} 
		  {refundedAmount > 0? <span className='red'> (
			{/* déjà remboursé  */}
		  {refundedAmount.toFixed(2)}$)</span> : ""}			
		 </p>);

		return (<p className="lstp" key={"tb3px"+rndKey+i}>${payment.amount} {"#Fac "+payment.id_invoices} 
		{" ("+pmethodList[pmethod]+")"}</p>);
	  }, this);

	  /*<IconButton edge="end" aria-label="download"  data-fileaddress={option.value} 
					data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
					data-filesheredown={1} data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} 
					onClick={this.handleDownloadFile}>
						<DownloadIcon />
					</IconButton>*/

		const indProd=70;

	  const rdKy=Math.floor(Math.random()*100);				
	  /*const transactionList = listOfTransactions.map((transaction, i) => { 
		const rndKey=Math.floor(Math.random()*100);		 
		//return ( <option key={item} value={item}>{item}</option>);
		// return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		// {" code "+payment.code} {" ("+pmethodList[pmethod]+")"}</p>);

		return (<p className="lstp" key={"p2x"+rndKey+rdKy}>{transaction.description} </p>);
	  }, this);*/





	  const transactionList = listOfTransactions.map((transaction, i) => { 
		const rndKey=Math.floor(Math.random()*100);	
		const status=transaction.status;	 
		//return ( <option key={item} value={item}>{item}</option>);
		// return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		// {" code "+payment.code} {" ("+pmethodList[pmethod]+")"}</p>);
		//'payed', 'sent', 'overdue', 'view', 'declined', 'refund', 'recurrent', 'partial', 'cancelled', 'paid-b2b', 'partial-b2b', 'cancelled-b2b')
		if(!this.isElawyer && status!==undefined && (status!=="payed" && status!=="paid-b2b")){
			return (<p className="lstp red" key={"p2x"+rndKey+i+rdKy}>{transaction.description} &nbsp; &nbsp; ${transaction.amount} <b>({transaction.status})</b>
			  &nbsp; &nbsp;  
			  {(status==="sent" || status==="partial")?<button type="button" className="btn btn-primary btn-sm xbtnsm"  data-closeid={3} data-closeinvoiceid={transaction.id_invoices} onClick={handleOFP}>{t("zqwNFFrmZn021_x")}</button>:""} 
			</p>);
		}

		return (<p className="lstp" key={"p2x"+rndKey+i+rdKy}>{transaction.description}  &nbsp; &nbsp; ${transaction.amount} </p>);
	  }, this);





	  const transactionListHist = listOfTransactionsHist.map((transaction, i) => { 
		const rndKey=Math.floor(Math.random()*100);	
		const status=transaction.status;	  
		//'payed', 'sent', 'overdue', 'view', 'declined', 'refund', 'recurrent', 'partial', 'cancelled', 'paid-b2b', 'partial-b2b', 'cancelled-b2b')
		if(status!==undefined && (status!=="payed" && status!=="paid-b2b")){
			return (<p className="lstp red" key={"zxtb3p2x"+rndKey+i}>{transaction.description} <b>({transaction.status})</b></p>);
		}

		return (<p className="lstp" key={"zxtb3p2x"+rndKey+i}>{transaction.description} </p>);
	  }, this);

	  const paymentListHist = listOfTicketsHist.map((payment, i) => { 
		const rndKey=Math.floor(Math.random()*100);
		const pmethodx=payment.pmethod!==null?payment.pmethod:0; 
		const pmethod=parseInt(pmethodx); 
		let refundedAmount=0;
          if(refunds!==undefined && refunds.length > 0){
            for(const refund of refunds){    
              if(refund.id_payments===payment.id && refund.status==="3"){
                refundedAmount +=parseFloat(refund.amount);
              } 
            }
          }

		return (<p className="lstp" key={"h00px"+rndKey+payment.id}>${payment.amount} {"#Fac "+payment.id_invoices} 
		 {" ("+pmethodList[pmethod]+")"}
		 <IconButton edge="end" aria-label="download Facture/Invoice"  data-fileaddress={payment.id_invoices} 
					data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
					data-productfieldid={0} data-fieldid={0} data-required={false} 
					data-filesheredown={1} data-labelnodel={0} 
					onClick={this.handleDownloadFileInvoice}>
						<DownloadIcon />
						<CheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
					</IconButton>
		   <IconButton edge="end" aria-label="download customized Facture/Invoice"  data-fileaddress={payment.id_invoices} 
				data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-caseid={0} data-index={0} 
				data-productfieldid={0} data-fieldid={0} data-required={false} 
				data-filesheredown={1} data-labelnodel={0} 
				onClick={this.handleDownloadFileInvoiceCus}>
					<DownloadIcon />
					<SpellcheckIcon style={{ color: "blue", fontWeight:"bolder" }} />	
			</IconButton>			
					{/* (allowRefundBtn && pmethodList[pmethod]==='Interac')?			 */}
		  {/* {(allowRefundBtn && (pmethodList[pmethod]==='Interac' || pmethodList[pmethod]==='stripe'))?
		  <IconButton edge="end" aria-label="Remborsement/Refund"  
		  data-fieldname={payment.id_invoices} data-id={payment.id_invoices} data-history={3} 
		   data-paymentid={payment.id} onClick={this.showRefundProcess}>
			  <AttachMoneyIcon /> Refund
		  </IconButton>:''}  */}
		  {refundedAmount > 0? <span className='red'> (
			{/* déjà remboursé  */}
		  {refundedAmount.toFixed(2)}$)</span> : ""}						
		 </p>);

		return (<p className="lstp" key={"htb3px"+rndKey+i}>${payment.amount} {"#Fac "+payment.id_invoices} 
		{" ("+pmethodList[pmethod]+")"}</p>);
	  }, this);


	  const PublicLinkFormPopup=this.PublicLinkFormPopup;
  
	  
		//this.clientOnZendeskSCR >0
		if(this.clientOnZendeskSCR >0){
	  return (
		<div className="iamneombzin"> 		
			  <AppBar position="static" color="default">
				<Tabs
				  value={value}
				  onChange={this.handleTabChange}
				  indicatorColor="primary"
				  textColor="primary"
				  variant="scrollable"
				  scrollButtons="auto"
				  aria-label="scrollable auto tabs example"
				>
				  <Tab label={t("zqwTabhome_x")} {...this.a11yProps(0)} /> 
				  <Tab label={t("zqwTabAjoutProd_x")}  {...this.a11yProps(1)} /> 
				  <Tab label={t("zqwTabDocument_x")}  {...this.a11yProps(2)} /> 
				   
				</Tabs>
			  </AppBar>
			  <TabPanel value={value} index={0}  className="iamneombz2">
				{/* <p className = "font-16"><strong>OTHER TAB #1 HOME: </strong> </p>
				<ul className="list text-black theme-colored angle-right  ">
				  <li  key={1} className="pl-20  font-18">  descr.value  </li>
					  <li  key={2} className="pl-20  font-18">  descr.value2  </li>
				</ul>   */}
		
					{/* <NdiFieldsFormzendesk  /> */}  
		
				{/* <NeoVentesFormB2b  />  */}

				<div className="iamneozendin">
					{/* <h3>{this.actionMode==='new'? t('zqwacAddNdiFields_x'): t('zqwacEditNdiFields_x')}</h3> */}
					<form onSubmit={this.handleSubmit}  noValidate autoComplete="off">
					{/*<form onSubmit={this.handleSubmit}>*/}
					
					<div className="form-group"  >
						{client_type==="2"?
						<><span style={{display:'inline'}}><b>{t("zqwNFFtxt19_x")}</b></span>   </>:
						<>
						{/* {txtaddprodpo} 						 */}
						<span style={{display:'inline'}}>
							{/* <a href="#" data-id={0} onClick={this.handleOpenFolderProduct}><PostAddIcon /></a> */}
						</span>  </>}
						 
						{/* {txtaddprodpoto} #{realticket_id} &nbsp; &nbsp; &nbsp; &nbsp;  */}
						{realElementFormsNatureDo}  
			{/* 			
						<a href="#" data-id={0} onClick={this.handleOpenProductXLang} title="changer la langue/ switch the language">
							<span className="fas fa-language"></span></a>   

							&nbsp; &nbsp; &nbsp; &nbsp; 
						<a href="#" data-id={0} onClick={this.handleOpenProductXLoad} title="recharger les données / reload data">
							<span className="fas fa-refresh"></span><SyncIcon /></a>   	 */}
							
						<br />   
						{/* +++++++++++++++++++++++++++++++++++++++++++++ */}
					</div>
			

						


					{/* {realElementForms} */}
					<div className="neoxacord"> 
					{/* {realElementFormsNatureDo} */}
					{realElementForms00}
					</div>
					{/* {realElementForms} */}
							
					<div className="form-group"  >
						{/* +++++++++++++++++++++++++++++++++++++++++++++ */}
						

					</div>			
					
					<div className="form-group" style={{display:'none'}} >
						<label htmlFor="id">{t('zqwid_x')}</label>
						<input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
						className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
						{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
					</div>
 					
					</form>
					<FolderProductPopup />
					<JudiciaryInvoicePopup />  <CustomInvoicePopup /> 
					<RefundProcessPopup /> 
					<Toastr messagex={myToastMessage} open={true} toastType={toastrType} anchorOrigin={1} />
					<FileDeletePopup /> <FileLabelUpdatePopup /> <NdiNeoDocSendFileOptionsPopup />
					<Loader  openx={this.state.isLoaderOpen}  />
					{/* <NeoSearchActions />       	 */}
				</div>
		 		
				 
			  </TabPanel>			   
			  <TabPanel value={value} index={1}>
				  
				 {/* <p className = "font-16"><strong>Poduits: </strong> </p> */}
				 {/* Ajouter produits  au #232358<br/>     
				 Onglet pour ajouter les produits et historique des produits achetés 
				 pour ce dossier, en incluant les virements interac<br/>  */}
				 <div className="form-group">
						{client_type==="2"?
						<><span style={{display:'inline'}}><b>{t("zqwNFFtxt19_x")}</b></span>   </>:
						<div style={{display: "flex", gap: "1rem", marginTop: "1rem"}}>
						<Button size='sm' onClick={handleOFP} color='primary'>
							<PostAddIcon />
							{`${txtaddprodpo} ${txtaddprodpoto} #${realticket_id ?? ""}`} 
						</Button>
							<ChangeFolderProduct ticketId={realticket_id} toast={this.showToast} fetchData={this.getClientGoTransactionList} />
							</div>
						}						
				 </div>
				 <p className = "font-16"> &nbsp; </p>
				 <p className = "font-16 neospxtrans"><strong>{t("zqwNFFtxt6_x")}</strong> </p>
				 <div className="form-group  neospxtrans">
					  {transactionList}
				 </div>
				 <p className = "font-16"> &nbsp; </p> 
				 <p className = "font-16 neospxpay"><strong>{t("zqwNFFtxt7_x")}</strong> </p>
				 <div className="form-group neospxpay">
					 {paymentList}
				 </div> 

				 <p className = "font-16"> &nbsp; </p> 
				<p className = "font-16 neospxpay"><strong>Les Historiques  du Client (Produits et  Paiements)</strong> </p>
				<div className="container neoHistLPT">
					<div className="containerx">				
						<Accordion key={"datHistLPT"+indProd}>
							<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-label="Expand"
							aria-controls={"HistLPT-sub2actions"+indProd+"-content"}
							id={"HistLPT-subac2-block"+indProd}
							>
								<span><b>Historique de Produits achetés</b></span>
							</AccordionSummary>
							<AccordionDetails> 
								{/* aaaaaaaaaa aaaaaaaaaaa aaaaaaa */}
								<div className="container">{transactionListHist}</div>
							</AccordionDetails>
						</Accordion>  
						<Accordion key={"restHistLPT"+indProd}>
							<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-label="Expand"
							aria-controls={"HistLPT-restcactions"+indProd+"-content"}
							id={"pHistLPT-restc-block"+indProd}
							>
								<span><b>Historique de Paiements Éffectués</b></span>
							</AccordionSummary>
							<AccordionDetails> 
								{/* bbbbbbbbbb bbbbbb bbbbbb bbbbbbbb bbbbbbbbb */}
								<div className="container">{paymentListHist}</div>
							</AccordionDetails>
						</Accordion> 
					</div>  
				 </div>  
				 <p className = "font-16"> &nbsp; </p> 
				 <p className = "font-16"> &nbsp; </p>     
				 
				      
				 
			  </TabPanel> 
			  <TabPanel value={value} index={2}>
				  
				 {/* <p className = "font-16"><strong>OTHER TAB #5 DOCUMENTS: </strong> </p> */}
				 <div className="form-group"  > 
					 <button type="button" className="btn btn-primary" onClick={this.gotoSendPublicForm}>{t('zqwNFFrmZn09_x')}</button> 
				 </div>
				 <div className="form-group neospxdoc"  >
					{realElementFormsDoc}
					{/* <p className = "font-16"><strong>Liste de Documents: </strong> </p> */}
					{realElementFormsDocQQQ}	 
				 </div>
			  </TabPanel> 

			  <FolderProductPopup />
			  <JudiciaryInvoicePopup />  <CustomInvoicePopup /> 
			  <RefundProcessPopup />
			  <PublicLinkFormPopup /> 
				<Toastr messagex={myToastMessage} open={true} toastType={toastrType} anchorOrigin={1} />
				<FileDeletePopup /> <FileLabelUpdatePopup /> <NdiNeoDocSendFileOptionsPopup />
				<Loader  openx={this.state.isLoaderOpen}  /> 
				<NeoSearchActions /> 
				<NeoFilePreviewPDFPopup />   
			</div>
	  );
	   }

	return (
      <div className="iamneozend">
        {/* <h3>{this.actionMode==='new'? t('zqwacAddNdiFields_x'): t('zqwacEditNdiFields_x')}</h3> */}
        <form onSubmit={this.handleSubmit}  noValidate autoComplete="off">
		{/*<form onSubmit={this.handleSubmit}>*/}
		
		{/* <div className="form-group"  >
			{client_type==="2"?
			<><span style={{display:'inline'}}><b>Client B2B</b></span>   </>:
			<>{txtaddprodpo} <span style={{display:'inline'}}>
				<a href="#" data-id={0} onClick={this.handleOpenFolderProduct}><PostAddIcon /></a>
			</span>  </>}
			{txtaddprodpoto} #{realticket_id} &nbsp; &nbsp; &nbsp; &nbsp; 
/* 			
			<a href="#" data-id={0} onClick={this.handleOpenProductXLang} title="changer la langue/ switch the language">
				<span className="fas fa-language"></span></a>   

				&nbsp; &nbsp; &nbsp; &nbsp; 
			<a href="#" data-id={0} onClick={this.handleOpenProductXLoad} title="recharger les données / reload data">
				<span className="fas fa-refresh"></span><SyncIcon /></a>   	 /
			     
			<br />    
          </div> */}
 

		    


		{realElementForms}
		<div className="neoxacord"> 
			{realElementFormsNatureDo}
			{realElementForms00}
		</div>
		{/* {realElementForms} */}
          		  
		<div className="form-group"  >
			 {/* +++++++++++++++++++++++++++++++++++++++++++++ */}
			 

          </div>			
		  
		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

          {/* <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
			<button type="button" onClick={this.handleCanceltest} className="btn btn-warning">teeeeeeeest</button>
          </div> */}

		 
        </form>
		<FolderProductPopup />
		<JudiciaryInvoicePopup />  <CustomInvoicePopup /> 
		<RefundProcessPopup /> 
		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} anchorOrigin={1} />
		<FileDeletePopup /> <FileLabelUpdatePopup />
		<Loader  openx={this.state.isLoaderOpen}  /> 
		<NeoSearchActions />
		<NeoFilePreviewPDFPopup />      	
      </div>
    );
  }


  TabPanel = (props) => {  
    //return (<div className="text-center mt-20 mt-sm-20">Bonjour</div>);
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}> 
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
 

  a11yProps= (index) => {  
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
    

  handleTabChange = (event, newValue) => {
     consoleXlog("WOOOOOOOOOOOOOOOOOOOOY TAB CHANGED ", newValue);
    // setValue(newValue);
    // if(newValue===0){
    //   this.componentReloadData(); 
	// }
	this.setState({ ["valueTab"]: newValue});
	if(newValue===1){
		const theticketId=this.state.realticket_id;
      this.getClientGoTransactionList (theticketId); 
	}
	 
  };

  getFieldContentBlock = (x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax) => {  
	const NeogenTextField=this.NeogenTextField;
	const NeogenNumberField=this.NeogenNumberField;
	const NeogenRadioBox=this.NeogenRadioBox;
	const NeogenCheckBox=this.NeogenCheckBox;
	const NeogenSelectBox=this.NeogenSelectBox;
	const NeogenTextarea=this.NeogenTextarea;
	const NeogenDateTimeField=this.NeogenDateTimeField;  
	const NeogenSelectReactField=this.NeogenSelectReactField;
	const NeogenInputMaskField=this.NeogenInputMaskField; 
	const NeogenFileField=this.NeogenFileField;
	const NeogenAnyGenericTextField=this.NeogenAnyGenericTextField; 
	const NeogenBlockField=this.NeogenBlockField;
	const NeogenBlockFieldCorpo=this.NeogenBlockFieldCorpo;
	let text="";

	switch (x) {				   					  	   
		case 0:
			return (<NeogenTextField key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm}  dataRecordNeo={dynamicFormDatax}/>); 
			break;
		case 1:
			return (<NeogenNumberField key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm}  dataRecordNeo={dynamicFormDatax}/>); 
			break;
		case 2:
			return (<NeogenRadioBox key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;
		case 3:
			return (<NeogenCheckBox key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;	
		case 4:
			fieldBlock["isMultiple"]=false; 
			return (<NeogenSelectBox key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;
		case 5:
			return (<NeogenTextarea key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;
		case 6:
			return (<NeogenDateTimeField key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;	
		case 7:
			return (<NeogenDateTimeField key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;
		case 8:
			return (<NeogenDateTimeField key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;
		case 9:
			fieldBlock["selectType"]=1; 
			//const moodx=this.state.mood;
			return (<NeogenSelectReactField key={i+"topc"} fieldBlock={fieldBlock} productField={item} 
			FieldData={FieldData} valueNextStepgost={""} mood={moodx} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;	
		case 10:
			return (<NeogenInputMaskField key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
			break;
		case 11:
				fieldBlock["isMultiple"]=true; 
				return (<NeogenSelectBox key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
				break;							
		case 12:  
				return (<NeogenFileField key={i+"topc"} fieldBlock={fieldBlock} productField={item} 
				FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
				break;
		case 13:  
				fieldBlock["inputType"]="email"; 
				return (<NeogenAnyGenericTextField key={i+"topc"} fieldBlock={fieldBlock} productField={item} FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
				break;
		case 14:
				fieldBlock["selectType"]=2; 
				//const moodx=this.state.mood;
				return (<NeogenSelectReactField key={i+"topc"} fieldBlock={fieldBlock} 
				productField={item} FieldData={FieldData} valueNextStepgost={""} mood={moodx} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
				break;
		case 15:
				//const valueNextStepgost=this.state.valueNextStepgost; 
				const valueNextStepgost=this.state.valueNextStep[i];
				//const moodx=this.state.mood;
				fieldBlock["selectType"]=3; 
				return (<NeogenSelectReactField key={i+"topc"+moodx} fieldBlock={fieldBlock} 
				productField={item} FieldData={FieldData} valueNextStepgost={valueNextStepgost} 
				mood={moodx} dynamicForm={dynamicForm}  dataRecordNeo={dynamicFormDatax} />); 
				break;	
		case 16:  
				return (<NeogenBlockField key={i+"topc"} fieldBlock={fieldBlock} productField={item} 
				FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
				break;						
		case 17:  
				return (<NeogenBlockFieldCorpo key={i+"topc"} fieldBlock={fieldBlock} productField={item} 
				FieldData={FieldData} dynamicForm={dynamicForm} dataRecordNeo={dynamicFormDatax} />); 
				break;	 
		default:
			  text = "No valid field found";  
		  }
  
		return (
		  null
		);
  }

  
  setMultiactions = (eventargs) => {
	const closeme=eventargs.closeme;
	const refresh=eventargs.refreshafterPayment;
	// let isChecked=false; 
	if(closeme!==undefined && closeme!==null && closeme==="close"){
		this.setState({ ["openProductFolder"]: false, ["refund_open"]: false, });	
	} 

	//if(eventargs.refreshafterPayment)
	if(refresh!==undefined && refresh!==null && refresh===1){
		const objToSend={id:0, ticketId:this.state.realticket_id};
		//this.loadProductsFields(objToSend);
		const theticketId=this.state.realticket_id;
		//objToSend.ticketId; //this.state.realticket_id;
		consoleXlog("REFRESHHHHHHHHHHING"); 
		this.getClientGoTransactionList (theticketId);   
	} 

  };

  FolderProductPopup = (props) => {
	const open = this.state.openProductFolder; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	const salesCloseId=this.state.salesCloseId;
	const salesCloseInvId=this.state.salesCloseInvId; 
	let productOptions = [];

    let productList = this.state.productsList.products.length > 0
    	&& this.state.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
        }
      return (
        null
      );
	}, this);
	
	//const AutocompleteClient=this.AutocompleteClient;

	 
	let transactionOptions = [];
 
	let id_products=""; let transactions_id=""; let transactionOption = {}; let productOption={};
	const clientProdTransInfo=this.state.clientProdTransInfo; 
	
	let transList = this.state.listOfTransactions.length > 0
    	&& this.state.listOfTransactions.map((item, i) => {
		//productOptions.push({ value: item.id, label: item.titre }); 
		transactionOptions.push({ value: item.id, label: item.description+ ' (prod'+item.id_products+') #inv'+item.id_invoices });
        //transactionOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
      return (
        null
      );
	}, this);

	let currentTicket="";
	if(this.state.realticket_id!==""){
		currentTicket=""+this.state.realticket_id;
	}else if(this.backTicketId!==0 || this.backTicketId!==""){
		currentTicket=""+this.backTicketId;
	}

	const realticket_id=this.state.realticket_id;

	const clientaddress=this.state.clientAddress;

	//consoleXlog("PROVINCE CLIENT ", clientProdTransInfo, clientaddress);
	consoleXlog("currentTicket--BUUUUG currentTicket ", currentTicket, this.backTicketId, this.state.realticket_id);


	return(<NeoMiniPayment openx={open} stripestatus={this.getStripStatus} 
		transactions={transactionOptions} transactionOption={transactionOption} 
		productsList={this.state.productsList} clientProdTransInfo={clientProdTransInfo} 
		multiactions={this.setMultiactions} currentTicket={currentTicket} 
		curTicket2={this.backTicketId} curTicket3={realticket_id} provinceInfotax={this.state.provinceInfotax}
		clientAddress={clientaddress}  salesCloseId={salesCloseId} 
		salesCloseInvId={salesCloseInvId} />  );

  }


  /////////////////////////////////////////////////////////////////////////////////

  JudiciaryInvoicePopup = (props) => {
	const open = this.state.openJudiciaryInvoice; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	let productOptions = [];

	const listOfTicketsInfo=this.state.listOfTicketsPayments;
	  let listOfTickets=[]; //this.state.listOfTicketsPayments;
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.payments!==undefined){
			listOfTickets=listOfTicketsInfo.payments;
			// if(domainInLaw===null || domainInLaw===""){   }else{
			// 	listOfTickets=listOfTicketsInfo.payments;
			// }
	  }
	  let extraInfo=[]; //let extraInfo="";
	  let listOfTransactions=[]; 
	  if(listOfTicketsInfo!==null && listOfTicketsInfo.transactions!==undefined){
		listOfTransactions=listOfTicketsInfo.transactions;
		// if(domainInLaw===null || domainInLaw===""){  }else{
		// 	listOfTransactions=listOfTicketsInfo.transactions;
		// }
	  }
	  
	  

	const transactionList = listOfTransactions.map((transaction, i) => { 
		const rndKey=Math.floor(Math.random()*100);		 
		//return ( <option key={item} value={item}>{item}</option>);
		// return (<p className="lstp" key={"px"+rndKey}>${payment.amount} {"#Fac "+payment.id_invoices} 
		// {" code "+payment.code} {" ("+pmethodList[pmethod]+")"}</p>);
		const isChecked=transaction.isChecked===undefined?false:transaction.isChecked;
		//const rndTrack= 

		return (<p className="lstp" key={"tb3p2x"+rndKey+i+isChecked}>
			<input type="checkbox" value={transaction.description} data-id={transaction.id} 
			data-index={i} data-price={transaction.amount} data-invoice={transaction.id_invoices} 
			checked={isChecked} onChange={this.handleCheckboxInvoiceItem}  />
			{transaction.description} </p>);
	  }, this);

	  //<input   type="checkbox" value={amount}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> 

    
	
	//const AutocompleteClient=this.AutocompleteClient;

	 
	let transactionOptions = [];
 
	let id_products=""; let transactions_id=""; let transactionOption = {}; let productOption={};
	const clientProdTransInfo=this.state.clientProdTransInfo; 
	
	//let transList = this.state.listOfTransactions.length > 0
     

	//const clientaddress=this.state.clientAddress;

	//consoleXlog("PROVINCE CLIENT ", clientProdTransInfo, clientaddress);

	return (
		<div>
		  <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseJudiciaryInvoicePopup} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-productFolder">
			<DialogContent id="form-dialog-productFolder">
			<div className="text-center"> 
			  <h5>{t("zqwNFFrmZn01_x")}</h5>   
			</div>
			<div className="form-group">
			<label htmlFor="province">{t('zqwprovince_x')} *</label>
              {/* <label htmlFor="province">Taxe de {t('zqwprovince_x')} *</label>  */}
              {/* <label htmlFor="province">{t("zqwNVFrmEl90_x")} {t('zqwprovince_x')} *</label> &nbsp;   */}
                    <select name="province" id="province" value={this.state.province} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={'form-control'} placeholder={t('zqwprovince_x')} >
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                      </select> 
            </div>
	 
			{/* <div className="form-group">
				<label htmlFor="name">{t('zqwPuptxt2_x')}</label>
				  <Select options={productOptions} onChange={this.handleProductChange}  /> 
			</div> */}

			  {/* <div className="form-group">
				  <div className="md-12">
					  <AutocompleteClient key={91000} propstep={1}  /> 
				  </div>      
			  </div> */}
			<div className="form-group">
				<label htmlFor="name">{t("zqwNFFtxt6_x")}</label>
				  {/* <Select options={transactionOptions} onChange={this.handleTransactionChange} /> */}
				  {/* <p> Transactions **** </p>  */}
				  {transactionList} 
			</div> 
			<div className="text-center"> 
				  <p> </p> 
				  <p> </p>  
				  <p> </p>  	  
   
		   
		  {(this.state.stripe_id!=='')?
		  <button type="button" name="coupon_validate" className="btn btn-info" onClick={this.payWithCardOnFile}>{t("zqwNVFrmEl75_x")}</button>:''
			 }
			  </div>      
			  <div className="text-center"> 
				  <p> </p> 
				  <p> </p>  
				  <p> </p>  
			  </div>
			  <div className="form-group">
				  <br/>
				  <input type="button" onClick={this.handleDownloadJudiciaryInvoice} className="btn btn-primary float-right" value={t('zqwNFFrmZn03_x')} /> &nbsp; &nbsp; 
			  </div> 
			<div className="form-row neoxtbl">
				{/* <div className="form-group">
				<label htmlFor="name">{t('zqwname_x')} du Produit</label>
				  <Select options={productOptions} onChange={this.handleProductChange}   /> 
			  </div>
			  <div className="form-group">
				  <div className="md-12">
					  <AutocompleteClient key={91000} propstep={1} /> 
				  </div>      
			  </div>      */}
			   
			  <div className="form-group col-md-9">
			  <br/>
			  <input type="button" onClick={this.closeJudiciaryInvoicePopup} className="btn btn-success" value={t('zqwTNVFtxt20_x')}  /> &nbsp; &nbsp; 
			  </div> 
			</div>
				
			</DialogContent>
			
		  </Dialog>
		</div>
	  );

  }

  handleOpenJudiciaryInvoice  = (anyEvent) => {
	const productsList=this.state.productsList.products;   
	const transactionList=this.state.listOfTransactions;  
	const province= this.state.province; 
	consoleXlog("weeeeeeeeeeeeeeeeeeeeee handle-OpenJudiciaryInvoice ");      
  	this.setState({["openJudiciaryInvoice"]: true }); 
  	//this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
	  if(province===""){
		const listOfTicketsInfo=this.state.listOfTicketsPayments;
		let listOfneocase=[]; 
		if(listOfTicketsInfo!==null && listOfTicketsInfo.neocase!==undefined){
			listOfneocase=listOfTicketsInfo.neocase; 
			const neocase=listOfneocase[0]; 
			if(neocase!==null && neocase.province!==undefined){
				this.setState({["province"]: neocase.province }); 
			}
		}
	  }	
   
	}

	closeJudiciaryInvoicePopup  = (anyEvent) => {     
		this.setState({["openJudiciaryInvoice"]: false, ["openCustomInvoice"]: false }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
	}

	handleCloseJudiciaryInvoicePopup= (anyEvent) => {     
		this.closeJudiciaryInvoicePopup(anyEvent); 
	}

	//setCheckboxInvoiceItem
	handleCheckboxInvoiceItem = (event) => {
		let isChecked=false; let onOffval="0";  
		//const index=event.currentTarget.dataset.address;
		const id=event.target.dataset.id;
		const index=event.target.dataset.index;
		const price=event.target.dataset.price;
		const invoice=event.target.dataset.invoice;
		const description=event.target.value;
		const quanity=1;
		const invoiceDescriptions=this.state.invoiceDescriptions;
		if(event.target.checked){ isChecked=true; onOffval="1"; }
		const this2=this;
		//consoleXlog(" ....SMMMMMMMMMMMS CHANGED handleCheckBoxCheck .... "+onOffval);  
		//consoleXlog(".... CHANGED CheckboxInvoiceItem .... ",id,index,price,invoice,description);

		const listOfTicketsInfo=this.state.listOfTicketsPayments;
		let listOfTransactions=[]; 
		if(listOfTicketsInfo!==null && listOfTicketsInfo.transactions!==undefined){
			listOfTransactions=listOfTicketsInfo.transactions; 
		}
		const transactionList = listOfTransactions.map((transaction, i) => {
			if(i===parseInt(index)){
				listOfTransactions[i]["isChecked"]=isChecked;

			}
			return null;			
		  }, this);

		if(isChecked){ 
			invoiceDescriptions.push({"description":description,"qty":1,"price":price,"index":index,
			"id":id,"invoice":invoice, });
			this.setState({ ["invoiceDescriptions"]: invoiceDescriptions }); 
		}else{
			const qList = invoiceDescriptions.map((desc, i) => { 
				if(desc.index===index){
					invoiceDescriptions.splice(i, 1); 
					this2.setState({ ["invoiceDescriptions"]: invoiceDescriptions }); 
				}				
				return null;	
			  }, this);
		}

		if(listOfTicketsInfo!==null && listOfTicketsInfo.transactions!==undefined){ 
			listOfTicketsInfo.transactions=listOfTransactions;
			this.setState({ ["listOfTicketsPayments"]: listOfTicketsInfo });   
		}

		//consoleXlog(".... CheckboxInvoiceItem TRACK .... ", listOfTicketsInfo);
 		 
	  };

	  handleDownloadJudiciaryInvoice = (event) => {                                                             
		event.preventDefault();
		const { t } = this.props;
		const invoiceDescriptions=this.state.invoiceDescriptions; 
		// invoiceDescriptions.push({"description":description,"qty":1,"price":price,"index":index,
		// 	"id":id,"invoice":invoice, });
		const province= this.state.province; 
		const listOfTicketsInfo=this.state.listOfTicketsPayments;
		const ticket_id = this.state.realticket_id; 
		const descriptionList=[]; const invoiceList=[];
		let smallInvoice=0;
		const qList = invoiceDescriptions.map((desc, i) => { 
			descriptionList.push({"description":desc.description,"qty":1,"price":desc.price}); 
			if(!invoiceList.includes(desc.invoice)){
				invoiceList.push(desc.invoice); 
				if(smallInvoice===0){ smallInvoice=parseInt(desc.invoice); }else{
					if(smallInvoice > parseInt(desc.invoice)){
						smallInvoice=parseInt(desc.invoice); 
					}
				}
			}			
			return null;	
		}, this);

		consoleXlog("DESCRIPTIONS & INV. ", descriptionList, invoiceList, smallInvoice, ticket_id, province); 
		if(descriptionList.length===0){
			this.showToastError(t("zqwNFFrmZn04_x"));
			return false;
		}

		if(parseInt(ticket_id) >0){ }else{
			this.showToastError(t("zqwNFFrmZn05_x"));
			return false;
		}

		if(smallInvoice >0){ }else{
			this.showToastError(t("zqwNFFrmZn06_x"));
			return false;
		}

		if(province!==null || province!==""){ }else{
			this.showToastError(t("zqwNFFrmZn07_x"));
			return false;
		}

		//if(config.isMULTIPART!==undefined && config.isMULTIPART===1){ 
		//if(config.isBLOB!==undefined && config.isBLOB===1){
		//const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
		const datefile = moment().format('YYYYMMDDhhmmss');
		let shortFilename="facJudiciaire-"+smallInvoice+"_"+datefile+".pdf";
		if(t("curlang")==="en"){
			shortFilename="judiciaryInv-"+smallInvoice+"_"+datefile+".pdf";
		}

		const NdiInvoice = {
			"InvoiceJudiciaire": 1,
			"ticketId": ticket_id,
			//fileaddress:"testneofile", shortFilename:"testneo",
			"invoiceDescriptions": descriptionList,
			"province": province,
			"invoiceId": smallInvoice,
			"shortFilename":shortFilename,
			//,"format": "file"
		};

		const this00=this;
		this.setState({ ["isLoaderOpen"]: true,  });	 

		//this.showToastError("Sorry, teeeeeeeeeeeeeeeeest ..... last ...."); return false;		
		//this.showToastError("nooooooooooooooooooooooooooo ooooooooooooooooooh!\n"+pacx); 	return false; 
		
		//Promise.resolve(this.props.downloadFileTicketData(myNdiTicketDataLeft))
		//Promise.resolve(this.props.downloadFileInvoiceTicketData(myNdiTicketDataLeft))
		Promise.resolve(this.props.downloadFileJudiciaryInvoice(NdiInvoice))
		.then((response)=>{
			//this.setState(response.NdiFieldsOne);
			//const resp=response.NdiFields;
			this.setState({ ["isLoaderOpen"]: false,  });	
			this.closeJudiciaryInvoicePopup(null); 
			//this.setState({ ["isLoaderOpen"]: false,  });	 
	
			return response;
		}).catch(function(error){ 
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
			  errMessage +="\n There was some error from the expected data from the server. Please try again later."
			}
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			this00.showToastError(errMessage);
			this00.setState({ ["isLoaderOpen"]: false,  });	 
			//alert(errMessage); //error
		  });
	
		//this.showToastError("---- laaaaaaaaaaaaast message to show \n"+pacx); 
		return false; 
	 
	  };


	  CustomInvoicePopup = (props) => {
		const open = this.state.openCustomInvoice; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		let productOptions = [];
	
		const listOfTicketsInfo=this.state.listOfTicketsPayments;
		  let listOfTickets=[]; //this.state.listOfTicketsPayments;		  
		  let extraInfo=[]; //let extraInfo="";
		  let listOfTransactions=[]; 	
		const transactionList = null;		 
		let transactionOptions = [];
		let id_products=""; let transactions_id=""; let transactionOption = {}; let productOption={};
		const clientProdTransInfo=this.state.clientProdTransInfo;  
	
		//consoleXlog("PROVINCE CLIENT ", clientProdTransInfo, clientaddress);
	
		return (
			<div>
			  <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseJudiciaryInvoicePopup} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-productFolder">
				<DialogContent id="form-dialog-customInvoice">
				<div className="text-center"> 
				  <h5>{t("zqwNFFrmZn013q_x")}</h5>   
				</div>
				<div className="form-group">
					<label htmlFor="customcompany">{t('zqwNFFrmZn0132q_x')} *</label> 
					<input type="text" name="customcompany" id="customcompany" defaultValue={this.state.customcompany} 
					onChange={this.handleChange} 
					// onBlur={this.handleFocusout}
              			className={'form-control'}  /> 
				</div>
				<div className="form-group">
					<label htmlFor="customaddress">{t('zqwNFFrmZn0133q_x')} *</label> 
					{/* <input type="text" name="customaddress" id="customaddress" defaultValue={this.state.customaddress} onChange={this.handleChange} onBlur={this.handleFocusout}
              			className={'form-control'}  />  */}
					<textarea type="text" name="customaddress" id="customaddress" defaultValue={this.state.customaddress} 
					onChange={this.handleChange} 
					// onBlur={this.handleFocusout}
              			className={'form-control'}  rows="4" cols="50"></textarea> 
				</div>	 
				
				<div className="text-center"> 
					  <p> </p> 
					  <p> </p>  
					  <p> </p>   
				  </div>      
				  <div className="text-center"> 
					  <p> </p> 
					  <p> </p>  
					  <p> </p>  
				  </div>
				  <div className="form-group">
					  <br/>
					  <input type="button" onClick={this.handleDownloadFileInvoiceCus2} className="btn btn-primary float-right" value={t('zqwNFFrmZn03_x')} /> &nbsp; &nbsp; 
				  </div> 
				<div className="form-row neoxtbl"> 				   
				  <div className="form-group col-md-9">
				  <br/>
				  <input type="button" onClick={this.closeJudiciaryInvoicePopup} className="btn btn-success" value={t('zqwTNVFtxt20_x')}  /> &nbsp; &nbsp; 
				  </div> 
				</div>
					
				</DialogContent>
				
			  </Dialog>
			</div>
		  );
	
	  }

	  handleDownloadFileInvoiceCus2 = (event) => {                                                             
		event.preventDefault();
		const { t } = this.props;
		const customcompany = this.state.customcompany;
		const customaddress = this.state.customaddress;
		 
		//const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
		//let myNdiTicketDataLeft = null;
		const myNdiTicketDataLeft=this.customInvoiceData;
		const tickeIdx=parseInt(myNdiTicketDataLeft.invoiceId);
		if(customcompany===null || customcompany==="" || customaddress===null || customaddress===""){
			//this.showToastError("Sorry, you need to enter the company name and its address to continue.");
			this.showToastError(t("zqwNFFrmZn0136q_x"));
			return false;
		}

		/*const myNdiTicketDataLeft = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
			field_data: elemVal, field_data_raw: field_data_rawx, 
			fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, 
			fileaddress:fileaddress, shortFilename:shortFilename,
			invoiceId:tickeIdx }; 
	
		const selectedFiles=this.state.selectedFiles;*/
	
	
		//check invoice ID tickeIdx  fieldname
		if(tickeIdx >0){ }
		else{
			//this.showToastError("Sorry, we need the invoice Id to be able to download the invoice.");
			this.showToastError(t("zqwNFFrmZn0135q_x"));
			return false;
		}

		myNdiTicketDataLeft.customInvoice=77;
		myNdiTicketDataLeft.customcompany=customcompany;
		myNdiTicketDataLeft.customaddress=customaddress; 
	 
		//this.showToastError("nooooooooooooooooooooooooooo ooooooooooooooooooh!\n"+pacx); 	return false; 
		
		//consoleXlog("daaaaaaaaaaaaaaaaaata ", myNdiTicketDataLeft);
		///Promise.resolve(this.props.downloadFileTicketData(myNdiTicketDataLeft))
		//Promise.resolve(this.props.downloadFileInvoiceTicketData(myNdiTicketDataLeft))
		Promise.resolve(this.props.downloadFileJudiciaryInvoice(myNdiTicketDataLeft))
		.then((response)=>{
			//const resp=response.NdiFields;
			////consoleXlog("HOHOHO response from server .... "); 
			// this.setState({ ["customcompany"]: "", ["customaddress"]: "",
			// ["openCustomInvoice"]: false, }); 
			this.setState({ ["openCustomInvoice"]: false, }); 
	
			return response;
		}).catch(function(error){ 
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
			  errMessage +="\n There was some error from the expected data from the server. Please try again later."
			}
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			this.showToastError(errMessage);
			 
			//consoleXlog("ERROR WITH TRANSACTION"); 
			//consoleXlog(error);
			//consoleXlog("SHORT ERROR MESSAGE: "+errMessage);
			//consoleXlog("RWA RESPONSE"); 
			////consoleXlog(rawError);
			 
			//localStorage.setItem("transerror", JSON.stringify(error));
			//localStorage.setItem("transerrorrawresp", JSON.stringify(rawError));
			//alert(errMessage); //error
		  });
	
		//this.showToastError("---- laaaaaaaaaaaaast message to show \n"+pacx); 
		return false; 
	 
	  };
	  

  ////////////////////////////////////////////////////////////////////////////////

   

  showRefundProcess = (event) => {
	//const open = this.state.openProductFolder; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    //const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	const invoiceId=event.currentTarget.dataset.id;
	const paymentId=event.currentTarget.dataset.paymentid;
	let history=0;
	//let productOptions = [];
	if(event.currentTarget.dataset.history!==undefined){
		history=parseInt(event.currentTarget.dataset.history);
	}
 

	//const clientaddress=this.state.clientAddress;
	consoleXlog("showRefundProcess CLIENT ", invoiceId, paymentId, event.currentTarget.dataset.hystory);

	this.setState({ ["refund_open"]: true,  ["refund_invoice"]: invoiceId, ["refund_payment"]: paymentId, ["fromHistory"]: history,});	

	// return(<NeoMiniPayment openx={open} stripestatus={this.getStripStatus} 
	// 	transactions={transactionOptions} transactionOption={transactionOption} 
	// 	productsList={this.state.productsList} clientProdTransInfo={clientProdTransInfo} 
	// 	multiactions={this.setMultiactions} currentTicket={currentTicket} 
	// 	clientAddress={clientaddress} />  );

  }

  handleCloseTransactionPopup  = (anyEvent) => {     
	this.setState({["refund_open"]: false, ["refundList_open"]: false, }); 
	//this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
	// const obj={closeme:'close'};
	// if(this.state.paymentRound >0){
	// 	obj.refreshafterPayment=1;
	// }  

	//this.props.multiactions(obj);
  }

  RefundProcessPopup = (props) => {
	const open = this.state.refund_open; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	let productOptions = [];

    let productList = this.state.productsList.products.length > 0
    	&& this.state.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
        }
      return (
        null
      );
	}, this);
	
	//const AutocompleteClient=this.AutocompleteClient;

	 
	let transactionOptions = [];
 
	let id_products=""; let transactions_id=""; let transactionOption = {}; let productOption={};
	const clientProdTransInfo=this.state.clientProdTransInfo; 
	let listOfTransactionsx=this.state.listOfTransactions;


	const listOfTicketsInfo=this.state.listOfTicketsPayments;
	let listOfTickets=[]; //this.state.listOfTicketsPayments;
	let listOfTicketsHist=[]; 	   
	let extraInfo=[]; //let extraInfo="";
	let listOfTransactions=[]; let listOfTransactionsHist=[]; 
	const invoiceIdgo=this.state.refund_invoice; 
	let taxfreeAmounts=[];	 	  

	if(this.state.fromHistory===3){
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.transactions!==undefined){
			listOfTransactionsx=listOfTicketsInfo.gohistory.transactions; 
		}		
		//   if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.transactions!==undefined){
		// 	listOfTransactionsHist=listOfTicketsInfo.gohistory.transactions; 
		//   }
		// if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.payments!==undefined){
		// 	listOfTicketsHist=listOfTicketsInfo.gohistory.payments; 
		// }
	}else{
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.transactions!==undefined){
			const listOfTransactionsx2=listOfTicketsInfo.gohistory.transactions; 
			let transList000 = listOfTransactionsx2.length > 0
    		&& listOfTransactionsx2.map((item, i) => {
				//transactionOptions.push({ value: item.id, label: item.description+ ' (prod'+item.id_products+') #inv'+item.id_invoices });
				console.log("zGOOOOWEEEEEEELL taxfreeAmounts ", invoiceIdgo, item.id_invoices); 
				if(""+invoiceIdgo===item.id_invoices){
					const preachat=JSON.parse(item.preachat);
					if(preachat!==undefined && preachat.amounts!==undefined && preachat.amounts.amountTaxFree!==undefined){
						taxfreeAmounts.push(parseFloat(preachat.amounts.amountTaxFree));
					}
				}
			return (	null			);
			}, this);
		}	
	}
	let transList = listOfTransactionsx.length > 0
    	&& listOfTransactionsx.map((item, i) => {
		//productOptions.push({ value: item.id, label: item.titre }); 
		transactionOptions.push({ value: item.id, label: item.description+ ' (prod'+item.id_products+') #inv'+item.id_invoices });
        //transactionOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
		console.log("GOOOOWEEEEEEELL taxfreeAmounts ", invoiceIdgo, item.id_invoices); 
		if(""+invoiceIdgo===item.id_invoices){
			const preachat=JSON.parse(item.preachat);
			if(preachat!==undefined && preachat.amounts!==undefined && preachat.amounts.amountTaxFree!==undefined){
				taxfreeAmounts.push(parseFloat(preachat.amounts.amountTaxFree));
			}
		}
      return (
        null
      );
	}, this);

	let currentTicket="";
	if(this.state.realticket_id!==""){
		currentTicket=""+this.state.realticket_id;
	}else if(this.backTicketId!==0 || this.backTicketId!==""){
		currentTicket=""+this.backTicketId;
	}

	const clientaddress=this.state.clientAddress;
	//this.state.listOfTicketsPayments.invoices/refunds/payments
	// const invoices=this.state.listOfTicketsPayments.invoices;
	// const payments=this.state.listOfTicketsPayments.payments;
	// const refunds=this.state.listOfTicketsPayments.refunds;
	let invoices=this.state.listOfTicketsPayments.invoices;
	let payments=this.state.listOfTicketsPayments.payments;
	let refunds=this.state.listOfTicketsPayments.refunds;
	const invoiceId=this.state.refund_invoice;
	const paymentId=this.state.refund_payment;
	let mainTransactions=this.state.listOfTicketsPayments.transactions;
	let transactionsRefBreakdown=this.state.listOfTicketsPayments.transactionsRefBreakdown;
	let credit=this.state.listOfTicketsPayments.credit;
	let creditHistory=this.state.listOfTicketsPayments.creditHistory;
	consoleXlog("fromHistory-fromHistory ----- ", this.state.fromHistory);
	if(this.state.fromHistory===3){
		//invoices=this.state.listOfTicketsPayments.invoices;
		//payments=this.state.listOfTicketsPayments.payments;
		//refunds=this.state.listOfTicketsPayments.refunds;
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.payments!==undefined){
			payments=listOfTicketsInfo.gohistory.payments; 
		}
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.invoices!==undefined){
			invoices=listOfTicketsInfo.gohistory.invoices; 
		}
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.refunds!==undefined){
			refunds=listOfTicketsInfo.gohistory.refunds; 
		}

		// consoleXlog("fromHistory-fromHistory ", invoices); PaymentRefundList NeoRefundPaymentList
		// consoleXlog("fromHistory-fromHistory ", payments);
		// consoleXlog("fromHistory-fromHistory ", refunds);
		// consoleXlog("fromHistory-fromHistory ", transactionOptions);

	}
	 
	//consoleXlog("PROVINCE CLIENT ", clientProdTransInfo, clientaddress);
	//consoleXlog("invoices payments refunds ", invoices, payments, refunds);
	let openkey="0000";
	if(refunds!==undefined || payments!==undefined || invoices!==undefined){
		openkey=new Date().getTime();
	}

	/*return(<NeoRefundPayment openx={open} stripestatus={this.getStripStatus} 
		transactions={transactionOptions} transactionOption={transactionOption} 
		productsList={this.state.productsList} clientProdTransInfo={clientProdTransInfo} 
		multiactions={this.setMultiactions} currentTicket={currentTicket} 
		clientAddress={clientaddress} invoiceId={invoiceId} paymentId={paymentId} 
		invoices={invoices} payments={payments} refunds={refunds} />  );*/

		return (
			<div>
			  <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseTransactionPopup} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-NeoRefundPayment">
				<DialogContent id="form-dialog-NeoRefundPayment">
				 
				<NeoRefundPayment openx={openkey} stripestatus={this.getStripStatus} 
		transactions={transactionOptions} transactionOption={transactionOption} 
		productsList={this.state.productsList} clientProdTransInfo={clientProdTransInfo} 
		multiactions={this.setMultiactions} currentTicket={currentTicket} 
		mainTransactions={mainTransactions} transRefBreakdown={transactionsRefBreakdown} 
		credit={credit} creditHistory={creditHistory}  
		clientAddress={clientaddress} invoiceId={invoiceId} paymentId={paymentId} 
		invoices={invoices} payments={payments} refunds={refunds} taxfreeAmounts={taxfreeAmounts} />


			   
					
				</DialogContent>
				
			  </Dialog> 
			</div>
		  );	

  }

  

  showRefundProcess2 = (event) => {
	//const open = this.state.openProductFolder; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    //const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	const invoiceId=event.currentTarget.dataset.id;
	const paymentId=event.currentTarget.dataset.paymentid;
	let history=0;
	//let productOptions = [];
	if(event.currentTarget.dataset.history!==undefined){
		history=parseInt(event.currentTarget.dataset.history);
	}
  
	//const clientaddress=this.state.clientAddress;
	//consoleXlog("showRefundProcess CLIENT ", invoiceId, paymentId, event.currentTarget.dataset.hystory);

	this.setState({ ["refundList_open"]: true,  ["refund_invoice"]: invoiceId, ["refund_payment"]: paymentId, ["fromHistory"]: history,});	

  }

  RefundProcessPopupList = (props) => {
	const open = this.state.refundList_open; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	let productOptions = [];

    let productList = this.state.productsList.products.length > 0
    	&& this.state.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
        }
      return (
        null
      );
	}, this);
	
	//const AutocompleteClient=this.AutocompleteClient;
	let mainTransactions=this.state.listOfTicketsPayments.transactions;
	let transactionsRefBreakdown=this.state.listOfTicketsPayments.transactionsRefBreakdown;

	 
	let transactionOptions = [];
 
	let id_products=""; let transactions_id=""; let transactionOption = {}; let productOption={};
	const clientProdTransInfo=this.state.clientProdTransInfo; 
	let listOfTransactionsx=this.state.listOfTransactions;


	const listOfTicketsInfo=this.state.listOfTicketsPayments;
	let listOfTickets=[]; //this.state.listOfTicketsPayments;
	let listOfTicketsHist=[]; 	   
	let extraInfo=[]; //let extraInfo="";
	let listOfTransactions=[]; let listOfTransactionsHist=[]; 
	const invoiceIdgo=this.state.refund_invoice;
	let taxfreeAmounts=[]; 
	

	if(this.state.fromHistory===3){
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.transactions!==undefined){
			listOfTransactionsx=listOfTicketsInfo.gohistory.transactions; 
		}		
		//   if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.transactions!==undefined){
		// 	listOfTransactionsHist=listOfTicketsInfo.gohistory.transactions; 
		//   }
		// if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.payments!==undefined){
		// 	listOfTicketsHist=listOfTicketsInfo.gohistory.payments; 
		// }
	}
	
	let transList = listOfTransactionsx.length > 0
    	&& listOfTransactionsx.map((item, i) => {
		//productOptions.push({ value: item.id, label: item.titre }); 
		transactionOptions.push({ value: item.id, label: item.description+ ' (prod'+item.id_products+') #inv'+item.id_invoices });
        //transactionOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
		console.log("GOOOOWEEEEEEELL taxfreeAmounts ", invoiceIdgo, item.id_invoices); 
		if(""+invoiceIdgo===item.id_invoices){
			const preachat=JSON.parse(item.preachat);
			if(preachat!==undefined && preachat.amounts!==undefined && preachat.amounts.amountTaxFree!==undefined){
				taxfreeAmounts.push(parseFloat(preachat.amounts.amountTaxFree));
			}
		}

      return (
        null
      );
	}, this);

	let currentTicket="";
	if(this.state.realticket_id!==""){
		currentTicket=""+this.state.realticket_id;
	}else if(this.backTicketId!==0 || this.backTicketId!==""){
		currentTicket=""+this.backTicketId;
	}

	const clientaddress=this.state.clientAddress;
	//this.state.listOfTicketsPayments.invoices/refunds/payments
	// const invoices=this.state.listOfTicketsPayments.invoices;
	// const payments=this.state.listOfTicketsPayments.payments;
	// const refunds=this.state.listOfTicketsPayments.refunds;
	let invoices=this.state.listOfTicketsPayments.invoices;
	let payments=this.state.listOfTicketsPayments.payments;
	let refunds=this.state.listOfTicketsPayments.refunds;
	const invoiceId=this.state.refund_invoice;
	const paymentId=this.state.refund_payment;

	//let mainTransactions=this.state.listOfTicketsPayments.transactions;
	//let transactionsRefBreakdown=this.state.listOfTicketsPayments.transactionsRefBreakdown;
	let credit=this.state.listOfTicketsPayments.credit;
	let creditHistory=this.state.listOfTicketsPayments.creditHistory;

	consoleXlog("fromHistory-fromHistory ----- ", this.state.fromHistory);
	if(this.state.fromHistory===3){
		//invoices=this.state.listOfTicketsPayments.invoices;
		//payments=this.state.listOfTicketsPayments.payments;
		//refunds=this.state.listOfTicketsPayments.refunds;
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.payments!==undefined){
			payments=listOfTicketsInfo.gohistory.payments; 
		}
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.invoices!==undefined){
			invoices=listOfTicketsInfo.gohistory.invoices; 
		}
		if(listOfTicketsInfo!==null && listOfTicketsInfo.gohistory!==undefined && listOfTicketsInfo.gohistory.refunds!==undefined){
			refunds=listOfTicketsInfo.gohistory.refunds; 
		}

		// consoleXlog("fromHistory-fromHistory ", invoices);
		// consoleXlog("fromHistory-fromHistory ", payments);
		// consoleXlog("fromHistory-fromHistory ", refunds);
		// consoleXlog("fromHistory-fromHistory ", transactionOptions);

	}
	 
	//consoleXlog("PROVINCE CLIENT ", clientProdTransInfo, clientaddress);
	//consoleXlog("invoices payments refunds ", invoices, payments, refunds);
	let openkey="0000";
	if(refunds!==undefined || payments!==undefined || invoices!==undefined){
		openkey=new Date().getTime();
	}
 

		return (
			<div>
			  <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseTransactionPopup} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-NeoRefundPayment3">
				<DialogContent id="form-dialog-NeoRefundPayment3">
				 
				<NeoRefundPaymentList openx={openkey} stripestatus={this.getStripStatus} 
		transactions={transactionOptions} transactionOption={transactionOption} 
		productsList={this.state.productsList} clientProdTransInfo={clientProdTransInfo} 
		multiactions={this.setMultiactions} currentTicket={currentTicket} 
		mainTransactions={mainTransactions} transRefBreakdown={transactionsRefBreakdown} 
		credit={credit} creditHistory={creditHistory}  
		clientAddress={clientaddress} invoiceId={invoiceId} paymentId={paymentId} 
		invoices={invoices} payments={payments} refunds={refunds} taxfreeAmounts={taxfreeAmounts} />


			   
			<div className="form-row neoxtbl">	 
				<div className="form-group col-md-9"> 
				<input type="button" onClick={this.handleCloseTransactionPopup} className="btn btn-success" value={t('zqwTNVFtxt20_x')}  /> &nbsp; &nbsp; 
				</div> 
			</div> 	
				</DialogContent>
				
			  </Dialog> 
			</div>
		  );	

  }




  AutocompleteClient = (props) => {    
    //AutocompleteClient (props){
    const listofSuggestion=this.state.listOfSuggestion;
    const valueClientSearch=this.state.valueClientSearch;
    const inputValueClientSearch=this.state.inputValueClientSearch;
    //const selectedIdSuggestion=this.state.selectedIdSuggestion; 
    const propstep=props.propstep;
    const this2=this;

    return (
      <Autocomplete

        value={valueClientSearch} 
        inputValue={inputValueClientSearch}
        onInputChange={(event, newInputValue) => {
          //setInputValue(newInputValue);
          this.setState({ ["inputValueClientSearch"]: newInputValue }); 
          ////consoleXlog('onInputChange search for autocomplete ='+newInputValue);
          if(newInputValue.length >2){ 
              //goto the server 
              //this.getClientSearchSuggestion(newInputValue); 
                setTimeout(() => {
                  this.getClientSearchSuggestion(newInputValue); 
                }, 0); 
          }
        }}

        onChange={(event, newValue) => { 

          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              this.setState({ ["valueClientSearch"]: newValue });  
            });
          } else if (newValue && newValue.inputValue) {
             
            this.setState({ ["valueClientSearch"]: newValue.inputValue });  
          } else {
            //setValue(newValue);
            this.setState({ ["valueClientSearch"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)}); 
             
            this.setState({ ["clientInfo"]: newValue}); 

            this.setState({ ["existedClientRO"]: true, ["emailDoesNotExist"]: 1, ["transactionId"]: 0});
			 
			const clientId=newValue.id;
			//this.clientFullInfoValidate();
			this.getClientTransactionList(clientId);
              
          }
 
        }}

        freeSolo
        id="free-solo-2-demo"
        disableClearable
        // options={listofSuggestion.map((option) => option.firstname+' '+option.lastname+' '+option.email)}
        options={listofSuggestion}


          getOptionLabel={(option) => {

            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            //return option.title;
            //this.setState({ ["selectedIdSuggestion"]: option.id}); 
          ////consoleXlog('sel id is '+option.id); 
          return (option.firstname+' '+option.lastname+' '+option.email);
            
          }}

          renderOption={(option) => (option.firstname+' '+option.lastname+' '+option.email)}

        renderInput={(params) => ( 
          <TextField
            {...params}
            label="Recherchez un Client"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    );
  }


  //////////////////////////////////N component composition/////////////////////////////////////////
 
  NeogenTextField = (props) => {
	const { t } = this.props;
	const field=props.fieldBlock;  
	const productField=props.productField; 
	const FieldData=props.FieldData;
	const dataRecordNeo=props.dataRecordNeo;
	let keyTrackerTxt=""; 
	const name=(""+field.name).replace(" ", "");
	const textLabel=field.text_label;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	//const rndKey=Math.floor(Math.random()*100); 
	let rndKey=""; 
	if(ticketDataLeft!==null && (ticketDataLeft.id >0 || ticketDataLeft.field_data!==null)){ //if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		//tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		rndKey=ticketDataLeft.last_update; 
		if(ticketDataLeft.id >0){ tickeId=ticketDataLeft.id; } 
		fieldDataval=ticketDataLeft.field_data;
		keyTrackerTxt="tdl"+rndKey;  
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
	}
	if(dataRecordNeo!=undefined && dataRecordNeo!=null && dataRecordNeo.id!==undefined){
		if(dataRecordNeo.id >0 && dataRecordNeo.field_data!==null && dataRecordNeo.field_data!==""){  
			//if(ticketDataLeft.id >0){ tickeId=ticketDataLeft.id; } 
			fieldDataval=dataRecordNeo.field_data; 
			rndKey=dataRecordNeo.last_update; 
			keyTrackerTxt="drn"+rndKey; 
			consoleXlog("OOOOOOOOOOOOHNEWDATA TF ", name, fieldDataval); 
		}
		if(dataRecordNeo.id >0 && dataRecordNeo.field_data_raw!==null && dataRecordNeo.field_data_raw!==""){  
			consoleXlog("OOOOOOOOOOOOHNEWDATA TF raw ", name, fieldDataval); 
			rndKey=dataRecordNeo.last_update; 
			keyTrackerTxt="drn2"+rndKey;   
			//const myListOptionsx= JSON.parse(dataRecordNeo.field_data_raw); 
			//fieldDataRaw=myListOptionsx; 
		} 
	} 
	const elementFormsErrors=this.state.elementFormsErrors;
	elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	const fieldDatavals=this.state.elementFormsErrors;
	//fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	//this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: []};
	//this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter gentxtfield ", myObjSetter); 
	//consoleXlog("####addSetter gentxtfield ", indexField, fieldid, productfieldid, name, textLabel, fieldDataval); 
	 
	const dynamicForm=props.dynamicForm;
	const evalregexp=field.eval_regexp;
	//const dynamicForm=this.state.dynamicForm[indexField];
	//error={(isRequired && fieldDataval==="")?true:false} 
	const dossiernextstep=this.state.dossiernextstep;
	// if(fieldid==="132"){
	// 	if(dossiernextstep==="455" || dossiernextstep==="456"){
	// 	}else{
	// 		return null;
	// 	}
	// }

	let onChangevar=this.handleChange2;
	let onBlurvar=this.handleFocusout;
	/*if(name.indexOf("-bfcorpo-")>=0){
		onChangevar=this.handleChange2Corpo;
		onBlurvar=this.handleFocusoutCorpo;
	}*/
	  
	return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
	  {(fieldid==="37" || fieldid==="30")?<button type="button" className="btn btn-primary" 
	  data-index={indexField} data-paadindex="-3" data-fieldtrack={fieldid==="37"?"pa":"cli"} 
					onClick={this.handlePASearchLauncher}><SearchIcon htmlColor="#FFFFFF" /> {t("zqwNDICItx3_x")}</button>:'' }
	  <div className="input-group">
	  <div className={this.colClassLeft}>
	  <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
		error={(dynamicForm["error"]===true)?true:false} fullWidth={true} 
		inputProps={{'data-index': indexField}}
		onChange={onChangevar} onBlur={onBlurvar} /> 
			<CopyIconButton text={fieldDataval ?? ""} />

		</div><div className={this.colClassRight}>
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a></div> 
	  </div>     
  </div>);


  return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
	  {fieldid==="37"?<button type="button" className="btn btn-primary" 
	  data-index={indexField} data-paadindex="-3" 
					onClick={this.handlePASearchLauncher}><SearchIcon htmlColor="#FFFFFF" /> {t("zqwNDICItx3_x")}</button>:'' }
	  <div className="input-group">
	  <div className={this.colClassLeft}>
	  <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
		error={(dynamicForm["error"]===true)?true:false} fullWidth={true} 
		inputProps={{'data-index': indexField}}
		onChange={this.handleChange2} onBlur={this.handleFocusout} /> 
		</div><div className={this.colClassRight}>
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a></div> 
	  </div>     
  </div>);

return (<div className="form-group" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
<div className="input-group">
<div className={this.colClassLeft}>
<TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
  error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
  inputProps={{'data-index': indexField}}
  onChange={this.handleChange2} onBlur={this.handleFocusout} /> 
  </div><div className={this.colClassRight}>
  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
   data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
  onClick={this.handleSubmitTicketData}> 
  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
  </span>
  </a></div> 
</div>     
</div>);
}

 NeogenNumberField = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  let keyTrackerTxt=""; 
  //const rndKey=Math.floor(Math.random()*100); 
  let rndKey=""; 
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false;
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data;
	  rndKey=ticketDataLeft.last_update; 
	  //keyTrackerTxt="nftdl"+rndKey;   
	  keyTrackerTxt="nftdl"+ticketDataLeft.last_update;   
	  if(isRequired && fieldDataval!==""){
	  isError=false;
	  }
  } 
  
  //const elementFormsErrors=this.state.elementFormsErrors;
  //elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
  //const fieldDatavals=this.state.elementFormsErrors;
  //fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
  //this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
  error: isError, check:isRequired, options: []};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter genNumberField ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;

  
  return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
  <div className="input-group">
	  <div className={this.colClassLeft}>
	  <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
		  error={(dynamicForm["error"]===true)?true:false} fullWidth={true} 
		  inputProps={{'data-index': indexField, inputMode: 'numeric', pattern: '[0-9]*'}} 
		  InputLabelProps={{ shrink: true }} 
		  onChange={this.handleChange2} onBlur={this.handleFocusout} /> 
		  <CopyIconButton text={fieldDataval ?? ""} />
		  </div><div className={this.colClassRight}>
		  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		  data-evalregexp={evalregexp}  
		  onClick={this.handleSubmitTicketData}> 
		  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		  </span>
		  </a></div> 
	  </div>     
  </div>);

 
}

 NeogenDateTimeField = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  let keyTrackerTxt=""; 
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  const ftype=field.field_type;
  let xInputMode="", xpattern="", xtraIsotxt=""; 
  //inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
  if(ftype==="date"){
	  xInputMode="date"; 
	  xtraIsotxt="T00:00:00";
	  xpattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"; 
  }else if(ftype==="datetime"){
	  xInputMode="datetime-local"; //xInputMode="datetime"; 
	  xpattern="([0-2][0-9]{3})\-([0-1][0-9])\-([0-3][0-9])T([0-5][0-9])\:([0-5][0-9])\:([0-5][0-9])(Z|([\-\+]([0-1][0-9])\:00))"; 
  }else if(ftype==="time"){
	  xInputMode="time"; xpattern="(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]){2}"; 
  }else{
	  xInputMode="date"; 
	  xtraIsotxt="T00:00:00";
	  xpattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"; 
  }

  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false;
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
	  keyTrackerTxt="dtftdl"+ticketDataLeft.last_update;   
	  if(isRequired && fieldDataval!==""){
	  isError=false;
	  }
  } 

  if(ftype==="date" && fieldDataval!==null && fieldDataval!==""){
	//const event = new Date(fieldDataval);
	//fieldDataval= dateFormat(event, 'yyyy-mm-dd', false);
	//fieldDataval=fieldDataval+xtraIsotxt;
  }  

  if(fieldDataval!==null && fieldDataval!==""){
	//const event = new Date(fieldDataval);
	//fieldDataval= dateFormat(event, 'yyyy-mm-dd', false);
	//fieldDataval=fieldDataval+xtraIsotxt;
	//fieldDataval=moment(fieldDataval).utc();
	consoleXlog("before-MOOOOOOOOOOMENT DATETIME ", fieldDataval);
	if(ftype==="date"){
		//fieldDataval=moment(fieldDataval).utc();
		fieldDataval=moment(fieldDataval).format("YYYY-MM-DD");
	}else if(ftype==="datetime"){
		//fieldDataval=moment(fieldDataval).utc();
		//fieldDataval=moment(fieldDataval).format("YYYY-MM-DDTHH:mm:ss");
		//fieldDataval=moment(fieldDataval).utc().format("YYYY-MM-DDTHH:mm:ss");
		fieldDataval=moment(fieldDataval).format("YYYY-MM-DDTHH:mm:ss");
	}else if(ftype==="time"){
		//fieldDataval=moment(fieldDataval).utc();
		fieldDataval=moment(fieldDataval).format("HH:mm:ss");
	}else{
		xInputMode="date"; 
		//fieldDataval=moment(fieldDataval).utc();
		fieldDataval=moment(fieldDataval).format("YYYY-MM-DD");
	}
	consoleXlog("MOOOOOOOOOOMENT DATETIME ", fieldDataval);
  }  

	//   const elementFormsErrors=this.state.elementFormsErrors;
	//   elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	//   const fieldDatavals=this.state.elementFormsErrors;
	//   fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	//   this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
  error: isError, check:isRequired, options: []};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter genDateTimeField ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;

  
  return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
  <div className="input-group">
	  <div className={this.colClassLeft}>
	  <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
		  error={(dynamicForm["error"]===true)?true:false} fullWidth={true} 
		  InputLabelProps={{ shrink: true }} type={xInputMode} 
		  inputProps={{'data-index': indexField}}
		  onChange={this.handleChange2} onBlur={this.handleFocusout} /> 
		  </div><div className={this.colClassRight}>
		  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		  data-evalregexp={evalregexp}  data-datetimeformat={ftype}
		  onClick={this.handleSubmitTicketData}> 
		  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		  </span>
		  </a></div> 
	  </div>     
  </div>);

 
}

 NeogenInputMaskField = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  let keyTrackerTxt=""; 
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  const mask=field.mask;
  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false;
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
	  keyTrackerTxt="imftdl"+ticketDataLeft.last_update;   
	  if(isRequired && fieldDataval!==""){
		isError=false;
	  }
	} 

	//   const elementFormsErrors=this.state.elementFormsErrors;
	//   elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	//   const fieldDatavals=this.state.elementFormsErrors;
	//   fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	//   this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: []};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();

  const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
  const letter = /(?!.*[DFIOQU])[A-Z]/i;
  const digit = /[0-9]/;
  const maskCanadaZipcode = [firstLetter, digit, letter, " ", digit, letter, digit];
  let maskApplied=null;
  if(mask==="canada-zipcode"){
	  maskApplied=maskCanadaZipcode;
  }else{
	  maskApplied=mask;
  }
  

  //consoleXlog("addSetter gentxtfield ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;
	//inputProps={{placeholder: 'Input 1', 'data-state': 'Data State 1'}}
	const dynamicForm=props.dynamicForm;
	const evalregexp=field.eval_regexp;
 
	
	return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
	<div className="input-group">
	<div className={this.colClassLeft}>
	{/* <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
	  error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
	  inputProps={{'data-index': indexField}}
	  onChange={this.handleChange2} onBlur={this.handleFocusout} /> "(999) 999-9999" */}

	   {/* <label htmlFor={name}>{textLabel}</label> */}
	   <label htmlFor={name}><span className={(dynamicForm["error"]===true)? 'fieldErrtxt' : 'blue'}>{textLabel}</span></label>
	<InputMask name={name} id={name} mask={maskApplied}  onChange={this.handleChange2} defaultValue={fieldDataval}  onBlur={this.handleFocusout} 
		  className={(dynamicForm["error"]===true)? 'form-control fieldErr' : 'form-control'} 
		  data-index={indexField} />
	  <CopyIconButton text={fieldDataval ?? ""} roundedInput/>
	  </div><div className={this.colClassRight}>
	  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	   data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	   data-evalregexp={evalregexp}  
	  onClick={this.handleSubmitTicketData}> 
	  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
	  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
	  </span>
	  </a></div> 
	</div>     
</div>);

}

 NeogenTextarea = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  let keyTrackerTxt=""; 
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;   let fieldDataRawval="";
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false;
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
//   if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
// 	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
// 	  if(isRequired && fieldDataval!==""){
// 	  isError=false;
// 	  }
//   }
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		fieldDataRawval=ticketDataLeft.field_data_raw; 
		keyTrackerTxt="tatdl"+ticketDataLeft.last_update;   
		if(isRequired && (fieldDataval!=="" || fieldDataRawval!=="")){
		isError=false;
		}
	} 

	const fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
	if(fieldDataRawval!=="" && fieldDataRaw!==null && fieldDataRaw.length >0){
		fieldDataval=fieldDataRaw[0]; 
		keyTrackerTxt="tatdl2"+ticketDataLeft.last_update;   
	}
	// else if(fieldDataval!==""){
	// 	isError=false;
	// }

	//   const elementFormsErrors=this.state.elementFormsErrors;
	//   elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	//   const fieldDatavals=this.state.elementFormsErrors;
	//   fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	//   this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
  error: isError, check:isRequired, options: []};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter genTextarea ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;

  
  return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
  <div className="input-group">
	  <div className={this.colClassLeft}>
	  <TextField name={name} id={name}  multiline rowsMax={4} 
		  label={textLabel} defaultValue={fieldDataval} 
		  error={(dynamicForm["error"]===true)?true:false} fullWidth={true} 
		  inputProps={{'data-index': indexField}}
		  onChange={this.handleChange2textarea} onBlur={this.handleFocusout} /> 
		  </div><div className={this.colClassRight}>
		  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		  data-evalregexp={evalregexp}  
		  onClick={this.handleSubmitTicketData}> 
		  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		  </span>
		  </a></div> 
	  </div>     
  </div>);
 
}

 NeogenRadioBox = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  let keyTrackerTxt=""; 
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  let fieldSet=null; //field.field_dataset;
  if(field.field_dataset!==""){
	  fieldSet= JSON.parse(field.field_dataset); 
  }

  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false; let fieldDataRawval="";
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
	  fieldDataRawval=ticketDataLeft.field_data_raw; 
	  keyTrackerTxt="rbftdl"+ticketDataLeft.last_update;   
	  if(isRequired && fieldDataval!==""){
	  isError=false;
	  }
  } 

  // let fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
  let fieldDataRaw=(fieldDataRawval==="" || fieldDataRawval===null)?[]:JSON.parse(fieldDataRawval); 
  const elementFormsErrors=this.state.elementFormsErrors;
  elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
  //const fieldDatavals=this.state.elementFormsErrors;
  //fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
  //this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  if(fieldDataval!==null && fieldDataval!=="" && (fieldDataRawval===null || fieldDataRawval==="")){
	//fieldDataRaw.push(fieldDataval);
	if(fieldDataRaw===null){ 
		fieldDataRaw=[];
		fieldDataRaw.push(fieldDataval);
		//fieldDataRaw[0]=fieldDataval; 
	}
	consoleXlog("Radio wooooooooooooooooy ", fieldDataval, name); 
  }  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
  error: isError, check:isRequired, options: fieldDataRaw};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter genCheckBox ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;
  //fieldDataRaw=ticketDataLeft.field_data_raw===""?"":JSON.parse(ticketDataLeft.field_data_raw); 
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;

  
  return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
  <div className="input-group">
	  <div className={this.colClassLeft}>
	  {/* <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
		  error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
		  inputProps={{'data-index': indexField}}
		  onChange={this.handleChange2} onBlur={this.handleFocusout} />  */}
		  
		  {/* <FormControl component="fieldset"  key={productField.id+"_ckb"}>
		  <FormLabel component="legend">{textLabel}</FormLabel>
		  <FormGroup>
		  {fieldSet!==null && fieldSet.map((option, ind) => ( 
				  <FormControlLabel  key={productField.id+"_"+ind}
				  control={<Checkbox  name={name} value={option.value} defaultChecked={fieldDataRaw.includes(option.value)} 
				  inputProps={{'data-index': indexField}} onChange={this.handleChangeOptions} />}
				  label={option.text+"---z"}
			  />
			  ))}
		  </FormGroup>  	
		  </FormControl> */}


		  <FormControl component="fieldset" key={productField.id+"_rad"}>
		  <FormLabel component="legend">
		  <span className={(dynamicForm["error"]===true)? 'fieldErrtxt' : 'blue'}>{textLabel}</span>
			  </FormLabel>
		  <RadioGroup aria-label={name} name={name} defaultValue={fieldDataval}>
			  {fieldSet!==null && fieldSet.map((option, ind) => (
				  <FormControlLabel  key={productField.id+"_"+ind}
				  value={option.value} control={<Radio inputProps={{'data-index': indexField}} onChange={this.handleChange2}  />}
				  label={option.label}
			  />
			  ))}
			  
		  </RadioGroup> 	
		  </FormControl>
		  
		  
		  </div><div className={this.colClassRight}>
		  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		  data-evalregexp={evalregexp}  
		  onClick={this.handleSubmitTicketData}> 
		  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		  </span>
		  </a></div> 
	  </div>     
  </div>);

  // return (<div key={field.id} className="form-group"> 
  // 	  <TextField name={name} id={name} label={textLabel} /> 		 
  // 	</div>);

  //<RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
  /*return (
	  <FormControl component="fieldset">
		<FormLabel component="legend">Gender</FormLabel>
		<RadioGroup aria-label="gender" name="gender1" defaultValue={""}>
		  <FormControlLabel value="female" control={<Radio />} label="Female" />
		  <FormControlLabel value="male" control={<Radio />} label="Male" />
		  <FormControlLabel value="other" control={<Radio />} label="Other" />
		  <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
		</RadioGroup>
	  </FormControl>
	);*/

	//control={<Radio  name={name} value={option.value}  />}
	return (
	  <FormControl component="fieldset" key={productField.id+"_rad"}>
		<FormLabel component="legend">Gender</FormLabel>
		<RadioGroup aria-label="gender" name="gender1" defaultValue={""}>
		  {fieldSet!==null && fieldSet.map((option, ind) => (
			  <FormControlLabel  key={productField.id+"_"+ind}
			  value={option.value} control={<Radio  />} 
			  label={option.text}
		  />
		  ))}
		   
		</RadioGroup>
		<span className="danger"><SaveOutlinedIcon htmlColor="#FF0000" /></span> 	
	  </FormControl>
	);  
}

 NeogenCheckBox = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  let keyTrackerTxt=""; 
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  let fieldSet=null; //field.field_dataset;
  if(field.field_dataset!==""){
	  fieldSet= JSON.parse(field.field_dataset);
  }

  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false; let fieldDataRawval="";
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
	  fieldDataRawval=ticketDataLeft.field_data_raw;
	  keyTrackerTxt="cbftdl"+ticketDataLeft.last_update;    
	  if(isRequired && fieldDataval!==""){
	  isError=false;
	  }
  } 

//   let defExistedVal="";
//   if(fieldDataval!==null && fieldDataval!=="" && (fieldDataRawval===null || fieldDataRawval==="")){
// 	defExistedVal=fieldDataval;
// 	consoleXlog("wooooooooooooooooy ", fieldDataval, name); 
//   }

  //control={<Checkbox  name={name} value={option.value} defaultChecked={(fieldDataRaw!==null && fieldDataRaw.includes(option.value) || (fieldDataval!==null && fieldDataval!=="" &&fieldDataval===option.value))}

  let fieldDataRaw=fieldDataRawval===""?[""]:JSON.parse(fieldDataRawval); 
  const elementFormsErrors=this.state.elementFormsErrors;
  //elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
  //const fieldDatavals=this.state.elementFormsErrors;
  //fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
  //this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  if(fieldDataval!==null && fieldDataval!=="" && (fieldDataRawval===null || fieldDataRawval==="")){
	//fieldDataRaw.push(fieldDataval);
	if(fieldDataRaw===null){ 
		fieldDataRaw=[];
		fieldDataRaw.push(fieldDataval);
		//fieldDataRaw[0]=fieldDataval; 
	}
	//consoleXlog("wooooooooooooooooy ", fieldDataval, name); 
  }  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
  error: isError, check:isRequired, options: fieldDataRaw};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter genCheckBox ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;
  //fieldDataRaw=ticketDataLeft.field_data_raw===""?"":JSON.parse(ticketDataLeft.field_data_raw); 
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;

  //consoleXlog("SELECTED ONE", fieldDataRaw); 

  
  return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
  <div className="input-group">
	  <div className={this.colClassLeft}>
	  {/* <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
		  error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
		  inputProps={{'data-index': indexField}}
		  onChange={this.handleChange2} onBlur={this.handleFocusout} />  */}
		  
		  <FormControl component="fieldset"  key={productField.id+"_ckb"}>
		  <FormLabel component="legend">
		  <span className={(dynamicForm["error"]===true)? 'fieldErrtxt' : 'blue'}>{textLabel}</span>
		  </FormLabel>
		  <FormGroup>
		  {fieldSet!==null && fieldSet.map((option, ind) => ( 
				  <FormControlLabel  key={productField.id+"_"+ind}
				  control={<Checkbox  name={name} value={option.value} defaultChecked={fieldDataRaw!==null && fieldDataRaw.includes(option.value)} 
				  inputProps={{'data-index': indexField}} onChange={this.handleChangeOptions} />}
				  label={option.label}
			  />
			  ))}
		  </FormGroup>
		  {/* <FormHelperText>Be careful</FormHelperText> */}  	
		  </FormControl>
		  
		  
		  </div><div className={this.colClassRight}>
		  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		  data-evalregexp={evalregexp}  
		  onClick={this.handleSubmitTicketData}> 
		  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		  </span>
		  </a></div> 
	  </div>     
  </div>);

  // return (<div key={field.id} className="form-group"> 
  // 	  <Checkbox name={name} id={name} label={textLabel} color="primary" /> 		 
  // 	</div>);

  //<FormControl component="fieldset" className={classes.formControl}>
  /*return (<FormControl component="fieldset">
  <FormLabel component="legend">{textLabel}</FormLabel>
  <FormGroup>
	<FormControlLabel
	  control={<Checkbox  name="gilad" />}
	  label="Gilad Gray"
	/>
	<FormControlLabel
	  control={<Checkbox  name="jason" />}
	  label="Jason Killian"
	/>
	<FormControlLabel
	  control={<Checkbox  name="antoine" />}
	  label="Antoine Llorca"
	/>
  </FormGroup>
  <FormHelperText>Be careful</FormHelperText>
</FormControl>);*/

//FieldData={fieldOptions:fieldOptions, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices};

  return (<FormControl component="fieldset"  key={productField.id+"_ckb"}>
  <FormLabel component="legend">{textLabel}</FormLabel>
  <FormGroup>
  {fieldSet!==null && fieldSet.map((option, ind) => (
		  // <MenuItem key={option.value} value={option.value}>
		  //   {option.label}
		  // </MenuItem>
		  <FormControlLabel  key={productField.id+"_"+ind}
		  control={<Checkbox  name={name} value={option.value}  />}
		  label={option.text}
	  />
		))}
  {/* <FormControlLabel
	  control={<Checkbox  name="gilad" />}
	  label="Gilad Gray"
  />  */}
  </FormGroup>
  <FormHelperText>Be careful</FormHelperText>
  <span className="danger"><SaveOutlinedIcon htmlColor="#FF0000" /></span> 	
  </FormControl>);
}

 NeogenSelectBox = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData; 
  let keyTrackerTxt=""; 
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  let fieldSet=null; //field.field_dataset;
  const fieldOptions=FieldData.fieldOptions;
  const isMultiple=field.isMultiple; 
  //consoleXlog("aaaaoooooooooooooooohhhh 000", field.field_dataset);
  if(field.field_dataset!=="" && field.field_dataset!==null){
	  
	fieldSet= JSON.parse(field.field_dataset);
	if((field.field_dataset).indexOf("tag")>-1 && (field.field_dataset).indexOf("value")>-1){ 
		let newfieldSet=[];
		//consoleXlog("insiiiiiiiiiiiiiiiiiiiiiide");
		for (const element of fieldSet) {
			////consoleXlog(element);
			//consoleXlog("oooooooooooooooohhhh", element); 
			//consoleXlog("insiiiiiiiiiiiiiiiiiiiiiide ", field.name, element.value, element.tag);
			newfieldSet.push({label:element.value, value:element.tag});
 
		}
		fieldSet= newfieldSet;
	}
  }else if(fieldOptions!==null){
	  fieldSet=[];
	  //consoleXlog("aaaaoooooooooooooooohhhh");
	  //fieldSet= JSON.parse(field.field_dataset);
	  for (const element of fieldOptions) {
		  ////consoleXlog(element);
		  //consoleXlog("oooooooooooooooohhhh", element);
		  //const tag=
		  
		 fieldSet.push({text:element.text_label, value:element.id});
		//   if(element.tag!==undefined){
		// 	fieldSet.push({text:element.text_label, value:element.id});
		//   }else{
		// 	fieldSet.push({text:element.text_label, value:element.id});
		//   }
	  }

	  //consoleXlog("aaaaoooooooooooooooohhhh zz", fieldSet);
  }

  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false;  let fieldDataRawval="";
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >=0){ 
	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
	  fieldDataRawval=ticketDataLeft.field_data_raw; 
	  keyTrackerTxt="nftdl"+ticketDataLeft.last_update;   
	  if(isRequired && fieldDataval!==""){
	  isError=false;
	  }
  } 
   
  const fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 

  const elementFormsErrors=this.state.elementFormsErrors;
  //elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
  //const fieldDatavals=this.state.elementFormsErrors;
  //fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
  //this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
  error: isError, check:isRequired, options: []};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter genSelectBox ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;
  
  if(isMultiple){
	  return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
		  <div className="input-group">
			  <div className={this.colClassLeft}>
			  {/* <TextField name={name} id={name} select multiple={true} label={textLabel} fullWidth={true} 
			  inputProps={{'data-xindex': indexField}} defaultValue={fieldDataval}  
			  onChange={(e) => this.handleChange3(e, indexField)}  key={productField.id+"stf"}>
			  {fieldSet!==null && fieldSet.map((option, ind) => (
				  <MenuItem key={productField.id+"_"+ind} value={option.value} 
				  dataindex3={ indexField}>
				  {option.label}
				  </MenuItem> 
			  ))}  
			  </TextField> */}  

			  {/* handleChangeOptions  onChange={(e) => this.handleChange3(e, indexField)} */}

			{/* <label htmlFor={name}>{textLabel} </label> */}
			<label htmlFor={name}><span className={(dynamicForm["error"]===true)? 'fieldErrtxt' : 'blue'}>{textLabel}</span></label>
			<select name={name} id={name} multiple={true}  className={'form-control'} 
			data-index={indexField}  defaultValue={fieldDataRaw}  
			onChange={this.handleChangeOptionsmulti}   
			 key={productField.id+"snat"}> 
			  <option value=""> Select... </option>
			  {fieldSet!==null && fieldSet.map((option, ind) => (
				  <option  key={productField.id+"_"+ind} value={option.value} 
				  dataindex3={indexField}>{option.label}</option>  
			  ))}    
			</select> 

				  </div><div className={this.colClassRight}>
				  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
				  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
				  data-evalregexp={evalregexp}  
				  onClick={this.handleSubmitTicketData}> 
				  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
				  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
				  </span>
				  </a></div> 
			  </div>     
		  </div>);
 
  }else{ 
	 consoleXlog("################fieldDataval########33");
	 consoleXlog(ticketDataLeft, "################", fieldDataval); 
	 consoleXlog("################fieldDataval########33");   	
  if(fieldDataval===null){ fieldDataval=""; } 
  return (<div className="form-group" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
  <div className="input-group">
	  <div className={this.colClassLeft}>
	  <TextField name={name} id={name} select label={textLabel} fullWidth={true} 
	  inputProps={{'data-xindex': indexField}} defaultValue={fieldDataval}  
	  onChange={(e) => this.handleChange3(e, indexField)}  key={productField.id+"stf"}>
		{fieldSet!==null && fieldSet.map((option, ind) => (
		  <MenuItem key={productField.id+"_"+ind} value={option.value} 
		  dataindex3={ indexField}>
			{option.label}
		  </MenuItem> 
		))}  
	   </TextField> 	
		  </div><div className={this.colClassRight}>
		  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		  data-evalregexp={evalregexp}  
		  onClick={this.handleSubmitTicketData}> 
		  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		  </span>
		  </a></div> 
	  </div>     
  </div>);
  }
 
}

 NeogenSelectReactField = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;
//   console.log(field)  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  let keyTrackerTxt=""; 
  const dataRecordNeo=props.dataRecordNeo;
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  const selectType=field.selectType; 
  const extraconfig=field.extraconfig;
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false; let fieldDatavalLabel="";
  const indexField=FieldData.index;   let fieldDataRawval="";  
  let myListOptions=[]; let myListOptions2=[]; 
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	  tickeId=ticketDataLeft.id; 
	  if(ticketDataLeft.field_data!==null && ticketDataLeft.field_data!=="")
	  	fieldDataval=ticketDataLeft.field_data; 

	  if(ticketDataLeft.field_data_raw!==null && ticketDataLeft.field_data_raw!=="" && ticketDataLeft.field_data_raw.length>0)	  
	  	fieldDataRawval=ticketDataLeft.field_data_raw; 

	  keyTrackerTxt="nftdl"+ticketDataLeft.last_update;   
	  if(isRequired && fieldDataval!==""){
		isError=false;
	  }
	  //const fieldDatavalraw=ticketDataLeft.field_data; 
  }

  let valueNextStepgost=props.valueNextStepgost;  
  //let fieldDataRaw=[];

  // let fieldDataRaw=fieldDataRawval===""?[]:JSON.parse(fieldDataRawval); 
  let fieldDataRaw=(fieldDataRawval==="" || fieldDataRawval===null)?[]:JSON.parse(fieldDataRawval); 
  let tokadm = "";

  let fieldSet=null; //field.field_dataset;
  const fieldOptions=FieldData.fieldOptions;
  //consoleXlog("aaaaoooooooooooooooohhhh 000", field.field_dataset);
  if(dataRecordNeo!=undefined && dataRecordNeo!=null && dataRecordNeo.id!==undefined){
	const myListOptionsx= JSON.parse(dataRecordNeo.field_data_raw); 
	fieldDataRaw=myListOptionsx; 
	/*for(let optionObj of myListOptionsx){
	  if(optionObj.label!==undefined && optionObj.value!==undefined){
		  myListOptions.push({label: optionObj.label, value:optionObj.value});
	  }else{
		  myListOptions.push({label: textLabel, value:optionObj});
	  }
	}*/
  }
  else if(field.field_dataset!=="" && field.field_dataset!==null){
	  //fieldSet= JSON.parse(field.field_dataset);
	  //myListOptions= JSON.parse(field.field_dataset);
	  if(extraconfig!==null && extraconfig==="caseMunicipality"){
		const myListOptions00= JSON.parse(field.field_dataset);
		myListOptions.push({ id: "", value: "", label: "Aucune ville/No city...." });
		for (const element of myListOptions00) {  
			if(element.id!==undefined && element.title!==undefined && element.municipality!==undefined && element.municipality.length>0){
				//myListOptions2.push({ value: element.id, label: element.text_label });
				for (const element2 of element.municipality){
					myListOptions.push({ id: element.id, value: element2, label: element.title+" ---> "+element2 });
				}    
			}

			// else if(element.value!==undefined && element.label!==undefined){
			// 	myListOptions2.push({ value: element.value, label: element.label });  
			// }			
		}

	  }else{
		myListOptions= JSON.parse(field.field_dataset);
	  }
  }else if(fieldOptions!==null){
	  fieldSet=[];
	  //consoleXlog("aaaaoooooooooooooooohhhh");
	  //fieldSet= JSON.parse(field.field_dataset);
	  for (const element of fieldOptions) { 
		  //consoleXlog("oooooooooooooooohhhh", element);
		  //fieldSet.push({text:element.text_label, value:element.id});
		  myListOptions.push({ value: element.id, label: element.text_label });
	  }
	  //orderOptions([colourOptions[0], colourOptions[1], colourOptions[3]])

	  //consoleXlog("aaaaoooooooooooooooohhhh zz", fieldSet);
  }

  let fieldDataRawNext=[];
  const valueNextStep=this.state.valueNextStep;
  if(selectType===3){
	  for (const element of fieldDataRaw) {
		  fieldDataRawNext.push({ value: element.value, label: element.label, isFixed:true });
	  } 
	  valueNextStep[indexField]= fieldDataRawNext;
	  //this.setState({ ["valueNextStep"]: fieldDataRawNext });
	  //this.setState({ ["valueNextStep"]: valueNextStep });
	  //this.setState({ ["valueNextStep"]: valueNextStep, ["valueNextStepgost"]: fieldDataRawNext });
	  //fieldDataRaw  
  }

  for (const element of myListOptions) {  
	  if(element.id!==undefined && element.text_label!==undefined){
		  myListOptions2.push({ value: element.id, label: element.text_label });   
	  }else if(element.value!==undefined && element.label!==undefined){
		  myListOptions2.push({ value: element.value, label: element.label });  
	  }
	  //myListOptions2.push({ value: element.id, label: element.text_label });
	  if(element.value===fieldDataval){
		  fieldDatavalLabel=element.label;
		  //break;
	  }
  }

  if(selectType===3){
	  if(myListOptions2!==null){
		for (let k=0; k<myListOptions2.length; k++) {
			//fieldDataRawNext.push({ value: element.value, label: element.label, isFixed:true });
			//if(myListOptions2[k].value===fieldDataRawNext.includes(myListOptions2[k].value)) 
			if(fieldDataRawNext.includes(myListOptions2[k].value)){
				//myListOptions2[k]["isFixed"]=true;
				myListOptions2[k]["isFixed"]=false;
				//myListOptions2[k]["key"]="kx"+indexField;
			}else{ myListOptions2[k]["isFixed"]=false;   } 
		}
	  }
	  
	  if(valueNextStepgost!==null){ 
		for (let k=0; k<valueNextStepgost.length; k++) {
			console.log("TEEEEEEEEEEEEEEEEEEEEEEST000 type=", selectType, "field_id=",productField.field_id, valueNextStepgost[k]); 
			//valueNextStepgost[k]["isFixed"]=true;
			//valueNextStepgost[k]["isFixed"]=false;
			if(valueNextStepgost[k]["isFixed"]===undefined){
				valueNextStepgost[k]["isFixed"]=false;
			} 		 
		}
	  }

	  //fieldDataRaw  myListOptionsWellPOZO
	  //this.setState({ ["valueNextStepall"]: myListOptions2 });
	  this.valueNextStepall[indexField]=myListOptions2;
  }

  //consoleXlog("AAAAAAAAAAAAAAAAAAAA", selectType, myListOptions2); 
  
  const elementFormsErrors=this.state.elementFormsErrors;
  //elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
  //const fieldDatavals=this.state.elementFormsErrors;
  //fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
  //this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: fieldDataRaw}; //fieldDataRaw
	  
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter genSelectReactField genSelectReactField ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;

//inputProps={{placeholder: 'Input 1', 'data-state': 'Data State 1'}}
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;

  console.log("TEEEEEEEEEEEEEEEEEEEEEEST selectType valueNextStepgost type=", selectType, 
  "field_id=",productField.field_id, valueNextStepgost, fieldDataRaw); 
if (name === "idsclo"){
	const valueNextStep=valueNextStepgost; 
	const rawData = props?.FieldData?.ticketDataLeft?.field_data_raw;
	const values = (typeof rawData === 'string' && rawData.trim() !== '') ? 
    	JSON.parse(rawData)?.map(i => (i?.value)) || [] : [];

	const options = myListOptions?.map(i => ({label: i?.label ?? "", value: i?.id_users ?? ""}))
	return (<div className="form-group" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	<div className="input-group">
	<div className={this.colClassLeft}>
	 <label htmlFor={name}><span className={(dynamicForm["error"]===true)? 'fieldErrtxt' : 'blue'}>{textLabel}</span></label>
	  <Select name={name} options={options}
	  isMulti
	  defaultValue={options?.filter(i => values.includes(i.value) )}    
	  error={(dynamicForm["error"]===true)?true:false} 
	  data-index={indexField}    
	  onChange={(e) => this.handleChangeIdsClo(e, name, indexField)}  key={productField.id+"rsl"} />

	  </div><div className={this.colClassRight}>
	  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	   data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	   data-evalregexp={evalregexp}  
	  onClick={this.handleSubmitTicketData}> 
	  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
	  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
	  </span>
	  </a></div> 
	</div>     
</div>)
}
	else if(selectType===3){
	  //myListOptions2
	  //const valueNextStep=this.state.valueNextStep[indexField];
	  const valueNextStep=valueNextStepgost; 
	  //const mood=Date.now(); 
	  const mood=props.mood; 
	  const tokadmx = localStorage.tokadmndi; 
	  if(tokadmx!==undefined && tokadmx!==null && tokadmx!==""){
		tokadm=tokadmx;  
	  }
	   
	  //const valueNextStep=this.state.valueNextStep;
	  return (<div className="form-group" key={productField.id+"tdv"+indexField+mood} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group" key={productField.id+"tdv2"+indexField+mood}>
	  <div className={this.colClassLeft} key={productField.id+"tdv3"+indexField+mood} 
	  data-index={indexField} data-name={name} onClick={this.setCurrentIndexStack}>
	   
	   {/* <label htmlFor={name}>{textLabel}</label> */}
	   <label htmlFor={name}><span className={(dynamicForm["error"]===true)? 'fieldErrtxt' : 'blue'}>{textLabel}</span></label>

		<div className="form-row"> 
            <div className={tokadm==="3"?"col-11":"col-12"} data-index={indexField} data-name={name} onMouseOver={this.setCurrentIndexStack} >
	<Select  
	  value={valueNextStepgost} isMulti styles={styles} isClearable={false}  
	  error={(dynamicForm["error"]===true)?true:false} data-index={indexField}    
	  name={name} className="basic-multi-select" classNamePrefix="select"
	  onChange={this.onChangeStatutNextStep} options={this.valueNextStepall[indexField]} 
		defaultMenuIsOpen={this.state.stackMenuIsOpen} 
	  
		/>
		</div>
		{tokadm ==="3"?
		<div className="col-1"> 
		<IconButton edge="end" aria-label="edit label"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		data-productfieldid={productfieldid} data-fieldid={fieldid} data-fieldname={name} 
		data-required={isRequired} data-delete={"addFollowup"} data-filelabel={""} 
		data-evalregexp={""} data-deletefile={"0"}  
		data-labelnodel={0} data-indexnextstep={indexField}    
			onClick={this.handleOpenFilePopup}>
			<AddIcon htmlColor="#000000" />
		</IconButton>
		</div>:''}
		</div>

	   {/* <input type="text" id="fname" name="fname" defaultValue={mood} /> */}
		</div><div className={this.colClassRight}>
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-indexnextstep={indexField} onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a></div> 
	  </div>     
  </div>);
	}else{
		if(fieldDataval===null){
			fieldDataval="";  
		}
		console.log(field)
	return (<div className="form-group" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	<div className="input-group">
	<div className={this.colClassLeft}>
	{/* <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
	  error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
	  inputProps={{'data-index': indexField}}
	  onChange={this.handleChange2} onBlur={this.handleFocusout} /> [{value:fieldDataval}] */}
	 
	 {/* <label htmlFor={name}>{textLabel}</label> */}
	 <label htmlFor={name}><span className={(dynamicForm["error"]===true)? 'fieldErrtxt' : 'blue'}>{textLabel}</span></label>
	  <Select name={name} options={myListOptions2}  
	  defaultValue={{value:fieldDataval, label:fieldDatavalLabel}}    
	  error={(dynamicForm["error"]===true)?true:false} 
	  data-index={indexField}    
	  onChange={(e) => this.handleChangeRSelect(e, name, indexField)}  key={productField.id+"rsl"} />

	  </div><div className={this.colClassRight}>
	  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	   data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	   data-evalregexp={evalregexp}  
	  onClick={this.handleSubmitTicketData}> 
	  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
	  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
	  </span>
	  </a></div> 
	</div>     
</div>);
 }
}
 

 NeogenFileField = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  const dataRecordNeo=props.dataRecordNeo;
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  const fieldDataset=field.field_dataset;
  const textMultiple=field.mask;
  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  let myListOptions=[]; 
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false;
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
	  if(isRequired && fieldDataval!==""){
		isError=false;
	  }
  }

  /////////////////////////////////////////////////////////////
  
  let fieldSet=null; //field.field_dataset; 
  //consoleXlog("Gooooooooooooooooooohhhh 000///000000 ", ticketDataLeft);
  ////consoleXlog("aaaaoooooooooooooooohhhh 000///000000 ", ticketDataLeft.field_data_raw);
  // if(field.field_dataset!=="" && field.field_dataset!==null){
  // 	//fieldSet= JSON.parse(field.field_dataset);
  // 	myListOptions= JSON.parse(field.field_dataset);
  // }

  /*if(ticketDataLeft!==null && ticketDataLeft.field_data_raw!=="" && field.field_data_raw!==null){
	  //fieldSet= JSON.parse(field.field_dataset);
	  //myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	  const myListOptionsx= JSON.parse(ticketDataLeft.field_data_raw);
	  //{option.value}
	  for(let optionObj of myListOptionsx){
		if(optionObj.label!==undefined && optionObj.value!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value});
		}else{
			myListOptions.push({label: textLabel, value:optionObj});
		}
	  }
  }*/

  if(dataRecordNeo!=null && dataRecordNeo.id!==undefined){
	const myListOptionsx= JSON.parse(dataRecordNeo.field_data_raw); 
	for(let optionObj of myListOptionsx){
		let bucketTxt="";
		if(optionObj.bucket!==undefined && optionObj.bucket!==null){
			bucketTxt=""+optionObj.bucket;
		}

		if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.labelnodel!==undefined && optionObj.listfiles!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value, labelnodel: optionObj.labelnodel, listfiles:optionObj.listfiles
				, bucket:bucketTxt});
		}else if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.labelnodel!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value, labelnodel: optionObj.labelnodel	, bucket:bucketTxt});
		}
	  else if(optionObj.label!==undefined && optionObj.value!==undefined){
		  myListOptions.push({label: optionObj.label, value:optionObj.value	, bucket:bucketTxt});
	  }else{
		  myListOptions.push({label: textLabel, value:optionObj	, bucket:bucketTxt});
	  }
	}
  }
  /*else if(fieldDataset!==null && fieldDataset!==""){
	//fieldSet= JSON.parse(field.field_dataset);
	//myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	  
	 // if(field.field_dataset!=="" && field.field_dataset!==null){
  
	const myListOptionsx= JSON.parse(fieldDataset);
	//{option.value}
	for(let optionObj of myListOptionsx){
	  if(optionObj.label!==undefined && optionObj.value!==undefined){
		  myListOptions.push({label: optionObj.label, value:optionObj.value, labelnodel: optionObj.labelnodel});
	  }else{
		  //myListOptions.push({label: textLabel, value:optionObj});
	  }
	}
}*/
else if(ticketDataLeft!==null && ticketDataLeft.field_data_raw!=="" && ticketDataLeft.field_data_raw!==null){
	//fieldSet= JSON.parse(field.field_dataset);
	//myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	const myListOptionsx= JSON.parse(ticketDataLeft.field_data_raw); 
	//consoleXlog("Weeeeeeeeeeeeeeeeeeah", ticketDataLeft.field_data_raw, myListOptionsx); 
	for(let optionObj of myListOptionsx){
		let bucketTxt="";
		if(optionObj.bucket!==undefined && optionObj.bucket!==null){
			bucketTxt=""+optionObj.bucket;
		}
		if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.labelnodel!==undefined && optionObj.listfiles!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value, labelnodel: optionObj.labelnodel, listfiles:optionObj.listfiles
				, bucket:bucketTxt});
		}else if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.labelnodel!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value, labelnodel: optionObj.labelnodel	, bucket:bucketTxt});
		}
		else if(optionObj.label!==undefined && optionObj.value!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value, bucket:bucketTxt});
		}else{
			myListOptions.push({label: textLabel, value:optionObj, bucket:bucketTxt});
		}
	}
}
else if(fieldDataset!==null && fieldDataset!==""){
	//fieldSet= JSON.parse(field.field_dataset);
	//myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	  
	 // if(field.field_dataset!=="" && field.field_dataset!==null){
  
	const myListOptionsx= JSON.parse(fieldDataset);
	//{option.value}
	for(let optionObj of myListOptionsx){
		let bucketTxt="";
		if(optionObj.bucket!==undefined && optionObj.bucket!==null){
			bucketTxt=""+optionObj.bucket;
		}
	  if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.listfiles!==undefined){
		  myListOptions.push({label: optionObj.label, value:optionObj.value, labelnodel: optionObj.labelnodel, listfiles:optionObj.listfiles
			, bucket:bucketTxt});
	  }else if(optionObj.label!==undefined && optionObj.value!==undefined){
		myListOptions.push({label: optionObj.label, value:optionObj.value, labelnodel: optionObj.labelnodel	, bucket:bucketTxt});
	  }else{
		  //myListOptions.push({label: textLabel, value:optionObj});
	  }
	}
}

	consoleXlog("Label", textLabel, "textMultiple", textMultiple, myListOptions); 

  /////////////////////////////////////////////////////////////

  const elementFormsErrors=this.state.elementFormsErrors;
  //elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
  //const fieldDatavals=this.state.elementFormsErrors;
  //fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
  //this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: []};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter gentxtfield ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;  xs-12 md-6
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;
 	  
	return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	<div className="input-group lstfileblck">
	<div className={this.colClassLeft2}>

	<div className="neoTopLbll">
	{/* <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
	  error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
	  inputProps={{'data-index': indexField}}
	  onChange={this.handleChange2} onBlur={this.handleFocusout} /> ["contained","outlined","text"] */}
	  
	  {/* <span className="form-group">{textLabel}</span>   */}
	  <label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp; 
	   {/* <Button variant="outlined" component="span" className="btn btn-warning">{myListOptions.length >0?"Changer Fichier":"Upload"}</Button>  */}
	   
		{/* <span className="form-group">download file</span> */}
		{/* {myListOptions.length >0? <span className="form-group">download file</span>:"cx"} */}
	  </label>
	  </div> 

	  <div className="neoTopLblr">
	  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	   data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	   data-evalregexp={evalregexp}  
	   data-fileshere={1}  onClick={this.handleSubmitTicketData}> 
	  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
	  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
	  </span>
	  </a>

	  <IconButton edge="end" aria-label="downloadAll"  data-fileaddress={tickeId+"_allFiles.zip"} 
			data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
			data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
			data-filesheredown={1} data-labelnodel={0} data-filedownloadall={"allFiles.zip"}
			// data-bucket={(option.bucket!==undefined && option.bucket!==null)?option.bucket:""} 
			onClick={this.handleDownloadFile}>
				<DownloadIcon />
				{/* <CheckIcon style={{ color: "green", fontWeight:"bolder" }} />						 */}
		</IconButton>

		<IconButton edge="end" aria-label="Send Email To require Doc"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
			data-productfieldid={productfieldid} data-fieldid={fieldid} 
			data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={"noLabel"} 
			data-evalregexp={""} data-deletefile={""}  
			data-labelnodel={0} data-senddemandingfile={1}    
				onClick={this.handleOpenFilePopupAll} className={"d-inline"}>
				<EmailIcon />
		</IconButton> 
	  </div> 

	  <Grid item xs={12} md={12}> 
		<div>
			<List dense={true}>
	  {myListOptions!==null && myListOptions.map((option, ind) => ( 
		  <div key={"ooofilx"+ind}> 
			<ListItem key={"filx"+ind} className="lstfilesep">
			  {/* <div className="neoxblck">  */}
				<div className="md-9">
				<ListItemAvatar>
					<Avatar>
					<FolderIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={(ind+1)+".- "+option.label}
					// secondary={secondary ? 'Secondary text' : null}
				/> 
				</div><div className="md-3">
				<ListItemSecondaryAction>
					{/* {(option.value!==null && option.value!=="" && 
					(option.listfiles===undefined || (option.listfiles!==undefined && option.listfiles.length==0)))?
					<>
					<IconButton edge="end" aria-label="delete"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"delete"} data-filelabel={option.label}
					data-evalregexp={""} data-deletefile={option.value}  
					data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}   
					   onClick={this.handleOpenFilePopup}>
						<DeleteIcon />
					</IconButton> &nbsp; &nbsp; 
					<IconButton edge="end" aria-label="edit label"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"editlabel"} data-filelabel={option.label} 
					data-evalregexp={""} data-deletefile={option.value}  
					data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}    
					   onClick={this.handleOpenFilePopup}>
						<SpellcheckIcon />
					</IconButton> &nbsp; &nbsp; 
					<IconButton edge="end" aria-label="download"  data-fileaddress={option.value} 
					data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
					data-filesheredown={1} data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} 
					onClick={this.handleDownloadFile}>
						<DownloadIcon />
						<CheckIcon style={{ color: "green", fontWeight:"bolder" }} />						
					</IconButton>  &nbsp; &nbsp;</>:'' 
					}					 */}

				{((option.value!==null && option.value!=="") ||  
					(option.listfiles!==undefined && option.listfiles.length >0))?
					<>
					<IconButton edge="end" aria-label="edit label"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"editlabel"} data-filelabel={option.label} 
					data-evalregexp={""} data-deletefile={option.value}  
					data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}    
					   onClick={this.handleOpenFilePopup}>
						<SpellcheckIcon />
					</IconButton> &nbsp; &nbsp; 

					{((option.value!==null && option.value!==""))?
					<IconButton edge="end" aria-label="delete"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"delete"} data-filelabel={option.label}
					data-evalregexp={""} data-deletefile={option.value}  
					data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}   
					   onClick={this.handleOpenFilePopup}>
						<DeleteIcon />
					</IconButton>:''
					}
					 &nbsp; &nbsp; 
					{((option.value!==null && option.value!==""))? 
						<>
					<IconButton edge="end" aria-label="download"  data-fileaddress={option.value} 
					data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
					data-filesheredown={1} data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} 
					data-bucket={(option.bucket!==undefined && option.bucket!==null)?option.bucket:""} 
					onClick={this.handleDownloadFile}>
						<DownloadIcon />
						<CheckIcon style={{ color: "green", fontWeight:"bolder" }} />						
					</IconButton> 
					<IconButton edge="end" aria-label="preview file"  data-fileaddress={option.value} 
					data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
					data-filesheredown={1} data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} 
					data-bucket={(option.bucket!==undefined && option.bucket!==null)?option.bucket:""} 
					onClick={this.handleOpenFilePreviewPDF}>
						<PreviewIcon /> 						  
					</IconButton> 
					<IconButton edge="end" aria-label="Send Link To require or read Doc"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={option.label} 
					data-evalregexp={""} data-deletefile={option.value}  
					data-fileaddress={option.value} 
					data-labelnodel={option.labelnodel} data-senddemandingfile={1}    
					   onClick={this.gotoSendPublicFormFile} className={"d-inline"}>
						<TaskIcon />
					</IconButton>
						</>:''
					}
					  
					&nbsp; &nbsp;
					
					</>:'' 
				}



					{(option.labelnodel!==undefined && (option.value===null || option.value==="") &&
					(option.listfiles===undefined || (option.listfiles!==undefined && option.listfiles.length==0)))?
					<>
					{this.clientOnZendeskSCR===0?
					<>
					{/* <input 
					accept="/*" className="d-inline"   onChange={this.handleChangeFile} 
					name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={option.labelnodel}    
					/>  */}

					{textMultiple==="multiple"?
						<input accept="*/*" className="form-group" multiple  onChange={this.handleChangeFile} 
						name={name} id={name}  type="file"  data-index={indexField}   data-multiple={indexField}     
						data-labelnodel={option.labelnodel} />:
						<input 
					accept="*/*" className="d-inline"   onChange={this.handleChangeFile} 
					name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={option.labelnodel}    
					/>
					}	  
 
					<IconButton edge="end" aria-label="Send Email To require Doc"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={option.label} 
					data-evalregexp={""} data-deletefile={option.value}  
					data-labelnodel={option.labelnodel} data-senddemandingfile={1}    
					   onClick={this.handleOpenFilePopup} className={"d-inline"}>
						<EmailIcon />
					</IconButton> 
					{/* <IconButton edge="end" aria-label="Send Link To require or read Doc"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={option.label} 
					data-evalregexp={""} data-deletefile={option.value}  
					data-labelnodel={option.labelnodel} data-senddemandingfile={1}    
					   onClick={this.gotoSendPublicFormFile} className={"d-inline"}>
						<TaskIcon />
					</IconButton> */}
					</>:
					<div  className="minZnd form-group">
					<label className="minZnd-file d-inline">{t("zqwNFFtxt13_x")} 
					{textMultiple==="multiple"?
						<input accept="*/*" className="form-group" multiple  onChange={this.handleChangeFile} 
						name={name} id={name}  type="file"  data-index={indexField}   data-multiple={indexField}     
						data-labelnodel={option.labelnodel} />:
						<input 
					accept="*/*" className="d-inline"   onChange={this.handleChangeFile} 
					name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={option.labelnodel}    
					/>
					}	  
					{/* <input 
					accept="/*" className="d-inline"   onChange={this.handleChangeFile} 
					name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={option.labelnodel}    
					/>  */}
					</label>
					<span className="d-inline"> 
					<IconButton edge="end" aria-label="Send Email To require Doc"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={option.label} 
					data-evalregexp={""} data-deletefile={option.value}  
					data-labelnodel={option.labelnodel} data-senddemandingfile={1}    
					   onClick={this.handleOpenFilePopup} className={"d-inline"}>
						<EmailIcon />
					</IconButton></span>
					</div>
					}
					</>:''}

				</ListItemSecondaryAction>
						 
				 
				</div>  

			    			   
			</ListItem> 

			{option.listfiles!==undefined && option.listfiles.length >0 && option.listfiles.map((suboption, subind) => (
				<ListItem key={"filx00s1"+ind+subind} className={option.listfiles.length===(subind+1)?"lstfilesepx":"lstfilesepx"}> 
					<p className="floatrtxt">
					<span>Doc #{(subind+1)}</span> 
					&nbsp; &nbsp;	
					<IconButton edge="end" aria-label="delete"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"delete"} data-filelabel={"Doc #"+(subind+1)}
					data-evalregexp={""} data-deletefile={suboption.value} data-subid={suboption.subid} 
					data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}   
					   onClick={this.handleOpenFilePopup}>
						<DeleteIcon />
					</IconButton> &nbsp; &nbsp; 					 
					<IconButton edge="end" aria-label="download"  data-fileaddress={suboption.value} 
					data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
					data-filesheredown={1} data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} 
					data-subid={suboption.subid}  
					data-bucket={(option.bucket!==undefined && option.bucket!==null)?option.bucket:""} 
					onClick={this.handleDownloadFile}>
						<DownloadIcon />
						<CheckIcon style={{ color: "green", fontWeight:"bolder" }} />						
					</IconButton>
					<IconButton edge="end" aria-label="preview file"  data-fileaddress={suboption.value} 
					data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
					data-filesheredown={1} data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} 
					data-subid={suboption.subid}  
					data-bucket={(option.bucket!==undefined && option.bucket!==null)?option.bucket:""} 
					onClick={this.handleOpenFilePreviewPDF}>
						<PreviewIcon /> 						
					</IconButton>     
					<IconButton edge="end" aria-label="Send Link To require or read Doc"  data-id={tickeId} 
					data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={option.label} 
					data-evalregexp={""} data-deletefile={suboption.value}  
					data-fileaddress={suboption.value} 
					data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} data-senddemandingfile={1}    
					   onClick={this.gotoSendPublicFormFile} className={"d-inline"}>
						<TaskIcon />
					</IconButton>
					</p> 
				</ListItem>
			))}

			{/* {(option.listfiles!==undefined && option.listfiles.length >0)?   
					<>
					 
					</>:'' 
			} */}

			{/* <ListItem key={"filx001"+ind} className="lstfilesepx"> 
				<p className="floatrtxt">kalaloooooooooooooo #1 #2</p> 
			</ListItem>
			<ListItem key={"filx002"+ind} className="lstfilesepx"> 
				<p className="floatrtxt">kalaloooooooooooooo #2</p> 
			</ListItem>
			<ListItem key={"filx003"+ind} className="lstfilesepx"> 
				<p className="floatrtxt">kalaloooooooooooooo #3 #2</p> 
			</ListItem>
			<ListItem key={"filx004"+ind} className="lstfilesepox"> 
				<p className="floatrtxt">kalaloooooooooooooo #4 #2</p> 
			</ListItem> */}
			</div>
		))}
			</List>
		  </div>
		</Grid>	

	  
	  {/* <input
		  accept="**" className="form-group" style={{ display: 'none' }} 
		  name={name} id={name}  type="file"  data-index={indexField}  
		  /> */}
	  <br />

		{/* <input 
		  accept="/" className="form-group"   onChange={this.handleChangeFile} 
		  name={name} id={name}  type="file"  data-index={indexField}    
		  /> */}

	{/* {textMultiple==="multiple"?
		<input 
		accept="/" className="form-group" multiple  onChange={this.handleChangeFile} 
		name={name} id={name}  type="file"  data-index={indexField}   data-multiple={indexField}     
		/>:<input 
		accept="/" className="form-group"   onChange={this.handleChangeFile} 
		name={name} id={name}  type="file"  data-index={indexField}    
		/>
	}	 */}

	{(textMultiple==="multiple"  && (fieldDataset===null || fieldDataset===""))?
		<input accept="*/*" className="form-group" multiple  onChange={this.handleChangeFile} 
		name={name} id={name}  type="file"  data-index={indexField}   data-multiple={indexField}     
		data-labelnodel={0} />:''
	}	    
   
	{(textMultiple!=="multiple" && myListOptions.length ==0)?
		<input 
		accept="*/*" className="form-group"   onChange={this.handleChangeFile} 
		name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={0}    
		/>:''
	}

<div className="form-row"> 
	<div className="form-group" >
		<label htmlFor="country">{t("zqwNFFtxt1_x")}</label>
		<div className="input-group">
			<select name="movingIndexFrom" id="movingIndexFrom" value={this.state.movingIndexFrom} onChange={this.handleChange} onBlur={this.handleFocusout}
				className='form-control' >
				<option value="-1">{t("zqwNFFtxt2_x")}</option>  
				{myListOptions!==null && myListOptions.map((option, ind) => (  
					<option  key={"sfrmf"+ind} value={ind}>{t("zqwNFFtxt4_x")} {(ind+1)}</option> 
				))}
			</select>
			<select name="movingIndexTo" id="movingIndexTo" value={this.state.movingIndexTo} onChange={this.handleChange} onBlur={this.handleFocusout}
				className='form-control' >
				<option value="-1">{t("zqwNFFtxt2_x")}</option> 
				{myListOptions!==null && myListOptions.map((option, ind) => (  
					<option key={"sfrmt"+ind}  value={ind}>{t("zqwNFFtxt5_x")} {(ind+1)}</option> 
				))}
			</select>			 
<button type="button" className="btn btn-primary" 
 data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	   data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	   data-evalregexp={""}  data-movingxtoy={"movingxtoy"}   
	     onClick={this.handleSubmitTicketData}> {t("zqwNFFtxt3_x")}
	  </button>

        </div></div>
</div>

	{/* {t('zqwcountry_x')} <select name="movingIndexFrom" id="movingIndexFrom" value={this.state.movingIndexFrom} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className='form-control' >
                        <option value="-1">Aucun/None</option>
                        <option value="BC">British Columbia</option> 
                      </select>
	<select name="movingIndexTo" id="movingIndexTo" value={this.state.movingIndexTo} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className='form-control' >
                        <option value="-1">Aucun/None</option>
                        <option value="BC">British Columbia</option> 
                      </select>				   */}

	{name.indexOf("_labelnodel")> -1?
		// <IconButton edge="end" aria-label="Add MoreDoc To require"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		// data-productfieldid={productfieldid} data-fieldid={fieldid} 
		// data-required={isRequired} data-delete={"reqMoreDoc"} data-filelabel={""} 
		// data-evalregexp={""} data-deletefile={""}  
		// data-labelnodel={7777777}    
		//    onClick={this.handleOpenFilePopup}>
		// 	<FolderIcon /> Décrire Fichier/Describe File
		// </IconButton> :''
		 <button type="button" className="btn btn-primary" 
		 data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		data-productfieldid={productfieldid} data-fieldid={fieldid} 
		data-required={isRequired} data-delete={"reqMoreDoc"} data-filelabel={""} 
		data-evalregexp={""} data-deletefile={""}  
		data-labelnodel={7777777}    
		 onClick={this.handleOpenFilePopup}> &nbsp; + &nbsp; </button>:'' 
	}	  

	  {/* {myListOptions.length >0? <span className="form-group"> Fichier existant 
	  <Button variant="outlined" component="span" className="btn btn-success" data-fileaddress={myListOptions[0]} 
	  data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	  data-filesheredown={1}  onClick={this.handleDownloadFile}>download</Button></span>:""}	 */}

	  </div>
	  {/* <div className={this.colClassRight2}>
	  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	   data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	   data-evalregexp={evalregexp}  
	   data-fileshere={1}  onClick={this.handleSubmitTicketData}> 
	  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
	  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
	  </span>
	  </a>

	  <IconButton edge="end" aria-label="downloadAll"  data-fileaddress={tickeId+"_allFiles.zip"} 
			data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
			data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
			data-filesheredown={1} data-labelnodel={0} data-filedownloadall={"allFiles.zip"}
			// data-bucket={(option.bucket!==undefined && option.bucket!==null)?option.bucket:""} 
			onClick={this.handleDownloadFile}>
				<DownloadIcon />
			// <CheckIcon style={{ color: "green", fontWeight:"bolder" }} />						 
		</IconButton>

		<IconButton edge="end" aria-label="Send Email To require Doc"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
			data-productfieldid={productfieldid} data-fieldid={fieldid} 
			data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={"noLabel"} 
			data-evalregexp={""} data-deletefile={""}  
			data-labelnodel={0} data-senddemandingfile={1}    
				onClick={this.handleOpenFilePopupAll} className={"d-inline"}>
				<EmailIcon />
		</IconButton> 
	  </div>  */}
	</div>     
</div>);
}


 NeogenAnyGenericTextField = (props) => {
  const { t } = this.props;
  const field=props.fieldBlock;  
  const productField=props.productField; 
  const FieldData=props.FieldData;
  let keyTrackerTxt=""; 
  const name=(""+field.name).replace(" ", "");
  const textLabel=field.text_label;
  let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
  let isRequired=false;
  const inputType=field.inputType;
  const placeholder=field.placeholder;
  const ticketDataLeft=FieldData.ticketDataLeft;
  let fieldDataval=""; let isError=false;
  const indexField=FieldData.index;
  if(productField.id >0){ productfieldid=productField.id; }
  if(productField.field_id >0){ fieldid=productField.field_id; }
  if(productField.required >0){ isRequired=true; isError=true;} 
  if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
  if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
	  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
	  keyTrackerTxt="agtftdl"+ticketDataLeft.last_update;   
	  if(isRequired && fieldDataval!==""){
		isError=false;
	  }
	} 
  const elementFormsErrors=this.state.elementFormsErrors;
  elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
  const fieldDatavals=this.state.elementFormsErrors;
  fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
  //this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  

  const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	error: isError, check:isRequired, options: []};
  //this.handleDynamicStateSetter(myObjSetter); 
  this.dynamicFormRefs[indexField]=React.createRef();
  //consoleXlog("addSetter gentxtfield ", myObjSetter); 
  //check: true, error: true
  //const dynamicForm=this.state.dynamicForm;
  const dynamicForm=props.dynamicForm;
  const evalregexp=field.eval_regexp;

	
	return (<div className="form-group" key={productField.id+keyTrackerTxt} ref={this.dynamicFormRefs[indexField]}>
	<div className="input-group">
	<div className={this.colClassLeft}>
	{/* <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
	  error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
	  inputProps={{'data-index': indexField}}
	  onChange={this.handleChange2} onBlur={this.handleFocusout} /> */}

	{/* <label htmlFor={name}>{textLabel}</label> */}
	<label htmlFor={name}><span className={(dynamicForm["error"]===true)? 'fieldErrtxt' : 'blue'}>{textLabel}</span></label>
	<input type={inputType} name={name} id={name} defaultValue={fieldDataval} 
	onChange={this.handleChange2} onBlur={this.handleFocusout}
	  className={(isRequired && fieldDataval==="")? 'form-control fieldErr' : 'form-control'} 
	  data-index={indexField} placeholder={placeholder} /> 
	  <CopyIconButton text={fieldDataval ?? ""} roundedInput/>
	  </div><div className={this.colClassRight}>
	  <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
	   data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
	   data-evalregexp={evalregexp}  
	  onClick={this.handleSubmitTicketData}> 
	  <span className="danger neosavehover">{(isRequired && fieldDataval==="")?
	  <SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
	  </span>
	  </a></div> 
	</div>     
</div>);
}


NeogenBlockField = (props) => {
	const { t } = this.props;
	const field=props.fieldBlock;  
	const productField=props.productField; 
	const FieldData=props.FieldData;
	const dataRecordNeo=props.dataRecordNeo;
	const name=(""+field.name).replace(" ", ""); 
	const textLabel=field.text_label;
	const fieldDataset=field.field_dataset;
	const textMultiple=field.mask;
	const extraconfig=field.extraconfig;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	let myListOptions=[]; let myBigListOptions=[]; 
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft?.field_data; 
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
	}
  
	/////////////////////////////////////////////////////////////
	
	let fieldSet=null; //field.field_dataset; 
	  
	if(dataRecordNeo!=null && dataRecordNeo.id!==undefined){
	  const myListOptionsx= JSON.parse(dataRecordNeo.field_data_raw); 
	  let chkCont=0, chkCont2=0;
	  for(let optionObj of myListOptionsx){
		 if(Array.isArray(optionObj)){
			chkCont++;
			myBigListOptions.push(optionObj);
		 } 
		else if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.labelnodel!==undefined){
			chkCont2++;
		}
		/*  if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.labelnodel!==undefined){
			  myListOptions.push({label: optionObj.label, value:optionObj.value, 
				labelnodel: optionObj.labelnodel, modelfieldid:optionObj.modelfieldid});
		  }
		else if(optionObj.label!==undefined && optionObj.value!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value});
		}else{
			myListOptions.push({label: textLabel, value:optionObj});
		}*/
	  }

	  if(chkCont2 >0){ myBigListOptions.push([myListOptionsx]); }
	}
	/*else if(fieldDataset!==null && fieldDataset!==""){
	  //fieldSet= JSON.parse(field.field_dataset);
	  //myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
		
	   // if(field.field_dataset!=="" && field.field_dataset!==null){
	
	  const myListOptionsx= JSON.parse(fieldDataset);
	  //{option.value}
	  for(let optionObj of myListOptionsx){
		if(optionObj.label!==undefined && optionObj.value!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value, labelnodel: optionObj.labelnodel});
		}else{
			//myListOptions.push({label: textLabel, value:optionObj});
		}
	  }
  }*/
  else if(ticketDataLeft!==null && ticketDataLeft.field_data_raw!=="" && field.field_data_raw!==null){
	  //fieldSet= JSON.parse(field.field_dataset);
	  //myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	  const myListOptionsx= JSON.parse(ticketDataLeft.field_data_raw); 
	  /*for(let optionObj of myListOptionsx){
		  if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.labelnodel!==undefined){
			  myListOptions.push({label: optionObj.label, value:optionObj.value, 
				labelnodel: optionObj.labelnodel, modelfieldid:optionObj.modelfieldid});  
		  }
		else if(optionObj.label!==undefined && optionObj.value!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value});
		}else{
			myListOptions.push({label: textLabel, value:optionObj});
		}
	  }*/

	  let chkCont2=0;
	  for(let optionObj of myListOptionsx){
		 if(Array.isArray(optionObj)){ 
			myBigListOptions.push(optionObj);
		 } 
		else if(optionObj.label!==undefined && optionObj.value!==undefined && optionObj.labelnodel!==undefined){
			chkCont2++;
		} 
	  }
	  
	  if(chkCont2 >0){ myBigListOptions.push([myListOptionsx]); }
  }
  /* 
  else if(fieldDataset!==null && fieldDataset!==""){  //show the predefined fields
	  //fieldSet= JSON.parse(field.field_dataset);
	  //myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
		
	   // if(field.field_dataset!=="" && field.field_dataset!==null){
	
	  const myListOptionsx= JSON.parse(fieldDataset);
	  //{option.value}
	  for(let optionObj of myListOptionsx){
		if(optionObj.label!==undefined && optionObj.value!==undefined){
			myListOptions.push({label: optionObj.label, value:optionObj.value, 
				labelnodel: optionObj.labelnodel, modelfieldid:optionObj.modelfieldid});
		}else{
			//myListOptions.push({label: textLabel, value:optionObj});
		}
	  }
  }*/
	/////////////////////////////////////////////////////////////
  
	const elementFormsErrors=this.state.elementFormsErrors;
	//elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	//const fieldDatavals=this.state.elementFormsErrors;
	//fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	//this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  
  
	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: []};
	//this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter gentxtfield ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;  xs-12 md-6
	const dynamicForm=props.dynamicForm;
	const evalregexp=field.eval_regexp;
	//const case_id=caseid; 
	const indexMain=indexField; 

	const dynamicFormAllData=this.state.dynamicFormAllData;
	const fields=dynamicFormAllData.fields; 
	const productFields=dynamicFormAllData.productFields;
	const ticketProds=dynamicFormAllData.ticketProds; //folders_products 
	const product_type_id=productField.product_type_id; 
	//const dynamicForm=this.state.dynamicForm[indexField];
	const fieldsIdsopt=dynamicFormAllData.fieldsIdsopt;
	const dynamicFormData=this.state.dynamicFormData;
	const dynamicFormDatax=dynamicFormData[indexField];
	const products=dynamicFormAllData.products;  
	const moodx=this.state.mood;
	let product=this.getProductRecord(products, product_type_id);  
	const prodId=product.name;
	let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
	let isticketProdNull=false; 
	if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
	const case_id=ticketProd.id;
	const productField2= JSON.parse(JSON.stringify(productField));  
	let showNothing=false;
	
	//consoleXlog("productField===productField", productField);
	if(productField.field_id==="124"){ //if(productField.id==="158"){
		const isTherePALawyer=this.state.isTherePALawyer; //157, 158 info
		//consoleXlog("productField===00000000000 "+isTherePALawyer);
		if(!isTherePALawyer){ showNothing=true; consoleXlog("productField===00000000111"); }
	}

	if(showNothing){
		//consoleXlog("productField===00000000222");
		return null;
	}

	 
	//"field_data_raw": "[{\"value\":\"451\",\"label\":\"Ouverture\",\"isFixed\":true},{\"value\":\"453\",\"label\":\"N\u00e9gociation\",\"isFixed\":true}]",

	const this0=this;
	const subListFieldForms = myBigListOptions!==undefined && myBigListOptions.length > 0
		  && myBigListOptions.map((itemMainListOption, iMain) => {
			myListOptions=itemMainListOption; 	   
	//consoleXlog("WOOOOOOOOOOOOOOOOOW00", fields);
	//consoleXlog("WOOOOOOOOOOOOOOOOOW00 ",iMain,  myListOptions);
	consoleXlog("WOOOOOOOOOOOOOOOOOW00 ", iMain);
	const subListFieldForms00 = myListOptions!==undefined && myListOptions.length > 0
		  && myListOptions.map((item, i) => { 
			consoleXlog("WOOOOOOOOOOOOOOOOOW111===="+i, item);
		  let fieldBlock=this0.getFieldRecord(fields, ""+item.modelfieldid) ?? {};
		//   console.log("FIELD BLOCK", fieldBlock)
		//   console.log("ITEM", item)
		//   console.log("FIELDS",fields)
		  //consoleXlog("WOOOOOOOOOOOOOOOOOW", item.modelfieldid, fieldBlock);
		  //fieldBlock.name=name+"_"+item.labelnodel; //item.name; 
		  
		//   fieldBlock.name=name+"-blockfield-"+iMain+"_"+item.labelnodel; 		   
		//   fieldBlock.text_label=item.label; 

		  //consoleXlog("WOOOOOOOOOOOOOOOOOW", item.modelfieldid, item.value, fieldBlock);
		  let x = this0.getFieldTypeId(fieldBlock?.field_type);
		  const subValueField=item.value;
		  const sublabelnodel=item.labelnodel;
		  const subindexblock=0;
		  //const ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		  const ticketDataLeftRec00={
			"id": tickeId, "product_field_id": productfieldid, "case_id": caseid, "field_data": ""+subValueField,
			"field_data_raw": "[{\"value\":\""+subValueField+"\",\"labelnodel\":\""+sublabelnodel+"\",\"indexblock\":"+subindexblock+" }]",
			"is_public": null,"creation_date": "2021-01-21 13:07:36","user_creation_id": "38","user_update_id": "38","last_update": "2021-01-21 13:09:45"
		}; 
		const ticketDataLeftRec2={
			"id": tickeId, "product_field_id": productfieldid, "case_id": caseid, "field_data": ""+subValueField,
			"field_data_raw": "", "isItBlockField": "1",
			"is_public": null,"creation_date": "2021-01-21 13:07:36","user_creation_id": "38","user_update_id": "38","last_update": "2021-01-21 13:09:45"
		}; 
		  let FieldDataq=null;
		  //return null;
		//   let product=this.getProductRecord(products, item.product_type_id);  
		//   const prodId=product.name;
		//   let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		//   let isticketProdNull=false; 
		//   if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
		  
		  //const case_id=ticketProd.id;
		  //consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		  /*if(typeListx.includes(fieldBlock.field_type)){
			  let fieldOptionsx=null;
			  //consoleXlog("QQQQQQQQQQQ222", item.field_id);
			  if(fieldsIdsopt.includes(item.field_id)){
				  //consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				  fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			  }
			   
			  FieldDataq={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }else{
			  FieldDataq={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }*/

		  const fieldBlock2= JSON.parse(JSON.stringify(fieldBlock)); 
		  fieldBlock2.name=name+"-blockfield-"+iMain+"_"+item.labelnodel; 		   
		  fieldBlock2.text_label=item.label;
		  //fieldBlock2.value=subValueField;  	  
		  const ticketDataLeftRec= JSON.parse(JSON.stringify(ticketDataLeftRec2));  	  

		  FieldDataq={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
			case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:indexMain};

		productField2.id=sublabelnodel;
  
		  //const moodx=this.state.mood;
		  let blockElem =null;
		  //const dynamicForm=this.state.dynamicForm[i];
		  //const dynamicFormDatax=dynamicFormData[i];
		  //consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		  //this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		  //if(isticketProdNull){ x=-9; } 

		  //return (<p key={"sbp"+i}>{item.label}</p>);
		  const indexMain2=indexMain+""+i;
		  //isItBlockField 
		  const dynamicFormDataxQ=JSON.parse(JSON.stringify(dynamicFormDatax));   
		  dynamicFormDataxQ.field_data=subValueField; //fix field_data on all 

		  	//return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
		  return this0.getFieldContentBlock(x, indexMain2, fieldBlock2, productField2, FieldDataq, dynamicForm, moodx, dynamicFormDataxQ);
		  const realElements=this.getFieldContentBlock(x, indexMain2, fieldBlock2, productField2, FieldDataq, dynamicForm, moodx, dynamicFormDataxQ);

		  return(
			<div  key={"qxsubtop"+indexMain2} className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
				<Accordion key={"rstcnt"+indexMain2}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"add-restcact"+indexMain2+"-content"}
					id={"prod-restc-block"+indexMain2}
					>
						<span>{"... #"+(i+1)}</span>
					</AccordionSummary>
					<AccordionDetails>
						<div className="container">{realElements}</div>
					</AccordionDetails>
				</Accordion></div>
			</div>
		  );
		 
	  }, this0); 
	//myListOptions!==null && myListOptions.map((option, ind) => (
	// for(let optionObj of myListOptions){
	// 	AddAlarmPAAAAAAAAA 
	// }
	const indexMain2=indexMain;
	const subListFieldFormsAcc=myListOptions!==undefined && myListOptions.length > 0? (
		<div  key={"qxsubtop"+indexMain2+""+iMain} className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
		<div className="containerxgf"> 
			<Accordion key={"rstcnt"+indexMain2}>
				<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-label="Expand"
				aria-controls={"add-restcact"+indexMain2+"-content"}
				id={"prod-restc-block"+indexMain2}
				>
					<span>{"... #"+(iMain+1)}</span>
				</AccordionSummary>
				<AccordionDetails>
				{(fieldid==="125" || fieldid==="126"  || fieldid==="162")?<button type="button" className="btn btn-primary" 
	  data-index={indexMain2} data-paadindex={iMain} data-fieldtrack={fieldid==="125"?"pa":fieldid==="162"?"ot":"cli"} 
	  data-xfieldid={fieldid}  onClick={this.handlePASearchLauncher}>
		<SearchIcon htmlColor="#FFFFFF" /> {t("zqwNDICItx3_x")}</button>:'' }
					<div className="container">{subListFieldForms00}</div>
				</AccordionDetails>
			</Accordion></div>
		</div>
	  ):(null);

	  return subListFieldFormsAcc;

	}, this); //end of BigList





	  return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group lstfileblckx">
	  <div key={"b1"+productField.id} className={this.colClassLeft}>
	  <label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp;  
		</label>
	    		   
		  <div key={"b1"+productField.id} className="md-12">{subListFieldForms}</div>
   
  
		</div><div className={this.colClassRight}>
		{/* <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-fileshere={0}  onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a> */}

		{/* <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-fileshere={0}  onClick={this.handleSubmitTicketDataQQQQ}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a>    */}

{((extraconfig===undefined || extraconfig===null) && extraconfig!=="one")?
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp} data-fieldblockelem={"add"}  
			onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover"><AddIcon htmlColor="#0A3CA4" /> 
		</span>
		</a>:''}

		{/* <IconButton
			aria-label="Ajouter/Add" 
			aria-haspopup="false"
			data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp} data-fieldblockelem={"add"}  
			onClick={this.handleSubmitTicketData}
			color="inherit"
			className="float-right  neosavehover"
			>
			<AddIcon htmlColor="#0A3CA4" /> 
		</IconButton> */}
		</div> 
	  </div>  
	  {productField.field_id==="130"?
	  <div className="input-group lstfileblckx"><p> &nbsp; <b>{t("zqwTSCnyrepre_x")}</b></p></div>:''  
	  }   
  </div>); 
  
  




	return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group lstfileblckx">
	  <div key={"b1"+productField.id} className={this.colClassLeft}>
	  <label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp;  
		</label>
	    	
  
		  <div key={"b1"+productField.id} className="md-12">{subListFieldForms}</div>
   
  
		</div><div className={this.colClassRight}>
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-fileshere={0}  onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a>
		<IconButton
			aria-label="Ajouter/Add" 
			aria-haspopup="false"
			data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp} data-fieldblockelem={"add"}  
			onClick={this.handleSubmitTicketData}
			color="inherit"
			className="float-right "
			>
			<AddIcon htmlColor="#0A3CA4" /> 
		</IconButton>
		</div> 
	  </div>     
  </div>);


		 
	  return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group lstfileblck">
	  <div className={this.colClassLeft}>
	  {/* <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
		error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
		inputProps={{'data-index': indexField}}
		onChange={this.handleChange2} onBlur={this.handleFocusout} /> ["contained","outlined","text"] */}
		
		{/* <span className="form-group">{textLabel}</span>   */}
		<label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp; 
		 {/* <Button variant="outlined" component="span" className="btn btn-warning">{myListOptions.length >0?"Changer Fichier":"Upload"}</Button>  */}
		 
		  {/* <span className="form-group">download file</span> */}
		  {/* {myListOptions.length >0? <span className="form-group">download file</span>:"cx"} */}
		</label>
  
		<Grid item xs={12} md={12}> 
		  <div>
			  <List dense={true}>
		{myListOptions!==null && myListOptions.map((option, ind) => (  
			  <ListItem key={"filx"+ind} className="lstfilesep">
				  <div className="md-9">
				  <ListItemAvatar>
					  <Avatar>
					  <FolderIcon />
					  </Avatar>
				  </ListItemAvatar>
				  <ListItemText
					  primary={(ind+1)+".- "+option.label}
					  // secondary={secondary ? 'Secondary text' : null}
				  /> 
				  </div><div className="md-3">
				  <ListItemSecondaryAction>
					  {(option.value!==null && option.value!=="")?
					  <>
					  <IconButton edge="end" aria-label="delete"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					  data-productfieldid={productfieldid} data-fieldid={fieldid} 
					  data-required={isRequired} data-delete={"delete"} data-filelabel={option.label}
					  data-evalregexp={""} data-deletefile={option.value}  
					  data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}   
						 onClick={this.handleOpenFilePopup}>
						  <DeleteIcon />
					  </IconButton> &nbsp; &nbsp; 
					  <IconButton edge="end" aria-label="edit label"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					  data-productfieldid={productfieldid} data-fieldid={fieldid} 
					  data-required={isRequired} data-delete={"editlabel"} data-filelabel={option.label} 
					  data-evalregexp={""} data-deletefile={option.value}  
					  data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}    
						 onClick={this.handleOpenFilePopup}>
						  <SpellcheckIcon />
					  </IconButton> &nbsp; &nbsp; 
					  <IconButton edge="end" aria-label="download"  data-fileaddress={option.value} 
					  data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
					  data-filesheredown={1} data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} 
					  onClick={this.handleDownloadFile}>
						  <DownloadIcon />
						  <CheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
					  </IconButton>  &nbsp; &nbsp;</>:'' 
					  }
					  {(option.labelnodel!==undefined && (option.value===null || option.value===""))?
					  <><input 
					  accept="*/*" className="form-group"   onChange={this.handleChangeFile} 
					  name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={option.labelnodel}    
					  />
   
  
					  <IconButton edge="end" aria-label="Send Email To require Doc"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					  data-productfieldid={productfieldid} data-fieldid={fieldid} 
					  data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={option.label} 
					  data-evalregexp={""} data-deletefile={option.value}  
					  data-labelnodel={option.labelnodel} data-senddemandingfile={1}    
						 onClick={this.handleOpenFilePopup}>
						  <EmailIcon />
					  </IconButton>
					  </>:''} 
				  </ListItemSecondaryAction></div> 
			  </ListItem> 
		  ))}
			  </List>
			</div>
		  </Grid>	
  
		
		{/* <input
			accept="**" className="form-group" style={{ display: 'none' }} 
			name={name} id={name}  type="file"  data-index={indexField}  
			/> */}
		<br />
  
		  {/* <input 
			accept="/" className="form-group"   onChange={this.handleChangeFile} 
			name={name} id={name}  type="file"  data-index={indexField}    
			/> */}
  
	   
  
	  {textMultiple==="multiple"?
		  <input accept="*/*" className="form-group" multiple  onChange={this.handleChangeFile} 
		  name={name} id={name}  type="file"  data-index={indexField}   data-multiple={indexField}     
		  data-labelnodel={0} />:''
	  }	    
  
	  {(textMultiple!=="multiple" && myListOptions.length ==0)?
		  <input 
		  accept="*/*" className="form-group"   onChange={this.handleChangeFile} 
		  name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={0}    
		  />:''
	  }
  
	  {name.indexOf("_labelnodel")> -1?
		   
		   <button type="button" className="btn btn-primary" 
		   data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		  data-productfieldid={productfieldid} data-fieldid={fieldid} 
		  data-required={isRequired} data-delete={"reqMoreDoc"} data-filelabel={""} 
		  data-evalregexp={""} data-deletefile={""}  
		  data-labelnodel={7777777}    
		   onClick={this.handleOpenFilePopup}>+ Décription Fichier/ + File Description</button>:'' 
	  }	  
   
  
		</div><div className={this.colClassRight}>
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-fileshere={1}  onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a></div> 
	  </div>     
  </div>);
  }
  

  
NeogenBlockFieldCorpo = (props) => {
	const { t } = this.props;
	const field=props.fieldBlock;  
	const productField=props.productField; 
	const FieldData=props.FieldData;
	const dataRecordNeo=props.dataRecordNeo;
	const name=(""+field.name).replace(" ", ""); 
	const textLabel=field.text_label;
	const fieldDataset=field.field_dataset;
	const textMultiple=field.mask;
	const extraconfig=field.extraconfig;
	let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
	let isRequired=false;
	let myListOptions=[]; let myBigListOptions=[]; 
	const ticketDataLeft=FieldData.ticketDataLeft;
	let fieldDataval=""; let isError=false;
	const indexField=FieldData.index;
	if(productField.id >0){ productfieldid=productField.id; }
	if(productField.field_id >0){ fieldid=productField.field_id; }
	if(productField.required >0){ isRequired=true; isError=true;} 
	if(FieldData.case_id >0){ caseid=FieldData.case_id; } 
	if(ticketDataLeft!==null && ticketDataLeft.id >0){ 
		tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data; 
		if(isRequired && fieldDataval!==""){
		  isError=false;
		}
	}

	let myBigListOptionsx={};
	const neodesks=this.state.dynamicFormAllData.neodesks;
  
	/////////////////////////////////////////////////////////////
	
	consoleXlog("NeogenBlockFieldCorpo-NeogenBlockFieldCorpo-NeogenBlockFieldCorpo ", productfieldid);
	consoleXlog("NeogenBlockFieldCorpo-- ", field, ticketDataLeft);
	consoleXlog("NeogenBlockFieldCorpo--neodesks ", field, neodesks);

	let fieldSet=null; //field.field_dataset; 
	  
	if(dataRecordNeo!=null && dataRecordNeo.id!==undefined){
		myBigListOptionsx= JSON.parse(dataRecordNeo.field_data_raw); 	  
	}	
  else if(ticketDataLeft!==null && ticketDataLeft.field_data_raw!=="" && field.field_data_raw!==null){
	  //fieldSet= JSON.parse(field.field_dataset);
	  //myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
	  myBigListOptionsx= JSON.parse(ticketDataLeft.field_data_raw); 
	  //const myListOptionsx= JSON.parse(ticketDataLeft.field_data_raw); 	  
  }else {
	consoleXlog("NeogenBlockFieldCorpo--neodesks******* ", field, neodesks);
	// if(neodesks.length >0){
	// 	let myNeodeskData=null;
	// 	for(let neodeskData of neodesks){
	// 		if(neodeskData.deskapp===field.extraconfig && neodeskData.deskapp==="1"){
	// 			myNeodeskData=neodeskData;
	// 			break;
	// 		}
	// 	}
	// 	if(myNeodeskData!==null){
	// 		const definition=myNeodeskData.definition;
	// 	}
	// }
	//consoleXlog("###NeogenBlockFieldCorpo--neodesks******* ", neodesks);
	if(neodesks.length >0){
		let myNeodeskData=null;
		for(let neodeskData of neodesks){
			consoleXlog("00###NeogenBlockFieldCorpo--neodesks******* ...0", field.extraconfig, neodeskData.deskapp, neodeskData);
			if(neodeskData.deskapp===field.extraconfig && neodeskData.desk_id==="1"){
				consoleXlog("00###NeogenBlockFieldCorpo--neodesks******* ...1");
				myNeodeskData=neodeskData;
				break;
			}
		}
		if(myNeodeskData!==null){
			//const definition=myNeodeskData.definition;
			const definition= JSON.parse(myNeodeskData.definition);
			if(definition.data!==undefined){
				definition.neo_desk_id=myNeodeskData.id;
				//myListOptions= JSON.parse(ticketDataLeft.field_data_raw);
				myBigListOptionsx= definition;
				consoleXlog("00###NeogenBlockFieldCorpo--neodesks******* WEEEEEEEE ", myListOptions);
			}
		}
	}
  }
  
	/////////////////////////////////////////////////////////////
  
	const elementFormsErrors=this.state.elementFormsErrors;
	//elementFormsErrors.push({index:indexField, required:isRequired, error:isError});
	//const fieldDatavals=this.state.elementFormsErrors;
	//fieldDatavals.push({index:indexField, id:tickeId, productfieldid:productfieldid, data:fieldDataval, data2:""});
	//this.setState({ ["elementsData"]: fieldDatavals, ["elementFormsErrors"]: elementFormsErrors});  
  
	const myObjSetter={id:tickeId, index:indexField, name:name, value:fieldDataval, 
	  error: isError, check:isRequired, options: []};
	//this.handleDynamicStateSetter(myObjSetter); 
	this.dynamicFormRefs[indexField]=React.createRef();
	//consoleXlog("addSetter gentxtfield ", myObjSetter); 
	//check: true, error: true
	//const dynamicForm=this.state.dynamicForm;  xs-12 md-6
	const dynamicForm=props.dynamicForm;
	const evalregexp=field.eval_regexp;
	//const case_id=caseid; 
	const indexMain=indexField; 

	const dynamicFormAllData=this.state.dynamicFormAllData;
	const fields=dynamicFormAllData.fields; 
	const productFields=dynamicFormAllData.productFields;
	const ticketProds=dynamicFormAllData.ticketProds; //folders_products 
	const product_type_id=productField.product_type_id; 
	//const dynamicForm=this.state.dynamicForm[indexField];
	const fieldsIdsopt=dynamicFormAllData.fieldsIdsopt;
	const dynamicFormData=this.state.dynamicFormData;
	const dynamicFormDatax=dynamicFormData[indexField];
	const products=dynamicFormAllData.products;  
	const moodx=this.state.mood;
	let product=this.getProductRecord(products, product_type_id);  
	const prodId=product.name;
	let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
	let isticketProdNull=false; 
	if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
	const case_id=ticketProd.id;
	const productField2= JSON.parse(JSON.stringify(productField));  
	let showNothing=false;
	
	//consoleXlog("productField===productField", productField);
	// if(productField.field_id==="124"){ //if(productField.id==="158"){
	// 	const isTherePALawyer=this.state.isTherePALawyer; //157, 158 info
	// 	//consoleXlog("productField===00000000000 "+isTherePALawyer);
	// 	if(!isTherePALawyer){ showNothing=true; consoleXlog("productField===00000000111"); }
	// }

	// if(showNothing){
	// 	//consoleXlog("productField===00000000222");
	// 	return null;
	// }
	let shouldRetBlock=0;

	//let subListFieldForms2=null; 
	let subListFieldForms2=[]; 
	const indexMain2=productField.id; 
	const this0=this;

	const personsGroup00=["informations_founders_", "informations_actionnaires_", 
  "informations_administrators_"];

  const personsGroup=[
	{"key":"informations_founders_", "label":"founders"}, 
    {"key":"informations_actionnaires_", "label":"actionnaires"}, 
    {"key":"informations_administrators_", "label":"administrators"}
  ];

  if(myBigListOptionsx.data===undefined){
	myBigListOptionsx.data={};
  }

  consoleXlog("NeogenBlockFieldCorpo-NeogenBlockFieldCorpo-", name, field, productField, myBigListOptionsx);

  const totListOptions=Object.entries(myBigListOptionsx.data).length;
  let qtyGo=0;  let textLabelPerson=""; let qtyGo2=0;
  const otherFieldsInfo=["informations_langue", "informations_activity", 
  "informations_actionPrice","informations_companyName", "informations_origineName",
  "informations_territoires",
  "informations_Jurisdiction","informations_accountantType","informations_capitalActions",
  "informations_otherName+","informations_otherAddress+",
  "informations_addressCompany+",
  "informations_dateEndFinancialYear"
  ,"informations_addressCompanyQuebec+","zzzz","zzzz","zzzz","zzzz","zzzz","zzzz","zzzz","zzzz"];

  let subListFieldForms3x=[];
  for (let infoObj of otherFieldsInfo) {  
    //let [fieldNameObj, objVal]=Object.entries(obj); 
	qtyGo2++;
	if(infoObj.indexOf("+")>-1){
		const smallSeckey=infoObj.replace("+","")+"_";
		let smallSec=infoObj.replace("+","");
		smallSec=smallSec.replace("informations_","");
		const pattern = new RegExp('^(' + smallSeckey + ')(.+)', 'i');  //'gi'
		const subListFieldForms4x=[];
		const textLabelSmallsec=""+t("incorpo."+smallSec); 
		let ffSmalf=0;
		for (let [fieldNameq, objq] of Object.entries(myBigListOptionsx.data)) {
			// let canShow=true;
			// if(fieldNameq==="informations_companyName" && objq==="optionDesignatingNumber"){
			// 	canShow=false;
			// }
			const resultTestq = pattern.test(fieldNameq); //if (resultTest === true) {
			const prevKeyRem=smallSeckey; //"informations_";
			if (resultTestq === true) {
				ffSmalf++;
			//if(infoObj===fieldNameq && objq!=="optionDesignatingNumber"){
			//if(infoObj===fieldNameq && canShow){
				consoleXlog(`infoooooooooGroup ${infoObj} : `);
				//const resultTest = ["_00000","_", ""+infoObj];
				const lastsFdName=fieldNameq.replace(prevKeyRem, "");
				//const resultTest = [smallSeckey, lastsFdName, ""+infoObj];
				const resultTest = [fieldNameq, fieldNameq, ""+lastsFdName];
				const realElements=getInternalFieldDivBlock(resultTest, objq, fieldNameq, productfieldid, prevKeyRem  );
				subListFieldForms4x.push(realElements);

			} 
		}
		if(ffSmalf >0){
			shouldRetBlock++;
			const qtyGox="0000"+qtyGo2+""+smallSeckey;
			const accordPersonRole02=(
			  <Accordion key={"corpo-pers-"+qtyGox+""+indexMain2}>
						  <AccordionSummary
						  expandIcon={<ExpandMoreIcon />}
						  aria-label="Expand"
						  aria-controls={"add-subcorpo-pers-"+qtyGox+""+indexMain2+"-content"}
						  id={"subcorpo-pers--block"+qtyGox+""+indexMain2}>
							  <span>{textLabelSmallsec}</span>
						  </AccordionSummary>
						  <AccordionDetails>
							  <div className="container">
								  {subListFieldForms4x}
							  </div>
						  </AccordionDetails>
					  </Accordion>
		  );
			subListFieldForms3x.push(accordPersonRole02);
		}
	}else{
      //consoleXlog(pattern, " ************cheeeeeck pattern matched!");
      for (let [fieldNameq, objq] of Object.entries(myBigListOptionsx.data)) {
		let canShow=true;
		if(fieldNameq==="informations_companyName" && objq==="optionDesignatingNumber"){
			canShow=false;
		}

		//if(infoObj===fieldNameq && objq!=="optionDesignatingNumber"){
		if(infoObj===fieldNameq && canShow){
			consoleXlog(`infoooooooooGroup ${infoObj} : `);
			const prevKeyRem="informations_";
			const resultTest = ["_00000","_", ""+infoObj];
			const realElements=getInternalFieldDivBlock(resultTest, objq, fieldNameq, productfieldid, prevKeyRem  );
			subListFieldForms3x.push(realElements);
			shouldRetBlock++;
		} 
	}
   } 
  }

  const qtyGox="0000";
  const accordPersonRole0=(
	<Accordion key={"corpo-pers-"+qtyGox+""+indexMain2}>
				<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-label="Expand"
				aria-controls={"add-subcorpo-pers-"+qtyGox+""+indexMain2+"-content"}
				id={"subcorpo-pers--block"+qtyGox+""+indexMain2}>
					<span>{"...Info"}</span>
				</AccordionSummary>
				<AccordionDetails>
					<div className="container">
						{subListFieldForms3x}
					</div>
				</AccordionDetails>
			</Accordion>
);

subListFieldForms2.push(accordPersonRole0);



  for (let obj of personsGroup) {  
    //let [fieldNameObj, objVal]=Object.entries(obj);
	let subListFieldForms2x=[];
	qtyGo++;
	//textLabelPerson=""+qtyGo+"- "+obj.label;
	textLabelPerson=""+qtyGo+"- "+t("incorpo."+obj.label); 
	let firstname="", lastname="", address="";
	let showFlagQty=0;
	//const varNameQ=t("incorpo."+fieldNamel);
	consoleXlog(`personsGroup ${obj.key}: ${obj.label}`);
    //consoleXlog(`personsGroup -- ${fieldNameObj}: ${objVal}`);
    consoleXlog(`TITLE ${obj.label}`);
    for(let goIn=0; goIn<10; goIn++){
      const keyPart=obj.key+""+goIn+"_";
	  textLabelPerson=""+(goIn+1)+"- "+t("incorpo."+obj.label);
	  showFlagQty=0; 
	  firstname="";
	  lastname="";
	  subListFieldForms2x=[];
	  subListFieldForms2x.push(null); 
	  subListFieldForms2x.push(null); 
	  subListFieldForms2x.push(null);
      //const pattern = /^${keyPart}/ig;
      //const pattern = new RegExp('^' + keyPart, 'gi');
      const pattern = new RegExp('^(' + keyPart + ')(.+)', 'i');  //'gi'
      consoleXlog(`STEP ${goIn} -- keyPart=${keyPart}`);
      //consoleXlog(pattern, " ************cheeeeeck pattern matched!");
      for (let [fieldName, obj] of Object.entries(myBigListOptionsx.data)) {
        //consoleXlog(`**************** ${fieldName}: ${obj}`);
        //if(fieldName.match(//))
        //var pattern = /^my/i;
		//declarationList certificatCheckList 
		let goOnShow=true;
		if (fieldName.indexOf("_atta_")>-1 || fieldName.indexOf("_declarationList_")>-1 
		|| fieldName.indexOf("_certificatCheckList_")>-1){ 
			goOnShow=false; 
		}

        //const resultTest = pattern.test(fieldName); //if (resultTest === true) {
        const resultTest = fieldName.match(pattern);
        if (resultTest !== null && obj!=="optionDesignatingNumber" && goOnShow===true) {   
			const prevKeyRem="informations_";
			showFlagQty++;  shouldRetBlock++;
			if(fieldName.indexOf("_firstname")>-1){	firstname=fieldName; }
			else if(fieldName.indexOf("_name")>-1){ lastname=fieldName; }
			else if(fieldName.indexOf("_street")>-1){ address=fieldName; }
		  const realElements=getInternalFieldDivBlock(resultTest, obj, fieldName, productfieldid, prevKeyRem  );

		  if(fieldName.indexOf("_firstname")>-1){ subListFieldForms2x[0]=realElements; }
		  else if(fieldName.indexOf("_name")>-1){ subListFieldForms2x[1]=realElements; }
		  else if(fieldName.indexOf("_street")>-1){ subListFieldForms2x[2]=realElements; }
		  else{
			subListFieldForms2x.push(realElements);
		  }
		  //subListFieldForms2x.push(realElements);

		  	///return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
		  //return this0.getFieldContentBlock(x, indexMain2, fieldBlock2, productField2, FieldDataq, dynamicForm, moodx, dynamicFormDataxQ);
		  //const realElements=this.getFieldContentBlock(x, indexMain2, fieldBlock2, productField2, FieldDataq, dynamicForm, moodx, dynamicFormDataxQ);

        } 

      }

	  if(showFlagQty >0){
		//subListFieldForms2.push(subListFieldForms2x); showFlagQty indexField 
		const accordPersonRole=(
			<Accordion key={"corpo-pers-"+qtyGo+goIn+""+indexMain2}>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-label="Expand"
						aria-controls={"add-subcorpo-pers-"+qtyGo+goIn+""+indexMain2+"-content"}
						id={"subcorpo-pers--block"+qtyGo+goIn+""+indexMain2}>
							<span>{"... #"+textLabelPerson}</span>
						</AccordionSummary>
						<AccordionDetails>
						{(firstname!=="" && lastname!=="" )?<button type="button" className="btn btn-primary" 
		  data-index={indexMain} data-paadindex={-1} data-firstname={firstname} data-lastname={lastname} 
						onClick={this.handleXSearchLauncher}><SearchIcon htmlColor="#FFFFFF" /> {t("zqwNDICItx3_x")}</button>:'' }
							<div className="container">
								{subListFieldForms2x}
							</div>
						</AccordionDetails>
					</Accordion>
		);
	
			//if(showFlagQty >0){
			subListFieldForms2.push(accordPersonRole);
	  }

	   
    }

	/*
	if(showFlagQty >0){
	//subListFieldForms2.push(subListFieldForms2x); showFlagQty indexField 
	const accordPersonRole=(
		<Accordion key={"corpo-pers-"+qtyGo+""+indexMain2}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"add-subcorpo-pers-"+qtyGo+""+indexMain2+"-content"}
					id={"subcorpo-pers--block"+qtyGo+""+indexMain2}>
						<span>{"... #"+textLabelPerson}</span>
					</AccordionSummary>
					<AccordionDetails>
					{(firstname!=="" && lastname!=="" )?<button type="button" className="btn btn-primary" 
	  data-index={indexMain} data-paadindex={-1} data-firstname={firstname} data-lastname={lastname} 
					onClick={this.handleXSearchLauncher}><SearchIcon htmlColor="#FFFFFF" /> {t("zqwNDICItx3_x")}</button>:'' }
						<div className="container">
							{subListFieldForms2x}
						</div>
					</AccordionDetails>
				</Accordion>
	);

		//if(showFlagQty >0){
		subListFieldForms2.push(accordPersonRole);
	}*/

	//subListFieldForms2.push(accordPersonRole);

    
  }


  if(shouldRetBlock===0){
	  //return null;
	  return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group lstfileblckx">
	  <div key={"b1"+productField.id} className={this.colClassLeft}>
	  	<label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp; </label>
	    		   
		  <div key={"b1"+productField.id} className="md-12"> 
		  	pas de données/ no data.
		  </div>   
  
		</div>
	  </div>  	  
  	</div>);
  }


	return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group lstfileblckx">
	  <div key={"b1"+productField.id} className={this.colClassLeft}>
	  <label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp;  
		</label>
	    		   
		  <div key={"b1"+productField.id} className="md-12">
			  {/* {subListFieldForms2} */}
			  {/* qqqqqqqqqqqqqqqqq zzzzzzzzzzz 4444444444 000000000000 2222222 33333333 777777 888 */}
			  {/* <br/> */}
			  <div  key={"qxsubtop"+indexMain2} className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
				<div className="containerx"> 
				<Accordion key={"rstcnt"+indexMain2}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"add-incorpo"+indexMain2+"-content"}
					id={"prod-incorpo-block"+indexMain2}>
						<span>{"... #"+textLabel}</span>
					</AccordionSummary>
					<AccordionDetails>
						<div className="container">
							{subListFieldForms2} 
						{/* qqqqqqqqqqqqqqqqq0 zzzzzz0zzzzz 4444444444 000000000000 2222222 33333333 777777 888 */}
						<br/>
						</div>
					</AccordionDetails>
				</Accordion>
				</div>
			</div>

		  </div>
   
  
		</div>
		<div className={this.colClassRight}>
		 
 
		</div> 
	  </div>  	  
  	</div>); 


	function getInternalFieldDivBlock(resultTest, obj, fieldName, productfieldid, prevKeyRem ){
		const series=resultTest[1];  const varName=resultTest[2];
		  const varNameValue=obj;
		  //const fieldIdProt=this0.getFieldIdToPrototype(varName);
		  let fieldNamel=varName;
		  fieldNamel=fieldNamel.replace(prevKeyRem, "");
		  //fieldNamel=fieldNamel.replace("informations_", ""); //, prevKeyRem="informations_";
		  const fieldIdProt=this0.getFieldIdToPrototype(fieldNamel);
		  //consoleXlog(`infoooooooooGroup00000000 ${fieldNamel} : ${varName}`);
		  //const varNameQ=t("incorpo."+fieldNamel);
		  let varNameQ="";
		  if(fieldNamel.indexOf("_")>-1){
			  const fieldNamelx2=fieldNamel.split("_");
			//varNameQ=("("+(fieldNamelx2[0]*1+1)+") ");
			if(fieldNamelx2[1]!==undefined && fieldNamelx2[1]!==null){
				varNameQ=t("incorpo."+fieldNamelx2[1]);
			}else{ varNameQ=("("+(fieldNamelx2[0]*1+1)+") "); }
		  }else{ varNameQ=t("incorpo."+fieldNamel); }
            //consoleXlog(pattern, "pattern matched! "); 
            //consoleXlog("[] pattern matched! ", resultTest[1], "2=", resultTest[2]); 
            //consoleXlog(`${fieldName}: ${obj}`);
			//consoleXlog("WOOOOOOOOOOOOOOOOOW111===="+i, item);
		  //let fieldBlock=this0.getFieldRecord(fields, ""+item.modelfieldid);
		  let fieldBlock=this0.getFieldRecord(fields, fieldIdProt); //"142" city  
		  //consoleXlog("WOOOOOOOOOOOOOOOOOW", item.modelfieldid, item.value, fieldBlock);
		  let x = this0.getFieldTypeId(fieldBlock.field_type);
		  const subValueField=varNameValue; //item.value;
		  const sublabelnodel="-1"; //varName; //item.labelnodel;
		  const subindexblock=0;
		  //const ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		  const ticketDataLeftRec00={
			"id": tickeId, "product_field_id": productfieldid, "case_id": caseid, "field_data": ""+subValueField,
			"field_data_raw": "[{\"value\":\""+subValueField+"\",\"labelnodel\":\""+sublabelnodel+"\",\"indexblock\":"+subindexblock+" }]",
			"is_public": null,"creation_date": "2021-01-21 13:07:36","user_creation_id": "38","user_update_id": "38","last_update": "2021-01-21 13:09:45"
		}; 
		const ticketDataLeftRec2={
			"id": tickeId, "product_field_id": productfieldid, "case_id": caseid, "field_data": ""+subValueField,
			"field_data_raw": "", "isItBlockField": "1",
			"is_public": null,"creation_date": "2021-01-21 13:07:36","user_creation_id": "38","user_update_id": "38","last_update": "2021-01-21 13:09:45"
		}; 
		  let FieldDataq=null;
		  //return null;
		 

		  const fieldBlock2= JSON.parse(JSON.stringify(fieldBlock)); 
		  //fieldBlock2.name=name+"-blockfield-"+iMain+"_"+item.labelnodel; 		   
		  //fieldBlock2.text_label=item.label;
		  fieldBlock2.name=name+"-bfcorpo-"+fieldName; 		   
		  fieldBlock2.text_label=varNameQ; //item.label; 
		  //fieldBlock2.value=subValueField;  	  
		  const ticketDataLeftRec= JSON.parse(JSON.stringify(ticketDataLeftRec2));  	  

		  FieldDataq={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
			case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:indexMain};

			//productField2.id=sublabelnodel;
  
		  //const moodx=this.state.mood;
		  let blockElem =null;
		  //const dynamicForm=this.state.dynamicForm[i];
		  //const dynamicFormDatax=dynamicFormData[i];
		  //consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		  //this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		  //if(isticketProdNull){ x=-9; } 

		  //return (<p key={"sbp"+i}>{item.label}</p>);
		  //const indexMain2=indexMain+""+i;
		  const indexMain2=indexMain+""+fieldName;
		   
		  const dynamicFormDataxQ=JSON.parse(JSON.stringify(dynamicFormDatax));   
		  dynamicFormDataxQ.field_data=subValueField; //fix field_data on all 

		  const realElements=this0.getFieldContentBlock(x, indexMain2, fieldBlock2, productField2, FieldDataq, dynamicForm, moodx, dynamicFormDataxQ);
	
		  return realElements;
	}




	 
	//"field_data_raw": "[{\"value\":\"451\",\"label\":\"Ouverture\",\"isFixed\":true},{\"value\":\"453\",\"label\":\"N\u00e9gociation\",\"isFixed\":true}]",

	//const this0=this;
	const subListFieldForms = myBigListOptions!==undefined && myBigListOptions.length > 0
		  && myBigListOptions.map((itemMainListOption, iMain) => {
			myListOptions=itemMainListOption; 	   
	//consoleXlog("WOOOOOOOOOOOOOOOOOW00", fields);
	//consoleXlog("WOOOOOOOOOOOOOOOOOW00 ",iMain,  myListOptions);
	consoleXlog("WOOOOOOOOOOOOOOOOOW00 ", iMain);
	const subListFieldForms00 = myListOptions!==undefined && myListOptions.length > 0
		  && myListOptions.map((item, i) => { 
			consoleXlog("WOOOOOOOOOOOOOOOOOW111===="+i, item);
		  let fieldBlock=this0.getFieldRecord(fields, ""+item.modelfieldid);
		  //consoleXlog("WOOOOOOOOOOOOOOOOOW", item.modelfieldid, fieldBlock);
		  //fieldBlock.name=name+"_"+item.labelnodel; //item.name; 
		  
		//   fieldBlock.name=name+"-blockfield-"+iMain+"_"+item.labelnodel; 		   
		//   fieldBlock.text_label=item.label; 

		  //consoleXlog("WOOOOOOOOOOOOOOOOOW", item.modelfieldid, item.value, fieldBlock);
		  let x = this0.getFieldTypeId(fieldBlock.field_type);
		  const subValueField=item.value;
		  const sublabelnodel=item.labelnodel;
		  const subindexblock=0;
		  //const ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, item.id);
		  const ticketDataLeftRec00={
			"id": tickeId, "product_field_id": productfieldid, "case_id": caseid, "field_data": ""+subValueField,
			"field_data_raw": "[{\"value\":\""+subValueField+"\",\"labelnodel\":\""+sublabelnodel+"\",\"indexblock\":"+subindexblock+" }]",
			"is_public": null,"creation_date": "2021-01-21 13:07:36","user_creation_id": "38","user_update_id": "38","last_update": "2021-01-21 13:09:45"
		}; 
		const ticketDataLeftRec2={
			"id": tickeId, "product_field_id": productfieldid, "case_id": caseid, "field_data": ""+subValueField,
			"field_data_raw": "", "isItBlockField": "1",
			"is_public": null,"creation_date": "2021-01-21 13:07:36","user_creation_id": "38","user_update_id": "38","last_update": "2021-01-21 13:09:45"
		}; 
		  let FieldDataq=null;
		  //return null;
		//   let product=this.getProductRecord(products, item.product_type_id);  
		//   const prodId=product.name;
		//   let ticketProd=this.getTicketProductRecord(ticketProds, prodId); 
		//   let isticketProdNull=false; 
		//   if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; isticketProdNull=true; }   
		  
		  //const case_id=ticketProd.id;
		  //consoleXlog("QQQQQQQQQQQ1111", item.field_id);
		  /*if(typeListx.includes(fieldBlock.field_type)){
			  let fieldOptionsx=null;
			  //consoleXlog("QQQQQQQQQQQ222", item.field_id);
			  if(fieldsIdsopt.includes(item.field_id)){
				  //consoleXlog("aaaaaazzzzzQQQQQQQQQQQ222", item.field_id);
				  fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
			  }
			   
			  FieldDataq={fieldOptions:fieldOptionsx, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }else{
			  FieldDataq={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
				  case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};
		  }*/

		  const fieldBlock2= JSON.parse(JSON.stringify(fieldBlock)); 
		  fieldBlock2.name=name+"-blockfield-"+iMain+"_"+item.labelnodel; 		   
		  fieldBlock2.text_label=item.label;
		  //fieldBlock2.value=subValueField;  	  
		  const ticketDataLeftRec= JSON.parse(JSON.stringify(ticketDataLeftRec2));  	  

		  FieldDataq={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:null, 
			case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:indexMain};

		productField2.id=sublabelnodel;
  
		  //const moodx=this.state.mood;
		  let blockElem =null;
		  //const dynamicForm=this.state.dynamicForm[i];
		  //const dynamicFormDatax=dynamicFormData[i];
		  //consoleXlog("///////####");  //consoleXlog(x, fieldBlock.field_type, fieldBlock);  //consoleXlog("///");
		  //this.elementFormsData.push({field:fieldBlock, productField:item, FieldData:FieldData}); 
		  //if(isticketProdNull){ x=-9; } 

		  //return (<p key={"sbp"+i}>{item.label}</p>);
		  const indexMain2=indexMain+""+i;
		  //isItBlockField 
		  const dynamicFormDataxQ=JSON.parse(JSON.stringify(dynamicFormDatax));   
		  dynamicFormDataxQ.field_data=subValueField; //fix field_data on all 

		  	//return this.getFieldContentBlock(x, i, fieldBlock, item, FieldData, dynamicForm, moodx, dynamicFormDatax);
		  return this0.getFieldContentBlock(x, indexMain2, fieldBlock2, productField2, FieldDataq, dynamicForm, moodx, dynamicFormDataxQ);
		  const realElements=this.getFieldContentBlock(x, indexMain2, fieldBlock2, productField2, FieldDataq, dynamicForm, moodx, dynamicFormDataxQ);

		  return(
			<div  key={"qxsubtop"+indexMain2} className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
			<div className="containerx"> 
				<Accordion key={"rstcnt"+indexMain2}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls={"add-restcact"+indexMain2+"-content"}
					id={"prod-restc-block"+indexMain2}
					>
						<span>{"... #"+(i+1)}</span>
					</AccordionSummary>
					<AccordionDetails>
						<div className="container">{realElements}</div>
					</AccordionDetails>
				</Accordion></div>
			</div>
		  );
		 
	  }, this0); 
	//myListOptions!==null && myListOptions.map((option, ind) => (
	// for(let optionObj of myListOptions){
	// 	AddAlarmPAAAAAAAAA 
	// }
	const indexMain2=indexMain;
	const subListFieldFormsAcc=myListOptions!==undefined && myListOptions.length > 0? (
		<div  key={"qxsubtop"+indexMain2+""+iMain} className="row" style={{display:'block', width:'100%', marginBottom:'10px'}}>
		<div className="containerxgf"> 
			<Accordion key={"rstcnt"+indexMain2}>
				<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-label="Expand"
				aria-controls={"add-restcact"+indexMain2+"-content"}
				id={"prod-restc-block"+indexMain2}
				>
					<span>{"... #"+(iMain+1)}</span>
				</AccordionSummary>
				<AccordionDetails>
				{(fieldid==="125" || fieldid==="126" || fieldid==="162")?<button type="button" className="btn btn-primary" 
	  data-index={indexMain2} data-paadindex={iMain} data-fieldtrack={fieldid==="125"?"pa":fieldid==="162"?"ot":"cli"} 
	  data-xfieldid={fieldid}  onClick={this.handlePASearchLauncher}><SearchIcon htmlColor="#FFFFFF" /> {t("zqwNDICItx3_x")}</button>:'' }
					<div className="container">{subListFieldForms00}</div>
				</AccordionDetails>
			</Accordion></div>
		</div>
	  ):(null);

	  return subListFieldFormsAcc;

	}, this); //end of BigList





	  return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group lstfileblckx">
	  <div key={"b1"+productField.id} className={this.colClassLeft}>
	  <label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp;  
		</label>
	    		   
		  <div key={"b1"+productField.id} className="md-12">{subListFieldForms}</div>
   
  
		</div><div className={this.colClassRight}>
		{/* <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-fileshere={0}  onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a> */}

		{/* <a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-fileshere={0}  onClick={this.handleSubmitTicketDataQQQQ}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a>    */}

{((extraconfig===undefined || extraconfig===null) && extraconfig!=="one")?
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp} data-fieldblockelem={"add"}  
			onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover"><AddIcon htmlColor="#0A3CA4" /> 
		</span>
		</a>:''}

		{/* <IconButton
			aria-label="Ajouter/Add" 
			aria-haspopup="false"
			data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp} data-fieldblockelem={"add"}  
			onClick={this.handleSubmitTicketData}
			color="inherit"
			className="float-right  neosavehover"
			>
			<AddIcon htmlColor="#0A3CA4" /> 
		</IconButton> */}
		</div> 
	  </div>  
	  {productField.field_id==="130"?
	  <div className="input-group lstfileblckx"><p> &nbsp; <b>{t("zqwTSCnyrepre_x")}</b></p></div>:''  
	  }   
  </div>); 
  
  




	return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group lstfileblckx">
	  <div key={"b1"+productField.id} className={this.colClassLeft}>
	  <label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp;  
		</label>
	    	
  
		  <div key={"b1"+productField.id} className="md-12">{subListFieldForms}</div>
   
  
		</div><div className={this.colClassRight}>
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-fileshere={0}  onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a>
		<IconButton
			aria-label="Ajouter/Add" 
			aria-haspopup="false"
			data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp} data-fieldblockelem={"add"}  
			onClick={this.handleSubmitTicketData}
			color="inherit"
			className="float-right "
			>
			<AddIcon htmlColor="#0A3CA4" /> 
		</IconButton>
		</div> 
	  </div>     
  </div>);


		 
	  return (<div className="form-group neospx" key={productField.id} ref={this.dynamicFormRefs[indexField]}>
	  <div className="input-group lstfileblck">
	  <div className={this.colClassLeft}>
	  {/* <TextField name={name} id={name} label={textLabel} defaultValue={fieldDataval} 
		error={(isRequired && fieldDataval==="")?true:false} fullWidth={true} 
		inputProps={{'data-index': indexField}}
		onChange={this.handleChange2} onBlur={this.handleFocusout} /> ["contained","outlined","text"] */}
		
		{/* <span className="form-group">{textLabel}</span>   */}
		<label htmlFor={name+'--'}>{textLabel} &nbsp; &nbsp; 
		 {/* <Button variant="outlined" component="span" className="btn btn-warning">{myListOptions.length >0?"Changer Fichier":"Upload"}</Button>  */}
		 
		  {/* <span className="form-group">download file</span> */}
		  {/* {myListOptions.length >0? <span className="form-group">download file</span>:"cx"} */}
		</label>
  
		<Grid item xs={12} md={12}> 
		  <div>
			  <List dense={true}>
		{myListOptions!==null && myListOptions.map((option, ind) => (  
			  <ListItem key={"filx"+ind} className="lstfilesep">
				  <div className="md-9">
				  <ListItemAvatar>
					  <Avatar>
					  <FolderIcon />
					  </Avatar>
				  </ListItemAvatar>
				  <ListItemText
					  primary={(ind+1)+".- "+option.label}
					  // secondary={secondary ? 'Secondary text' : null}
				  /> 
				  </div><div className="md-3">
				  <ListItemSecondaryAction>
					  {(option.value!==null && option.value!=="")?
					  <>
					  <IconButton edge="end" aria-label="delete"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					  data-productfieldid={productfieldid} data-fieldid={fieldid} 
					  data-required={isRequired} data-delete={"delete"} data-filelabel={option.label}
					  data-evalregexp={""} data-deletefile={option.value}  
					  data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}   
						 onClick={this.handleOpenFilePopup}>
						  <DeleteIcon />
					  </IconButton> &nbsp; &nbsp; 
					  <IconButton edge="end" aria-label="edit label"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					  data-productfieldid={productfieldid} data-fieldid={fieldid} 
					  data-required={isRequired} data-delete={"editlabel"} data-filelabel={option.label} 
					  data-evalregexp={""} data-deletefile={option.value}  
					  data-labelnodel={option.labelnodel===undefined?0:option.labelnodel}    
						 onClick={this.handleOpenFilePopup}>
						  <SpellcheckIcon />
					  </IconButton> &nbsp; &nbsp; 
					  <IconButton edge="end" aria-label="download"  data-fileaddress={option.value} 
					  data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					  data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
					  data-filesheredown={1} data-labelnodel={option.labelnodel===undefined?0:option.labelnodel} 
					  onClick={this.handleDownloadFile}>
						  <DownloadIcon />
						  <CheckIcon style={{ color: "green", fontWeight:"bolder" }} />	
					  </IconButton>  &nbsp; &nbsp;</>:'' 
					  }
					  {(option.labelnodel!==undefined && (option.value===null || option.value===""))?
					  <><input 
					  accept="*/*" className="form-group"   onChange={this.handleChangeFile} 
					  name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={option.labelnodel}    
					  />
   
  
					  <IconButton edge="end" aria-label="Send Email To require Doc"  data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					  data-productfieldid={productfieldid} data-fieldid={fieldid} 
					  data-required={isRequired} data-delete={"sendEmailForDoc"} data-filelabel={option.label} 
					  data-evalregexp={""} data-deletefile={option.value}  
					  data-labelnodel={option.labelnodel} data-senddemandingfile={1}    
						 onClick={this.handleOpenFilePopup}>
						  <EmailIcon />
					  </IconButton>
					  </>:''} 
				  </ListItemSecondaryAction></div> 
			  </ListItem> 
		  ))}
			  </List>
			</div>
		  </Grid>	
  
		    
  
	  {textMultiple==="multiple"?
		  <input accept="*/*" className="form-group" multiple  onChange={this.handleChangeFile} 
		  name={name} id={name}  type="file"  data-index={indexField}   data-multiple={indexField}     
		  data-labelnodel={0} />:''
	  }	    
  
	  {(textMultiple!=="multiple" && myListOptions.length ==0)?
		  <input 
		  accept="*/*" className="form-group"   onChange={this.handleChangeFile} 
		  name={name} id={name}  type="file"  data-index={indexField} data-labelnodel={0}    
		  />:''
	  }
  
	  {name.indexOf("_labelnodel")> -1?
		   
		   <button type="button" className="btn btn-primary" 
		   data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		  data-productfieldid={productfieldid} data-fieldid={fieldid} 
		  data-required={isRequired} data-delete={"reqMoreDoc"} data-filelabel={""} 
		  data-evalregexp={""} data-deletefile={""}  
		  data-labelnodel={7777777}    
		   onClick={this.handleOpenFilePopup}>+ Décription Fichier/ + File Description</button>:'' 
	  }	  
   
  
		</div><div className={this.colClassRight}>
		<a href="#" data-fieldname={name} data-id={tickeId} data-caseid={caseid} data-index={indexField} 
		 data-productfieldid={productfieldid} data-fieldid={fieldid} data-required={isRequired} 
		 data-evalregexp={evalregexp}  
		 data-fileshere={1}  onClick={this.handleSubmitTicketData}> 
		<span className="danger neosavehover">{(isRequired && fieldDataval==="")?
		<SaveOutlinedIcon htmlColor="#FF0000" />:<SaveOutlinedIcon htmlColor="#000000" />} 
		</span>
		</a></div> 
	  </div>     
  </div>);
  }
  
  /////////////////////////////////////////////////////////////////////////////////////////////////


  handleOpenFilePopup = (event) => { 
	//this.setState({ [event.target.name]: event.target.value }); //dynamicForm 
	const { name, value } = event.target; 

	const fieldname=event.currentTarget.dataset.fieldname;
	const index=event.currentTarget.dataset.index; 
	const tickeId=parseInt(event.currentTarget.dataset.id);
	const caseid=event.currentTarget.dataset.caseid;
	const productfieldid=event.currentTarget.dataset.productfieldid;
	const fieldid=event.currentTarget.dataset.fieldid;
	const required=event.currentTarget.dataset.required;
	const fileshere=event.currentTarget.dataset.fileshere; 
	const evalregexp=event.currentTarget.dataset.evalregexp;
	const editlabelText=event.currentTarget.dataset.editlabeltext;
	const deleteGo=event.currentTarget.dataset.delete;
	const deleteFileName=event.currentTarget.dataset.deletefile;
	const filelabel=event.currentTarget.dataset.filelabel; 
	const labelnodelx=event.currentTarget.dataset.labelnodel;
	let labelnodel=-2; 
	if(labelnodelx!==undefined){
		labelnodel=parseInt(labelnodelx);
	}
	 
	consoleXlog("handleOpenFilePopup labelnodel", labelnodel);
	//const tmpeventDataset={};  
	// const tmpeventDataset000 = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
	// 	field_data: elemVal, field_data_raw: field_data_rawx, 
	// 	fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id }; 

		/*    
	data-id={tickeId} data-caseid={caseid} data-index={indexField} 
					data-productfieldid={productfieldid} data-fieldid={fieldid} 
					data-required={isRequired} data-delete={"delete"} 
					data-evalregexp={""} data-deletefile={option.value} */  
	const tmpeventDataset = {id: tickeId, productfieldid: productfieldid, caseid: caseid,
		deletefile:deleteFileName, labelnodel:labelnodel, 
		required:required, fieldname:fieldname, fieldid:fieldid, index:index, filelabel:filelabel }; 
	if(deleteGo!==undefined && deleteGo!==""){ 
		tmpeventDataset.deleteFileGo=deleteGo; //"delete";  
		tmpeventDataset.delete=deleteGo;
		if(deleteGo==="editlabel" || deleteGo==="reqMoreDoc" || deleteGo==="addFollowup"){ 
			tmpeventDataset.editlabelText=editlabelText; 
		}
	}	
	 
	//this.setState({["openFileDelete"]: true, ["tmpeventDataset"]: tmpeventDataset });
	if(deleteGo!==undefined && deleteGo!==""){    
		if(deleteGo==="editlabel"){ 
			this.setState({["openFileLabelUpdate"]: true, ["tmpeventDataset"]: tmpeventDataset,
			["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
		}else if(deleteGo==="sendEmailForDoc"){ 
			this.setState({["openFileLabelUpdate"]: true, ["tmpeventDataset"]: tmpeventDataset,
			["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
		}else if(deleteGo==="reqMoreDoc"){ 
			this.setState({["openFileLabelUpdate"]: true, ["tmpeventDataset"]: tmpeventDataset,
			["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
		}else if(deleteGo==="delete"){
			this.setState({["openFileDelete"]: true, ["tmpeventDataset"]: tmpeventDataset,
			["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
		}else if(deleteGo==="addFollowup"){ 
			this.setState({["openFileLabelUpdate"]: true, ["tmpeventDataset"]: tmpeventDataset,
			["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
		}
	} 
  };
  handleClosePopup3 = (event) => {  
	//const { name, value } = event.target;  
	//this.setState({["openFileDelete"]: true }); 
  };
  closePopup3 = (event) => {  
	//const { name, value } = event.target;  
	this.setState({["openFileDelete"]: false }); 
  };
  //handleConfirmDelete
  handleClosePopup4 = (event) => {  
	//const { name, value } = event.target;  
	//this.setState({["openFileDelete"]: true }); 
	// this.setState({["openFileDelete"]: false, ["openPublicLinkForm"]: false, }); 
	 
  };
  closePopup4 = (event) => {  
	//const { name, value } = event.target;  
	//this.setState({["openFileLabelUpdate"]: false, ["openPublicLinkForm"]: false, });
	this.setState({["openFileLabelUpdate"]: false, ["openPublicLinkForm"]: false, ["openXNeoDocSendFile"]: false, }); 
	
  };

  
  handleOpenFilePopupAll = (event) => { 
	//this.setState({ [event.target.name]: event.target.value }); //dynamicForm 
	const { name, value } = event.target; 

	const fieldname=event.currentTarget.dataset.fieldname;
	const index=event.currentTarget.dataset.index; 
	const tickeId=parseInt(event.currentTarget.dataset.id);
	const caseid=event.currentTarget.dataset.caseid;
	const productfieldid=event.currentTarget.dataset.productfieldid;
	const fieldid=event.currentTarget.dataset.fieldid;
	const required=event.currentTarget.dataset.required;
	const fileshere=event.currentTarget.dataset.fileshere; 
	const evalregexp=event.currentTarget.dataset.evalregexp;
	const editlabelText=event.currentTarget.dataset.editlabeltext;
	const deleteGo=event.currentTarget.dataset.delete;
	const deleteFileName=event.currentTarget.dataset.deletefile;
	const filelabel=event.currentTarget.dataset.filelabel; 
	const labelnodelx=event.currentTarget.dataset.labelnodel;
	let labelnodel=-2; 
	if(labelnodelx!==undefined){
		labelnodel=parseInt(labelnodelx);
	}
	 
	consoleXlog("handleOpenFilePopup labelnodel", labelnodel);
	//const tmpeventDataset={};  
	 
	const tmpeventDataset = {id: tickeId, productfieldid: productfieldid, caseid: caseid,
		deletefile:deleteFileName, labelnodel:labelnodel, 
		required:required, fieldname:fieldname, fieldid:fieldid, index:index, filelabel:filelabel }; 
	if(deleteGo!==undefined && deleteGo!==""){ 
		tmpeventDataset.deleteFileGo=deleteGo; //"delete";  
		tmpeventDataset.delete=deleteGo;
		if(deleteGo==="editlabel" || deleteGo==="reqMoreDoc" || deleteGo==="addFollowup"){ 
			tmpeventDataset.editlabelText=editlabelText; 
		}
	}	
	 
	//this.setState({["openFileDelete"]: true, ["tmpeventDataset"]: tmpeventDataset });
	// if(deleteGo!==undefined && deleteGo!==""){    
	// 	if(deleteGo==="editlabel"){ 
	// 		this.setState({["openFileLabelUpdate"]: true, ["tmpeventDataset"]: tmpeventDataset,
	// 		["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
	// 	}else if(deleteGo==="sendEmailForDoc"){ 
	// 		this.setState({["openFileLabelUpdate"]: true, ["tmpeventDataset"]: tmpeventDataset,
	// 		["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
	// 	}else if(deleteGo==="reqMoreDoc"){ 
	// 		this.setState({["openFileLabelUpdate"]: true, ["tmpeventDataset"]: tmpeventDataset,
	// 		["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
	// 	}else if(deleteGo==="delete"){
	// 		this.setState({["openFileDelete"]: true, ["tmpeventDataset"]: tmpeventDataset,
	// 		["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
	// 	}else if(deleteGo==="addFollowup"){ 
	// 		this.setState({["openFileLabelUpdate"]: true, ["tmpeventDataset"]: tmpeventDataset,
	// 		["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
	// 	}
	// } 

	this.setState({["openXNeoDocSendFile"]: true, ["tmpeventDataset"]: tmpeventDataset,
			["fileLabelDescription"]: filelabel, ["labelnodel"]:labelnodel, });
  };


  FileDeletePopup = (props) => {  
    const open = this.state.openFileDelete; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	const tmpeventDataset=this.state.tmpeventDataset; 
	const fieldname=tmpeventDataset.fieldname;
	const index=tmpeventDataset.index; 
	const tickeId=parseInt(tmpeventDataset.id);
	const caseid=tmpeventDataset.caseid;
	const productfieldid=tmpeventDataset.productfieldid;
	const fieldid=tmpeventDataset.fieldid;
	const required=tmpeventDataset.required;
	const fileshere=tmpeventDataset.fileshere; 
	const evalregexp=tmpeventDataset.evalregexp;
	const editlabelText=tmpeventDataset.editlabeltext;
	const deleteGo=tmpeventDataset.delete;
	const deleteFileName=tmpeventDataset.deletefile;
	const filelabel=tmpeventDataset.filelabel; 
	const fileLabelDescription=this.state.fileLabelDescription; 
  
    return (
      <div>
        <Dialog maxWidth={"lg"} open={open} onClose={this.handleClosePopup3} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-pop3">
          <DialogContent id="form-dialog-pop3">
          <div className="text-center"> 
            <h5>Elimination du Fichier</h5>  
          </div>

		  <div className="form-group">
			  <label htmlFor="name">Vous êtes en train de confirmer l'élimination d'un fichier</label>	
			  <br/><label htmlFor="name" className="red"> 
			  '{fileLabelDescription}'</label>		 
		  </div>
			        
            <div className="text-center"> 
				<p> </p> 
				<p> </p>  
				<p> </p>  
			</div>
			<div className="form-group">
				<br/>
				<input type="button" 
				 data-id={tickeId} data-caseid={caseid} data-index={index} 
				 data-productfieldid={productfieldid} data-fieldid={fieldid} 
				 data-required={required} data-delete={"delete"} data-filelabel={fileLabelDescription}
				 data-evalregexp={""} data-deletefile={deleteFileName}  
				onClick={this.handleSubmitTicketData} className="btn btn-primary float-right" value={'Confirmer/Confirm'} /> &nbsp; &nbsp; 
            </div> 
          <div className="form-row neoxtbl">
			<div className="form-group col-md-9">
            <br/>
            <input type="button" onClick={this.closePopup3} className="btn btn-success" value={'Fermer/Close'} /> &nbsp; &nbsp; 
            </div> 
          </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }

  FileLabelUpdatePopup = (props) => {  
    const open = this.state.openFileLabelUpdate; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	const tmpeventDataset=this.state.tmpeventDataset; 
	const fieldname=tmpeventDataset.fieldname;
	const index=tmpeventDataset.index; 
	const tickeId=parseInt(tmpeventDataset.id);
	const caseid=tmpeventDataset.caseid;
	const productfieldid=tmpeventDataset.productfieldid;
	const fieldid=tmpeventDataset.fieldid;
	const required=tmpeventDataset.required;
	const fileshere=tmpeventDataset.fileshere; 
	const evalregexp=tmpeventDataset.evalregexp;
	const editlabelText=tmpeventDataset.editlabeltext;
	const actionGo=tmpeventDataset.delete;
	const deleteFileName=tmpeventDataset.deletefile;
	const filelabel=tmpeventDataset.filelabel; 
	const fileLabelDescription=this.state.fileLabelDescription; 
	const valueDescription2=this.state.fileLabelDescription2; 
	 
  //fileLabelDescription  editlabeltext
  let titlePoupup="No titile/Sans Titre";
  let btnText=""; let textLabelField=""; let textLabelField2="";
  if(actionGo==="editlabel"){
	titlePoupup="Actualisation du Label du Fichier";
	btnText='Actualiser/Update';
	textLabelField="Noveau label du fichier";
  }else  if(actionGo==="sendEmailForDoc"){
	titlePoupup="Envoi de Courriel";
	btnText='Envoyer Email';
	textLabelField="Label du fichier";
  }else  if(actionGo==="reqMoreDoc"){
	titlePoupup="Ajouter Fichier additionnel";
	btnText='Ajouter Fichier';
	textLabelField="Label du fichier";
  }
  else  if(actionGo==="addFollowup"){
	titlePoupup="Ajouter un nouveau type de suivi";
	btnText='Ajouter Type de suivi';
	textLabelField="Nom du suivi (Français)";
	textLabelField2="Nom du suivi (Anglais)";
  }

  const compoundCommand="{\"frfollowup\":\""+fileLabelDescription+"\", \"enfollowup\":\""+valueDescription2+"\" }";

   

    return (
      <div>
        <Dialog maxWidth={"lg"} open={open} onClose={this.handleClosePopup4} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-pop4">
          <DialogContent id="form-dialog-pop4">
          <div className="text-center"> 
            <h5>{titlePoupup}</h5>  
          </div>

		  {/* <div className="form-group">
			  <label htmlFor="name">Vous êtes en train d'actualiser label d'un fichier</label>	 
		  </div> */}
		{(actionGo==="addFollowup")?
		<>
		  <div className="form-group">
			  <label htmlFor="name">{textLabelField}</label><br/>
			  <input className="form-control"  onChange={this.handleChange} defaultValue={fileLabelDescription} 
			  name="fileLabelDescription"  id="fileLabelDescription"  type="text"  />		 
		  </div>
		  <div className="form-group">
			  <label htmlFor="name">{textLabelField2}</label><br/>
			  <input className="form-control"  onChange={this.handleChange} defaultValue={valueDescription2} 
			  name="fileLabelDescription2"  id="fileLabelDescription2"  type="text"  />		 
		  </div>
			        
            <div className="text-center"> 
				<p> </p>   
			</div>
			 
			<div className="form-group">
				 
				<input type="button" 
				data-id={tickeId} data-caseid={caseid} data-index={index} 
				data-productfieldid={productfieldid} data-fieldid={fieldid} data-fieldname={fieldname}
				data-required={required} data-delete={actionGo} data-filelabel={compoundCommand} 
				data-evalregexp={""} data-deletefile={deleteFileName} 
				onClick={this.handleSubmitTicketData} 
				className="btn btn-primary float-right" value={btnText} /> &nbsp; &nbsp; 
            </div>
		</>:<>
		  <div className="form-group">
			  <label htmlFor="name">{textLabelField}</label><br/>
			  <input className="form-control"  onChange={this.handleChange} defaultValue={fileLabelDescription} 
			  name="fileLabelDescription"  id="fileLabelDescription"  type="text"  />		 
		  </div> 
			        
            <div className="text-center"> 
				<p> </p>   
			</div>
			 
			<div className="form-group">
				 
				<input type="button" 
				data-id={tickeId} data-caseid={caseid} data-index={index} 
				data-productfieldid={productfieldid} data-fieldid={fieldid} 
				data-required={required} data-delete={actionGo} data-filelabel={fileLabelDescription} 
				data-evalregexp={""} data-deletefile={deleteFileName} 
				onClick={this.handleSubmitTicketData} 
				className="btn btn-primary float-right" value={btnText} /> &nbsp; &nbsp; 
            </div> </>

		}
          <div className="form-row neoxtbl">
			<div className="form-group col-md-9">
            <br/>
            <input type="button" onClick={this.closePopup4} className="btn btn-success" value={'Fermer/Close'} /> &nbsp; &nbsp; 
            </div> 
          </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }


  //NdiNeoDocSendFileOptions  handleOpenFilePopupAll = 
  NdiNeoDocSendFileOptionsPopup = (props) => {  
    const open = this.state.openXNeoDocSendFile; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	const tmpeventDataset=this.state.tmpeventDataset; 
	const fieldname=tmpeventDataset.fieldname;
	const index=tmpeventDataset.index; 
	const tickeId=parseInt(tmpeventDataset.id);
	const caseid=tmpeventDataset.caseid;
	const productfieldid=tmpeventDataset.productfieldid;
	const fieldid=tmpeventDataset.fieldid;
	const required=tmpeventDataset.required;
	const fileshere=tmpeventDataset.fileshere; 
	const evalregexp=tmpeventDataset.evalregexp;
	const editlabelText=tmpeventDataset.editlabeltext;
	const actionGo=tmpeventDataset.delete;
	const deleteFileName=tmpeventDataset.deletefile;
	const filelabel=tmpeventDataset.filelabel; 
	const fileLabelDescription=this.state.fileLabelDescription; 
	const valueDescription2=this.state.fileLabelDescription2; 
	 
  //fileLabelDescription  editlabeltext
  let titlePoupup="No titile/Sans Titre";
  let btnText=""; let textLabelField=""; let textLabelField2="";
  if(open===undefined || open==false){
	return null;
  }

   ///////////////////////////////////////////////////////////////////////////////////
   	const fullObj=this.state.dynamicFormAllData; 

	const products=fullObj.products;  
	const productFields=fullObj.productFields;  
	const fields=fullObj.fields; 
	// const fieldOptions=fullObj.fieldOptions;
	// const fieldsIdsopt=fullObj.fieldsIdsopt;
	// const fieldServices=fullObj.fieldServices;
	
	///const case_id=fullObj.case_id;
	const ticketDataLeft=fullObj.optionsData;
	const ticketProds=fullObj.ticketProds; //folders_products
	//let ticketDataLeftRec=null;
	const realticket_id=fullObj.ticket_id;
	const clientProdTransInfo=fullObj.clientProdTransInfo;
	const personType=fullObj.personType;
	const domainid=this.state.domainInLaw;
	
	// let fieldList = []; let text="";
	// let dynamicForm = []; 
	// const typeListx = ["select","checkbox","radio"];
	// let selectedFiles=[]; let valueNextStep=[];
	// const dynamicFormall=this.state.dynamicForm;
	// const dynamicFormData=this.state.dynamicFormData;
	
   let fieldBlock=this.getFieldRecord(fields, fieldid);  //fields, item.field_id
	//if(fieldBlock.field_type!=="file"){  return (null); }	
	//let x = this0.getFieldTypeId(fieldBlock.field_type);
	const ticketDataLeftRec=this.getTicketDataRecord(ticketDataLeft, productfieldid); //item.id tickeId

	//const fieldBlock2= JSON.parse(JSON.stringify(fieldBlock));   	  
	//const ticketDataLeftRec2= JSON.parse(JSON.stringify(ticketDataLeftRec));


   ///////////////////////////////////////////////////////////////////////////////////

    return (
      <div>
        <Dialog maxWidth={"lg"} open={open} onClose={this.handleClosePopup4} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-pop4">
          <DialogContent id="form-dialog-pop4">
          {/* <div className="text-center"> 
            <h5>{titlePoupup}</h5>  
          </div> */}

		  {/* <div className="form-group">
			  <label htmlFor="name">Vous êtes en train d'actualiser label d'un fichier</label>	 
		  </div> */}
		
         <NdiNeoDocSendFileOptions 
			id={tickeId} caseid={caseid} index={index} 
			productfieldid={productfieldid} fieldid={fieldid} 
			required={required} delete={actionGo} filelabel={fileLabelDescription} 
			evalregexp={""} deletefile={deleteFileName} ticket={realticket_id}
			tmpeventDataset={tmpeventDataset}  domainid={domainid}  
			fieldBlock={fieldBlock} ticketDataLeftRec={ticketDataLeftRec}
		 />

          <div className="form-row neoxtbl">
			<div className="form-group col-md-9">
            <br/>
            <input type="button" onClick={this.closePopup4} className="btn btn-success" value={'Fermer/Close'} /> &nbsp; &nbsp; 
            </div> 
          </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }
 

  getClientGoTransactionList = (theticketId = this.state.realticket_id) => {   
    let clientId=this.state.neoid; //0; //this.state.idClient;
	const listOfTickets=this.state.listOfTicketsPayments;
	consoleXlog("WITH TRANSACTION getClientTransactionList "); 
		let ticketId=this.state.realticket_id; 
		if((ticketId==="" || ticketId===0)  && theticketId >0){ 
			ticketId=theticketId; 
		} 
		if(clientId===null || clientId===""){ clientId=0; }		   
      const serachObj={clientId:clientId, ticketId:ticketId};
	  const this00=this;  
	  this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""}); 
	  Promise.resolve(this.props.getClientPaymentsFullInfo(serachObj))
      .then((response)=>{ 
      this.setState({ ["listOfTicketsPayments"]: response.NeoVentesOne, ["isLoaderOpen"]: false }); 
      return response;
      }).catch(function(error){ 
        const errObj=error;
        let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
        if(errObj.status===undefined){
          errMessage +="\n There was some error from the expected data from the server. Please try again later."
        }
        this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
      });
  }


  	//openFilePreviewPDF
	  handleCloseFilePreviewPDF  = (anyEvent) => {  
		consoleXlog("OLD STYLE SUCCESS handleCloseFilePreviewPDF ");  
		this.setState({["openFilePreviewPDF"]: false, ["openNeoSearchlist"]: false, }); 
	}
	
	handleOpenFilePreviewPDF  = (event) => { 
		event.preventDefault();
		const { t } = this.props;
		const id = this.state.id;
		const product_field_id = this.state.product_field_id;
		const case_id = this.state.case_id;
		const field_data = this.state.field_data;
		const field_data_raw = this.state.field_data_raw;
		const is_public = this.state.is_public;
		const creation_date = this.state.creation_date;
		const user_creation_id = this.state.user_creation_id;
		const user_update_id = this.state.user_update_id;
		const last_update = this.state.last_update;
		const realticket_id=this.state.realticket_id; 
		const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
		
		const fieldname=event.currentTarget.dataset.fieldname;
		const index=event.currentTarget.dataset.index;
		let tickeId=0;
		const tickeIdx=parseInt(event.currentTarget.dataset.id);
		const caseid=event.currentTarget.dataset.caseid;
		const productfieldid=event.currentTarget.dataset.productfieldid;
		const fieldid=event.currentTarget.dataset.fieldid;
		const required=event.currentTarget.dataset.required;
		const fileshere=event.currentTarget.dataset.fileshere; 

		const filesheredown=event.currentTarget.dataset.filesheredown; 
		const fileaddress=event.currentTarget.dataset.fileaddress; 
		const bucketTxt=event.currentTarget.dataset.bucket; 
		

		const dynamicForm=this.state.dynamicForm; 
		const elementObj=dynamicForm[index]; 
		const elemVal=elementObj["'"+fieldname+"'"];
		const options= dynamicForm[index]["options"];
		const field_data_rawx=options.length===0?"":JSON.stringify(options);
		let isfileshere=0;
		//this.setState({["dynamicForm"]: dynamicForm }); 
		const tickeIdNeo=parseInt(dynamicForm[index]["id"]);
		if(tickeIdNeo >0 && tickeIdx===0){ tickeId=tickeIdNeo; }else if(tickeIdx >0){ tickeId=tickeIdx; }
		const fileaddress00=fileaddress.split("/");
		const shortFilename=fileaddress00[(fileaddress00.length-1)];

		let pacx=" fieldname="+fieldname+" index="+index+" dataId="+tickeId+" caseid="+caseid+" realticket_id="+realticket_id+
		"\n productfieldid="+productfieldid+" fieldid="+fieldid+" required="+required+" zx="+elemVal+" raw="+field_data_rawx+
		" fileaddress="+fileaddress+" shortFilename="+shortFilename;
		
		//const NdiTicketDataLeft = {id: id, product_field_id: product_field_id, case_id: case_id, field_data: field_data, field_data_raw: field_data_raw, is_public: is_public, creation_date: creation_date, user_creation_id: user_creation_id, user_update_id: user_update_id, last_update: last_update, }
		//let myNdiTicketDataLeft = null;
		const myNdiTicketDataLeft = {id: tickeId, product_field_id: productfieldid, case_id: caseid, 
			field_data: elemVal, field_data_raw: field_data_rawx, 
			fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id, 
			fileaddress:fileaddress, shortFilename:shortFilename, bucket:bucketTxt }; 

		const selectedFiles=this.state.selectedFiles;
	
	
		this.setState({["openFilePreviewPDF"]: true, ["dataXFilePreview"]: myNdiTicketDataLeft }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success");
		//this.setState(this.stateInit);
		consoleXlog("OLD STYLE SUCCESS handleOpenFilePreviewPDF ");  
	
	}

	setMessageDoneAction = (props) => {  
		//this.setState({["zaaaaaaaaaaaa"]: true,  }); 
		console.log("setMessageDoneAction=", props);
		if(props!==undefined && props!==null && props.stateName!==undefined && props.stateName!==null){
		  console.log("setMessageDoneAction #1");
		  if(props.stateVal!==undefined && props.stateVal!==null){
			this.setState({[props.stateName]: props.stateVal,  });

			if(props.stateSaveAll!==undefined && props.stateSaveAll!==null  && props.stateSaveAll==="yes"){
				const changeCounter=this.state.changeCounter;
				if(changeCounter>0){
					this.handleSaveAll({});
					console.log("handleSaveAll after recherche saved setMessageDoneAction #2123");
				}
			}

			if(props.pushToParentWindow!==undefined && props.pushToParentWindow!==null  && props.pushToParentWindow==="yes"){
				const msgIframe=props; 
				msgIframe.message="HEEEEELO 00007 BACK TO SALES from NDI.... ";
				msgIframe.messageFunc="setMessageDoneAction";
				const parentWindow = window.parent;
				//try{}.catch(error => { throw(error); });

				try {
					//iframeEl.contentWindow.postMessage(msgIframe, '*');
					parentWindow.postMessage(msgIframe, "*");
					console.log("we push message to parent window setMessageDoneAction #2000");
				}
				catch(err) {
					// = err.message;
				}
			}

			//stateSaveAll: "yes", pushToParentWindow: "yes",};
			
			// const msgIframe=JSON.parse(JSON.stringify(localStorage)); 
			// msgIframe.message="HEEEEELO 00007 FROM SALES.... ";
			// iframeEl.contentWindow.postMessage(msgIframe, '*');

			console.log("setMessageDoneAction #2");
		  }
		}
	};
  
	//neoSearchData 
	handleCloseNeoSearch  = (anyEvent) => {  
		consoleXlog("OLD STYLE SUCCESS handleCloseNeoSearch ");  
		this.setState({["openNeoSearch"]: false, ["openNeoSearchlist"]: false, }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success");
		//this.setState(this.stateInit);
	
	}
	
	handleOpenNeoSearch  = (anyEvent) => {     
		this.setState({["openNeoSearch"]: true }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success");
		//this.setState(this.stateInit);
		consoleXlog("OLD STYLE SUCCESS handleOpenNeoSearch ");  
	
	}
		
	NeoSearchActions = (props) => {
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		const myLang=t("curlang");
		const openNeoSearch=this.state.openNeoSearch;
		if(!openNeoSearch){
		  return (null);
		}
		
	
		//const ticket_id=this.state.ticket_id;
		//const client_id=this.state.client_id;
		//realticket_id  client_id
		const ticket_id=this.state.realticket_id;
		const client_id=this.state.neoid; //client_id;
		const neoSearchFullName=this.state.neoSearchFullName;
	
		if(ticket_id!==null && ticket_id!=="" && client_id!==null && client_id!==""){}else{
			consoleXlog(" Uuuuuuuuups nothing to show ", ticket_id, client_id);
			return (null);
		}

		const refFuncAction=this.setMessageDoneAction;
	
		//const neoSearchData=this.state.neoSearchData;     
		//const neoSearch=this.state.neoSearchList; 
		// if(neoSearch!==undefined && neoSearch!==null && neoSearch.length >0){}else{
		//   return (null);
		// }
	
		//consoleXlog("neoSearchneoSearchneoSearchneoSearch ", neoSearch);
		consoleXlog("in NeoSearchActions neoSearchFullName ", neoSearchFullName);
		 
	
	
		//return (<NdiInterestConflict neoSearchData={neoSearch}  />);  
		//lg sm md  fullWidth={"sm"} maxWidth={"lg"} 
		//maxWidth={"sm"} 
		return ( 
			<div>
			  <Dialog maxWidth={"lg"} open={openNeoSearch} onClose={this.handleCloseNeoSearch} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
				<DialogContent id="form-dialog-confirm"> 
				  <NdiInterestConflict ticket_id={""+ticket_id} client_id={""+client_id} 
				  neoSearchFullName={neoSearchFullName} paStateFuncAction={refFuncAction} />
	  
					<div className="form-group col-md-9">
					<br/>
					<input type="button" onClick={this.handleCloseNeoSearch} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
					 
					</div>                               
				</DialogContent>
				
			  </Dialog>
			</div> 
		);
	
		// if(myLang==="fr"){
		//   return this.xHelpInfoAsOnTheSiteFr(event);
		// }else{
		//   return this.xHelpInfoAsOnTheSiteEn(event);
		// }
	}

	//NeoFilePreviewPDF
	NeoFilePreviewPDFPopup = (props) => {
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		const myLang=t("curlang");
		const openNeoSearch=this.state.openFilePreviewPDF;
		if(!openNeoSearch){
			consoleXlog(" no paaaaaaaaaaassssssssss Uuuuuuuuups nothing to show ");
		  return (null);
		}

		const dataXFilePreview=this.state.dataXFilePreview;
		
	
		//const ticket_id=this.state.ticket_id;
		//const client_id=this.state.client_id;
		//realticket_id  client_id
		const ticket_id=this.state.realticket_id;
		const client_id=this.state.neoid; //client_id;
		const neoSearchFullName=this.state.neoSearchFullName;
	
		if(ticket_id!==null && ticket_id!=="" && client_id!==null && client_id!==""){}else{
			consoleXlog(" Uuuuuuuuups nothing to show ", ticket_id, client_id);
			return (null);
		}

		let isfullScreen=true;
	
		//const neoSearchData=this.state.neoSearchData;     
		//const neoSearch=this.state.neoSearchList; 
		// if(neoSearch!==undefined && neoSearch!==null && neoSearch.length >0){}else{
		//   return (null);
		// }
	
		//consoleXlog("neoSearchneoSearchneoSearchneoSearch ", neoSearch);
		consoleXlog("NeoFilePreviewPDF - in NeoSearchActions passssssssser ", dataXFilePreview);
		 
	    //const obj={"realticket_id":props.realticket_id,"fileaddress":props.fileaddress,"shortFilename":props.shortFilename,"bucket":props.bucket};
      
	
		//return (<NdiInterestConflict neoSearchData={neoSearch}  />);  
		 //lg sm md  fullWidth={"sm"} maxWidth={"lg"} 
		//maxWidth={"sm"} 
		return ( 
			<div>
				<input type="button" onClick={this.handleCloseFilePreviewPDF} className="btn btn-danger float-right" value={'X2'} />
			  <Dialog maxWidth={"lg"}  fullScreen={isfullScreen} open={openNeoSearch} onClose={this.handleCloseFilePreviewPDF} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-pdfpreview">
				<DialogContent id="form-dialog-pdfpreview"> 
				<input type="button" onClick={this.handleCloseFilePreviewPDF} className="btn btn-danger float-right" value={'X'} />
				  <NeoFilePreviewPDF ticket_id={""+ticket_id} client_id={""+client_id} 
				  neoSearchFullName={neoSearchFullName} dataXFile={dataXFilePreview}  />
	  
					<div className="form-group col-md-9">
					<br/>
					<input type="button" onClick={this.handleCloseFilePreviewPDF} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
					 
					</div>                               
				</DialogContent>
				
			  </Dialog>
			</div> 
		);
	
		// if(myLang==="fr"){
		//   return this.xHelpInfoAsOnTheSiteFr(event);
		// }else{
		//   return this.xHelpInfoAsOnTheSiteEn(event);
		// }
	}

	
	handlePASearchLauncher  = (event) => {     
		//this.setState({["openNeoSearch"]: true }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success");
		//this.setState(this.stateInit); 37,38   125: 1252,1251
		const index=event.target.dataset.index;
		const paadindex=event.target.dataset.paadindex;
		const fieldtrack=event.target.dataset.fieldtrack;
	
		const dynamicForms=this.state.dynamicForm;
		//if(dynamicForm[index]["options"]===null){ dynamicForm[index]["options"]=[]; }
		//const options= dynamicForm[index]["options"]; 
		let myNdiTicketDataLeftList=[];
		const realticket_id=this.state.realticket_id;
		let counter=0;
		const dynamicFormData=this.state.dynamicFormData;
					 
		// consoleXlog(data); 
		// consoleXlog("single block data ");  
		//const datax=JSON.parse(JSON.stringify(data));
		const dynamicFormAllData=this.state.dynamicFormAllData;
		const fieldList=dynamicFormAllData.fields;  
		const productFields=dynamicFormAllData.productFields;  
		const client_type=this.state.client_type;
		const idNeoId=this.state.neoid; // 0;
		const anyField= dynamicForms[index]; 
		const anyField2= dynamicForms[(parseInt(index)+1)]; 
		let fullNameSearch=""; 
		if(paadindex==="-3"){
			//consoleXlog("handlePASearchLauncher-QQ ", index, anyField["id"],anyField2["id"], "tx",anyField.firstname_pa, anyField["'firstname_pa'"], anyField2["'lastname_pa'"], "#########",anyField["firstname_pa"], anyField2["lastname_pa"]); 
			//fullNameSearch=""+anyField["'firstname_pa'"]+" "+anyField2["'lastname_pa'"];
			if(fieldtrack==="cli"){
				fullNameSearch=""+anyField["'firstname'"]+" "+anyField2["'lastname'"]; 
			}else if(fieldtrack==="ot"){
				fullNameSearch=""+anyField["'firstname'"]+" "+anyField2["'lastname'"]; 
			}else{
				fullNameSearch=""+anyField["'firstname_pa'"]+" "+anyField2["'lastname_pa'"]; 
			} 
		}else{
			///fullNameSearch=""+anyField["options"][paadindex][1]["value"]+" "+anyField["options"][paadindex][0]["value"]; 
			// if(fieldtrack==="cli"){
			// 	fullNameSearch=""+anyField["options"][paadindex][1]["value"]+" "+anyField["options"][paadindex][0]["value"]; 
			// }else{
			// 	fullNameSearch=""+anyField["options"][paadindex][1]["value"]+" "+anyField["options"][paadindex][0]["value"]; 
			// } 

			fullNameSearch=""+anyField["options"][paadindex][1]["value"]+" "+anyField["options"][paadindex][0]["value"]; 
		}
	
	
		// consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher ", index, paadindex);  
		// consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher ", dynamicFormAllData);   
		// consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher ", dynamicForms); 
		// consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher ", index, anyField,anyField2, anyField["firstname_pa"], anyField2["lastname_pa"]); 
		consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher-fullNameSearch ", fullNameSearch); 
	
		this.setState({["openNeoSearch"]: true, ["neoSearchFullName"]: fullNameSearch }); 
	}

	handleXSearchLauncher  = (event) => {     
		//this.setState({["openNeoSearch"]: true }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success");
		//this.setState(this.stateInit); 37,38   125: 1252,1251
		const index=event.target.dataset.index;
		const paadindex=event.target.dataset.paadindex;
		const firstname=event.target.dataset.firstname;
		const lastname=event.target.dataset.lastname;
	
		const dynamicForms=this.state.dynamicForm;
		//if(dynamicForm[index]["options"]===null){ dynamicForm[index]["options"]=[]; }
		//const options= dynamicForm[index]["options"]; 
		let myNdiTicketDataLeftList=[];
		const realticket_id=this.state.realticket_id;
		let counter=0;
		const dynamicFormData=this.state.dynamicFormData;
					 
		// consoleXlog(data); 
		// consoleXlog("single block data ");  
		//const datax=JSON.parse(JSON.stringify(data));
		const dynamicFormAllData=this.state.dynamicFormAllData;
		const fieldList=dynamicFormAllData.fields;  
		const productFields=dynamicFormAllData.productFields;  
		const client_type=this.state.client_type;
		const idNeoId=this.state.neoid; // 0;
		const anyField= dynamicForms[index]; 
		const anyField2= dynamicForms[(parseInt(index)+1)]; 
		consoleXlog("***single block data handleXSearchLauncher ", index, anyField);  
		const options=anyField["options"];


		//////////////////////////////
		// const dynamicForm=this.state.dynamicForm;
		// const myobj={};
		// consoleXlog("handleChange2Corpo ", index, event.target.name, event.target.value, "OOOObjQQQ", dynamicForm);

		// const options=dynamicForm[index]["options"];
		// consoleXlog("handleChange2Corpo 0000Sux222 ", index, namex, nameSubkey, options.data[""+nameSubkey], value, options);
		// if(options.data[""+nameSubkey]!==undefined){
		// 	options.data[""+nameSubkey]= value;
		// 	//dynamicForm[index]["options"]=options;
		// 	consoleXlog("handleChange2Corpo 0000Sux000 ", index, namex, nameSubkey);
		// }
		//////////////////////////////


		consoleXlog("single block data handleXSearchLauncher ");  
		consoleXlog("single  firstname: ", options.data[firstname]);  
		consoleXlog("single  lastname: ", options.data[lastname]);
		//return false;  


		let fullNameSearch=""; 
		if(options.data[firstname]!==undefined && options.data[lastname]!==undefined){
			fullNameSearch=""+options.data[firstname]+" "+options.data[lastname]; 
		}else if(options.data[firstname]!==undefined && options.data[firstname]!==null  && options.data[firstname]!==""){
			fullNameSearch=""+options.data[firstname]; 
		}else if(options.data[lastname]!==undefined && options.data[lastname]!==null  && options.data[lastname]!==""){
			fullNameSearch=""+options.data[lastname]; 
		}

		if(fullNameSearch===""){ return false; }

		// if(paadindex==="-3"){
		// 	//consoleXlog("handlePASearchLauncher-QQ ", index, anyField["id"],anyField2["id"], "tx",anyField.firstname_pa, anyField["'firstname_pa'"], anyField2["'lastname_pa'"], "#########",anyField["firstname_pa"], anyField2["lastname_pa"]); 
		// 	fullNameSearch=""+anyField["'firstname_pa'"]+" "+anyField2["'lastname_pa'"]; 
		// }else{
		// 	fullNameSearch=""+anyField["options"][paadindex][1]["value"]+" "+anyField["options"][paadindex][0]["value"]; 
		// }
	
	
		// consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher ", index, paadindex);  
		// consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher ", dynamicFormAllData);   
		// consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher ", dynamicForms); 
		// consoleXlog("OLD STYLE SUCCESS handlePASearchLauncher ", index, anyField,anyField2, anyField["firstname_pa"], anyField2["lastname_pa"]); 
		consoleXlog("OLD STYLE SUCCESS handleXSearchLauncher-fullNameSearch ", fullNameSearch); 
	
		this.setState({["openNeoSearch"]: true, ["neoSearchFullName"]: fullNameSearch }); 
	}


	
	handleLoadNeoDocNotes = (event) => {   
		//const serachObj={search:search, searchEmail:""};  // /notesfields
		const theticketId=this.state.realticket_id; 
		let clientId=this.state.neoid; //0; //this.state.idClient;
		const listOfTickets=this.state.listOfTicketsPayments;
		consoleXlog("WITH TRANSACTION getNeoDocNotes "); 
		//if(listOfTickets.length==0){
			let ticketId=this.state.realticket_id; 
			if((ticketId==="" || ticketId===0)  && theticketId >0){ 
				ticketId=theticketId; 
			} 
			if(clientId===null || clientId===""){ clientId=0; }		   
		  //const serachObj={clientId:clientId, ticketId:ticketId};
		  const serachObj={form_name:"", ticket:ticketId};
		  const this00=this;  
		  consoleXlog("QQWITH TRANSACTION getNeoDocNotes "); 
		  this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""}); 
		  //getClientPaymentsFullInfo
		  //Promise.resolve(this.props.getClientTransactionsFullInfo(serachObj))
		  Promise.resolve(this.props.getNeoDocNotes(serachObj))
		  .then((response)=>{ 
			consoleXlog('getNeoDocNotes  '); consoleXlog(response); 
			//const resp=response;  notesList
			const allFieldsNotes={notesList:response.NdiFieldsOne};
			this.setState({ ["allFieldsNotes"]: allFieldsNotes, ["isLoaderOpen"]: false, ["allFieldsNotesgo"]:3 });
		  	//this.setState({ ["allFieldsNotes"]: response.NdiFieldsOne, ["isLoaderOpen"]: false }); 
		  	//this.setState({ ["isLoaderOpen"]: false});  
		  return response;
		  }).catch(function(error){ 
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
			  errMessage +="\n There was some error from the expected data from the server. Please try again later."
			}
			this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			 
			consoleXlog("ERROR WITH TRANSACTION"); 
			consoleXlog(error);
			consoleXlog("SHORT ERROR MESSAGE: "+errMessage);
			consoleXlog("RWA RESPONSE");  
		  });
		//}
		
	  }


//   gotoSendPublicForm = () => {

//   }

  gotoSendPublicForm = (event) => {  
	//const { name, value } = event.target;  publicLinkFormOrFile:1, publicFormOrFile:{},
	//this.setState({["openPublicLinkForm"]: true }); 
	this.setState({["openPublicLinkForm"]: true, publicLinkFormOrFile:1, publicFormOrFile:{}, }); 
  };

  gotoSendPublicFormFile = (event) => {  
	//const { name, value } = event.target;  publicLinkFormOrFile:1, publicFormOrFile:{},
	// const index=event.target.dataset.index;
	// const fieldid=event.target.dataset.fieldid;
	// const productfieldid=event.target.dataset.productfieldid;
	// const datarecordid=event.target.dataset.datarecordid;
	// const labelnodel=event.target.dataset.labelnodel;
	// const labelname=event.target.dataset.labelname;
	// const fileaddress=event.target.dataset.fileaddress;

	const index=event.currentTarget.dataset.index;
	const fieldid=event.currentTarget.dataset.fieldid;
	const productfieldid=event.currentTarget.dataset.productfieldid;
	const datarecordid=event.currentTarget.dataset.datarecordid;
	const labelnodel=event.currentTarget.dataset.labelnodel;
	const labelname=event.currentTarget.dataset.filelabel;
	const fileaddress=event.currentTarget.dataset.fileaddress;
	
	const publicFormOrFile={productfieldid:productfieldid,fieldid:fieldid,index:index,
		datarecordid:datarecordid, labelnodel:labelnodel, labelname:labelname, 
		fileaddress:fileaddress};
	//this.setState({["openPublicLinkForm"]: true });
	this.setState({ publicLinkFormOrFile:2, 
	publicFormOrFile:publicFormOrFile, ["openPublicLinkForm"]: true,});  

	console.log("publicFormOrFile=", publicFormOrFile);
  };

  PublicLinkFormPopup = (props) => {  
    const open = this.state.openPublicLinkForm; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	const tmpeventDataset=this.state.tmpeventDataset; 
	const fieldname=tmpeventDataset.fieldname;
	const index=tmpeventDataset.index; 
	const currentTic= this.state.realticket_id; 
	const dynamicFormAllData=this.state.dynamicFormAllData;
	const formOrFile = this.state.publicLinkFormOrFile; 
	const publicFormOrFile = this.state.publicFormOrFile; 
		 
	 
   

    return (
      <div>
        <Dialog maxWidth={"lg"} open={open} onClose={this.handleClosePopup4} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-pop4">
          <DialogContent id="form-dialog-pop4">
          <div className="text-center"> 
            <h5>{t("zqwNFFrmZn09_x")}</h5>  
          </div>

		  {/* <div className="form-group">
			  <label htmlFor="name">Vous êtes en train openPublicLinkForm openPublicLinkForm </label>	 
		  </div>  */}
		  <PublicLinkForm currentTicket={currentTic} dynamicFormAllData={dynamicFormAllData} 
		  formOrFile={formOrFile} publicFormOrFile={publicFormOrFile} /> 
		 
		  {/* <div className="form-group">
			  <label htmlFor="name">{textLabelField}</label><br/>
			  <input className="form-control"  onChange={this.handleChange} defaultValue={fileLabelDescription} 
			  name="fileLabelDescription"  id="fileLabelDescription"  type="text"  />		 
		  </div>
		  <div className="form-group">
			  <label htmlFor="name">{textLabelField2}</label><br/>
			  <input className="form-control"  onChange={this.handleChange} defaultValue={valueDescription2} 
			  name="fileLabelDescription2"  id="fileLabelDescription2"  type="text"  />		 
		  </div>
			        
            <div className="text-center"> 
				<p> </p>   
			</div>
			 
			<div className="form-group">
				 
				<input type="button" 
				data-id={tickeId} data-caseid={caseid} data-index={index} 
				data-productfieldid={productfieldid} data-fieldid={fieldid} data-fieldname={fieldname}
				data-required={required} data-delete={actionGo} data-filelabel={compoundCommand} 
				data-evalregexp={""} data-deletefile={deleteFileName} 
				onClick={this.handleSubmitTicketData} 
				className="btn btn-primary float-right" value={btnText} /> &nbsp; &nbsp; 
            </div>
		 
		  <div className="form-group">
			  <label htmlFor="name">{textLabelField}</label><br/>
			  <input className="form-control"  onChange={this.handleChange} defaultValue={fileLabelDescription} 
			  name="fileLabelDescription"  id="fileLabelDescription"  type="text"  />		 
		  </div>  */}
			        
            <div className="text-center"> 
				<p> </p>   
			</div>
			 
			{/* <div className="form-group">
				 
				<input type="button" 
				data-id={tickeId} data-caseid={caseid} data-index={index} 
				data-productfieldid={productfieldid} data-fieldid={fieldid} 
				data-required={required} data-delete={actionGo} data-filelabel={fileLabelDescription} 
				data-evalregexp={""} data-deletefile={deleteFileName} 
				onClick={this.handleSubmitTicketData} 
				className="btn btn-primary float-right" value={btnText} /> &nbsp; &nbsp; 
            </div>  */}

		 
          <div className="form-row neoxtbl">
			<div className="form-group col-md-9">
            <br/>
            <input type="button" onClick={this.closePopup4} className="btn btn-success" value={'Fermer/Close'} /> &nbsp; &nbsp; 
            </div> 
          </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }

  getFieldIdToPrototype = (name) => {  
	//const { name, value } = event.target;  
	//this.setState({["openPublicLinkForm"]: true });
	//const fiedList=["postal","open","open","open","open"];
	//const fiedList=["postal","Birthday","province","gender","startDate","certificatDate","open","open"];
	//const fiedListId=["35","1","36","139","1","1","142","142"];
	const fieldList=["postal","Birthday","province-zx","gender","startDate","certificatDate","langue","open2","open3"];
	//1:dateechance type dateTime, 74:zen_Date_Prescription type date
	const fiedListId=["35","1","36","139","1","1","138","142","142"];
	const indexFound=fieldList.indexOf(name);
	//if(fieldList.includes())
	if(indexFound >-1){
		return fiedListId[indexFound];
	}

	return "142";
  };

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

}

//const TransNdiFieldsForm = withTranslation()(NdiFieldsFormzendesk); 
const mapStateToProps = (state) => ({ NdiFields: state.NdiFields });

const mapDispatchToProps = { addOrUpdateNdiFields, getNdiFields, getProductFields, getTicketSummary, 
	addOrUpdateTicketData, downloadFileTicketData,
	getClientSearch, getAllProducts, getClientTransactions, addFoldersProducts, getNeoDocNotes, 
	getClientTransactionsFullInfo, getClientPaymentsFullInfo, downloadFileInvoiceTicketData,downloadFileJudiciaryInvoice,downloadFileFideicommis };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NdiFieldsFormzendesk));
