import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getAllProducts, getAllProducts0 } from "../../actions/NeoVentesAction";
import { genLang } from "../../composables/genLang";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { SwapVert } from "@material-ui/icons";
import axios from "axios";
import { decryptText } from "../../appEncryption";
const URL = process.env.REACT_APP_API_URL;

export function ChangeFolderProduct({ ticketId, toast, fetchData }) {
  const [product, setProduct] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getProducts();
  }, [open, dispatch]);

  async function getProducts() {
    dispatch(getAllProducts0()).then((response) => {
      const prods =
        response?.NeoVentes?.products.map((i) => ({
          label: i.titre,
          value: i.id,
        })) ?? [];
      setProductsList(prods);
    });
  }

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(URL + "/ChangeProductInFolder", {
        selected_product_id: product,
        ticket_id: Number(ticketId),
      })
      .then((res) => {
        setOpen(false);
        toast(
          genLang({
            en: "Product changed successfully",
            fr: "Produit modifié avec succès",
          }),
          "success"
        );
        fetchData();
      })
      .catch((err) => {
        console.error(err);
        toast(genLang({ en: "Error", fr: "Erreur" }), "error");
      })
      .finally(() => setLoading(false));
  };

  const storedUser = localStorage.getItem("user");
  let userType = "";
  if (storedUser) {
    const decryptedUser = decryptText(storedUser).split(":");
    userType = decryptedUser?.[3] ?? "";
  }

  if (!userType || userType === "elawyer") return null;

  return (
    <>
      <Button color="primary" outline onClick={() => setOpen(!open)} size="sm">
        <SwapVert />
        {genLang({
          en: "Change the product without changing the price",
          fr: "Changer de produit sans changer le prix",
        })}
      </Button>
      <Modal isOpen={open} size="lg" centered>
        <ModalHeader toggle={() => setOpen(false)}>
          {genLang({
            en: "Change product of folder",
            fr: "Changer le produit actuel du dossier",
          })}
        </ModalHeader>
        <ModalBody>
          <form className="d-flex flex-column" style={{ gap: "1rem" }}>
            <div>
              <label>
                {genLang({ en: "New product", fr: "Nouveau produit" })}
              </label>
              <Select
                options={productsList}
                onChange={(e) => setProduct(e ? e.value : "")}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={!product}
            block
          >
            {loading ? (
              <Spinner size="sm" />
            ) : (
              genLang({ en: "Submit", fr: "Envoyer" })
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
